import Vue from 'vue';

// Import and register directives
const directives = {
    mask: require('./mask').default,
    uppercase: require('./uppercase').default,
    lowercase: require('./lowercase').default,
    'test-id': require('./testId').default
};

for (const [name, directive] of Object.entries(directives)) {
    Vue.directive(name, directive);
}
