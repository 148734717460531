import Inputmask from 'inputmask';
import getInput from '@/lib/helpers/getInput';

export default {
    inserted: (el, binding) => {
        const input = getInput(el);

        if (binding.value && input) {
            new Inputmask(binding.value).mask(input);
        }
    },
    update: (el, binding) => {
        const input = getInput(el);

        if (binding.value && input) {
            if (JSON.stringify(binding.oldValue) !== JSON.stringify(binding.value)) {
                new Inputmask(binding.value).mask(input);
            }
        }
    }
};
