import { BaseModel } from '@/models';
import AddressTypeEnum from '@/enums/addressType';
import i18n from '@/lib/i18n';
import store from '@/store';

export class Address extends BaseModel {
    static entity = 'addresses';

    static fields() {
        return {
            ...super.fields(),
            type: this.enum(AddressTypeEnum, AddressTypeEnum.NONE).nullable(),
            start_on: this.string(null).nullable(),
            end_on: this.string(null).nullable(),
            line1: this.string(null).nullable(),
            line2: this.string(null).nullable(),
            city: this.string(null).nullable(),
            region: this.string(null).nullable(),
            postcode: this.string(null).nullable(),
            country: this.string(null).nullable(),
            default: this.boolean(false).nullable()
        };
    }

    get country_name() {
        if (!this.country) {
            return null;
        }

        const country = store.getters['countries/countryFromAlpha2Code'](this.country);

        if (!country) {
            return null;
        }

        return country.name;
    }

    get is_valid() {
        return [this.line1, this.city, this.postcode, this.country].every(v => v && v.length > 0);
    }

    get computed_name() {
        if (this.line1) {
            return [this.line1, this.line2, this.city, this.region, this.country_name, this.postcode]
                .filter(v => v)
                .join(', ');
        }

        return i18n.t('address');
    }
}

export default Address;
