import getValidator from '@/lib/helpers/getValidator';

export const getValidatorFromString = validationConfig => {
    let validator = () => false;
    let args = [];

    // e.g. { validationConfig: true }
    if (validationConfig === true || validationConfig === 'true') {
        validator = value => value === true;
    }

    // e.g. { validationConfig: false }
    else if (validationConfig === false || validationConfig === 'false') {
        validator = value => value === false;
    }

    // e.g. { validationConfig: 'required' }
    //      { validationConfig: 'max:3' }
    //      { validationConfig: () => customValidator() }
    else {
        const split = validationConfig.split(':');

        if (split.length > 1) {
            args = split[1].split(',');
        }

        if (typeof split[0] === 'string') {
            validator = getValidator(split[0]);
        } else if (typeof split[0] === 'function') {
            validator = split[0];
        }
    }

    return { validator, args };
};

export default getValidatorFromString;
