import { MediaCollection, MediaMarketing } from '@/models';
import i18n from '@/lib/i18n';
import MediaFormatEnum from '@/enums/media/format';
import MediaMarketingTypeEnum from '@/enums/media/marketing/type';

export class MediaMarketingCollection extends MediaCollection {
    static type = 'MediaMarketingCollection';
    static endpoint = MediaMarketing.api.index;
    static model = MediaMarketing;

    static fields() {
        return {
            ...super.fields(),
            offer_id: this.string(null).nullable(),
            product_id: this.string(null).nullable()
        };
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                filterable: true,
                width: '14rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                sortable: true,
                width: '20rem'
            }),
            this.createHeader('description', i18n.t('description'), {
                width: '18rem',
                format: 'wysiwyg'
            }),
            this.createHeader('document_date', i18n.t('document_date'), {
                filterable: true,
                width: '10rem',
                format: 'datetime'
            }),
            this.createHeader('format', i18n.t('format'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: MediaFormatEnum,
                enumA11yKey: 'enums.media_format'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '14rem',
                format: 'enum',
                enum: MediaMarketingTypeEnum,
                enumA11yKey: 'enums.media_marketing_type'
            }),
            this.createHeader('type_name', i18n.t('offer_or_product'), {
                width: '15rem',
                transform: data => {
                    if (data.item.offer) {
                        return data.item.offer.name;
                    }
                    if (data.item.product) {
                        return data.item.product.name;
                    }
                    return null;
                },
                to: data => {
                    if (data.item.offer) {
                        return {
                            name: 'Offer',
                            query: { offer_id: data.item.offer.id }
                        };
                    }

                    return null;
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.offer_id) {
            // filters.offer_id = this.filterOperator('is') + ':' + this.offer_id;
            filters.offer_id = this.offer_id;
        }

        if (this.product_id) {
            // filters.product_id = this.filterOperator('is') + ':' + this.product_id;
            filters.product_id = this.product_id;
        }

        if (this.media_format) {
            filters.format = this.media_format_filter_value;
        }

        if (this.media_type) {
            filters.type = this.media_type_filter_value;
        }

        return filters;
    }

    get preset_attr() {
        let attr = {};

        if (this.offer_id) {
            attr.offer_id = this.offer_id;
        }

        if (this.product_id) {
            attr.product_id = this.product_id;
        }

        if (this.media_format) {
            attr.format = this.media_format;
        }

        if (this.media_type) {
            attr.type = this.media_type;
        }

        return attr;
    }
}

export default MediaMarketingCollection;
