import { Collection, Fund } from '@/models';
import i18n from '@/lib/i18n';

export class FundCollection extends Collection {
    static type = 'FundCollection';
    static endpoint = Fund.api.index;
    static model = Fund;

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('status', i18n.t('status'), {
                classList: data => {
                    if (data.value === 'EVERGREEN') {
                        return 'success--text text--darken-1 font-weight-bold';
                    } else if (data.value === 'OPEN') {
                        return 'info--text text--darken-2 font-weight-bold';
                    } else if (data.value === 'CLOSED') {
                        return 'error--text text--darken-2 font-weight-bold';
                    }

                    return null;
                },
                width: '7rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default FundCollection;
