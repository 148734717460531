const copyToClipboard = textToCopy => {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(textToCopy);
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;

        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error('Unable to copy value:', textToCopy, error);
        }

        document.body.removeChild(textArea);
    }
};

export default copyToClipboard;
