import toNumber from './toNumber';
import isObject from 'lodash/isObject';

/**
 * Percentage Formatting
 * @param {Number} value
 * @returns String
 */
const toPercentage = (value, decimals = 2, abbreviate = false) => {
    let options = {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: isObject(decimals) ? 2 : decimals
    };

    if (isObject(decimals)) {
        options = {
            ...options,
            ...decimals
        };
    }

    if (abbreviate) {
        options = {
            ...options,
            notation: 'compact',
            compactDisplay: 'short'
        };
    }

    return toNumber(value, options);
};

export default toPercentage;
