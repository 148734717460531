import { BaseModel, Investor, Adviser, User } from '@/models';
import InvestorApi from '@/api/InvestorApi';
import i18n from '@/lib/i18n';
import getFilterOperator from '@/lib/helpers/getFilterOperator';

export class InvestorAdviserRelationship extends BaseModel {
    static entity = 'investorAdviserRelationship';
    static Api = InvestorApi;

    static primaryKey = ['investor_id', 'adviser_id'];

    static fields() {
        return {
            ...super.fields(),
            advised: this.boolean(false),
            visibility_groups: this.attr([]).nullable(),
            investment_account_application_signed_at: this.string(null).nullable(),
            investment_account_opened_at: this.string(null).nullable(),
            investment_account_applied_at: this.string(null).nullable(),
            investment_account_authorised_at: this.string(null).nullable(),
            investment_account_authorised_set_at: this.string(null).nullable(),
            investment_account_authorised_set_by_id: this.string(null).nullable(),
            investment_account_authorised_set_by: this.belongsTo(User, 'investment_account_authorised_set_by_id'),
            investment_account_authorised_signoff_by_id: this.string(null).nullable(),
            investment_account_authorised_signoff_by: this.belongsTo(
                User,
                'investment_account_authorised_signoff_by_id'
            ),

            primary_contact_id: this.string(null).nullable(),
            primary_contact: this.belongsTo(User, 'primary_contact_id'),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id')
        };
    }

    get id() {
        return `${this.investor_id || 'INVESTOR_ID'}-${this.adviser_id || 'ADVISER_ID'}`;
    }

    get has_applied() {
        return !!this.investment_account_applied_at;
    }

    get is_authorised() {
        return !!this.investment_account_authorised_at;
    }

    get has_visibility_groups() {
        return this.visibility_groups && this.visibility_groups.length > 0;
    }

    async $get(options = {}) {
        this.$loading = true;

        return await this.constructor.$get(this.investor_id, this.adviser_id, options).finally(() => {
            this.$loading = false;
        });
    }
    static async $get(investor_id, adviser_id, options = {}) {
        if (!investor_id) {
            throw new ReferenceError(`Invalid Investor ID when calling $get on model ${this.name}`);
        }

        if (!adviser_id) {
            throw new ReferenceError(`Invalid Adviser ID when calling $get on model ${this.name}`);
        }

        options = {
            force: options.force || false,
            type: options.type || 'DETAIL',
            params: options.params || {}
        };

        if (!options.force) {
            let modelInstance = this.findBy({ investor_id, adviser_id });

            if (modelInstance && modelInstance.$responseFormat >= this.ApiResponseFormatEnum[options.type]) {
                return modelInstance;
            }
        }

        const params = {
            ...options.params
        };

        if (options.type === 'FULL') {
            params.full_object = true;
        } else if (options.type === 'SUMMARY') {
            params.summary_object = true;
        }

        await this.api.getAdviserRelationship(investor_id, adviser_id, params, {
            persistent: options.force,
            responseFormat: this.ApiResponseFormatEnum[options.type]
        });

        return this.findBy({ investor_id, adviser_id });
    }

    static async $create(data = null) {
        if (!data) {
            throw new Error('Missing payload when creating investor/adviser relationship');
        }
        if (!data.investor_id) {
            throw new Error('Missing investor_id when creating investor/adviser relationship', data);
        }
        if (!data.adviser_id) {
            throw new Error('Missing adviser_id when creating investor/adviser relationship', data);
        }

        const { investor_id, adviser_id } = data;

        await this.api.postAdviserRelationship(data);

        return this.findBy({ investor_id, adviser_id });
    }

    async $update(data = null) {
        this.$updating = true;

        const method = data ? 'PATCH' : 'PUT';
        data = data || this.$toPayload();

        this.$saving = Object.keys(data);

        return await this.constructor.$update(this.investor_id, this.adviser_id, method, data).finally(() => {
            this.$updating = false;
            this.$saving = [];
        });
    }

    static async $update(investor_id, adviser_id, method = 'PUT', data = null) {
        if (!investor_id) {
            throw new Error('Missing investor_id when updating investor/adviser relationship', data);
        }
        if (!adviser_id) {
            throw new Error('Missing adviser_id when updating investor/adviser relationship', data);
        }

        if (method === 'PUT') {
            await this.api.putAdviserRelationship(investor_id, adviser_id, data);
        } else if (method === 'PATCH') {
            await this.api.patchAdviserRelationship(investor_id, adviser_id, data);
        } else {
            throw new Error(`${this.name} method not found when trying to update`);
        }

        return this.findBy({ investor_id, adviser_id });
    }

    async $remove() {
        this.$removing = true;

        return await this.constructor.$remove(this.investor_id, this.adviser_id).finally(() => {
            this.$removing = false;
        });
    }
    static async $remove(investor_id, adviser_id) {
        if (!investor_id) {
            throw new Error('Missing investor_id when deleting investor/adviser relationship');
        }
        if (!adviser_id) {
            throw new Error('Missing adviser_id when deleting investor/adviser relationship');
        }

        return await this.api.removeAdviserRelationship(investor_id, adviser_id);
    }

    get is_valid() {
        if (!this.primary_contact_id && (!this.primary_contact || !this.primary_contact.id)) {
            return false;
        }
        if (!this.adviser_id && (!this.adviser || !this.adviser.id)) {
            return false;
        }
        return true;
    }

    get computed_name() {
        let name = [];

        if (this.adviser && this.adviser.name) {
            name.push(this.adviser.name);
        }

        if (this.primary_contact && this.primary_contact.name) {
            name.push(this.primary_contact.name);
        }

        if (name.length > 0) {
            return name.join(' - ');
        }

        return i18n.t('adviser_account');
    }

    get primary_contact_filters() {
        if (!this.adviser_id) {
            return {};
        }

        return {
            role_in_organisation: `${this.adviser_id}`,
            fca_number: `${getFilterOperator('is_not')}:null`
        };
    }

    get investment_account_authorised_signoff_by_filters() {
        if (!this.Auth().is_gi) {
            return {};
        }

        const gi = Adviser.findBy({ name: 'GrowthInvest' });

        if (!gi) {
            return {};
        }

        return {
            role_in_organisation: `${gi.id}`
        };
    }

    get investment_account_authorised_set_by_filters() {
        if (!this.Auth().is_gi) {
            return {};
        }

        const gi = Adviser.findBy({ name: 'GrowthInvest' });

        if (!gi) {
            return {};
        }

        return {
            role_in_organisation: `${gi.id}`
        };
    }

    get relationship_status_colour() {
        if (!this.has_applied && !this.is_authorised) {
            return 'warning';
        } else if (this.has_applied && this.is_authorised) {
            return 'success';
        } else if (this.has_applied && !this.is_authorised) {
            return 'info';
        }

        return 'grey';
    }

    get relationship_status_text() {
        if (!this.has_applied && !this.is_authorised) {
            return 'Awaiting application';
        } else if (this.has_applied && this.is_authorised) {
            return 'Authorised';
        } else if (this.has_applied && !this.is_authorised) {
            return 'Awaiting authorisation';
        }

        return null;
    }
}

export default InvestorAdviserRelationship;
