<template>
    <node-view-wrapper class="tiptap-alert">
        <transition appear name="fade-transition">
            <v-alert class="tiptap-alert px-3" :color="alertColor" text dense>
                <v-row class="tiptap-alert__row" align="start" no-gutters>
                    <v-col v-if="alertIcon" class="shrink">
                        <v-icon class="tiptap-alert__icon mr-2 d-block" size="1.2rem" :color="alertColor">{{
                            alertIcon
                        }}</v-icon>
                    </v-col>
                    <v-col class="tiptap-alert__content grow">
                        <node-view-content />
                    </v-col>
                    <v-col v-if="isGrowthInvest() && editor.options.editable" class="shrink pl-3">
                        <tiptap-alert-options :options="options" :update-attributes="updateAttributes" />
                    </v-col>
                </v-row>
            </v-alert>
        </transition>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2';
import AlertTypeEnum from '@/enums/alertType';

export default {
    name: 'TiptapAlert',
    components: {
        NodeViewWrapper,
        NodeViewContent,
        TiptapAlertOptions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/lib/tiptap/components/TiptapAlertOptions.vue'
            )
    },
    props: {
        ...nodeViewProps
    },
    computed: {
        type() {
            return this.node.attrs.type || 'INFO';
        },
        options() {
            return {
                type: this.type
            };
        },
        alertTypeValue() {
            if (this.type !== null) {
                return AlertTypeEnum[this.type.toUpperCase()];
            }

            return null;
        },
        alertColor() {
            if (this.alertTypeValue !== null) {
                return this.alertTypeValue.color;
            }

            return this.color;
        },
        alertTextColor() {
            if (this.alertTypeValue !== null) {
                return `${this.alertTypeValue.color}--text`;
            }

            return `${this.color}--text`;
        },
        alertIcon() {
            if (this.alertTypeValue !== null) {
                return this.alertTypeValue.icon;
            }

            return this.icon;
        }
    }
};
</script>

<style lang="scss">
.tiptap-alert {
    display: table;
    &__content {
        min-width: 3rem;
        *:last-child {
            margin-bottom: 0;
        }
        h1,
        h2,
        h3 {
            color: inherit !important;
        }
    }
}
</style>
