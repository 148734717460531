/**
 * Wait for X ms
 * @param {Number} ms
 */
const wait = async ms => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};

export default wait;
