import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import isEmail from 'validator/lib/isEmail';
import strim from '@/lib/helpers/strim';

export class EmailValidator extends BaseValidator {
    static key = 'email';

    static validator(value, label = 'Email') {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            valid = isEmail(strim(value));
        }

        if (!valid) {
            return i18n.t('validator.email', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default EmailValidator;
