const UserRoleEnum = Object.freeze({
    ACCOUNTANT: 'ACCOUNTANT',
    ADMINISTRATOR: 'ADMINISTRATOR',
    ADVISER: 'ADVISER',
    CONTENT_APPROVER: 'CONTENT_APPROVER',
    DIRECTOR: 'DIRECTOR',
    ENTREPRENEUR: 'ENTREPRENEUR',
    FUND_MANAGER_AUDITOR: 'FUND_MANAGER_AUDITOR',
    MANAGEMENT: 'MANAGEMENT',
    MANAGER: 'MANAGER',
    MARKETING: 'MARKETING',
    OPERATIONS: 'OPERATIONS',
    OWNER: 'OWNER',
    PLATFORM_SUPERADMIN: 'PLATFORM_SUPERADMIN',
    READONLY: 'READONLY',
    SUPERUSER: 'SUPERUSER',
    USER: 'USER'
});

export default UserRoleEnum;
