import isPublic from '@/router/lib/isPublic';
import { changeContext } from '@/lib/auth';
import Auth from '@/models/Auth';

const urlGuard = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (auth.organisation && !auth.has_valid_hostname) {
            changeContext(auth.organisation, auth.account);
            next();
        } else {
            next();
        }
    }
};

export default urlGuard;
