export default {
    Checkbox: {
        icon: 'checkbox',
        text: 'Checkbox',
        command: ctx => {
            ctx.editor.chain().focus().toggleCheckbox().run();
        }
    },
    RadioList: {
        icon: 'radiolist',
        text: 'Radio Button List',
        command: ctx => {
            ctx.editor.chain().focus().toggleRadioList().run();
        }
    },
    Input: {
        icon: 'input',
        text: 'Input',
        command: ctx => {
            ctx.editor.chain().focus().insertInput().run();
        }
    }
};
