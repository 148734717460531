import FilterOperatorEnum from '@/enums/filter/operator';
import clock from '@/lib/clock';
import toIso8601 from '@/lib/helpers/toIso8601';

const filterMatchesItem = (item, key, filter) => {
    let match = true;

    if (Array.isArray(filter)) {
        match = filter.every(value => this.filterMatchesItem(item, key, value));
    } else if (key in item) {
        const hasOperator = filter.includes(':');

        let operator = FilterOperatorEnum.IS.value;
        let criteria = null;

        if (hasOperator) {
            [operator, criteria] = filter.match(/([^:]+):(.*)/).slice(1);
        } else {
            criteria = filter;
        }

        if (operator === FilterOperatorEnum.IN.value) {
            criteria = criteria.replace('[', '').replace(']', '').replace(' ', '').split(',');
        }

        const value = item[`${key}`];

        if (operator === FilterOperatorEnum.CONTAINS.value) {
            match = typeof value === 'string' && value.includes(criteria);
        } else if (operator === FilterOperatorEnum.NOT_CONTAINS.value) {
            match = typeof value === 'string' && !value.includes(criteria);
        } else if (operator === FilterOperatorEnum.STARTS_WITH.value) {
            match = typeof value === 'string' && value.startsWith(criteria);
        } else if (operator === FilterOperatorEnum.ENDS_WITH.value) {
            match = typeof value === 'string' && value.endsWith(criteria);
        } else if (operator === FilterOperatorEnum.IS.value) {
            match = value === criteria;
            if (!match && clock(value).isValid() && clock(criteria).isValid()) {
                match = clock(value).startOf('day').isSame(clock(criteria).startOf('day'));
            }
        } else if (operator === FilterOperatorEnum.IS_NOT.value) {
            match = value !== criteria;
        } else if (operator === FilterOperatorEnum.EMPTY.value) {
            match =
                value === null ||
                value === undefined ||
                ((typeof value === 'string' || Array.isArray(value)) && !value.length) ||
                (typeof value === 'object' && !Object.keys(value).length);
        } else if (operator === FilterOperatorEnum.NOT_EMPTY.value) {
            match =
                value !== null &&
                value !== undefined &&
                (typeof value === 'string' || Array.isArray(value)) &&
                value.length > 0 &&
                typeof value === 'object' &&
                Object.keys(value).length > 0;
        } else if (operator === FilterOperatorEnum.LESS_THAN_OR_IS.value) {
            match = typeof value === 'number' && value <= criteria;
        } else if (operator === FilterOperatorEnum.MORE_THAN_OR_IS.value) {
            match = typeof value === 'number' && value >= criteria;
        } else if (operator === FilterOperatorEnum.IS_BEFORE.value) {
            match = typeof value === 'string' && clock(toIso8601(value)).isBefore(clock(toIso8601(criteria))) === true;
        } else if (operator === FilterOperatorEnum.IS_AFTER.value) {
            match = typeof value === 'string' && clock(toIso8601(value)).isAfter(clock(toIso8601(criteria))) === true;
        } else if (operator === FilterOperatorEnum.IN.value) {
            match = Array.isArray(criteria) && criteria.includes(value);
        }

        if (!match) {
            console.warn('Match not found:', { key, value, filter });
        }
    } else {
        match = false;
    }

    return match;
};

export default filterMatchesItem;
