import each from 'lodash/each';

/**
 * Convert a Vue component to an object of default prop values
 * @param {Object} Component
 * @returns Object
 */
const toPropObject = Component => {
    let item = {};

    each(Component.props, (v, prop) => {
        item[`${prop}`] = v.default;
    });

    return item;
};

export default toPropObject;
