import isNumber from 'lodash/isNumber';

/**
 * Number Formatting
 * @param {Number} value
 * @returns String
 */
const toNumber = (value, options = {}, returnObject = null) => {
    if (value === null || value === undefined) {
        return null;
    }

    if (!isNumber(value)) {
        return null;
    }

    options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options
    };

    const formatter = new Intl.NumberFormat('en-GB', options);

    if (returnObject === null) {
        return formatter.format(value);
    }

    return formatter;
};

export default toNumber;
