import { Organisation } from '@/models';
import organisationTypeEnum from '@/enums/organisation/type';
import RegistrarApi from '@/api/RegistrarApi';

export class Registrar extends Organisation {
    static entity = 'registrars';
    static Api = RegistrarApi;

    static fields() {
        return {
            ...super.fields(),
            type: this.string(organisationTypeEnum.REGISTRAR)
        };
    }
}

export default Registrar;
