import createThemeFromBranding from '@/lib/helpers/createThemeFromBranding';
import iconfont from '@/lib/vuetify/iconfont';
import icons from '@/lib/vuetify/icons';
import config from '@/config';

/**
 * Sets the Vuetify theme
 * @param {Object} colours
 */
const applyThemeFromBranding = colours => {
    const themes = createThemeFromBranding(colours);

    if (themes && config.ENV !== 'test') {
        window.Vue.$vuetify.theme.options.customProperties = true;
        window.Vue.$vuetify.theme.themes = themes;
        window.Vue.$vuetify.theme.applyTheme();
        window.Vue.$vuetify.icons.iconfont = iconfont;
        window.Vue.$vuetify.icons.values = icons;
    }
};

export default applyThemeFromBranding;
