import BaseApi from '@/api/BaseApi';

export class RemoteApplicationApi extends BaseApi {
    static resource = 'remote-applications';

    get endpoints() {
        return {
            ...super.endpoints,
            submit: this.submit.bind(this),
            updateAll: this.updateAll.bind(this)
        };
    }

    get mock_endpoints() {
        return {
            ...super.mock_endpoints,
            submit: this.submit_mock.bind(this),
            updateAll: this.updateAll_mock.bind(this)
        };
    }

    async submit(id) {
        return await super.post({}, { base_url: `${this.base_url}/${id}/submit` });
    }

    async submit_mock(id) {
        let data = this._getMocked();

        if (!data) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        const item = data.find(item => item.id === id);

        if (!item) {
            return {
                status: 'fail',
                message: 'Not found'
            };
        }

        item.status = 'SENT';

        item.submitted_by = this._auth().user;
        item.submitted_by_id = item.submitted_by.id;
        item.submitted_at = BaseApi.clock().toISOString();

        item.last_updated_by = this._auth().user;
        item.last_updated_by_id = item.last_updated_by.id;
        item.last_updated = BaseApi.clock().toISOString();

        return await this.patch_mock(item);
    }

    async updateAll(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        const response = await BaseApi.api.post(
            `${this.base_url}/update-all`,
            {
                ...BaseApi.removeNull({
                    offset,
                    limit,
                    sort_by
                }),
                ...filters
            },
            config
        );

        return this._respond(response, () => {
            this._insertModels(response.data);
        });
    }

    async updateAll_mock(offset = null, limit = null, sort_by = null, filters = {}) {
        let data = this._getMocked();

        data = this._filterMocked(data, filters);

        if (offset !== null && limit !== null) {
            data = data.slice(offset, offset + limit);
        }

        data = data.map(item => ({
            ...item,
            status: 'ACCEPTED'
        }));

        if (sort_by) {
            // todo;
        }

        let response = {
            status: 'success',
            data,
            metadata: {
                total: data.length,
                limit,
                offset
            }
        };

        return this._respond(response);
    }
}

export default RemoteApplicationApi;
