import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';
import Adviser from '@/models/Adviser';

const setAdviser = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (!auth.organisation_id) {
            auth.adviser = null;
        } else if (auth.is_adviser && (!auth.adviser || auth.adviser.id !== auth.organisation_id)) {
            try {
                const adviser = await Adviser.$get(auth.organisation_id);
                auth.adviser = adviser;
            } catch (error) {
                auth.adviser = null;
            }
        }

        next();
    }
};

export default setAdviser;
