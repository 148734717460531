const setAttrById = (json, id, attr, value) => {
    for (const index in json.content) {
        const item = json.content[`${index}`];

        if (item.attrs && 'id' in item.attrs && item.attrs.id === id) {
            json.content[`${index}`].attrs[`${attr}`] = value;
        }

        if (item.content) {
            json.content[`${index}`] = setAttrById(json.content[`${index}`], id, attr, value);
        }
    }

    return json;
};

export default setAttrById;
