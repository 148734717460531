import { faker } from '@faker-js/faker';

/**
 * Mock data generator
 * @param {Object} fields
 * @param {Number} limit
 * @returns {Promise<Array>}
 * @example
 * const data = await mock({
 *     id: 'investors.index:id'
 *     name: faker => faker.name.firstName(),
 *     slug: (faker, item) => item.name.replaceAll(' ', '-').toLowerCase()
 *     intro: 'Hello {{ person.prefix }} {{ person.lastName }}',
 *     status: faker => faker.helpers.arrayElement(['DRAFT', 'PUBLISHED', 'ARCHIVED']),
 * }, 10);
 */
export async function mock(fields, limit = 1) {
    const data = [];

    for (let i = 0; i < limit; i++) {
        const item = {};

        for (const field in fields) {
            try {
                item[`${field}`] = await getFieldValue(fields[`${field}`], item, mock);
            } catch (error) {
                console.error(error);
            }
        }

        data.push(item);
    }

    return data;
}

async function getFieldValue(fieldValue, item, mock) {
    let value = null;

    if (fieldValue === null) {
        value = null;
    } else if (typeof fieldValue === 'string') {
        value = await getStringValue(fieldValue);
    } else if (typeof fieldValue === 'function') {
        if (fieldValue.prototype && fieldValue.prototype.constructor) {
            value = await getApiValue(fieldValue);
        } else {
            value = await fieldValue(faker, item, mock);
            value = await getStringValue(value);
            value = await getApiValue(value);
        }
    } else {
        value = fieldValue;
    }

    return value;
}

export async function getStringValue(value) {
    if (typeof value === 'string') {
        if (value.includes('{{')) {
            value = faker.helpers.fake(value);
        }
    }

    return value;
}

const API_CACHE = {};

export async function getApiValue(value) {
    if (typeof value === 'function' && value.prototype && value.prototype.constructor) {
        const name = value.prototype.constructor.name;

        let items = [];

        if (API_CACHE[`${name}`] && API_CACHE[`${name}`].length) {
            items = API_CACHE[`${name}`];
        } else {
            items = (await new value().index(0, 40)).data;
            API_CACHE[`${name}`] = items;
        }

        return faker.helpers.arrayElement(items);
    }

    return value;
}

export default mock;
