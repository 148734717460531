const findConfig = value => {
    if (value && 'content' in value) {
        const config = value.content.find(node => node.type === 'Config');
        if (config) {
            return config.attrs;
        }
    }

    return undefined;
};

export default findConfig;
