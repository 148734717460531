import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';

const redirectIncompleteInvestor = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (auth.is_initialised && auth.is_investor && auth.is_owner && to.name === 'Dashboard') {
            const adviserAccounts = auth.investor.adviser_accounts;

            if (
                !auth.disable_investor_welcome_page &&
                adviserAccounts.some(aa => aa.investment_account_authorised_at === null)
            ) {
                next({ name: 'Settings' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
};

export default redirectIncompleteInvestor;
