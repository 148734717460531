import { Collection, Product } from '@/models';

export class ProductCollection extends Collection {
    static type = 'ProductCollection';
    static endpoint = Product.api.index;
    static model = Product;

    static fields() {
        return {
            company_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.company_id) {
            filters.company_id = this.filterOperator('is') + ':' + this.company_id;
        }

        return filters;
    }
}

export default ProductCollection;
