import {
    BaseModel,
    FundManager,
    Provider,
    Nominee,
    Custodian,
    Registrar,
    Broker,
    ReceivingAgent,
    Fees
} from '@/models';
import TargetDividendTypeEnum from '@/enums/targetDividendType';
import VctFundStatus from '@/enums/vct/fundStatus';
import VctDividendReinvestment from '@/enums/vct/dividendReinvestment';
import getTargetDividend from '@/lib/helpers/getTargetDividend';

export class VctStats extends BaseModel {
    static entity = 'vctStats';

    static fields() {
        return {
            summary: this.attr(null),
            //
            target_dividend_type: this.enum(TargetDividendTypeEnum).nullable(),
            target_dividend: this.number(null).nullable(),
            target_dividend_description: this.string(null).nullable(),
            target_dividend_start_on: this.string(null).nullable(),
            //
            dividend_start_on: this.string(null).nullable(),
            esg_qualifying: this.boolean(null).nullable(),
            aic_sector_id: this.string(null).nullable(),
            aic_sector: this.attr({ name: null }),
            status: this.enum(VctFundStatus).nullable(),
            // acquired_by_id:
            // acquired_by:
            // acquired:
            launch_at: this.string(null).nullable(),
            close_at: this.string(null).nullable(),
            //
            exit_strategy: this.attr(null).nullable(),
            //
            funds_raised: this.attr(this.defaultMoney).nullable(),
            total_assets: this.attr(this.defaultMoney).nullable(),
            dividend_reinvestment_offered: this.enumList(VctDividendReinvestment).nullable(),
            tax_relief_on_adviser_fee: this.boolean(null).nullable(),
            //
            buyback_discount: this.number(null).nullable(),
            gross_gearing: this.number(null).nullable(),
            domiciled: this.attr(null).nullable(),
            //
            sample_companies: this.attr(null).nullable(),
            sample_portfolio_companies: this.attr(null).nullable(),
            // Relationships
            fund_manager_id: this.string(null).nullable(),
            fund_manager: this.belongsTo(FundManager, 'fund_manager_id'),
            provider_id: this.string(null).nullable(),
            provider: this.belongsTo(Provider, 'provider_id'),
            nominee_id: this.string(null).nullable(),
            nominee: this.belongsTo(Nominee, 'nominee_id'),
            custodian_id: this.string(null).nullable(),
            custodian: this.belongsTo(Custodian, 'custodian_id'),
            registrar_id: this.string(null).nullable(),
            registrar: this.belongsTo(Registrar, 'registrar_id'),
            broker_id: this.string(null).nullable(),
            broker: this.belongsTo(Broker, 'broker_id'),
            receiving_agent_id: this.string(null).nullable(),
            receiving_agent: this.belongsTo(ReceivingAgent, 'receiving_agent_id'),
            fees_id: this.string(null).nullable(),
            fees: this.belongsTo(Fees, 'fees_id')
        };
    }

    get target_dividend_display() {
        return getTargetDividend(this.target_dividend_type, this.target_dividend);
    }
}

export default VctStats;
