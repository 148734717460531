import {
    BaseModel,
    Investor,
    Adviser,
    Offer,
    Product,
    Transaction,
    Shortlist,
    Proposal,
    Fund,
    User,
    Registration,
    Agreement
} from '@/models';
import ActivityScopeEnum from '@/enums/activity/scope';
import ActivityTypeEnum from '@/enums/activity/type';
import ActivitySpecialEventEnum from '@/enums/activity/specialActivityEvent';

import AlertTypeEnum from '@/enums/alert/type';

import ActivityApi from '@/api/ActivityApi';

export class Activity extends BaseModel {
    static entity = 'activities';
    static Api = ActivityApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.string(),
            icon: this.string(),
            date: this.string(),
            title: this.string(),
            text: this.string(),
            type: this.enum(ActivityTypeEnum, ActivityTypeEnum.ACTIVITY),
            scope: this.enum(ActivityScopeEnum),
            special_event: this.enum(ActivitySpecialEventEnum),
            todo: this.boolean(null).nullable(),
            editable: this.boolean(false).nullable(),
            details: this.attr(null).nullable(),
            created_at: this.string(null).nullable(),
            created_by_id: this.string(null).nullable(),
            created_by: this.belongsTo(User, 'created_by_id'),

            alert_type: this.enum(AlertTypeEnum).nullable(),
            alert_text: this.string(null).nullable(),
            alerts: this.attr([]).nullable(),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id'),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            transaction_id: this.string(null).nullable(),
            transaction: this.belongsTo(Transaction, 'transaction_id'),

            shortlist_id: this.string(null).nullable(),
            shortlist: this.belongsTo(Shortlist, 'shortlist_id'),

            proposal_id: this.string(null).nullable(),
            proposal: this.belongsTo(Proposal, 'proposal_id'),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id'),

            user_id: this.string(null).nullable(),
            user: this.belongsTo(User, 'user_id'),

            registration_id: this.string(null).nullable(),
            registration: this.belongsTo(Registration, 'registration_id'),

            agreement_id: this.string(null).nullable(),
            agreement: this.belongsTo(Agreement, 'agreement_id')
        };
    }
}

export default Activity;
