import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import strim from '@/lib/helpers/strim';
import { find, isEmpty } from 'lodash';
import { findValidationRules, findNodesByType, findNodeById } from '@/lib/tiptap';
import getValidatorFromString from '@/lib/helpers/getValidatorFromString';

export class TiptapValidator extends BaseValidator {
    static key = 'tiptap';

    static validator(value, returnErrors = true) {
        if (typeof value === 'string' && strim(value).length > 0) {
            try {
                value = JSON.parse(value);
            } catch (error) {
                return i18n.t('validator.declaration');
            }
        }

        const rules = findValidationRules(value);

        if (rules && typeof value === 'object' && 'content' in value) {
            const errors = TiptapValidator.mergeErrorMessages(
                TiptapValidator.getErrorMessages('some', rules.some, value),
                TiptapValidator.getErrorMessages('all', rules.all, value)
            );

            if (Object.keys(errors).length) {
                return returnErrors ? errors : i18n.t('validator.tiptap');
            } else {
                return true;
            }
        } else {
            // Legacy declarations require all Checkbox and RadioList nodes to be true
            const checkboxes = findNodesByType(value, 'Checkbox');
            const radioLists = findNodesByType(value, 'RadioList');

            if (isEmpty(checkboxes) && isEmpty(radioLists)) {
                return true;
            }

            if (find([...checkboxes, ...radioLists], node => [false, null, undefined].includes(node.attrs.value))) {
                return i18n.t('validator.tiptap');
            }

            return true;
        }
    }

    static getErrorMessages(type, rules, value) {
        const errors = {};

        if (rules && Array.isArray(rules)) {
            for (const ruleset of rules) {
                const rulesetErrors = {};

                // Skip ruleset with failing conditions
                if ('_conditions' in ruleset) {
                    const conditionErrors = TiptapValidator.getErrorMessages('all', [ruleset._conditions], value);
                    if (Object.keys(conditionErrors).length) {
                        continue;
                    }
                }

                let totalRulesInRuleset = Object.keys(ruleset).length;

                for (let nodeId in ruleset) {
                    if (nodeId === '_conditions') {
                        totalRulesInRuleset = totalRulesInRuleset - 1;
                        continue;
                    }

                    const node = findNodeById(value, nodeId);
                    const { validator, args } = getValidatorFromString(ruleset[`${nodeId}`]);

                    if (!(nodeId in rulesetErrors) || !Array.isArray(rulesetErrors[`${nodeId}`])) {
                        rulesetErrors[`${nodeId}`] = [];
                    }

                    if (node) {
                        const validation = validator(node.attrs.value, ...args);
                        if (validation !== true) {
                            if (validation === false) {
                                rulesetErrors[`${nodeId}`].push('Required');
                            } else {
                                rulesetErrors[`${nodeId}`].push(validation);
                            }
                        }
                    } else {
                        rulesetErrors[`${nodeId}`] = `Node with ID "${nodeId}" not found`;
                    }

                    if (!rulesetErrors[`${nodeId}`].length) {
                        delete rulesetErrors[`${nodeId}`];
                    }
                }

                if (type === 'all') {
                    if (Object.keys(rulesetErrors).length > 0) {
                        Object.assign(errors, rulesetErrors);
                    }
                } else if (type === 'some') {
                    if (Object.keys(rulesetErrors).length === totalRulesInRuleset) {
                        Object.assign(errors, rulesetErrors);
                    }
                }
            }
        }

        return errors;
    }

    static mergeErrorMessages(obj1, obj2) {
        const result = {};

        for (let field in obj1) {
            result[`${field}`] = obj1[`${field}`];
        }

        for (let field in obj2) {
            if (field in result) {
                result[`${field}`] = [...new Set([...result[`${field}`], ...obj2[`${field}`]])];
            } else {
                result[`${field}`] = obj2[`${field}`];
            }
        }

        return result;
    }
}

export default TiptapValidator;
