// Tiptap extensions
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import ListKeymap from '@tiptap/extension-list-keymap';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Blockquote from '@tiptap/extension-blockquote';
import History from '@tiptap/extension-history';
import Placeholder from '@tiptap/extension-placeholder';
import Gapcursor from '@tiptap/extension-gapcursor';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import Superscript from '@tiptap/extension-superscript';

// Custom extensions
import Checkbox from '@/lib/tiptap/extensions/Checkbox';
import ExpansionPanel from '@/lib/tiptap/extensions/ExpansionPanel';
import Alert from '@/lib/tiptap/extensions/Alert';
import DynamicValue from '@/lib/tiptap/extensions/DynamicValue';
import RadioList from '@/lib/tiptap/extensions/RadioList';
import RadioItem from '@/lib/tiptap/extensions/RadioItem';
import Readonly from '@/lib/tiptap/extensions/Readonly';
import Input from '@/lib/tiptap/extensions/Input';
import Config from '@/lib/tiptap/extensions/Config';
import Validator from '@/lib/tiptap/extensions/Validator';
import Outline from '@/lib/tiptap/extensions/Outline';

export default [
    // Tiptap extensions
    Document,
    Paragraph,
    Heading,
    Text,
    Bold,
    Italic,
    Link,
    ListItem,
    ListKeymap,
    BulletList,
    OrderedList,
    Blockquote,
    History,
    Placeholder.configure({
        placeholder: () => 'Write something...'
    }),
    Gapcursor,
    Table.configure({ resizable: true }),
    TableRow,
    TableHeader,
    TableCell,
    Superscript,

    // Custom extensions
    Checkbox,
    ExpansionPanel,
    Alert,
    DynamicValue,
    RadioList,
    RadioItem,
    Readonly,
    Input,
    Config,
    Validator,
    Outline
];
