import { Collection, InvestorRegistration } from '@/models';
import InvestorRegistrationStatusEnum from '@/enums/investor/registrationStatus';
import RegistrationStatusEnum from '@/enums/registration/status';
import ProfileStatusEnum from '@/enums/profile/status';
import ApplicationStatusEnum from '@/enums/application/status';
import i18n from '@/lib/i18n';

export class InvestorRegistrationCollection extends Collection {
    static type = 'InvestorRegistrationCollection';
    static endpoint = InvestorRegistration.api.index;
    static model = InvestorRegistration;

    static fields() {
        return {
            ...super.fields(),
            adviser_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.adviser_id) {
            filters.adviser_id = this.filterOperator('is') + ':' + this.adviser_id;
        }

        return filters;
    }

    get default_headers() {
        return ['client', 'status', 'registration_status', 'profile_status', 'application_status'];
    }

    get pdf_headers() {
        return ['name', 'email', 'status', 'registration_status', 'profile_status', 'application_status'];
    }

    get headers() {
        return [
            this.createHeader('client', i18n.t('client'), {
                width: '18rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true,
                sortable: false
            }),
            this.createHeader('email', i18n.t('email'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true,
                sortable: false
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                sortable: false,
                format: 'enum',
                enum: InvestorRegistrationStatusEnum,
                enumA11yKey: 'enums.investor_registration_status'
            }),
            this.createHeader('registration_status', i18n.t('registration_status'), {
                filterable: true,
                sortable: false,
                format: 'enum',
                enum: RegistrationStatusEnum,
                enumA11yKey: 'enums.registration_status',
                align: 'right'
            }),
            this.createHeader('profile_status', i18n.t('onboarding_status'), {
                filterable: true,
                sortable: false,
                format: 'enum',
                enum: ProfileStatusEnum,
                enumA11yKey: 'enums.profile_status',
                align: 'right'
            }),
            this.createHeader('application_status', i18n.t('application_status'), {
                filterable: true,
                sortable: false,
                format: 'enum',
                enum: ApplicationStatusEnum,
                enumA11yKey: 'enums.application_status',
                align: 'right'
            })
        ];
    }
}

export default InvestorRegistrationCollection;
