import { BaseModel } from '@/models';
import i18n from '@/lib/i18n';

export class PhoneNumber extends BaseModel {
    static entity = 'phoneNumbers';

    static fields() {
        return {
            ...super.fields(),
            number: this.string(null).nullable(),
            country: this.string(null).nullable(), // Alpha2Code
            prefix: this.string(null).nullable(),
            national_dialling_code: this.number(0).nullable(),
            type_id: this.string(null).nullable(),
            number_type: this.attr(null).nullable()
        };
    }

    get is_valid() {
        return [this.number, this.country, this.number_type].every(v => v && v.length > 0);
    }

    get computed_name() {
        let name = '';

        if (this.prefix) {
            name += this.prefix;
        }

        if (this.number) {
            name += this.number;
        }

        if (this.number_type) {
            name += ' - ' + this.number_type;
        }

        if (this.number && name) {
            return name;
        }

        return i18n.t('phone_number');
    }

    get e164() {
        if (this.prefix && this.number) {
            return `${this.prefix}${this.number}`;
        }

        return null;
    }
}

export default PhoneNumber;
