import { HoldingCollection } from '@/models';
import i18n from '@/lib/i18n';

export class HoldingSummaryCollection extends HoldingCollection {
    static type = 'HoldingSummaryCollection';

    get headers() {
        let headers = [
            this.createHeader('gross_investment', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem'
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem'
            }),
            this.createHeader('uninvested_capital', i18n.t('fund_cash_balance'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),

            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_exits', i18n.t('total_exits'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('distributions', i18n.t('distributions'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem'
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_irr', i18n.t('total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_irr', i18n.t('nav_total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_profit_loss', i18n.t('total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_profit_loss', i18n.t('nav_total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_irr',
                i18n.t('total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_irr',
                i18n.t('nav_total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_profit_loss_including_tax_relief',
                i18n.t('total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_profit_loss_including_tax_relief',
                i18n.t('nav_total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader('total_return_with_exits', i18n.t('total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_with_exits', i18n.t('nav_total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_shortlisted', i18n.t('total_shortlisted'), {
                format: 'number',
                width: '10rem'
            }),
            this.createHeader('total_proposed', i18n.t('total_proposed'), {
                format: 'number',
                width: '10rem'
            }),
            this.createHeader('platform_cash_balance', i18n.t('platform_cash_balance'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem'
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'gross_investment',
            'invested_amount',
            'uninvested_capital',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_moic',
            'nav_total_return_moic',

            'total_return_percentage',
            'nav_total_return_percentage',

            'total_return_including_tax_relief',
            'nav_total_return_including_tax_relief',

            'total_return_including_tax_relief_moic',
            'nav_total_return_including_tax_relief_moic',

            'total_return_including_tax_relief_percentage',
            'nav_total_return_including_tax_relief_percentage',

            'total_shortlisted',
            'total_proposed',
            'platform_cash_balance'
        ];
    }

    get pdf_headers() {
        return [
            'gross_investment',
            'invested_amount',
            'uninvested_capital',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_percentage',
            'nav_total_return_percentage',

            'total_return_including_tax_relief',
            'nav_total_return_including_tax_relief',

            'total_return_including_tax_relief_percentage',
            'nav_total_return_including_tax_relief_percentage',

            'total_shortlisted',
            'total_proposed',
            'platform_cash_balance'
        ];
    }
}

export default HoldingSummaryCollection;
