import { Collection, Holding } from '@/models';
import i18n from '@/lib/i18n';

export class SystemHoldingsCollection extends Collection {
    static type = 'SystemHoldingsCollection';
    static endpoint = Holding.api.index;
    static model = Holding;

    get default_headers() {
        return [
            'name',
            'reconciled',
            'gi_ref',
            'share_quantity',
            'share_issue_price',
            'share_issue_on',
            'investor_id',
            'adviser_id',
            'control'
        ];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '15rem'
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                format: 'boolean',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '8rem'
            }),
            this.createHeader('share_issue_price', i18n.t('share_issue_price'), {
                format: 'money',
                width: '8rem'
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '8rem'
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name,
                to: data => {
                    const adviserId = data.item?.adviser?.id;

                    if (!adviserId) return null;

                    return {
                        name: 'SettingsFirmInformation',
                        query: { organisation_id: adviserId, organisation_type: 'adviser' }
                    };
                }
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name,
                to: data => {
                    const investorId = data.item?.investor?.id;

                    if (!investorId) return null;

                    return {
                        name: 'SettingsProfile',
                        query: { organisation_id: investorId, organisation_type: 'investor' }
                    };
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem'
            })
        ];
    }
}

export default SystemHoldingsCollection;
