import snakeCase from 'lodash/snakeCase';
import has from 'lodash/has';
import isLive from '@/lib/helpers/isLive';

export default {
    bind(element, binding, vnode) {
        if (!isLive()) {
            let target = null;

            let page = '';
            let field = '';
            let action = '';

            const nodeTypes = ['INPUT', 'TEXTAREA', 'BUTTON', 'A'];

            if (!nodeTypes.includes(element.nodeName)) {
                if (element.classList.contains('v-textarea')) {
                    target = element.querySelector('textarea');
                } else if (element.classList.contains('v-input')) {
                    target = element.querySelector('input');
                } else if (element.classList.contains('v-btn')) {
                    target = element.querySelector('button');
                } else if (element.classList.contains('app-button')) {
                    target = element.querySelector('button');
                }
            }

            if (!target) {
                if (['button', 'link', 'tab'].includes(element.getAttribute('role'))) {
                    target = element;
                }

                if (['button'].includes(element.getAttribute('type'))) {
                    target = element;
                }

                if (element.getAttribute('href') !== '') {
                    target = element;
                }

                target = target || element.querySelector('[role=button]');
                target = target || element.querySelector('[role=link]');
                target = target || element.querySelector('[role=tab]');
                target = target || element.querySelector('[type=button]');
                target = target || element.querySelector('[href]:not([href=""])');
            }

            if (!target) {
                console.warn(`Target element not found when trying to assign Test ID to:`, element);
                return;
            }

            let id = '';

            if (typeof binding.value === 'string') {
                id = binding.value;
            } else {
                page = snakeCase(vnode.context.$route.name);
                field = snakeCase(target.nodeName);
                action = 'default';

                if (target.nodeName === 'BUTTON') {
                    action = target.innerText || action;
                } else if (target.getAttribute('role') === 'BUTTON') {
                    action = target.innerText || action;
                } else if (target.nodeName === 'INPUT') {
                    action = target.name || action;
                } else if (target.nodeName === 'TEXTAREA') {
                    action = target.name || action;
                } else if (target.nodeName === 'A') {
                    field = 'link';
                    action = target.innerText || action;
                }

                if (has(binding.value, 'page')) {
                    page = snakeCase(binding.value.page);
                }

                if (has(binding.value, 'field')) {
                    field = snakeCase(binding.value.field);
                }

                if (has(binding.value, 'action')) {
                    action = snakeCase(binding.value.action);
                }

                id = `${page}-${field}-${snakeCase(action)}`;
            }

            target.setAttribute('data-test-id', id);
        }
    }
};
