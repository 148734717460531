import { redirectToLogin } from '@/lib/auth';
import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';

const loadUser = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else if (Auth().onboarding) {
        next();
    } else {
        try {
            await Auth().setCurrentUser();

            if (to.name && to.name.startsWith('InvestorOnboarding')) {
                next({ name: 'SettingsProfile' });
            } else {
                next();
            }
        } catch (error) {
            const httpStatus = error?.response?.status;

            if (httpStatus === 404) {
                await Auth().setRegistration();
                next();
            } else if (httpStatus < 500) {
                return redirectToLogin({
                    rd: window.location.href,
                    app_uri: window.location.host
                });
            }
        }
    }
};

export default loadUser;
