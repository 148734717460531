import { BaseModel, Custodian } from '@/models';
import i18n from '@/lib/i18n';

export class CustodyAccount extends BaseModel {
    static entity = 'custodyAccount';

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            reference: this.string(null).nullable(),
            custodian_id: this.string(null).nullable(),
            custodian: this.belongsTo(Custodian, 'custodian_id')
        };
    }

    get is_valid() {
        return !!this.reference && (this.custodian_id || (this.custodian && this.custodian.name));
    }

    get computed_name() {
        if (this.is_valid) {
            return [this.custodian.name, this.reference].join(' - ');
        }
        return i18n.t('custody_account');
    }
}

export default CustodyAccount;
