import { BaseModel } from '@/models';

export class News extends BaseModel {
    static entity = 'news';

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            headline: this.string(''),
            posted_on: this.string(null).nullable(),
            body: this.attr(null).nullable(),
            parent_name: this.string(null).nullable()
        };
    }
}

export default News;
