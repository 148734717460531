import { Collection, Investor, Provider } from '@/models';
import i18n from '@/lib/i18n';

export class DriManagementCollection extends Collection {
    static type = 'DriManagementCollection';
    static endpoint = Investor.api.providers;
    static model = Provider;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {
            dri_available: this.filterOperator('is') + ':' + true
        };

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('provider'), {
                width: '100%',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '9rem',
                filterable: true,
                active: false
            }),
            this.createHeader('fca_number', i18n.t('fca_number'), {
                width: '12rem',
                filterable: true,
                active: false
            })
        ];
    }
}

export default DriManagementCollection;
