import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapRadioList from '@/lib/tiptap/components/TiptapRadioList.vue';

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'RadioList',
    content() {
        return `${this.options.itemTypeName}+`;
    },
    group: 'block',
    inline: false,
    defining: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapRadioList);
    },

    addAttributes() {
        return {
            id: { default: null },
            value: { default: null }
        };
    },

    addCommands() {
        return {
            setRadioList:
                () =>
                ({ commands }) => {
                    return commands.wrapIn(this.name);
                },
            toggleRadioList:
                () =>
                ({ commands }) => {
                    return commands.toggleWrap(this.name);
                },
            unsetRadioList:
                () =>
                ({ commands }) => {
                    return commands.lift(this.name);
                }
        };
    },

    addOptions() {
        return {
            itemTypeName: 'RadioItem',
            HTMLAttributes: {
                default: {
                    class: 'tiptap-radio-list',
                    'data-id': null,
                    'data-value': null
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-radio-list',
                getAttrs: node => ({
                    id: node.getAttribute('data-id'),
                    value: node.getAttribute('data-value')
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            {
                class: 'tiptap-radio-list',
                'data-id': HTMLAttributes.id,
                'data-value': HTMLAttributes.value
            },
            0
        ];
    }
});
