import { HoldingCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';

export class BulkShareAllotmentHoldingCollection extends HoldingCollection {
    static type = 'BulkShareAllotmentHoldingCollection';
    static endpoint = Holding.api.index;
    static model = Holding;

    static fields() {
        return {
            ...super.fields(),
            product_id: this.string(null).nullable()
        };
    }

    get headers() {
        return [
            this.createHeader('allotting', '', {
                width: '3rem',
                align: 'center'
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '9rem',
                filterable: true
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name
            }),
            this.createHeader('fund_id', i18n.t('fund'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Fund')
                },
                transform: data => data.item?.fund?.name
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '9rem'
            }),
            this.createHeader('share_issue_price', i18n.t('share_issue_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '9rem',
                align: 'right',
                sortable: false
            }),
            this.createHeader('invested_amount', i18n.t('total_invested'), {
                format: 'money',
                width: '10rem',
                formatOptions: super.money_format_options
            }),
            this.createHeader('initial_tax_relief', i18n.t('tax_relief'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '12rem',
                sortable: false
            }),
            this.createHeader('gross_investment_amount', i18n.t('proposed_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get default_headers() {
        return ['name', 'gi_ref', 'share_quantity', 'invested_amount', 'investor_id'];
    }

    get preparation_headers() {
        return [
            'allotting',
            'name',
            'investor_id',
            'share_quantity',
            'share_issue_price',
            'share_issue_on',
            'initial_tax_relief',
            'gross_investment_amount',
            'control'
        ];
    }

    get preset_filters() {
        let filters = {
            pending_allotment: this.filterOperator('is') + ':true'
        };

        if (this.product_id) {
            filters.product_id = this.filterOperator('is') + ':' + this.product_id;
        }

        return filters;
    }
}

export default BulkShareAllotmentHoldingCollection;
