import permissions from '@/enums/auth/permission';
import i18n from '@/lib/i18n';
import upperFirst from 'lodash/upperFirst';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import { tabs } from '@/views/Offers';

export default [
    {
        path: '/offers',
        name: 'Offers',
        component: () =>
            import(
                /* webpackChunkName: "offers" */
                '@/views/Offers.vue'
            ),
        redirect: {
            name: 'Offers' + upperFirst(camelCase(tabs[0]))
        },
        meta: {
            title: i18n.t('investment_offers'),
            heading: i18n.t('investment_offers'),
            description: i18n.t('see_list_of_all_offers_available'),
            permissions: [permissions.INVESTMENT_BOOK]
        },
        children: tabs.map(tab => ({
            path: '/offers/' + kebabCase(tab),
            name: 'Offers' + upperFirst(camelCase(tab)),
            component: () =>
                import(
                    /* webpackChunkName: "offers" */
                    '@/views/Offers' + upperFirst(camelCase(tab)) + '.vue'
                ),
            meta: {
                title: i18n.t('investment_offers') + ' - ' + i18n.t('enums.offers_tabs.' + snakeCase(tab)),
                heading: i18n.t('enums.offers_tabs.' + snakeCase(tab)),
                overline: i18n.t('investment_offers'),
                breadcrumb_label: tab === 'all' ? i18n.t('offers') : i18n.t('enums.offers_tabs.' + snakeCase(tab))
            }
        }))
    },
    {
        path: '/offer',
        name: 'Offer',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "offers" */
                '@/views/offer/Index.vue'
            ),
        meta: {
            title: i18n.t('investment_offer'),
            heading: i18n.t('offer'),
            breadcrumbs: [
                {
                    heading: i18n.t('investment_offers'),
                    to: { name: 'Offers' }
                }
            ]
        }
    }
];
