import iconfont from './iconfont';
import values from './icons';

const config = {
    theme: {
        options: {
            customProperties: true
        },
        icons: {
            iconfont,
            values
        },
        themes: {
            light: {},
            dark: {}
        }
    },
    breakpoint: {
        mobileBreakpoint: 'sm' // 960
    }
};

export default config;
