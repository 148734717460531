import { HoldingCollection } from '@/models';
import i18n from '@/lib/i18n';

export class InvestmentsSingleCompanyPanelCollection extends HoldingCollection {
    static type = 'InvestmentsSingleCompanyPanelCollection';

    static fields() {
        return {
            ...super.fields(),
            product_id: this.string(null).nullable()
        };
    }

    get default_headers() {
        return [
            'investor.name',
            'tranche.fund.name',
            'share_issue_on',
            'gross_investment_amount',
            'invested_amount',
            'share_quantity',
            'current_value',
            'nav',
            'total_return',
            'total_return_moic',
            'total_return_percentage',
            'total_return_including_tax_relief',
            'total_return_including_tax_relief_moic',
            'total_return_including_tax_relief_percentage'
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.show_consolidated_view) {
            filters.consolidated = true;
        }

        if (this.status) {
            filters.status = this.filterOperator('is') + ':OPEN';
        }

        if (this.product_id) {
            filters.product_id = this.filterOperator('is') + ':' + this.product_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('investor.name', i18n.t('investor'), {
                path: 'investor.name',
                width: '18rem',
                minWidth: '14rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('tranche.fund.name', i18n.t('fund'), {
                path: 'tranche.fund.name',
                width: '18rem',
                minWidth: '14rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '9rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '8rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('gross_investment_amount', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('invested_amount', i18n.t('total_invested'), {
                format: 'money',
                width: '10rem',
                formatOptions: super.money_format_options,
                sortable: true,
                filterable: true
            }),

            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                sortable: true,
                filterable: true,
                active: !this.show_nav_totals
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                sortable: true,
                filterable: true,
                active: this.show_nav_totals
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: true,
                filterable: true
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: true,
                filterable: true
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '8rem',
                sortable: true,
                filterable: true,
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '8rem',
                sortable: true,
                filterable: true,
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                sortable: true,
                filterable: true,
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                sortable: true,
                filterable: true,
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                sortable: true,
                filterable: true,
                active: !this.show_nav_totals
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    sortable: true,
                    filterable: true,
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    sortable: true,
                    filterable: true,
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    sortable: true,
                    filterable: true,
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    sortable: true,
                    filterable: true,
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    sortable: true,
                    filterable: true,
                    active: this.show_nav_totals
                }
            )
        ];
    }
}

export default InvestmentsSingleCompanyPanelCollection;
