import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class IsFalseValidator extends BaseValidator {
    static key = 'isFalse';

    static validator(value, label) {
        if (!value !== false) {
            return i18n.t('validator.false', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default IsFalseValidator;
