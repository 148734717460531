import { BaseModel, Adviser, Address, Branding, Terms, AppQuestion } from '@/models';
import MarketingConsentEnum from '@/enums/marketingConsent';
import InvestorCategoryEnum from '@/enums/investor/category';
import RegistrationStatusEnum from '@/enums/registration/status';
import config from '@/config';
import RegistrationApi from '@/api/RegistrationApi';

export class Registration extends BaseModel {
    static entity = 'registrations';
    static Api = RegistrationApi;

    get default_adviser_company_name() {
        return 'GrowthInvest Private Clients';
    }

    static fields() {
        return {
            id: this.string(null).nullable(),
            name: this.string(null).nullable(),
            email: this.string(null).nullable(),
            addresses: this.modelList(Address, []),
            consent_marketing: this.enum(MarketingConsentEnum).nullable(),
            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),
            adviser_name: this.string(null).nullable(),
            adviser_company_name: this.string(null).nullable(),
            has_adviser: this.boolean(null).nullable(),
            is_advised: this.boolean(false).nullable(),
            investor_type: this.enum(InvestorCategoryEnum).nullable(),
            investor_type_attempts: this.number(0).nullable(),
            classification_questions: this.attr(null).nullable(),
            on_boarding_status: this.enum(RegistrationStatusEnum, RegistrationStatusEnum.PENDING).nullable(),
            signout_url: this.string(null).nullable(),
            created_at: this.string(null).nullable(),
            last_updated: this.string(null).nullable(),
            template: this.attr(null).nullable()
        };
    }

    get first_name() {
        if (!this.name) {
            return null;
        }

        return this.name.split(' ')[0];
    }

    get has_valid_terms() {
        return this.required_terms.every(t => t.accepted);
    }

    get has_valid_address() {
        return this.addresses && this.addresses.length > 0 && this.addresses.some(address => address.is_valid);
    }

    get has_valid_adviser() {
        if (this.adviser_company_name === this.default_adviser_company_name) {
            return true;
        }

        return (this.adviser_id || !this.has_adviser) && this.is_advised !== null;
    }

    get has_valid_category() {
        return this.investor_type !== null;
    }

    get has_valid_declarations() {
        return !this.awaiting_declarations.length;
    }

    get has_valid_classification() {
        return this.has_valid_classification_answers;
    }

    get next_step() {
        if (this.is_complete) {
            return false;
        }

        if (this.is_failed_classification) {
            return null;
        }

        if (!this.has_valid_terms) {
            return 'InvestorOnboardingTerms';
        }

        if (!this.has_valid_address) {
            return 'InvestorOnboardingAddress';
        }

        if (!this.has_valid_adviser) {
            return 'InvestorOnboardingAdviser';
        }

        if (!this.has_valid_category) {
            return 'InvestorOnboardingCategory';
        }

        if (
            this.is_elective_professional &&
            this.awaiting_declarations.length === 1 &&
            !this.has_valid_classification
        ) {
            return 'InvestorOnboardingClassification';
        }

        if (!this.has_valid_declarations) {
            return 'InvestorOnboardingDeclaration';
        }

        if (!this.has_valid_classification) {
            return 'InvestorOnboardingClassification';
        }

        return null;
    }

    get is_pending() {
        return this.on_boarding_status === RegistrationStatusEnum.PENDING;
    }

    get is_complete() {
        return this.on_boarding_status === RegistrationStatusEnum.COMPLETE;
    }

    get is_failed_classification() {
        return this.on_boarding_status === RegistrationStatusEnum.FAILED_CLASSIFICATION;
    }

    get is_valid() {
        if (this.exceeded_max_investor_type_attempts) {
            return false;
        }

        if (this.is_failed_classification) {
            return false;
        }

        return true;
    }

    get max_investor_type_attempts_consumed() {
        return this.investor_type_attempts >= this.max_investor_type_attempts;
    }

    get exceeded_max_investor_type_attempts() {
        return this.investor_type_attempts > this.max_investor_type_attempts;
    }

    get max_investor_type_attempts() {
        return config.APP_MAX_ONBOARDING_INVESTOR_TYPE_ATTEMPTS || 3;
    }

    get has_valid_classification_answers() {
        if (!Array.isArray(this.classification_questions)) {
            return false;
        }

        if (!this.classification_questions.length) {
            return false;
        }

        return this.classification_questions.every(question => new AppQuestion(question).is_valid);
    }

    get is_certified_sophisticated() {
        return this.investor_type === InvestorCategoryEnum.CERTIFIED_SOPHISTICATED;
    }

    get is_elective_professional() {
        return this.investor_type === InvestorCategoryEnum.ELECTIVE_PROFESSIONAL;
    }

    get is_high_net_worth() {
        return this.investor_type === InvestorCategoryEnum.HIGH_NET_WORTH;
    }

    get is_professional() {
        return this.investor_type === InvestorCategoryEnum.PROFESSIONAL;
    }

    get is_retail_advised() {
        return this.investor_type === InvestorCategoryEnum.RETAIL_ADVISED;
    }

    get is_retail_restricted() {
        return this.investor_type === InvestorCategoryEnum.RETAIL_RESTRICTED;
    }

    get is_self_cert_sophisticated() {
        return this.investor_type === InvestorCategoryEnum.SELF_CERT_SOPHISTICATED;
    }

    get is_retail() {
        return this.is_retail_restricted || this.is_retail_advised;
    }

    get required_terms() {
        if (!this.template || !this.template.terms) {
            return [];
        }

        return this.template.terms.map(t => (t instanceof BaseModel ? t : new Terms(t)));
    }

    get required_declarations() {
        if (!this.template || !this.template.declarations) {
            return [];
        }

        const investorCategoryMap = {
            is_retail_advised: 'RETAIL_ADVISED',
            is_retail_restricted: 'RETAIL_RESTRICTED',
            is_self_cert_sophisticated: 'SELF_CERT_SOPHISTICATED',
            is_certified_sophisticated: 'CERTIFIED_SOPHISTICATED',
            is_high_net_worth: 'HIGH_NET_WORTH',
            is_elective_professional: 'ELECTIVE_PROFESSIONAL',
            is_professional: 'PROFESSIONAL'
        };

        const selectedCategory = Object.keys(investorCategoryMap).find(key => this[`${key}`]);

        if (!selectedCategory) {
            return [];
        }

        const declarations = this.template.declarations.filter(
            declaration => declaration.investor_type === investorCategoryMap[`${selectedCategory}`]
        );

        declarations.sort((a, b) => a.sort_order - b.sort_order);

        return declarations;
    }

    get signed_declaration_ids() {
        if (!this.template || !this.template.declarations) {
            return [];
        }

        let signedIds = localStorage.getItem('signed_declaration_ids');

        if (!signedIds) {
            return [];
        }

        return JSON.parse(signedIds);
    }

    set signed_declaration_ids(ids) {
        if (!Array.isArray(ids)) {
            ids = [];
        }

        ids = [...new Set(ids)];

        localStorage.setItem('signed_declaration_ids', JSON.stringify(ids));
    }

    get awaiting_declarations() {
        return this.required_declarations.filter(d => !this.signed_declaration_ids.includes(d.id));
    }

    get required_classification_questions() {
        if (!this.template || !this.template.questions) {
            return [];
        }

        const investorCategoryMap = {
            is_retail_advised: 'RETAIL',
            is_retail_restricted: 'RETAIL',
            is_self_cert_sophisticated: 'SOPHISTICATED',
            is_certified_sophisticated: 'SOPHISTICATED',
            is_high_net_worth: 'HIGH_NET_WORTH',
            is_elective_professional: 'PROFESSIONAL',
            is_professional: 'PROFESSIONAL'
        };

        const selectedCategory = Object.keys(investorCategoryMap).find(key => this[`${key}`]);

        if (!selectedCategory) {
            return [];
        }

        const questions = this.template.questions[`${investorCategoryMap[`${selectedCategory}`]}`];
        return questions.map(q => (q instanceof BaseModel ? q : new AppQuestion(q)));
    }

    get branding() {
        return Branding.find('public');
    }

    get is_branded() {
        return this.branding && !!this.branding.custom_app_url;
    }
}

export default Registration;
