const PmiStructureEnum = Object.freeze({
    AIF: 'AIF',
    FUND: 'FUND',
    LIMITED_PARTNERSHIP: 'LIMITED_PARTNERSHIP',
    PRODUCT: 'PRODUCT',
    SICAV: 'SICAV',
    UNIT_TRUST: 'UNIT_TRUST'
});

export default PmiStructureEnum;
