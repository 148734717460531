import BaseApi from '@/api/BaseApi';
import { Proposal } from '@/models';

export class ShortlistApi extends BaseApi {
    static resource = 'shortlists';

    get endpoints() {
        return {
            ...super.endpoints,
            requestIllustration: this.requestIllustration.bind(this),
            putEntry: this.putEntry.bind(this),
            proposeEntry: this.proposeEntry.bind(this),
            removeEntry: this.removeEntry.bind(this)
        };
    }

    _filterGetResponseData(data) {
        if (data && Array.isArray(data.entries)) {
            data.entries = data.entries.map(item => {
                item.offer_id = item.offer.id;
                item.shortlist_id = data.id;
                item.client_id = data.investor.id;
                return item;
            });
        }

        return data;
    }

    async requestIllustration(shortlistId, message) {
        const response = await BaseApi.api.post(`${this.base_url}/${shortlistId}/request-illustration`, { message });
        return this._respond(response);
    }

    async putEntry(entry) {
        for (const key in entry) {
            if (entry[`${key}`] && typeof entry[`${key}`] === 'object' && entry[`${key}`].amount === null) {
                entry[`${key}`] = null;
            }
        }

        const response = await BaseApi.api.put(`${this.base_url}/${entry.shortlist_id}/entry`, entry);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('Shortlist');
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async proposeEntry(entry, config = {}) {
        const response = await BaseApi.api.post(`${this.base_url}/entry/${entry.id}/propose`, null, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('Shortlist');
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async removeEntry(id, shortlistId) {
        const response = await BaseApi.api.remove(`${this.base_url}/entry/${id}`);

        return this._respond(response, () => {
            this._removeItemInCollections(id);
            this._clearCollection('Shortlist');
            Proposal.delete(id);
            this.model.delete(shortlistId);
        });
    }
}

export default ShortlistApi;
