import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapConfig from '@/lib/tiptap/components/TiptapConfig.vue';

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'Config',
    group: 'block',
    marks: '',
    atom: true,
    selectable: false,
    isolating: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapConfig);
    },

    addAttributes() {
        return {
            /**
             * Validation rules
             *
             * some - an array of objects where at least one object key value pair must pass validation.
             *
             * Each key value pair in the "some" object must have a key which is the attr.id of a Node
             * and a value which is the required attr.value to pass validation.
             *
             * e.g. If you have two Checkbox nodes and the user must select at least one, you could write
             * the following:
             * {
             *      "some": [{
             *          "checkbox_1": true,
             *          "checkbox_2": true
             *      }]
             * }
             *
             * all - the same as "some" but all the key value pairs must pass validation.
             */
            validation: {
                default: {
                    some: null,
                    all: null
                }
            },
            state: {
                default: []
            }
        };
    },

    addOptions() {
        return { HTMLAttributes: {} };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-config',
                getAttrs: node => ({
                    validation: JSON.parse(node.getAttribute('data-validation')),
                    state: JSON.parse(node.getAttribute('data-state'))
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            {
                class: 'tiptap-config',
                'data-validation': JSON.stringify(HTMLAttributes.validation),
                'data-state': JSON.stringify(HTMLAttributes.state)
            }
        ];
    }
});
