import BaseApi from '@/api/BaseApi';
import { attachHeaders } from '@/api/lib/request';

export class BrandingApi extends BaseApi {
    static resource = 'branding';

    get endpoints() {
        return {
            get: this.get.bind(this),
            put: super.endpoints.put.bind(this),
            setColours: this.setColours.bind(this),
            setImage: this.setImage.bind(this)
        };
    }

    async get(id) {
        let response = await BaseApi.api.get(id ? `${this.base_url}/${id}` : this.base_url);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterGetResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async setColours(adviserId, colours = []) {
        return BaseApi.api.put(`${this.base_url}/${adviserId}/colours`, colours);
    }

    async setImage(adviserId, image = {}) {
        return BaseApi.api.put(
            `${this.base_url}/${adviserId}/image`,
            { key: image.key, image: image.file },
            { headers: attachHeaders({ 'Content-Type': 'multipart/form-data' }) }
        );
    }
}

export default BrandingApi;
