import { Collection, Media } from '@/models';
import i18n from '@/lib/i18n';
import MediaFormatEnum from '@/enums/media/format';

export class MediaCollection extends Collection {
    static type = 'MediaCollection';
    static model = Media;

    static fields() {
        return {
            ...super.fields(),
            type: this.string(null).nullable(),
            media_format: this.attr(null).nullable(),
            media_type: this.attr(null).nullable()
        };
    }

    download_url(item) {
        return item.url;
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                filterable: true,
                width: '14rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                sortable: true,
                width: '10rem'
            }),
            this.createHeader('description', i18n.t('description'), {
                width: '18rem',
                format: 'wysiwyg'
            }),
            this.createHeader('document_date', i18n.t('document_date'), {
                filterable: true,
                width: '10rem',
                format: 'datetime'
            }),
            this.createHeader('format', i18n.t('format'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: MediaFormatEnum,
                enumA11yKey: 'enums.media_format'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get default_headers() {
        return ['name', 'description', 'document_date', 'type'];
    }

    get filter_key() {
        return this.type ? `${this.type}_id` : null;
    }

    get filter_value() {
        if (this.filter_key && this[this.filter_key]) {
            return this.filterOperator('is') + ':' + this[this.filter_key];
        }
        return null;
    }

    get media_format_filter_value() {
        if (!this.media_format) {
            return null;
        }
        const media_format = Array.isArray(this.media_format) ? this.media_format : [this.media_format];
        return this.filterOperator('in') + ':[' + media_format.join(',') + ']';
    }

    get media_type_filter_value() {
        if (!this.media_type) {
            return null;
        }
        const media_type = Array.isArray(this.media_type) ? this.media_type : [this.media_type];
        return this.filterOperator('in') + ':[' + media_type.join(',') + ']';
    }

    get preset_filters() {
        let filters = {};

        if (this.filter_value) {
            filters[this.filter_key] = this.filter_value;
        }

        if (this.media_format) {
            filters.format = this.media_format_filter_value;
        }

        if (this.media_type) {
            filters.type = this.media_type_filter_value;
        }

        return filters;
    }

    get preset_attr() {
        let attr = {};

        if (this.filter_value && this.filter_key) {
            attr[this.filter_key] = this[this.filter_key];
        }

        if (this.media_format) {
            attr.format = this.media_format;
        }

        if (this.media_type) {
            attr.type = this.media_type;
        }

        return attr;
    }
}

export default MediaCollection;
