<template>
    <node-view-wrapper class="tiptap-config">
        <tiptap-config-options
            v-if="isGrowthInvest() && editor.options.editable"
            :options="options"
            :update-attributes="updateAttributes"
            :json="editor.getJSON()"
        />
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

export default {
    name: 'TiptapConfig',
    components: {
        NodeViewWrapper,
        TiptapConfigOptions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/lib/tiptap/components/TiptapConfigOptions.vue'
            )
    },
    props: {
        ...nodeViewProps
    },
    computed: {
        validation() {
            return this.node.attrs.validation;
        },
        state() {
            return this.node.attrs.state;
        },
        options() {
            return {
                validation: this.validation,
                state: this.state
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.tiptap-config {
    position: relative;
    display: block;
}
</style>
