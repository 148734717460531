const isEmailMatch = (email1 = '', email2 = '') => {
    if (!(typeof email1 === 'string') || !(typeof email2 === 'string')) {
        return false;
    }

    const clean = email => email.replaceAll(' ', '').toLowerCase();

    return clean(email1) === clean(email2);
};

export default isEmailMatch;
