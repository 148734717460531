import { Model } from '@vuex-orm/core';

export const defaultBrandingColours = {
    primaryBackground: '#5ABDF9',
    primaryLight: '#DFEAF1',
    primaryText: '#0670B1'
};

export const defaultBrandingImages = {
    logo: require('@/images/default_logo.svg'),
    avatar: require('@/images/default_avatar.svg'),
    favicon: require('@/images/default_favicon.png')
};

export class Branding extends Model {
    static entity = 'branding';

    static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
            _text: this.attr({}),
            _colours: this.attr(defaultBrandingColours),
            _images: this.attr(defaultBrandingImages),
            _dark: this.boolean(false),
            _custom_app_url: this.string(null).nullable()
        };
    }

    static mock() {
        return {};
    }

    set text(payload) {
        this._text = payload ? payload : null;
    }

    get text() {
        return this._text || {};
    }

    set colours(payload) {
        this._colours = payload ? { ...defaultBrandingColours, ...payload } : defaultBrandingColours;
    }

    get colours() {
        return { ...defaultBrandingColours, ...this._colours };
    }

    set images(payload) {
        this._images = payload ? { ...defaultBrandingImages, ...payload } : defaultBrandingImages;
    }

    get images() {
        return { ...defaultBrandingImages, ...this._images };
    }

    set dark(payload) {
        this._dark = payload ? payload : false;
    }

    get dark() {
        return this._dark;
    }

    get custom_app_url() {
        return this._custom_app_url;
    }

    get colour() {
        return key => this.colours[`${key}`];
    }

    get image() {
        return key => this.images[`${key}`];
    }
}

export default Branding;
