export default {
    bold: {
        icon: 'bold',
        text: 'Bold',
        shortcut: 'CTRL B',
        command: ctx => {
            ctx.editor.chain().focus().toggleBold().run();
        }
    },
    italic: {
        icon: 'italic',
        text: 'Italic',
        shortcut: 'CTRL I',
        command: ctx => {
            ctx.editor.chain().focus().toggleItalic().run();
        }
    },
    superscript: {
        icon: 'superscript',
        text: 'Superscript',
        shortcut: 'CTRL .',
        command: ctx => {
            ctx.editor.chain().focus().toggleSuperscript().run();
        }
    },
    blockquote: {
        icon: 'blockquote',
        text: 'Blockquote',
        shortcut: 'CTRL SHFT B',
        command: ctx => {
            ctx.editor.chain().focus().toggleBlockquote().run();
        }
    },
    bulletlist: {
        icon: 'bulletlist',
        text: 'Bullet List',
        command: ctx => {
            ctx.editor.chain().focus().toggleBulletList().run();
        }
    },
    orderedlist: {
        icon: 'orderedlist',
        text: 'Ordered List',
        command: ctx => {
            ctx.editor.chain().focus().toggleOrderedList().run();
        }
    },
    ExpansionPanel: {
        icon: 'expansionPanel',
        text: 'Expansion Panel',
        command: ctx => {
            ctx.editor.chain().focus().toggleExpansionPanel().run();
        }
    },
    Alert: {
        icon: 'alert',
        text: 'Alert',
        command: ctx => {
            ctx.editor.chain().focus().toggleAlert().run();
        }
    },
    link: {
        icon: 'link',
        text: 'Link',
        command: (ctx, args) => {
            ctx.editor.chain().focus().toggleLink(args).run();
        },
        options: {
            type: 'link',
            args: {
                href: 'https://'
            }
        }
    }
};
