export default {
    removeFormat: {
        icon: 'removeFormat',
        text: 'Remove Formatting',
        command: ctx => {
            ctx.editor.chain().focus().unsetAllMarks().run();
        }
    },
    undo: {
        icon: 'undo',
        text: 'Undo',
        command: ctx => {
            ctx.editor.chain().focus().undo().run();
        }
    },
    redo: {
        icon: 'redo',
        text: 'Redo',
        command: ctx => {
            ctx.editor.chain().focus().redo().run();
        }
    },
    save: {
        icon: 'save',
        text: 'Save',
        command: ctx => {
            ctx.$emit('save', ctx.editor.getJSON());
        }
    }
};
