import uniq from 'lodash/uniq';
import map from 'lodash/map';
// import isUndefined from 'lodash/isUndefined';

/**
 * Get the current browser languages
 * @returns Array
 */
const discoverLanguages = () => {
    let languages = [];

    // if (!isUndefined(window)) {
    //     const n = window.navigator;

    //     if (n.languages) {
    //         languages = languages.concat(n.languages);
    //     }

    //     if (n.language) {
    //         languages.push(n.language);
    //     }

    //     if (n.userLanguage) {
    //         languages.push(n.userLanguage);
    //     }

    //     if (n.browserLanguage) {
    //         languages.push(n.browserLanguage);
    //     }

    //     if (n.systemLanguage) {
    //         languages.push(n.systemLanguage);
    //     }
    // }

    // Fallback
    languages.push('en-GB');

    languages = uniq(languages);

    languages = map(languages, l => {
        if (l.indexOf('-') === -1) {
            return l.toUpperCase();
        }
        return l.split('-')[1].toUpperCase();
    });

    return languages;
};

export default discoverLanguages;
