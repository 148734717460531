import getFilterOperator from '@/lib/helpers/getFilterOperator';
import getNoCriteriaOperators from '@/lib/helpers/getNoCriteriaOperators';

export function toSearchFilters(criteria, filters = {}, filterKey = 'name', filterOperator = 'CONTAINS') {
    if (criteria && typeof criteria === 'object') {
        filters = {
            ...filters,
            ...Object.keys(criteria).reduce((acc, key) => {
                let value = criteria[`${key}`];

                if (!value.includes(':') && !getNoCriteriaOperators().includes(value)) {
                    value = `${getFilterOperator('contains')}:${value}`;
                }

                acc[`${key}`] = value;
                return acc;
            }, {})
        };
    } else {
        filters = {
            ...filters,
            [filterKey]: `${getFilterOperator(filterOperator)}:${criteria}`
        };
    }

    return filters;
}

export default toSearchFilters;
