import authGuard from './middleware/authGuard';
import i18n from '@/lib/i18n';
import isLive from '@/lib/helpers/isLive';

import library from './routes/library';
import offers from './routes/offers';
import shortlists from './routes/shortlists';
import proposals from './routes/proposals';
import portfolio from './routes/portfolio';
import reports from './routes/reports';
import system from './routes/system';
import settings from './routes/settings';
import investorOnboarding from './routes/investor-onboarding';

const routes = [
    {
        path: '/',
        component: () =>
            import(
                /* webpackChunkName: "core" */
                '@/views/Index.vue'
            ),
        beforeEnter: authGuard,
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: () =>
                    import(
                        /* webpackChunkName: "core" */
                        '@/views/Dashboard.vue'
                    ),
                meta: {
                    title: i18n.t('dashboard'),
                    organisations: ['ADVISER', 'INVESTOR']
                }
            },
            ...library,
            ...offers,
            ...shortlists,
            ...proposals,
            ...portfolio,
            ...reports,
            ...system,
            ...settings,
            ...investorOnboarding
        ]
    },
    {
        path: '/risk-summary',
        name: 'RiskSummary',
        component: () =>
            import(
                /* webpackChunkName: "core" */
                '@/views/RiskSummary.vue'
            ),
        meta: {
            title: i18n.t('risk_summary')
        }
    },
    {
        path: '/debug/:param1?',
        name: 'Debug',
        component: () =>
            import(
                /* webpackChunkName: "core" */
                '@/views/Debug.vue'
            ),
        beforeEnter: (to, from, next) => {
            if (isLive()) {
                next({ name: 'PageError' });
            }

            next();
        }
    },
    {
        path: '/problem',
        name: 'FatalError',
        component: () =>
            import(
                /* webpackChunkName: "core" */
                '@/views/error/FatalError.vue'
            ),
        meta: {
            title: i18n.t('weve_encountered_a_problem')
        }
    },
    {
        path: '*',
        name: 'PageError',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "core" */
                '@/views/error/PageError.vue'
            ),
        meta: {
            title: i18n.t('weve_encountered_a_problem'),
            public: true
        }
    }
];

export default routes;
