import FieldType from '@/enums/field/type';
import isString from 'lodash/isString';
import has from 'lodash/has';

/**
 * Return a FieldType object
 * @param {String} key
 * @returns {Object} FieldType
 */
const getFieldType = key => {
    if (!isString(key)) {
        return null;
    }

    key = key.toUpperCase();

    if (!has(FieldType, key)) {
        return null;
    }

    return FieldType[`${key}`];
};

export default getFieldType;
