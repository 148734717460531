import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class UtrValidator extends BaseValidator {
    static key = 'utr';

    static validator(value) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = value.replace(/\s/g, '');
            const rgx = new RegExp(/^([0-9]{10}|[0-9]{9}k|k[0-9]{9}|[0-9]{13}|[0-9]{12}k|k[0-9]{12})$/i);
            valid = value.match(rgx);
        }

        if (!valid) {
            return i18n.t('validator.utr');
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default UtrValidator;
