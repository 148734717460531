import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import strim from '@/lib/helpers/strim';
import isUUID from 'validator/lib/isUUID';

export class UuidValidator extends BaseValidator {
    static key = 'uuid';

    static validator(value, label = 'UUID') {
        value = strim(value);

        if (!value) {
            return true;
        }

        if (isUUID(value)) {
            return true;
        }

        return i18n.t('validator.uuid', { field: label });
    }

    static arguments() {
        return [];
    }
}

export default UuidValidator;
