import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import { BankAccount } from '@/models';

export class BankAccountsOrDeferredValidator extends BaseValidator {
    static key = 'bankAccountsOrDeferred';

    static validator(value, deferred) {
        if (deferred === true) {
            return true;
        }

        if ([null, false].includes(deferred) && (!value || !Array.isArray(value) || !value.length)) {
            return 'Bank accounts must be added or deferred';
        }

        const results = [];

        const requiredFields = ['account_name', 'account_number', 'sort_code'];

        for (const baIndex in value) {
            const ba = new BankAccount(value[`${baIndex}`]);
            const field = `bank_accounts[${baIndex}]`;

            for (const attr of requiredFields) {
                if (!ba[`${attr}`]) {
                    results.push({
                        field: `${field}.${attr}`,
                        result: i18n.t('validator.required_field', attr)
                    });
                }
            }
        }

        return results;
    }

    static arguments() {
        return [];
    }
}

export default BankAccountsOrDeferredValidator;
