import AlertTypeEnum from '@/enums/alertType';
import isString from 'lodash/isString';
import has from 'lodash/has';

/**
 * Return a AlertType object
 * @param {String} key
 * @returns {Object} AlertType
 */
const getAlertType = key => {
    if (!isString(key)) {
        return null;
    }

    key = key.toUpperCase();

    if (!has(AlertTypeEnum, key)) {
        return null;
    }

    return AlertTypeEnum[`${key}`];
};

export default getAlertType;
