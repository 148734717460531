const findIds = (value, ids = {}) => {
    for (const item of value.content) {
        if (item.attrs && item.attrs.id) {
            ids[`${item.attrs.id}`] = item;
        }
        if (item.content) {
            Object.assign(ids, findIds(item, ids));
        }
    }
    return ids;
};

export default findIds;
