import clock from '@/lib/clock';
import today from '@/lib/helpers/now';

/**
 * Return the number of days until the end of the tax year
 * @param {String|Object}
 */
const getDaysUntilTaxYearEnd = (date = null) => {
    const now = date ? clock(date).startOf('day') : today().startOf('day');
    let fyEndDate = now.clone().month(3).date(5);

    if (now.isAfter(fyEndDate)) {
        fyEndDate = fyEndDate.add(1, 'year');
    }

    return fyEndDate.diff(now, 'days');
};

export default getDaysUntilTaxYearEnd;
