import { BaseModel, AppQuestionAnswer } from '@/models';
import AppQuestionTypeEnum from '@/enums/AppQuestionType';

export class AppQuestion extends BaseModel {
    static entity = 'questions';

    static fields() {
        return {
            id: this.string(null).nullable(),
            name: this.string(null).nullable(),
            text: this.attr(null),
            answers: this.modelList(AppQuestionAnswer, []),
            preset_answers: this.attr([]),
            value: this.attr([]).nullable(),
            required: this.boolean(true),
            type: this.string(AppQuestionTypeEnum.MULTIPLE_CHOICE),
            purpose: this.string(null).nullable(),
            key: this.string(null).nullable(),
            section: this.string(null).nullable(),
            sort_order: this.number(0).nullable()
        };
    }

    $filterPayload(payload) {
        delete payload.preset_answers;
        return payload;
    }

    get is_type_multiple_choice() {
        return this.type === AppQuestionTypeEnum.MULTIPLE_CHOICE;
    }

    get is_type_string() {
        return this.type === AppQuestionTypeEnum.STRING;
    }

    get is_type_wysiwyg() {
        return this.type === AppQuestionTypeEnum.WYSIWYG;
    }

    get is_type_textarea() {
        return this.type === AppQuestionTypeEnum.TEXTAREA;
    }

    get is_type_section() {
        return this.type === AppQuestionTypeEnum.SECTION;
    }

    get has_no_correct_answer() {
        return !this.answers.some(answer => answer.correct);
    }

    get has_single_correct_answer() {
        return this.answers.filter(answer => answer.correct).length <= 1;
    }

    get has_multiple_correct_answers() {
        return this.answers.filter(answer => answer.correct).length > 1;
    }

    get has_value() {
        return Array.isArray(this.value) && this.value.length > 0;
    }

    get is_valid() {
        return this.answers.every(answer => answer.is_valid);
    }

    get is_answered() {
        return this.answers.some(answer => answer.value);
    }

    get is_complete() {
        return (this.required && this.is_answered) || !this.required;
    }

    get answer() {
        if (this.has_single_correct_answer) {
            const found = this.answers.find(answer => answer.value);
            if (found) return [found];
        } else {
            return this.answers.filter(answer => answer.value);
        }

        return [];
    }

    get result() {
        return {
            question_id: this.id,
            question_text: this.text,
            // investor_type_tag: null,
            is_valid: this.is_valid,
            // todo; Send an array of answer results for multiple answer questions when the B/E accepts them CORE-1243
            answer: this.answer.map(answer => answer.result)[0]
        };
    }

    setup() {
        if (!this.is_answered) {
            this.reset_answers();

            if (this.has_multiple_correct_answers && this.has_value) {
                this.value.forEach(id => this.set_answer_by_id(id, true));
            }

            if (this.has_single_correct_answer && this.has_value) {
                this.set_answer_by_id(this.value, true);
            }

            this.preset_answers = JSON.parse(JSON.stringify(this.answers));
        }
    }

    reset_answers() {
        this.answers.forEach(answer => (answer.value = false));
    }

    set_value() {
        this.value = this.answer.map(answer => answer.id);
    }

    set_answer(answer, value) {
        if (this.has_single_correct_answer && this.is_type_multiple_choice) {
            if (value || !this.required) {
                this.reset_answers();
                answer.value = value;
            }
        }

        if (this.has_multiple_correct_answers && this.is_type_multiple_choice) {
            answer.value = value;
        }

        if (!this.is_type_multiple_choice) {
            this.answers = answer.text ? [answer] : [];
        }

        this.set_value();
    }

    set_answer_by_id(id, value) {
        const answer = this.answers.find(answer => answer.id === id);
        if (answer) {
            this.set_answer(answer, value);
        }
    }
}

export default AppQuestion;
