import { Collection, Shortlist } from '@/models';
import i18n from '@/lib/i18n';

export class ShortlistCollection extends Collection {
    static type = 'ShortlistCollection';
    static endpoint = Shortlist.api.index;
    static model = Shortlist;

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                to: data => ({ name: 'Shortlist', query: { shortlist_id: data.item.id } }),
                filterable: {
                    operators: this.getOperators(['contains', 'starts_with', 'ends_with'])
                }
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name
            }),
            this.createHeader('total_offers', i18n.t('total_offers'), {
                format: 'number',
                transform: data => data.item.entry_quantity,
                width: '10rem'
            }),
            this.createHeader('total_value', i18n.t('total_value'), {
                format: 'money',
                transform: data => data.item.total_proposed_amount,
                width: '12rem'
            }),
            this.createHeader('last_edited_date', i18n.t('last_edited_date'), {
                format: 'datetime',
                width: '14rem',
                align: 'right'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default ShortlistCollection;
