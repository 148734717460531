const AgreementStatusEnum = Object.freeze({
    OUT_FOR_SIGNATURE: 'OUT_FOR_SIGNATURE',
    OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
    OUT_FOR_ACCEPTANCE: 'OUT_FOR_ACCEPTANCE',
    OUT_FOR_FORM_FILLING: 'OUT_FOR_FORM_FILLING',
    OUT_FOR_APPROVAL: 'OUT_FOR_APPROVAL',
    AUTHORING: 'AUTHORING',
    CANCELLED: 'CANCELLED',
    SIGNED: 'SIGNED',
    APPROVED: 'APPROVED',
    DELIVERED: 'DELIVERED',
    ACCEPTED: 'ACCEPTED',
    FORM_FILLED: 'FORM_FILLED',
    EXPIRED: 'EXPIRED',
    ARCHIVED: 'ARCHIVED',
    PREFILL: 'PREFILL',
    WIDGET_WAITING_FOR_VERIFICATION: 'WIDGET_WAITING_FOR_VERIFICATION',
    DRAFT: 'DRAFT',
    DOCUMENTS_NOT_YET_PROCESSED: 'DOCUMENTS_NOT_YET_PROCESSED',
    WAITING_FOR_FAXIN: 'WAITING_FOR_FAXIN',
    WAITING_FOR_VERIFICATION: 'WAITING_FOR_VERIFICATION',
    WAITING_FOR_NOTARIZATION: 'WAITING_FOR_NOTARIZATION'
});

export default AgreementStatusEnum;
