import { Model } from '@vuex-orm/core';
import uuid from '@/lib/helpers/uuid';
import Auth from '@/models/Auth';
import getQueryParams from '@/lib/helpers/getQueryParams';
import router from '@/router';

export class AppListItem extends Model {
    static entity = 'appListItems';

    static fields() {
        return {
            id: this.uid(() => uuid()),
            name: this.string(''),
            icon: this.string(null).nullable(),
            tooltip: this.string(null).nullable(),
            route: this.attr({}),
            tags: this.attr([]),
            items: this.attr([]),
            permissions: this.attr([]),
            setupLoaded: this.number(0),
            populate: this.attr(null),
            // state
            $active: this.boolean(false),
            $disabled: this.boolean(false),
            $expanded: this.boolean(false),
            $hidden: this.boolean(false),
            $loading: this.boolean(false),
            $readonly: this.boolean(false)
        };
    }

    get is_parent() {
        return this.items.length > 0 || this.populate;
    }

    get is_expandable() {
        return this.is_parent && !this.$loading && !this.$disabled && !this.$hidden;
    }

    get has_route() {
        return Object.keys(this.route).length > 0;
    }

    get has_route_match() {
        if (!this.has_route) {
            return false;
        }

        const currentRoute = router.currentRoute;

        if (currentRoute.name !== this.route.name) {
            return false;
        }

        for (const param in this.route.params) {
            if (currentRoute.params[`${param}`] !== this.route.params[`${param}`]) {
                return false;
            }
        }

        const queryParams = getQueryParams();

        for (const query in this.route.query) {
            if (!queryParams[`${query}`] || queryParams[`${query}`] !== this.route.query[`${query}`]) {
                return false;
            }
        }

        return true;
    }

    get is_clickable() {
        return (
            this.has_route &&
            !this.$disabled &&
            !this.$hidden &&
            !this.$readonly &&
            (!this.$active || (this.$active && !this.has_route_match))
        );
    }

    get authorised() {
        if (this.permissions.length === 0) {
            return true;
        }

        let passed = 0;

        for (let p of this.permissions) {
            if (Auth().can(p)) {
                passed++;
            }
        }

        return passed === this.permissions.length;
    }

    get items_has_route_match() {
        if (!this.is_parent) {
            return false;
        }

        const itemHas = this.items.find(item => item.has_route_match);
        const itemsHas = this.items.find(item => item.items_has_route_match);

        return itemHas || itemsHas;
    }

    async setup(force = false) {
        if (!force && this.setupLoaded) {
            return this;
        }

        this.$active = false;

        if (this.is_parent) {
            this.items = await Promise.all(this.items.map(item => item.setup(force)));
        }

        if (this.has_route_match) {
            if (!this.items.length && this.populate) {
                await this.load();
                this.items = await Promise.all(this.items.map(item => item.setup(force)));
            }
            this.$active = !this.items_has_route_match;
        }

        if (this.items_has_route_match) {
            this.$expanded = true;
        }

        this.setupLoaded = this.setupLoaded + 1;
        return this;
    }

    async load() {
        if (this.populate) {
            this.$loading = true;
            this.items = await this.populate();
            this.$expanded = this.items.length > 0;
            this.$loading = false;
        }
        return this;
    }

    click() {
        if (!this.is_clickable) {
            return this;
        }

        this.$active = false;
        this.items = this.items.map(item => item.set_inactive());
        this.$active = true;

        return this;
    }

    set_inactive() {
        this.$active = false;
        this.items = this.items.map(item => item.set_inactive());

        return this;
    }
}

export default AppListItem;
