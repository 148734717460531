import { OfferCollection } from '@/models';
import i18n from '@/lib/i18n';

export class OfferEsgCollection extends OfferCollection {
    static type = 'OfferEsgCollection';

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            })
        ];
    }

    get default_headers() {
        return ['name'];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('is') + ':ALTS'
        };
    }
}

export default OfferEsgCollection;
