import { BaseModel, User } from '@/models';
import OrganisationTypeEnum from '@/enums/organisation/type';

export class ProposalSignatory extends BaseModel {
    static entity = 'proposal-signatories';

    static fields() {
        return {
            ...super.fields(),
            cc: this.boolean(true).nullable(),
            role: this.string(null).nullable(),
            type: this.enum(OrganisationTypeEnum).nullable(),
            order: this.number(0).nullable(),
            user_id: this.string(null).nullable(),
            user: this.belongsTo(User, 'user_id')
        };
    }
}

export default ProposalSignatory;
