import toNumber from './toNumber';
import isObject from 'lodash/isObject';

/**
 * Currency Formatting
 * @param {Number} value
 * @returns String
 */
const toCurrency = (value, currency = 'GBP', decimals = 2, abbreviate = false) => {
    let options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: isObject(decimals) ? 2 : decimals
    };

    if (isObject(decimals)) {
        options = {
            ...options,
            ...decimals
        };
    }

    if (abbreviate) {
        options = {
            ...options,
            notation: 'compact',
            compactDisplay: 'short',
            minimumFractionDigits: 0
        };
    }

    return toNumber(value, options);
};

export default toCurrency;
