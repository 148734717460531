import { Collection, InvestorAdviserRelationship, Investor } from '@/models';
import i18n from '@/lib/i18n';

export class IntermediaryCollection extends Collection {
    static type = 'IntermediaryCollection';
    static model = InvestorAdviserRelationship;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id')
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('adviser_name', i18n.t('name'), {
                transform: data => data.item.adviser.name || null,
                width: '14rem',
                minWidth: '14rem',
                filterable: true
            }),
            this.createHeader('reference', i18n.t('reference'), {
                transform: data => this.investor.getAdviserPlatformAccount(data.item.adviser_id).gi_ref || null,
                width: '9rem'
            }),
            this.createHeader('adviser_fca_number', i18n.t('fca_number'), {
                transform: data => data.item.adviser.fca_number || null,
                width: '9rem'
            }),
            this.createHeader('adviser_tel', i18n.t('phone_number'), {
                transform: data => data.item.adviser?.company_info?.tel?.e164 || null,
                width: '10rem'
            }),
            // this.createHeader('adviser_address', i18n.t('address'), {
            //     transform: data => data.item.adviser.address || null,
            //     width: '12rem'
            // }),
            // this.createHeader('adviser_website', i18n.t('website'), {
            //     transform: data => data.item.adviser.website || null,
            //     width: '10rem',
            //     to: data => data.item.adviser.website
            // }),
            this.createHeader('primary_contact_name', i18n.t('primary_contact'), {
                transform: data => data.item.adviser.primary_contact?.name || null,
                width: '10rem'
            }),
            this.createHeader('visibility_groups', i18n.t('visibility_groups'), {
                width: '14rem',
                active: this.Auth().is_gi || (this.Auth().is_adviser && this.Auth().has_role('SUPERADMIN')),
                transform: data => data.item.visibility_groups.map(vg => vg.name).join(', ') || null
            }),
            this.createHeader('investment_account_applied_at', i18n.t('application_date'), {
                format: 'datetime',
                width: '11rem',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('investment_account_authorised_at', i18n.t('authorisation_date'), {
                format: 'datetime',
                width: '11rem',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('advised', i18n.t('advised'), {
                width: '8rem',
                format: 'boolean',
                active: false
            }),
            this.createHeader('control', '', {
                active: !this.Auth().is_investor,
                format: 'control',
                width: '10.8rem',
                persistent: true
            })
        ];
    }
}

export default IntermediaryCollection;
