import { MediaCollection, MediaBranding } from '@/models';

export class MediaBrandingCollection extends MediaCollection {
    static type = 'MediaBrandingCollection';
    static endpoint = MediaBranding.api.index;
    static model = MediaBranding;

    static fields() {
        return {
            ...super.fields(),
            adviser_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.adviser_id) {
            // filters.adviser_id = this.filterOperator('is') + ':' + this.adviser_id;
            filters.adviser_id = this.adviser_id;
        }

        if (this.media_format) {
            filters.format = this.media_format_filter_value;
        }

        if (this.media_type) {
            filters.type = this.media_type_filter_value;
        }

        return filters;
    }
}

export default MediaBrandingCollection;
