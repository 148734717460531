import store from '@/store';

const clearQueues = async (to, from, next) => {
    await store.dispatch('snackQueue/clear');
    await store.dispatch('dialogQueue/clear');

    next();
};

export default clearQueues;
