const replaceQueryParam = (key, value) => {
    const currentUrl = window.location.href;
    const params = new URLSearchParams(currentUrl.split('?')[1]);

    if (value === null || value === undefined) {
        params.delete(key);
    } else {
        params.set(key, value);
    }

    let newUrl = currentUrl.split('?')[0];

    if (params.toString() !== '') {
        newUrl += '?' + params.toString();
    }

    window.history.replaceState({}, '', newUrl);
};

export default replaceQueryParam;
