import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';
import Investor from '@/models/Investor';

const setOrganisation = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (!auth.organisation_id) {
            auth.investor = null;
        } else if (auth.is_investor && (!auth.investor || auth.investor.id !== auth.organisation_id)) {
            try {
                const investor = await Investor.$get(auth.organisation_id);
                auth.investor = investor;
            } catch (error) {
                auth.investor = null;
            }
        }

        next();
    }
};

export default setOrganisation;
