import store from '@/store';

/**
 * Add a snack item to the queue
 * @param {Object} options
 */
const snack = options => {
    store.dispatch(`snackQueue/add`, options);
};

export default snack;
