import { Extension as TiptapExtension } from '@tiptap/core';
import { v4 as uuid } from 'uuid';
import { Plugin, PluginKey } from '@tiptap/pm/state';

const OutlinePlugin = ({ getId, anchorTypes = ['heading'] }) =>
    new Plugin({
        key: new PluginKey('outline'),
        appendTransaction(transactions, oldState, newState) {
            const tr = newState.tr;
            let hasChanged = false;

            if (transactions.some(transaction => transaction.docChanged)) {
                const usedIds = [];

                newState.doc.descendants((node, pos) => {
                    const existingId = node.attrs['data-outline-id'];

                    if (anchorTypes.includes(node.type.name) && node.textContent.length !== 0) {
                        if (!existingId || usedIds.includes(existingId)) {
                            let newId = '';
                            newId = getId ? getId(node.textContent) : uuid();
                            tr.setNodeMarkup(pos, undefined, { ...node.attrs, 'data-outline-id': newId, id: newId });
                            hasChanged = true;
                        }
                        usedIds.push(existingId);
                    }
                });
            }

            return hasChanged ? tr : null;
        }
    });

const getLastHeadingOnLevel = (headings, level) => {
    let result = headings.filter(heading => heading.level === level).pop();
    if (level !== 0) {
        return result || getLastHeadingOnLevel(headings, level - 1);
    }
};

const getHeadlineLevel = (item, outlineItems) => {
    let currentLevel = 1;
    const lastItem = outlineItems.at(-1);
    const lastItemWithEqualOrHigherLevel = [...outlineItems]
        .reverse()
        .find(item => item.originalLevel <= item.node.attrs.level);

    const previousLevel = lastItemWithEqualOrHigherLevel?.level || 1;
    if (item.node.attrs.level > (lastItem?.originalLevel || 1)) {
        currentLevel = (lastItem?.level || 1) + 1;
    } else if (item.node.attrs.level < (lastItem?.originalLevel || 1)) {
        currentLevel = previousLevel;
    } else {
        currentLevel = lastItem?.level || 1;
    }
    return currentLevel;
};

const getLinearIndexes = (item, outlineItems) => {
    const lastItem = outlineItems.at(-1);
    return lastItem ? (lastItem.itemIndex || 1) + 1 : 1;
};

const getHierarchicalIndexes = (item, outlineItems, currentLevel) => {
    const level = currentLevel || item.node.attrs.level || 1;
    let index = 1;
    const itemsAtSameOrHigherLevel = outlineItems.filter(outlineItem => outlineItem.level <= level);

    if (itemsAtSameOrHigherLevel.at(-1)?.level === level) {
        index = (itemsAtSameOrHigherLevel.at(-1)?.itemIndex || 1) + 1;
    }
    return index;
};

const getPrefix = (item, outlineItems) => {
    const lastItem = outlineItems.at(-1);
    if (!lastItem) {
        return '1';
    }

    let prefix = '';
    const level = item.node.attrs.level || 1;

    if (level === lastItem.originalLevel + 1) {
        prefix = lastItem.itemPrefix + '.1';
    } else if (level > lastItem.originalLevel + 1) {
        // FILL THE GAPS BETWEEN THE LAST ITEM
        const gap = level - lastItem.originalLevel + 1;
        prefix = lastItem.itemPrefix + '.' + Array(gap).join('1.').replace(/\.$/, '');
    } else if (level === lastItem.originalLevel) {
        const lastNumber = parseInt(lastItem.itemPrefix.match(/\d+$/), 10);
        prefix = lastItem.itemPrefix.replace(/\d+$/, (lastNumber + 1).toString());
    } else {
        let matchingItem = outlineItems
            .slice()
            .reverse()
            .find(outlineItem => outlineItem.originalLevel === level);

        if (matchingItem) {
            const lastNumber = parseInt(matchingItem.itemPrefix.match(/\d+$/), 10);
            prefix = matchingItem.itemPrefix.replace(/\d+$/, (lastNumber + 1).toString());
        } else {
            prefix = level === 1 ? '1' : `${level}.1`;
        }
    }

    return prefix;
};

const updateOutline = (content, options) => {
    const { editor, anchorTypes } = options;
    let headings = [];
    let scrolledOverHeadings = [];
    let activeHeading = null;

    editor.state.doc.descendants((node, pos) => {
        if (anchorTypes.includes(node.type.name)) {
            headings.push({ node, pos });
        }
    });

    headings.forEach(heading => {
        const domNode = editor.view.domAtPos(heading.pos + 1).node;
        if (options.storage.scrollPosition >= domNode.offsetTop) {
            activeHeading = heading.node.attrs['data-outline-id'];
            scrolledOverHeadings.push(heading.node.attrs['data-outline-id']);
        }
    });

    content = content.map(item => ({
        ...item,
        isActive: item.id === activeHeading,
        isScrolledOver: scrolledOverHeadings.includes(item.id)
    }));

    if (options.onUpdate) {
        const initialLoad = options.storage.content.length === 0;
        options.onUpdate(content, initialLoad);
    }

    return content;
};

const createOutline = options => {
    const { editor, onUpdate, getIndexFn, getPrefixFn, getLevelFn, anchorTypes } = options;
    let outlineItems = [];
    let headings = [];

    editor.state.doc.descendants((node, pos) => {
        if (anchorTypes.includes(node.type.name)) {
            headings.push({ node, pos });
        }
    });

    headings.forEach(heading => {
        if (heading.node.textContent.length === 0) return;

        const domNode = editor.view.domAtPos(heading.pos + 1).node;
        const isScrolledOver = options.storage.scrollPosition >= domNode.offsetTop;
        const originalLevel = heading.node.attrs.level;
        const level = getLevelFn(heading, outlineItems);
        const itemIndex = getIndexFn(heading, outlineItems, level);
        const itemPrefix = getPrefixFn(heading, outlineItems, level);

        outlineItems.push({
            itemIndex,
            itemPrefix,
            id: heading.node.attrs['data-outline-id'],
            originalLevel,
            level,
            textContent: heading.node.textContent,
            pos: heading.pos,
            isActive: false,
            isScrolledOver,
            node: heading.node,
            dom: domNode
        });
    });

    outlineItems = updateOutline(outlineItems, options);

    if (onUpdate) {
        const initialLoad = options.storage.content.length === 0;
        onUpdate(outlineItems, initialLoad);
    }

    options.storage.anchors = headings.map(h => h.node);
    options.storage.content = outlineItems;
    editor.view.dispatch(editor.state.tr.setMeta('outline', outlineItems));
};

const OutlineExtension = TiptapExtension.create({
    name: 'outline',

    addStorage() {
        return {
            content: [],
            anchors: [],
            scrollHandler: () => null,
            scrollPosition: 0
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.anchorTypes || ['headline'],
                attributes: {
                    id: {
                        default: null,
                        renderHTML: attributes => ({ id: attributes.id }),
                        parseHTML: element => element.id || null
                    },
                    'data-outline-id': {
                        default: null,
                        renderHTML: attributes => ({ 'data-outline-id': attributes['data-outline-id'] }),
                        parseHTML: element => element.dataset.outlineId || null
                    }
                }
            }
        ];
    },

    addOptions() {
        return {
            onUpdate: () => {},
            getId: () => uuid(),
            scrollParent: typeof window !== 'undefined' ? () => window : undefined,
            anchorTypes: ['heading']
        };
    },

    onUpdate() {
        const options = {
            editor: this.editor,
            storage: this.storage,
            onUpdate: this.options.onUpdate,
            getIndexFn: this.options.getIndex || getHierarchicalIndexes,
            getPrefixFn: this.options.getPrefix || getPrefix,
            getLevelFn: this.options.getLevel || getHeadlineLevel,
            anchorTypes: this.options.anchorTypes
        };
        createOutline(options);
    },

    onCreate() {
        const { tr } = this.editor.state;
        const usedIds = [];

        this.editor.state.doc.descendants((node, pos) => {
            const existingId = node.attrs['data-outline-id'];

            if (this.options.anchorTypes.includes(node.type.name) && node.textContent.length !== 0) {
                if (!existingId || usedIds.includes(existingId)) {
                    let newId = '';
                    newId = this.options.getId ? this.options.getId(node.textContent) : uuid();
                    tr.setNodeMarkup(pos, undefined, { ...node.attrs, 'data-outline-id': newId, id: newId });
                }
                usedIds.push(existingId);
            }
        });

        this.editor.view.dispatch(tr);

        createOutline({
            editor: this.editor,
            storage: this.storage,
            onUpdate: this.options.onUpdate,
            getIndexFn: this.options.getIndex || getLinearIndexes,
            getPrefixFn: this.options.getPrefix || getPrefix,
            getLevelFn: this.options.getLevel || getHeadlineLevel,
            anchorTypes: this.options.anchorTypes
        });

        this.storage.scrollHandler = () => {
            const scrollParent = this.options.scrollParent();
            const scrollPosition = scrollParent instanceof HTMLElement ? scrollParent.scrollTop : scrollParent.scrollY;
            this.storage.scrollPosition = scrollPosition || 0;

            const updatedContent = updateOutline(this.storage.content, {
                editor: this.editor,
                anchorTypes: this.options.anchorTypes,
                storage: this.storage,
                onUpdate: this.options.onUpdate
            });

            this.storage.content = updatedContent;
        };

        if (this.options.scrollParent) {
            const scrollParent = this.options.scrollParent();
            scrollParent.addEventListener('scroll', this.storage.scrollHandler);
        }
    },

    onDestroy() {
        const scrollParent = this.options.scrollParent();
        scrollParent?.removeEventListener('scroll', this.storage.scrollHandler);
    },

    addProseMirrorPlugins() {
        return [OutlinePlugin({ getId: this.options.getId, anchorTypes: this.options.anchorTypes })];
    }
});

export {
    OutlineExtension as Outline,
    OutlinePlugin,
    OutlineExtension as default,
    getHeadlineLevel,
    getHierarchicalIndexes,
    getLastHeadingOnLevel,
    getLinearIndexes,
    getPrefix
};
