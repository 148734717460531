import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class NationalInsuranceNumberValidator extends BaseValidator {
    static key = 'nationalInsuranceNumber';

    static validator(value) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = value.toUpperCase();
            value = value.replace(/\s/g, '');

            /* eslint-disable max-len */
            // prettier-ignore
            const rgx = new RegExp(/^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i);

            valid = value.match(rgx);
        }

        if (!valid) {
            return i18n.t('validator.national_insurance_number');
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default NationalInsuranceNumberValidator;
