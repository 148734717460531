import i18n from '@/lib/i18n';
import upperFirst from 'lodash/upperFirst';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import { tabKeys } from '@/views/Settings';

export default [
    {
        path: '/settings',
        name: 'Settings',
        component: () =>
            import(
                /* webpackChunkName: "settings" */
                '@/views/Settings.vue'
            ),
        meta: {
            title: i18n.t('settings'),
            heading: i18n.t('settings')
        },
        children: tabKeys.map(tab => ({
            path: '/settings/' + kebabCase(tab),
            name: 'Settings' + upperFirst(camelCase(tab)),
            component: () =>
                import(
                    /* webpackChunkName: "settings" */
                    '@/views/Settings' + upperFirst(camelCase(tab)) + '.vue'
                ),
            meta: {
                title: i18n.t('settings') + ' - ' + i18n.t('enums.settings_tabs.' + snakeCase(tab)),
                heading: i18n.t('enums.settings_tabs.' + snakeCase(tab))
            }
        }))
    }
];
