import config from '@/config';

export default {
    mounted() {
        if (!config.FRESHDESK_WIDGET_ID) {
            console.warn('Freshdesk widget ID not set');
            return;
        }

        window.fwSettings = {
            widget_id: config.FRESHDESK_WIDGET_ID,
            locale: 'en'
        };

        !(function () {
            if ('function' != typeof window.FreshworksWidget) {
                var n = function () {
                    n.q.push(arguments);
                };
                (n.q = []), (window.FreshworksWidget = n);
            }
        })();

        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = `https://euc-widget.freshworks.com/widgets/${config.FRESHDESK_WIDGET_ID}.js`;
        document.head.appendChild(script);
    }
};
