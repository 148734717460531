const UpdateFrequencyEnum = Object.freeze({
    HOURLY: 'HOURLY',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHTLY: 'FORTNIGHTLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    BIANNUALLY: 'BIANNUALLY',
    ANNUALLY: 'ANNUALLY'
});

export default UpdateFrequencyEnum;
