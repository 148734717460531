/**
 * Return an object to preview an image File
 * @param {File} file
 */
const toImage = file => {
    try {
        return {
            name: file.name,
            size: file.size,
            type: file.type,
            src: URL.createObjectURL(file)
        };
    } catch (error) {
        return null;
    }
};

export default toImage;
