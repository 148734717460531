import AppDialog from '@/components/AppDialog';
import uuid from '@/lib/helpers/uuid';
import toPropObject from '@/lib/helpers/toPropObject';
import isNil from 'lodash/isNil';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

export default {
    dialogQueue: {
        namespaced: true,
        state: {
            items: []
        },
        getters: {
            items: state => state.items,
            item: state => {
                return id => {
                    if (isNil(id)) return null;
                    return find(state.items, ['id', id]);
                };
            }
        },
        actions: {
            add({ commit }, options) {
                options = { ...toPropObject(AppDialog), ...options };

                if (!has(options, 'id')) {
                    options.id = uuid(8);
                }

                commit('ADD_ITEM', options);
            },
            async remove({ commit }, id) {
                await commit('REMOVE_ITEM', id);
            },
            clear({ commit }) {
                commit('CLEAR_ALL_ITEMS');
            }
        },
        mutations: {
            ADD_ITEM(state, payload) {
                state.items.push(payload);
            },
            REMOVE_ITEM(state, id) {
                const index = findIndex(state.items, ['id', id]);
                if (index > -1) {
                    state.items.splice(index, 1);
                }
            },
            CLEAR_ALL_ITEMS(state) {
                state.items = [];
            }
        }
    }
};
