import { Collection } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';
import clock from '@/lib/clock';
import { yearsAndMonths } from '@/lib/utils/duration';
import ReportApi from '@/api/ReportApi';

export class ReportNegligibleValueCollection extends Collection {
    static type = 'ReportNegligibleValueCollection';
    static endpoint = new ReportApi().methods.getInvestorNegligibleValue;

    static fields() {
        return {
            adviser_id: this.string(null).nullable(),
            investor_id: this.string(null).nullable(),
            tax_year: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.adviser_id) {
            filters.adviser_id = this.filterOperator('is') + ':' + this.adviser_id;
        }

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.tax_year) {
            filters.tax_year = this.filterOperator('is') + ':' + this.tax_year;
        }

        return filters;
    }

    get money_format_options() {
        return {
            decimals: 2,
            abbreviate: false
        };
    }

    get headers() {
        let headers = [
            this.createHeader('product', i18n.t('name'), {
                filterable: true,
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            }),
            this.createHeader('fund', i18n.t('fund'), {
                filterable: true,
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            }),
            this.createHeader('investor', i18n.t('investor'), {
                filterable: true,
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            }),
            this.createHeader('type', i18n.t('type'), {
                format: 'enum',
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                width: '6rem'
            }),
            this.createHeader('invested_amount', i18n.t('purchase_cost'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '12rem'
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '11rem'
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '8rem'
            }),
            this.createHeader('tax_relief', i18n.t('applicable_tax_relief'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '12rem'
            }),
            this.createHeader('current_value', i18n.t('current_value'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '12rem'
            }),
            this.createHeader('tax_year', i18n.t('tax_year'), {
                width: '10rem',
                transform: data => this.getTaxYear(data.item.tax_year, { abbreviate: true }),
                sortable: true
            }),
            this.createHeader('duration_of_holding', i18n.t('duration_of_holding'), {
                width: '12rem',
                transform: data => {
                    try {
                        return yearsAndMonths(clock(data.item.share_issue_on), clock());
                    } catch (error) {
                        return null;
                    }
                },
                sortable: true
            })
            // this.createHeader('disposal_letter', i18n.t('disposal_letter'), {
            //     width: '10rem',
            //     format: 'boolean',
            //     sortable: true
            // }),
            // this.createHeader('disposal_letter_on', i18n.t('disposal_letter_date'), {
            //     width: '12rem',
            //     format: 'date'
            // })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'product',
            'fund',
            'type',
            'investor',
            'invested_amount',
            'share_issue_on',
            'share_quantity',
            'tax_relief',
            'current_value',
            'tax_year',
            'duration_of_holding',
            'disposal_letter',
            'disposal_letter_on'
        ];
    }

    get investor_headers() {
        return [
            'product',
            'fund',
            'type',
            'invested_amount',
            'share_issue_on',
            'share_quantity',
            'tax_relief',
            'current_value',
            'tax_year',
            'duration_of_holding',
            'disposal_letter',
            'disposal_letter_on'
        ];
    }
}

export default ReportNegligibleValueCollection;
