import { BaseModel } from '@/models';

export class AgreementSignatory extends BaseModel {
    static entity = 'agreements';

    static fields() {
        return {
            id: this.string(null).nullable(),
            external_id: this.string(null).nullable(),
            participant_set_id: this.string(null).nullable(),
            email: this.string(null).nullable(),
            name: this.string(null).nullable(),
            role: this.string(null).nullable(),
            order: this.number(null).nullable(),
            user: this.attr(null).nullable()
        };
    }
}

export default AgreementSignatory;
