import { Collection, Fund } from '@/models';
import i18n from '@/lib/i18n';
import FundTypeEnum from '@/enums/fund/type';
import FundStatusEnum from '@/enums/fund/status';

export class SystemFundsCollection extends Collection {
    static type = 'SystemFundsCollection';
    static endpoint = Fund.api.index;
    static model = Fund;

    get default_headers() {
        return ['name', 'gi_ref', 'type', 'status'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '15rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: FundTypeEnum,
                enumA11yKey: 'enums.fund_type'
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                filterable: true,
                width: '7rem',
                enum: FundStatusEnum,
                enumA11yKey: 'enums.fund_status',
                enumTypes: {
                    [FundStatusEnum.OPEN]: 'info',
                    [FundStatusEnum.CLOSED]: 'error',
                    [FundStatusEnum.EVERGREEN]: 'success'
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default SystemFundsCollection;
