import { Collection, Offer } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';
import OfferStatusEnum from '@/enums/offer/status';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import SDRLabelEnum from '@/enums/SDRLabel';
import toCurrency from '@/lib/helpers/toCurrency';

export class SystemOffersCollection extends Collection {
    static type = 'SystemOffersCollection';
    static endpoint = Offer.api.index;
    static model = Offer;

    static fields() {
        return {
            ...super.fields(),
            show_approved_panel: this.boolean(false),
            closed: this.boolean(true)
        };
    }

    get default_headers() {
        return [
            'name',
            'gi_ref',
            'tax_status',
            'status',
            'close_at',
            'over_allotment_available',
            'over_allotment',
            'workflow_status'
        ];
    }

    get headers() {
        return [
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            }),
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '12rem',
                minWidth: '12rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '10rem'
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '8rem'
            }),
            this.createHeader('close_at', i18n.t('close_at'), {
                format: 'datetime',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('over_allotment_available', i18n.t('over_allotting'), {
                format: 'boolean',
                path: 'over_allotment_available',
                transform: data => {
                    if (!data.item.over_allotment_actual && !data.item.over_allotment_proposed) {
                        return false;
                    }

                    return data.item.over_allotment_available;
                },
                filterable: true,
                width: '12rem'
            }),
            this.createHeader('over_allotment', i18n.t('over_allotment'), {
                format: 'money',
                transform: data => {
                    if (data.item.over_allotment_actual) {
                        return data.item.over_allotment_actual;
                    } else if (data.item.over_allotment_proposed) {
                        return data.item.over_allotment_proposed;
                    } else {
                        return null;
                    }
                },
                width: '10rem'
            }),
            this.createHeader('workflow_status', i18n.t('workflow_status'), {
                format: 'enum',
                width: '9rem',
                filterable: {
                    operators: this.getOperators(['eq', 'neq', 'in'])
                },
                enum: OfferWorkflowStatusEnum,
                enumA11yKey: 'enums.offer_workflow_status',
                enumTypes: {
                    [OfferWorkflowStatusEnum.DRAFT]: 'grey',
                    [OfferWorkflowStatusEnum.LIVE]: 'success'
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                filterable: true,
                width: '7rem',
                enum: OfferStatusEnum,
                enumA11yKey: 'enums.offer_status',
                enumTypes: {
                    [OfferStatusEnum.OPEN]: 'info',
                    [OfferStatusEnum.CLOSED]: 'error',
                    [OfferStatusEnum.EVERGREEN]: 'success',
                    [OfferStatusEnum.COMING_SOON]: 'warning'
                }
            }),

            this.createHeader('sdr_label', i18n.t('sdr_label'), {
                format: 'enum',
                filterable: true,
                enum: SDRLabelEnum,
                enumA11yKey: 'enums.sdr_label',
                width: '8rem',
                active: false
            })
        ];
    }

    get preset_filters() {
        let filters = {
            workflow_status: `${getFilterOperator('in')}:[${OfferWorkflowStatusEnum.DRAFT},${
                OfferWorkflowStatusEnum.LIVE
            }]`
        };

        if (!this.show_approved_panel) {
            filters.off_panel = 'true';
        }

        if (this.closed) {
            filters.closed = 'true';
        }

        return filters;
    }

    get bulk_update_headers() {
        return [
            ...super.bulk_update_headers,
            this.createHeader('name', i18n.t('name'), {
                editable: false,
                filterable: false,
                format: 'string',
                width: '100%',
                component: 'DisplayString',
                componentOptions: {
                    label: true
                }
            }),
            this.createHeader('raised', i18n.t('funds_raised'), {
                editable: true,
                filterable: false,
                format: 'money',
                width: '14rem',
                component: 'FieldMoney'
            }),
            this.createHeader('over_allotment_available', i18n.t('over_allotting'), {
                editable: true,
                filterable: false,
                format: 'boolean',
                width: '9rem',
                component: 'AppSwitch'
                // componentOptions: {
                //     setItemValueCallback: ({ id, value, item, setItemValue }) => {
                //         if (value === false) {
                //             setItemValue(id, 'over_allotment_actual', null, item.over_allotment_actual);
                //         }
                //     }
                // }
            }),
            this.createHeader('over_allotment_actual', i18n.t('over_allotment'), {
                editable: true,
                filterable: false,
                format: 'money',
                width: '14rem',
                component: 'FieldMoney',
                componentOptions: {
                    // disabled: data => data.item.over_allotment_available !== true,
                    placeholder: data => {
                        if (data.item.over_allotment_proposed && data.item.over_allotment_proposed.amount) {
                            return `e.g. ${toCurrency(data.item.over_allotment_proposed.amount)}`;
                        }
                        return null;
                    }
                }
            }),
            this.createHeader('close_at', i18n.t('close_at'), {
                editable: true,
                filterable: true,
                format: 'datetime',
                width: '10rem',
                component: 'FieldDatetime',
                componentOptions: {
                    presetTime: '00:00:00'
                }
            }),
            this.createHeader('next_allotment_at', i18n.t('next_allotment'), {
                editable: true,
                filterable: true,
                format: 'datetime',
                width: '10rem',
                component: 'FieldDatetime',
                componentOptions: {
                    presetTime: '00:00:00'
                }
            }),
            this.createHeader('deadline_at', i18n.t('deadline'), {
                editable: true,
                filterable: true,
                format: 'datetime',
                width: '10rem',
                component: 'FieldDatetime',
                componentOptions: {
                    presetTime: '00:00:00'
                }
            }),
            this.createHeader('deadline_details', i18n.t('deadline_details'), {
                editable: true,
                filterable: false,
                format: 'wysiwyg',
                width: '16rem',
                component: 'FieldPopupWysiwyg',
                componentOptions: {
                    wysiwyg: true
                }
            }),

            // Filters
            this.createHeader('tax_status', i18n.t('tax_status'), {
                active: false,
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                }
            }),
            this.createHeader('workflow_status', i18n.t('workflow_status'), {
                active: false,
                format: 'enum',
                filterable: {
                    operators: this.getOperators(['eq', 'neq', 'in'])
                },
                enum: OfferWorkflowStatusEnum,
                enumA11yKey: 'enums.offer_workflow_status',
                enumTypes: {
                    [OfferWorkflowStatusEnum.DRAFT]: 'grey',
                    [OfferWorkflowStatusEnum.LIVE]: 'success'
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                active: false,
                format: 'enum',
                filterable: true,
                enum: OfferStatusEnum,
                enumA11yKey: 'enums.offer_status',
                enumTypes: {
                    [OfferStatusEnum.OPEN]: 'info',
                    [OfferStatusEnum.CLOSED]: 'error',
                    [OfferStatusEnum.EVERGREEN]: 'success',
                    [OfferStatusEnum.COMING_SOON]: 'warning'
                }
            }),
            this.createHeader('sdr_label', i18n.t('sdr_label'), {
                active: false,
                format: 'enum',
                filterable: true,
                enum: SDRLabelEnum,
                enumA11yKey: 'enums.sdr_label'
            })
        ];
    }
}

export default SystemOffersCollection;
