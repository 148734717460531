<template>
    <node-view-wrapper :class="className">
        <v-checkbox
            v-model="value"
            :class="`${className}__input`"
            color="success"
            :on-icon="getIcon('radioOn')"
            :off-icon="getIcon('radioOff')"
            hide-details
            :readonly="value"
            dense
        />
        <node-view-content :class="`${className}__content`" as="div" />
    </node-view-wrapper>
</template>

<script>
import TiptapRadioList from './TiptapRadioList.vue';
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2';
import wait from '@/lib/helpers/wait';

export default {
    name: 'TiptapRadioItem',
    components: {
        NodeViewWrapper,
        NodeViewContent
    },
    props: {
        ...nodeViewProps
    },
    data: () => ({
        className: 'tiptap-radio-item',
        radioListId: null,
        index: null
    }),
    computed: {
        value: {
            get() {
                return this.node.attrs.value;
            },
            set(value) {
                this.updateAttributes({ value });

                if (value && this.radioListId) {
                    this.$root.$emit(`TiptapRadioList/${this.radioListId}/change`, this.index);
                }
            }
        }
    },
    async mounted() {
        await wait(100);

        this.index = this.getRadioItems().indexOf(this.$el);

        if (this.editor.options.editable) {
            this.updateCount();
        }

        this.$root.$on(`TiptapRadioList/${this.radioListId}/${this.index}`, value => {
            if (value !== this.value) {
                this.value = value;
            }
        });
    },
    beforeDestroy() {
        this.$root.$off(`TiptapRadioList/${this.radioListId}/${this.index}`);
        this.updateCount(true);
    },
    methods: {
        getRadioList() {
            const $radioList = this.$el.closest(`.${TiptapRadioList.data().className}`);
            this.radioListId = $radioList.getAttribute('data-id');
            return $radioList;
        },
        getRadioItems() {
            return Array.from(this.getRadioList().querySelectorAll(`.${this.className}`));
        },
        updateCount(onDestroy = false) {
            const count = onDestroy ? this.getRadioItems().length - 1 : this.getRadioItems().length;
            this.$root.$emit(`TiptapRadioList/${this.radioListId}/updateCount`, count);
        }
    }
};
</script>

<style lang="scss">
.tiptap-radio-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    &__input {
        margin-right: 0.5rem;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
    }
    &__content {
        *:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
