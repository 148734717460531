import { Collection, Terms } from '@/models';
import TermsStatusEnum from '@/enums/terms/status';
import TermsTypeEnum from '@/enums/terms/type';
import i18n from '@/lib/i18n';

export class SystemTermsCollection extends Collection {
    static type = 'SystemTermsCollection';
    static endpoint = Terms.api.index;
    static model = Terms;

    static fields() {
        return {
            ...super.fields(),
            approved: this.boolean(null).nullable()
        };
    }

    get default_headers() {
        return ['status', 'type', 'effective_at', 'summary'];
    }

    get headers() {
        return [
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: false,
                sortable: false,
                width: '8rem',
                format: 'enum',
                enum: TermsStatusEnum,
                enumA11yKey: 'enums.terms_status',
                enumTypes: {
                    [TermsStatusEnum.DRAFT]: 'success',
                    [TermsStatusEnum.APPROVED]: 'error',
                    [TermsStatusEnum.SUPERCEDED]: 'grey'
                }
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                sortable: false,
                width: '12rem',
                format: 'enum',
                enum: TermsTypeEnum,
                enumA11yKey: 'enums.terms_type'
            }),
            this.createHeader('effective_at', i18n.t('effective_at'), {
                width: '10rem',
                filterable: true,
                sortable: false,
                format: 'date'
            }),
            this.createHeader('summary', i18n.t('summary'), {
                width: '100%',
                format: 'wysiwyg',
                sortable: false
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                width: '9rem',
                filterable: true,
                sortable: false,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('created_by_id', i18n.t('created_by'), {
                width: '14rem',
                sortable: false,
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.created_by?.name,
                fallback: i18n.t('system'),
                active: false
            }),
            this.createHeader('last_updated', i18n.t('last_updated_at'), {
                width: '9rem',
                filterable: true,
                sortable: false,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('last_updated_by_id', i18n.t('last_updated_by'), {
                width: '14rem',
                sortable: false,
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.last_updated_by?.name,
                fallback: i18n.t('system'),
                active: false
            })
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.approved === true) {
            filters.status = `${this.filterOperator('is')}:${TermsStatusEnum.APPROVED}`;
        } else if (this.approved === false) {
            filters.status = `${this.filterOperator('is_not')}:${TermsStatusEnum.APPROVED}`;
        }

        return filters;
    }

    transformFilters(filters = {}) {
        if (filters.type && filters.type === 'eq:GLOBAL') {
            filters.type = this.filterOperator('empty');
        } else if (filters.type && filters.type === 'neq:GLOBAL') {
            filters.type = this.filterOperator('not_empty');
        }
        return filters;
    }
}

export default SystemTermsCollection;
