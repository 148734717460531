import { News, Fund } from '@/models';

export class FundNews extends News {
    static entity = 'fundNews';

    static fields() {
        return {
            ...super.fields(),
            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id')
        };
    }
}

export default FundNews;
