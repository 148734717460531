import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import strim from '@/lib/helpers/strim';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';

export class RequiredValidator extends BaseValidator {
    static key = 'required';

    static validator(value, label = 'Field') {
        let valid = false;

        if (typeof value === 'string' && strim(value).length > 0) {
            valid = true;
        } else if (Array.isArray(value) && value.length > 0) {
            valid = true;
        } else if (!Array.isArray(value) && isObject(value) && !isEmpty(value)) {
            if ('currency' in value && 'amount' in value) {
                if (value.amount !== null) {
                    valid = true;
                }
            } else {
                valid = true;
            }
        } else if (isBoolean(value)) {
            valid = true;
        } else if (isNumber(value)) {
            valid = true;
        } else if (value instanceof File) {
            valid = true;
        }

        if (!valid) {
            return label && label !== 'Field'
                ? i18n.t('validator.required_field', { field: label })
                : i18n.t('validator.required');
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default RequiredValidator;
