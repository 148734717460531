import i18n from '@/lib/i18n';
import clock from '@/lib/clock';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import has from 'lodash/has';
import filter from 'lodash/filter';

const findNextOfferEvent = offer => {
    let events = [];

    if (has(offer, 'open_at')) {
        events.push({
            name: i18n.t('open_at'),
            date: offer.open_at
        });
    }

    if (has(offer, 'close_at')) {
        events.push({
            name: i18n.t('close_at'),
            date: offer.close_at
        });
    }

    if (has(offer, 'existing_investor_close_at')) {
        events.push({
            name: i18n.t('existing_investor_close_at'),
            date: offer.existing_investor_close_at
        });
    }

    if (has(offer, 'early_bird_close_at')) {
        events.push({
            name: i18n.t('early_bird_close_at'),
            date: offer.early_bird_close_at
        });
    }

    if (has(offer, 'next_allotment_at')) {
        events.push({
            name: i18n.t('next_allotment_at'),
            date: offer.next_allotment_at
        });
    }

    events = filter(events, event => {
        const date = clock(event.date);
        return date.isValid() && date.isAfter(clock());
    });

    events = sortBy(events, event => {
        return clock(event.date).valueOf();
    });

    return head(events);
};

export default findNextOfferEvent;
