import { Collection, Tranche } from '@/models';
import i18n from '@/lib/i18n';

export class TrancheCollection extends Collection {
    static type = 'TrancheCollection';
    static endpoint = Tranche.api.index;
    static model = Tranche;

    get headers() {
        return [
            this.createHeader('fund_name', i18n.t('fund'), {
                path: 'fund.name',
                width: '18rem',
                minWidth: '18rem',
                to: data => ({ name: 'SystemDataEntryTranchesEdit', params: { id: data.item.id } })
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                format: 'boolean',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('discretionary', i18n.t('discretionary'), {
                format: 'boolean',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('multi_investment', i18n.t('multi_investment'), {
                format: 'boolean',
                filterable: true,
                width: '10rem'
            }),
            this.createHeader('cash_balance', i18n.t('cash_balance'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem'
            }),
            this.createHeader('total_investment', i18n.t('total_investment'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem'
            }),
            this.createHeader('invested_on', i18n.t('invested_on'), {
                format: 'date',
                width: '9rem'
            }),
            this.createHeader('investor_name', i18n.t('investor'), {
                path: 'investor.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('adviser_name', i18n.t('adviser'), {
                path: 'adviser.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default TrancheCollection;
