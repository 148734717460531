import { Collection, Investor, Product } from '@/models';
import i18n from '@/lib/i18n';

export class DriManagementPanelCollection extends Collection {
    static type = 'DriManagementPanelCollection';
    static endpoint = Investor.api.products;
    static model = Product;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable(),
            provider_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {
            dri_available: this.filterOperator('is') + ':' + true
        };

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.provider_id) {
            filters.provider_id = this.filterOperator('is') + ':' + this.provider_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('product'), {
                width: '100%',
                minWidth: '18rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem',
                active: false
            }),
            this.createHeader('control', i18n.t('dri'), {
                format: 'control',
                width: '8rem',
                align: 'center',
                persistent: true
            })
        ];
    }
}

export default DriManagementPanelCollection;
