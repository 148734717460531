import { BaseModel, Product, Fund, Holding } from '@/models';
import ProposalInvestmentStatusEnum from '@/enums/proposal/investmentStatus';
import ProductApi from '@/api/ProductApi';
import FundApi from '@/api/FundApi';
import $m from '@/lib/money';
import floor from '@/lib/helpers/floor';

export class ProposalInvestment extends BaseModel {
    static entity = 'proposal-investments';

    static fields() {
        return {
            ...super.fields(),
            status: this.enum(ProposalInvestmentStatusEnum, null).nullable(),
            amount: this.attr(this.defaultMoney).nullable(),
            _amount: this.attr(this.defaultMoney).nullable(), // Exact amount without rounding
            share_quantity: this.number(null).nullable(),
            share_price: this.attr(this.defaultMoney).nullable(),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id'),

            holding_id: this.string(null).nullable(),
            holding: this.belongsTo(Holding, 'holding_id')
        };
    }

    static mock(data = {}) {
        return {
            status: faker =>
                faker.helpers.arrayElement([
                    ProposalInvestmentStatusEnum.REJECTED,
                    ProposalInvestmentStatusEnum.PENDING_ALLOTMENT,
                    ProposalInvestmentStatusEnum.COMPLETED
                ]),
            amount: faker => ({
                amount: faker.number.float(10000, 1000000),
                currency: 'GBP'
            }),
            share_quantity: faker => faker.number.int(1, 10000),
            share_price: faker => ({
                amount: faker.number.float(1, 100),
                currency: 'GBP'
            }),
            product: ProductApi,
            product_id: (faker, item) => item.product.id,
            fund: FundApi,
            fund_id: (faker, item) => item.fund.id,
            holding: null,
            holding_id: null,
            ...data
        };
    }

    get name() {
        if (this.product) {
            return this.product.name;
        } else if (this.fund) {
            return this.fund.name;
        }
        return null;
    }

    get currency() {
        if (this.product) {
            return this.product.currency || 'GBP';
        } else if (this.fund) {
            return this.fund.currency || 'GBP';
        }
        return 'GBP';
    }

    get is_rejected() {
        return this.status === ProposalInvestmentStatusEnum.REJECTED;
    }

    get is_pending_allotment() {
        return this.status === ProposalInvestmentStatusEnum.PENDING_ALLOTMENT;
    }

    get is_completed() {
        return this.status === ProposalInvestmentStatusEnum.COMPLETED;
    }

    get status_type() {
        if (this.is_rejected) {
            return 'error';
        } else if (this.is_pending_allotment) {
            return 'warning';
        } else if (this.is_completed) {
            return 'success';
        }
        return null;
    }

    get has_rounded_amount() {
        const fixed = this.amount?.amount || 0;
        const exact = this._amount?.amount || 0;

        return fixed !== exact && fixed === Number(exact.toFixed(2));
    }

    get rounded_loss() {
        const fixed = this.amount?.amount || 0;
        const exact = this._amount?.amount || 0;

        return fixed - exact;
    }

    recalculateShareQuantity() {
        if (this.share_price) {
            const fixedAmount = this.amount?.amount || null;
            const exactAmount = this._amount?.amount || null;

            if (exactAmount && fixedAmount !== exactAmount && fixedAmount === Number(exactAmount.toFixed(2))) {
                // If amount is not equal to the exact amount and the amount is equal to the exact amount rounded
                // to 2 decimal places, do not recalculate share quantity.
                return;
            }

            const data = this.getCalculatedShareQuantity(this.amount);

            if (data) {
                this.share_quantity = data.share_quantity;
                this.amount = data.amount;
                this._amount = data.amount;

                return;
            }
        }

        this.share_quantity = 0;
    }

    getCalculatedShareQuantity(amount) {
        if (!amount || !amount?.amount) {
            return null;
        }

        if (!this.share_price || !this.share_price?.amount) {
            return null;
        }

        let share_quantity = floor($m(Math.abs(amount.amount)).divide(this.share_price.amount).value, 0);
        amount = $m(share_quantity).multiply(this.share_price.amount, { precision: 2 }).value;

        return { share_quantity, amount: { amount, currency: this.currency } };
    }

    recalculateAmount() {
        if (this.share_quantity) {
            const data = this.getCalculatedAmount(this.share_quantity);

            if (data) {
                this.amount = data.amount;
                this.share_quantity = data.share_quantity;

                if (this.amount && this.amount.amount) {
                    const exactAmount = this.amount.amount;

                    this._amount = {
                        amount: exactAmount,
                        currency: this.amount.currency
                    };

                    const fixedAmount = Number(exactAmount.toFixed(2));

                    if (exactAmount !== fixedAmount) {
                        this.amount.amount = fixedAmount;
                    }
                }

                return;
            }
        }

        this.amount = {
            amount: 0,
            currency: this.currency
        };

        this._amount = {
            amount: 0,
            currency: this.currency
        };
    }

    getCalculatedAmount(share_quantity) {
        if (!share_quantity) {
            return null;
        }

        share_quantity = Number(share_quantity);

        if (isNaN(share_quantity)) {
            return null;
        }

        if (!this.share_price || !this.share_price?.amount) {
            return null;
        }

        const amount = {
            amount: $m(share_quantity).multiply(this.share_price.amount, { precision: 2 }).value,
            currency: this.currency
        };

        return { amount, share_quantity };
    }
}

export default ProposalInvestment;
