import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class IsTrueValidator extends BaseValidator {
    static key = 'isTrue';

    static validator(value, label) {
        if (!value !== true) {
            return i18n.t('validator.true', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default IsTrueValidator;
