import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import strim from '@/lib/helpers/strim';

export class PostcodeValidator extends BaseValidator {
    static key = 'postcode';

    static validator(value, label) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = strim(value).toUpperCase();

            const rgx = new RegExp(
                // eslint-disable-next-line max-len
                /(?:(?:[A-PR-UWYZ][A-HK-Y]?[0-9](?:[0-9]|[ABEHMNPRVWXY])?|WC[0-9][A-HJKPQS]) ?[0-9][ABD-HJLNP-UW-Z]{2}|GIR ?0AA)$/
            );

            valid = value.match(rgx);
        }

        if (!valid) {
            return i18n.t('validator.postcode', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default PostcodeValidator;
