import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class UrlValidator extends BaseValidator {
    static key = 'url';

    static validator(value, label = 'Day') {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = value.replace(/\s/g, '');
            valid = value.startsWith('http://') || value.startsWith('https://');
        }

        if (!valid) {
            return i18n.t('validator.url', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default UrlValidator;
