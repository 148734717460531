import http from '@/lib/http';

// Base URL
export const baseURL = http.defaults.baseURL;

// API methods
import get from '@/api/lib/get';
import head from '@/api/lib/head';
import options from '@/api/lib/options';
import patch from '@/api/lib/patch';
import post from '@/api/lib/post';
import put from '@/api/lib/put';
import remove from '@/api/lib/remove';

// API helpers
import removeNull from '@/api/lib/helpers/removeNull';

// Export API methods and helpers
export default {
    get,
    head,
    options,
    patch,
    post,
    put,
    remove,
    helpers: {
        removeNull
    }
};
