import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapCheckbox from '@/lib/tiptap/components/TiptapCheckbox.vue';

export const css = `
.tiptap-checkbox {
    position: relative;
    background: var(--v-background-darken2);
    border-radius: 4px;
    padding: 1rem 1.4rem 1rem 3rem;
    margin-bottom: 1rem;
}
.tiptap-checkbox :last-child {
    margin-bottom: 0;
}
.tiptap-checkbox input[type=checkbox] {
    position: absolute;
    top: 1.1rem;
    left: 1rem;
    height: 1.1rem;
    width: 1.1rem;
    accent-color: var(--v-success-base);
}
.tiptap-checkbox input[type=checkbox]:disabled {
    accent-color: var(--v-success-base);
}
`;

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'Checkbox',
    content: 'block*',
    marks: '_',
    group: 'block',
    inline: false,
    defining: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapCheckbox);
    },

    addAttributes() {
        return {
            id: { default: null },
            value: { default: false },
            disabled: { default: false }
        };
    },

    addCommands() {
        return {
            setCheckbox:
                () =>
                ({ commands }) => {
                    return commands.wrapIn(this.name);
                },
            toggleCheckbox:
                () =>
                ({ commands }) => {
                    return commands.toggleWrap(this.name);
                },
            unsetCheckbox:
                () =>
                ({ commands }) => {
                    return commands.lift(this.name);
                }
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {
                default: {
                    class: 'tiptap-checkbox',
                    'data-id': null,
                    'data-value': 'false',
                    'data-disabled': 'false'
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-checkbox',
                getAttrs: node => ({
                    id: node.getAttribute('data-id'),
                    value: node.getAttribute('data-value') === 'true',
                    disabled: node.getAttribute('data-disabled') === 'true'
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { class: 'tiptap-checkbox' },
            [
                'input',
                {
                    type: 'checkbox',
                    id: HTMLAttributes.id,
                    value: HTMLAttributes.value ? 'on' : 'off',
                    checked: HTMLAttributes.value ? 'checked' : null,
                    disabled: HTMLAttributes.disabled ? null : 'disabled'
                }
            ],
            ['label', { for: HTMLAttributes.id }, 0]
        ];
    }
});
