import FileValidator from '@/lib/validators/FileValidator';

export class ImageValidator extends FileValidator {
    static key = 'image';

    static validator(value, label = 'Image', accept = ['jpg', 'jpeg', 'png', 'svg']) {
        return super.validator(value, label, accept);
    }

    static arguments() {
        return [];
    }
}

export default ImageValidator;
