import request from '@/api/lib/request';

/**
 * Send a GET request.
 *
 * @param {String} url
 * @param {Object} params
 * @returns {Object}
 */
const get = async (url, params = {}, config = {}) => {
    return await request({
        method: 'GET',
        url,
        params,
        ...config
    });
};

export default get;
