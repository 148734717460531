<template>
    <node-view-wrapper
        class="tiptap-input"
        :class="{
            'tiptap-input--error': !valid,
            'tiptap-input--editable': isGrowthInvest() && editor.options.editable
        }"
    >
        <div class="tiptap-input__wrap">
            <transition appear name="fade-transition" mode="out-in">
                <component
                    :is="component"
                    v-if="!hidden"
                    v-model="inputValue"
                    :label="label"
                    :hide-details="!errorMessages || !errorMessages.length"
                    :hide-label="!label"
                    hide-placeholder
                    :error-messages="errorMessages"
                    :disabled="disabled || node.attrs.disabled"
                />
                <p
                    v-else-if="hidden && isGrowthInvest() && editor.options.editable"
                    class="ma-0 mt-1 py-2 text--secondary"
                >
                    Hidden
                </p>
            </transition>
            <div v-if="isGrowthInvest() && editor.options.editable" class="tiptap-input__options">
                <tiptap-input-options :options="options" :update-attributes="updateAttributes" />
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import { findFieldStateConditions, findNodeById } from '@/lib/tiptap';

export default {
    name: 'TiptapInput',
    components: {
        NodeViewWrapper,
        TiptapInputOptions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/lib/tiptap/components/TiptapInputOptions.vue'
            )
    },
    props: {
        ...nodeViewProps
    },
    data: () => ({
        component: null
    }),
    computed: {
        inputValue: {
            get() {
                return this.node.attrs.value;
            },
            set(value) {
                this.updateAttributes({ value });

                this.$root.$emit(`Tiptap/input`, {
                    name: this.node.type.name,
                    content: this.node.content,
                    value
                });
            }
        },
        options() {
            return {
                id: this.id,
                type: this.type,
                label: this.label
            };
        },
        id() {
            return this.node.attrs.id;
        },
        type() {
            return this.node.attrs.type;
        },
        label() {
            return this.node.attrs.label;
        },
        valid() {
            if (!this.id) {
                return true;
            }
            if (this.editor.storage.Validator.valid) {
                return true;
            }
            if (!this.editor.storage.Validator.results) {
                return true;
            }
            if (!(this.id in this.editor.storage.Validator.results)) {
                return true;
            }
            return false;
        },
        errorMessages() {
            if (this.valid) {
                return null;
            }

            const messages = this.editor.storage.Validator.results[`${this.id}`];

            if (Array.isArray(messages)) {
                return messages;
            }

            return [messages];
        },
        disabled() {
            const json = this.editor.getJSON();

            const state = findFieldStateConditions(json);
            if (!state) {
                return false;
            }

            const condition = state.find(item => item.field_id === this.id && item.field_state === 'disabled');
            if (!condition) {
                return false;
            }
            const conditionNode = findNodeById(json, condition.condition_field_id);
            if (!conditionNode) {
                return false;
            }

            return conditionNode.attrs.value === condition.condition_field_value;
        },
        hidden() {
            const json = this.editor.getJSON();

            const state = findFieldStateConditions(json);
            if (!state) {
                return false;
            }

            const condition = state.find(item => item.field_id === this.id && item.field_state === 'hidden');
            if (!condition) {
                return false;
            }
            const conditionNode = findNodeById(json, condition.condition_field_id);
            if (!conditionNode) {
                return false;
            }

            return conditionNode.attrs.value === condition.condition_field_value;
        }
    },
    watch: {
        'node.attrs.type'(type) {
            this.inputValue = null;
            this.setComponent(type);
        }
    },
    mounted() {
        this.setComponent(this.type);
    },
    methods: {
        setComponent(type) {
            type = type || 'String';
            this.component = () => import(`@/components/fields/${type}`);

            if (type === 'Money' && this.node.attrs.value === null) {
                this.updateAttributes({ value: { amount: null, currency: 'GBP' } });
            }
        }
    }
};
</script>

<style lang="scss">
.tiptap-input {
    position: relative;
    margin-top: 0.5rem;
    .v-text-field__details {
        display: none;
    }

    &--error {
        .v-text-field__details {
            display: block;
        }
    }

    &--editable {
        padding-left: 2rem;
    }

    &__options {
        position: absolute;
        top: 0.6rem;
        left: 0.7rem;
        width: 2rem;
    }
}

table td .ProseMirror-trailingBreak {
    display: none;
}

table td p {
    min-height: 1.8rem;
}

table td .tiptap-input {
    margin-top: 0;

    &--editable {
        padding-left: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .v-input__slot,
    fieldset,
    input {
        border: 0 !important;
        min-height: 0;
    }
    .v-input {
        font-size: 1rem;
    }
    .v-input__slot {
        min-height: 1.8rem !important;
    }
}
</style>
