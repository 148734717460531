import { InvestmentsCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';

export class InvestmentsVctCollection extends InvestmentsCollection {
    static type = 'InvestmentsVctCollection';
    static endpoint = Holding.api.byProduct;

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        filters.tax_status = this.filterOperator('is') + ':VCT';
        filters.tranche_id = this.filterOperator('is') + ':null';

        return filters;
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '14rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('product_id', i18n.t('product'), {
                active: false,
                width: '18rem',
                minWidth: '14rem',
                sortable: true,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Product')
                }
            }),
            this.createHeader('quantity', i18n.t('investments'), {
                format: 'number',
                width: '9rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('gross_investment_amount', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: true,
                filterable: true
            }),
            this.createHeader('invested_amount', i18n.t('total_invested'), {
                format: 'money',
                width: '10rem',
                formatOptions: super.money_format_options,
                sortable: true,
                filterable: true
            }),

            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: true,
                filterable: true
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: true,
                filterable: true
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: true,
                filterable: true
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: true,
                filterable: true
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: true,
                filterable: true
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: true,
                filterable: true
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: true
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: true,
                filterable: true
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: true,
                filterable: true
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals,
                    sortable: true,
                    filterable: true
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: true,
                    filterable: true
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: true,
                    filterable: true
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals,
                    sortable: true,
                    filterable: true
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals,
                    sortable: true,
                    filterable: true
                }
            ),

            // Following headers are for filtering, not for display
            this.createHeader('purchased_on', `${i18n.t('date')} ${i18n.t('invested')}`, {
                format: 'date',
                filterable: true,
                active: false
            }),
            this.createHeader('tax_year', i18n.t('tax_year'), {
                width: '6rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/TaxYear')
                },
                active: false
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name,
                active: false
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name,
                active: false
            }),
            this.createHeader('fund_id', i18n.t('fund'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Fund')
                },
                transform: data => data.item?.fund?.name,
                active: false
            })
        ];
    }
}

export default InvestmentsVctCollection;
