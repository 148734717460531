import Auth from '@/models/Auth';

/**
 * Returns a list of all the keys in an object or array.
 * @param {Object, Array} value
 */
const getOrganisationType = async ($route = null) => {
    if ($route) {
        if ($route.query.organisation_type) {
            return $route.query.organisation_type.toUpperCase();
        } else {
            return null;
        }
    }

    return Auth().organisation_type;
};

export default getOrganisationType;
