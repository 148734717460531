import LookupApi from '@/api/LookupApi';

const COUNTRIES_LOCAL_STORAGE_KEY = 'countries-v1';

const isDefaultCountry = country => {
    return country.alpha2Code === 'GB';
};

const sortCountries = (a, b) => {
    if (isDefaultCountry(a)) {
        return -1;
    }

    if (isDefaultCountry(b)) {
        return 1;
    }

    return a.name.localeCompare(b.name);
};

export default {
    countries: {
        namespaced: true,
        state: {
            loading: false,
            countries: []
        },
        getters: {
            countries: state => state.countries,
            countriesLoading: state => state.loading,
            countryFromAlpha2Code: state => {
                return (alpha2Code, fallback = undefined) => {
                    const country = state.countries.find(country => country.alpha2Code === alpha2Code);

                    if (!country) {
                        return fallback;
                    }

                    return country;
                };
            },
            countryFromAlpha3Code: state => {
                return (alpha3Code, fallback = undefined) => {
                    const country = state.countries.find(country => country.alpha3Code === alpha3Code);

                    if (!country) {
                        return fallback;
                    }

                    return country;
                };
            }
        },
        actions: {
            async getCountries({ commit, state }) {
                commit('SET_LOADING', true);

                if (state.countries.length) {
                    commit('SET_LOADING', false);
                    return;
                }

                let countries = localStorage.getItem(COUNTRIES_LOCAL_STORAGE_KEY);

                if (countries) {
                    countries = JSON.parse(countries);
                } else {
                    countries = await new LookupApi().methods.getCountries();
                    countries = countries.map(country => {
                        let name = country.name;

                        if (name.includes('United Kingdom')) {
                            name = 'United Kingdom';
                        }

                        return {
                            name: name,
                            nativeName: country.nativeName,
                            demonym: country.demonym,
                            alpha2Code: country.alpha2Code,
                            alpha3Code: country.alpha3Code,
                            callingCode: country.callingCodes[0],
                            timezone: country.timezones[0],
                            flag: require(`@/images/flags/${country.alpha2Code}.svg`),
                            language: {
                                name: country.languages[0].name,
                                iso639_1: country.languages[0].iso639_1
                            },
                            currency: {
                                code: country.currencies[0].code,
                                name: country.currencies[0].name,
                                symbol: country.currencies[0].symbol
                            }
                        };
                    });
                }

                countries = countries.sort(sortCountries);

                commit('SET_COUNTRIES', countries);
                commit('SET_LOADING', false);
            }
        },
        mutations: {
            SET_COUNTRIES(state, payload) {
                state.countries = payload;
                localStorage.setItem(COUNTRIES_LOCAL_STORAGE_KEY, JSON.stringify(payload));
            },
            SET_LOADING(state, payload) {
                state.loading = payload;
            }
        }
    }
};
