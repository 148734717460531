import { ActivityCollection, Activity } from '@/models';
import i18n from '@/lib/i18n';
import toCurrency from '@/lib/helpers/toCurrency';

export class ActivityInvestorMarketingCollection extends ActivityCollection {
    static type = 'ActivityInvestorMarketingCollection';
    static endpoint = Activity.api.investorMarketing;

    get default_headers() {
        return ['created_at', 'activity', 'user_id', 'user_email', 'offer_id', 'shortlist_id', 'proposal_id'];
    }

    get pdf_headers() {
        return ['created_at', 'title', 'text', 'user_id', 'user_email', 'offer_id', 'shortlist_id', 'proposal_id'];
    }

    get headers() {
        return [
            this.createHeader('created_at', i18n.t('date'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('activity', i18n.t('activity'), {
                width: '35rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('title', i18n.t('activity'), {
                width: '12rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/ActivityTitle'),
                    options: {
                        type: 'marketing'
                    }
                }
            }),
            this.createHeader('text', i18n.t('text'), {
                width: '38rem',
                filterable: true
            }),
            this.createHeader('user_id', i18n.t('user'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.user?.name
            }),
            this.createHeader('user_email', i18n.t('user_email'), {
                width: '14rem',
                transform: data => data.item?.user?.email,
                classList: ['text--secondary', 'text--small'],
                filterable: true
            }),
            this.createHeader('offer_id', i18n.t('offer'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Offer')
                },
                transform: data => data.item?.offer?.name,
                to: data => {
                    const offerId = data.item?.offer?.id;

                    if (!offerId) return null;

                    return {
                        name: 'Offer',
                        query: { offer_id: offerId }
                    };
                }
            }),
            this.createHeader('shortlist_id', i18n.t('shortlist'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Shortlist')
                },
                transform: data => data.item?.shortlist?.name,
                to: data => {
                    const shortlistId = data.item?.shortlist?.id;

                    if (!shortlistId) return null;

                    return {
                        name: 'Shortlist',
                        query: { shortlist_id: shortlistId }
                    };
                }
            }),
            this.createHeader('proposal_id', i18n.t('proposal'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Proposal')
                },
                transform: data => {
                    let name = [];

                    if (data.item?.proposal?.offer?.name) {
                        name.push(data.item?.proposal?.offer?.name);
                    }

                    if (data.item?.proposal?.amount?.amount) {
                        name.push(toCurrency(data.item.proposal.amount.amount, data.item.proposal.amount.currency));
                    }

                    name = name.join(' - ');

                    return name ? name : null;
                },
                to: data => {
                    const proposalId = data.item?.proposal?.id;

                    if (!proposalId) return null;

                    return {
                        name: 'Proposals',
                        query: { proposal: proposalId }
                    };
                }
            })
        ];
    }
}

export default ActivityInvestorMarketingCollection;
