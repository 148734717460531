import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class PhoneNumberValidator extends BaseValidator {
    static key = 'phoneNumber';

    static validator(value, label) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = value.replace(/[\s-]/g, '');

            const rgx = new RegExp(/([\d]{9,17})/);

            valid = value.match(rgx);
        }

        if (!valid) {
            return i18n.t('validator.phone_number', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default PhoneNumberValidator;
