export default {
    paragraph: {
        icon: 'paragraph',
        text: 'Paragraph',
        command: ctx => {
            ctx.editor.chain().focus().setParagraph().run();
        }
    },
    h1: {
        icon: 'h1',
        text: 'Heading 1',
        command: ctx => {
            ctx.editor.chain().focus().toggleHeading({ level: 1 }).run();
        }
    },
    h2: {
        icon: 'h2',
        text: 'Heading 2',
        command: ctx => {
            ctx.editor.chain().focus().toggleHeading({ level: 2 }).run();
        }
    },
    h3: {
        icon: 'h3',
        text: 'Heading 3',
        command: ctx => {
            ctx.editor.chain().focus().toggleHeading({ level: 3 }).run();
        }
    }
};
