const SDRLabelEnum = Object.freeze({
    IMPROVERS: 'IMPROVERS',
    FOCUS: 'FOCUS',
    IMPACT: 'IMPACT',
    MIXED_GOALS: 'MIXED_GOALS',
    CHARACTERISTICS: 'CHARACTERISTICS',
    NO_LABEL: 'NO_LABEL'
});

export default SDRLabelEnum;
