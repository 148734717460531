import BaseApi from '@/api/BaseApi';

export class MiscApi extends BaseApi {
    static resource = 'misc';

    get base_url() {
        return `/${this.constructor.version}`;
    }

    get endpoints() {
        return {
            getCurrentUser: this.getCurrentUser.bind(this),
            getPublicBranding: this.getPublicBranding.bind(this),
            getFileFromUrl: this.getFileFromUrl.bind(this)
        };
    }

    async getCurrentUser() {
        const response = await BaseApi.api.get(`${this.base_url}/whoami`);

        // if (response.name === 'E2E Adviser') {
        //     response.organisations = response.organisations.map(org => {
        //         if (org.name === 'GrowthInvest' && org.type === 'ADVISER') {
        //             org.type = 'PRODUCT_PROVIDER';
        //             org.roles = ['SUPERUSER2'];
        //         }

        //         return org;
        //     });
        // }

        // console.info('Current User:', response);

        return response;
    }

    async getPublicBranding() {
        return await BaseApi.api.get(`${this.base_url}/public/branding`);
    }

    async getFileFromUrl(url) {
        return BaseApi.api.get(
            url,
            {},
            {
                responseType: 'blob',
                withCredentials: false
            }
        );
    }
}

export default MiscApi;
