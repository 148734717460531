import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import endsWith from 'lodash/endsWith';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';

/**
 * Returns a filtered object with only the properties that are not null
 * or properties that don't end with ':undefined' or ':null'
 *
 * @param {Object} v
 * @returns {Object}
 */
export function removeNull(v) {
    v = mapValues(v, item => {
        if (!Array.isArray(item) && isObject(item)) {
            return removeNull(item);
        }
        return item;
    });

    return omitBy(v, isNullValue);
}

export function isNullValue(item) {
    if (isNil(item)) {
        return true;
    }

    if (endsWith(item, ':undefined')) {
        return true;
    }

    if (endsWith(item, ':null')) {
        return true;
    }

    if (isObject(item) && has(item, 'prefix') && item.prefix === '+44' && Object.keys(item).length === 1) {
        return true;
    }

    if (isObject(item) && has(item, 'currency') && !has(item, 'amount')) {
        return true;
    }

    if (isObject(item) && has(item, 'region') && !has(item, 'national_number')) {
        return true;
    }

    if (isObject(item) && !has(item, 'region') && has(item, 'national_number')) {
        return true;
    }

    if (isObject(item) && has(item, 'region') && has(item, 'national_number') && item.national_number === null) {
        return true;
    }

    if (isObject(item) && has(item, 'region') && has(item, 'national_number') && item.region === null) {
        return true;
    }

    if (isObject(item) && isEmpty(item)) {
        return true;
    }

    return false;
}

export default removeNull;
