import uuid from '@/lib/helpers/uuid';
import clock from '@/lib/clock';
import envConfig from '@/config';

export default {
    requests: {
        namespaced: true,
        state: {
            requests: []
        },
        getters: {
            find: state => id => {
                return state.requests.find(request => request.id === id);
            },
            findAll:
                state =>
                ({ url = null, method = null, params = null, headers = null }) => {
                    return state.requests.filter(request => {
                        if (url !== null && request.url !== url) {
                            return false;
                        }

                        if (method !== null && request.method !== method) {
                            return false;
                        }

                        if (headers !== null && JSON.stringify(request.headers) !== JSON.stringify(headers)) {
                            return false;
                        }

                        if (params !== null && JSON.stringify(request.params) !== JSON.stringify(params)) {
                            return false;
                        }

                        return true;
                    });
                },
            controller: (state, getters) => id => {
                const request = getters.find(id);
                return request && request.controller;
            },
            signal: (state, getters) => id => {
                const controller = getters.controller(id);
                return controller && controller.signal;
            },
            aborted: (state, getters) => id => {
                const signal = getters.signal(id);
                return signal && signal.aborted;
            }
        },
        actions: {
            async add({ commit }, config) {
                commit('CLEAR_EXPIRED_REQUESTS');

                const request = {
                    id: uuid(),
                    method: config.method,
                    url: config.url,
                    promise: config.promise || null,
                    status: 'pending',
                    controller: new AbortController(),
                    abortGroup: 'abortGroup' in config ? config.abortGroup : null,
                    persistent: 'persistent' in config && config.persistent === true,
                    params: 'params' in config ? JSON.parse(JSON.stringify(config.params)) : null,
                    headers: config.headers,
                    response: null,
                    request_at: clock().toISOString(),
                    response_at: null
                };

                commit('ADD_REQUEST', request);
                return Promise.resolve(request.id);
            },
            aborted({ commit }, id) {
                commit('ABORT_REQUEST', id);
                return Promise.resolve(id);
            },
            setPromise({ commit }, { id = null, promise = null }) {
                commit('SET_PROMISE', { id, promise });
                return Promise.resolve(id);
            },
            completed({ commit }, { id = null, response = null }) {
                commit('SET_COMPLETED', { id, response });
                return Promise.resolve(id);
            },
            rejected({ commit }, { id = null, response = null }) {
                commit('SET_REJECTED', { id, response });
                return Promise.resolve(id);
            },
            clearAll({ commit }) {
                commit('CLEAR_ALL_REQUESTS');
            }
        },
        mutations: {
            ABORT_REQUEST(state, id) {
                const request = state.requests.find(request => request.id === id);
                request.controller.abort();
                request.status = 'aborted';
            },
            ADD_REQUEST(state, request) {
                state.requests.push(request);
            },
            SET_PROMISE(state, { id = null, promise = null }) {
                const request = state.requests.find(request => request.id === id);
                if (request) {
                    request.promise = promise;
                }
            },
            SET_COMPLETED(state, { id = null, response = null }) {
                const request = state.requests.find(request => request.id === id);
                if (request) {
                    request.status = 'completed';
                    request.response = response;
                    request.response_at = clock().toISOString();
                }
            },
            SET_REJECTED(state, { id = null, response = null }) {
                const request = state.requests.find(request => request.id === id);
                if (request) {
                    request.status = 'rejected';
                    request.response = response;
                    request.response_at = clock().toISOString();
                }
            },
            CLEAR_ALL_REQUESTS(state) {
                state.requests = [];
            },
            CLEAR_EXPIRED_REQUESTS(state) {
                state.requests = state.requests.filter(request => {
                    if (request.status !== 'pending' && request.response_at !== null) {
                        return clock().diff(clock(request.response_at), 'milliseconds') < envConfig.API_LIFETIME;
                    }
                    return true;
                });
            }
        }
    }
};
