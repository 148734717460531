export default {
    system: {
        namespaced: true,
        state: {
            loading: false,
            menuPanelActive: false,
            onboarding: false
        },
        getters: {
            loading: state => state.loading,
            menuPanelActive: state => state.menuPanelActive,
            isOnboarding: state => state.onboarding
        },
        actions: {
            startLoading({ commit }) {
                commit('STARTED_LOADING');
            },
            stopLoading({ commit }) {
                commit('STOPPED_LOADING');
            },
            setMenuPanel({ commit }, active) {
                commit('SET_MENU_VISIBILITY', active);
            },
            toggleMenuPanel({ commit, state }) {
                commit('SET_MENU_VISIBILITY', !state.menuPanelActive);
            }
        },
        mutations: {
            STARTED_LOADING(state) {
                state.loading = true;
            },
            STOPPED_LOADING(state) {
                state.loading = false;
            },
            SET_MENU_VISIBILITY(state, active) {
                state.menuPanelActive = active;
            },
            SET_ONBOARDING(state, payload) {
                state.onboarding = Boolean(payload);
            }
        }
    }
};
