import TargetDividendTypeEnum from '@/enums/targetDividendType';
import toPercentage from '@/lib/helpers/toPercentage';
import i18n from '@/lib/i18n';

/**
 * Return the target dividend string
 * @param {Number} value
 * @param {String} type
 */
const getTargetDividend = (type, value) => {
    if (value === null || value === undefined) {
        return null;
    }

    if (type === TargetDividendTypeEnum.YIELD) {
        return `${toPercentage(value)} yield per annum`;
    } else if (type === TargetDividendTypeEnum.ABSOLUTE) {
        return `${value}${i18n.t('p_per_share')}`;
    }

    return null;
};

export default getTargetDividend;
