import uuid from '@/lib/helpers/uuid';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import findIndex from 'lodash/findIndex';

export default {
    snackQueue: {
        namespaced: true,
        state: {
            items: [],
            processing: false,
            timeoutId: null
        },
        getters: {
            items: state => state.items,
            processing: state => state.processing
        },
        actions: {
            add({ commit, dispatch }, options) {
                if (isUndefined(options.id)) {
                    options.id = uuid(8);
                }
                if (isUndefined(options.timeout)) {
                    options.timeout = -1;
                }
                commit('ADD_ITEM', options);
                dispatch('process');
            },
            remove({ commit, state, dispatch }, id) {
                clearTimeout(this.timeoutId);
                commit('REMOVE_ITEM', id);

                if (state.items.length > 0) {
                    dispatch('process');
                }
            },
            clear({ commit }) {
                clearTimeout(this.timeoutId);
                commit('CLEAR_ALL_ITEMS');
            },
            process({ commit, state, dispatch }) {
                commit('SET_PROCESSING', true);

                if (isArray(state.items) && state.items.length > 0) {
                    const item = last(state.items);

                    if (item.timeout > 0) {
                        state.timeoutId = setTimeout(() => {
                            dispatch('remove', item.id);
                        }, item.timeout);
                    }
                }

                commit('SET_PROCESSING', false);
            }
        },
        mutations: {
            ADD_ITEM(state, payload) {
                state.items.reverse();
                state.items.push(payload);
                state.items.reverse();
            },
            REMOVE_ITEM(state, id) {
                const index = findIndex(state.items, ['id', id]);
                if (index > -1) {
                    state.items.splice(index, 1);
                }
            },
            SET_PROCESSING(state, payload) {
                state.processing = payload;
            },
            CLEAR_ALL_ITEMS(state) {
                state.items = [];
            }
        }
    }
};
