<template>
    <node-view-wrapper class="tiptap-expansion-panel">
        <transition appear name="fade-transition">
            <v-alert class="tiptap-expansion-panel px-3" :class="classList" text dense>
                <v-row class="tiptap-expansion-panel__row" align="start" no-gutters>
                    <v-col v-if="alertIcon" class="shrink">
                        <v-icon class="tiptap-expansion-panel__icon mr-2 d-block" size="1.2rem" :color="alertColor">{{
                            alertIcon
                        }}</v-icon>
                    </v-col>
                    <v-col class="grow">
                        <div class="mb-0 mr-2 primaryText--text" role="button" @click="toggle()" @keyup="toggle()">
                            <span class="font-weight-bold">{{ heading }}</span>
                            <v-icon
                                v-if="!editable"
                                small
                                dense
                                class="tiptap-expansion-panel__expand"
                                :class="expanded ? 'fa-rotate-90' : false"
                                color="primaryText"
                            >
                                {{ getIcon('expand') }}
                            </v-icon>
                        </div>
                        <node-view-content class="tiptap-expansion-panel__content" />
                    </v-col>
                    <v-col v-if="isGrowthInvest() && editable" class="shrink pl-3">
                        <tiptap-expansion-panel-options :options="options" :update-attributes="updateAttributes" />
                    </v-col>
                </v-row>
            </v-alert>
        </transition>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2';
import AlertTypeEnum from '@/enums/alertType';

export default {
    name: 'TiptapExpansionPanel',
    components: {
        NodeViewWrapper,
        NodeViewContent,
        TiptapExpansionPanelOptions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/lib/tiptap/components/TiptapExpansionPanelOptions.vue'
            )
    },
    props: {
        ...nodeViewProps
    },
    data: () => ({
        expanded: false
    }),
    computed: {
        classList() {
            let classList = ['tiptap-expansion-panel'];

            if (this.expanded) {
                classList.push('tiptap-expansion-panel--expanded');
            }

            return classList.join(' ');
        },
        editable() {
            return this.editor.options.editable;
        },
        heading() {
            return this.node.attrs.heading;
        },
        type() {
            return this.node.attrs.type || 'INFO';
        },
        options() {
            return {
                heading: this.heading,
                type: this.type
            };
        },
        alertTypeValue() {
            if (this.type !== null) {
                return AlertTypeEnum[this.type.toUpperCase()];
            }

            return null;
        },
        alertColor() {
            if (this.alertTypeValue !== null) {
                return this.alertTypeValue.color;
            }

            return this.color;
        },
        alertTextColor() {
            if (this.alertTypeValue !== null) {
                return `${this.alertTypeValue.color}--text`;
            }

            return `${this.color}--text`;
        },
        alertIcon() {
            if (this.alertTypeValue !== null) {
                return this.alertTypeValue.icon;
            }

            return this.icon;
        }
    },
    mounted() {
        if (this.editable) {
            this.expanded = true;
        }
    },
    methods: {
        toggle() {
            if (!this.editable) {
                this.expanded = !this.expanded;
            }
        }
    }
};
</script>

<style lang="scss">
.tiptap-expansion-panel {
    display: table;
    &:before {
        background-color: #f5f5f5 !important;
        z-index: 0 !important;
        opacity: 1 !important;
    }
    .v-alert__wrapper {
        position: relative;
        z-index: 1;
    }
    &__content {
        display: none;
        min-width: 3rem;
        *:last-child {
            margin-bottom: 0;
        }
        h1,
        h2,
        h3 {
            color: inherit !important;
        }
    }
    &__expand {
        margin-left: 0.5rem;
    }
    &--expanded {
        .tiptap-expansion-panel__content {
            display: block;
        }
    }
}
</style>
