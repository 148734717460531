import i18n from '@/lib/i18n';

export default [
    {
        path: '/hello',
        redirect: '/onboarding'
    },
    {
        path: '/onboarding',
        name: 'InvestorOnboarding',
        component: () =>
            import(
                /* webpackChunkName: "investor-onboarding" */
                '@/views/InvestorOnboarding.vue'
            ),
        meta: {
            title: i18n.t('hello')
        },
        children: [
            {
                path: 'terms',
                name: 'InvestorOnboardingTerms',
                meta: {
                    keepParentAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "investor-onboarding" */
                        '@/views/InvestorOnboardingTerms.vue'
                    )
            },
            {
                path: 'address',
                name: 'InvestorOnboardingAddress',
                meta: {
                    keepParentAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "investor-onboarding" */
                        '@/views/InvestorOnboardingAddress.vue'
                    )
            },
            {
                path: 'adviser',
                name: 'InvestorOnboardingAdviser',
                meta: {
                    keepParentAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "investor-onboarding" */
                        '@/views/InvestorOnboardingAdviser.vue'
                    )
            },
            {
                path: 'category',
                name: 'InvestorOnboardingCategory',
                meta: {
                    keepParentAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "investor-onboarding" */
                        '@/views/InvestorOnboardingCategory.vue'
                    )
            },
            {
                path: 'declaration',
                name: 'InvestorOnboardingDeclaration',
                meta: {
                    keepParentAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "investor-onboarding" */
                        '@/views/InvestorOnboardingDeclaration.vue'
                    )
            },
            {
                path: 'classification',
                name: 'InvestorOnboardingClassification',
                meta: {
                    keepParentAlive: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "investor-onboarding" */
                        '@/views/InvestorOnboardingClassification.vue'
                    )
            }
        ]
    }
];
