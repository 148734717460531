import has from 'lodash/has';

const getInput = $el => {
    if (has($el, 'tagName') && $el.tagName === 'INPUT') {
        return $el;
    }

    return $el.querySelector('input');
};

export default getInput;
