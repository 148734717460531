export default {
    update(el) {
        let input = el;

        if (el.nodeName !== 'INPUT') {
            input = el.querySelector('input');
        }

        input.value = input.value.toLowerCase();
    }
};
