import findConfig from './findConfig';

const findValidationRules = value => {
    const config = findConfig(value);

    if (!config || !('validation' in config)) {
        return undefined;
    }

    return config.validation;
};

export default findValidationRules;
