import BaseApi from '@/api/BaseApi';

export class AccountApi extends BaseApi {
    static resource = 'accounts';

    get endpoints() {
        return {
            ...super.endpoints,
            getBalances: this.getBalances.bind(this)
        };
    }

    async getBalances(id, filters = {}) {
        return await BaseApi.api.get(`${this.base_url}/${id}/balances`, filters);
    }
}

export default AccountApi;
