import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Import configuration and i18n resources
import config from './../../config';
import messages from './messages';
import numberFormats from './numberFormats';

// Use the VueI18n plugin
Vue.use(VueI18n);

// Create and export the VueI18n instance
export default new VueI18n({
    locale: config.I18N_LOCALE || 'en',
    fallbackLocale: config.I18N_LOCALE_FALLBACK || 'en',
    messages,
    numberFormats
});
