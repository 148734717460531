import Vue from 'vue';
import router from '@/router';
import * as Sentry from '@sentry/vue';
import store from '@/store';
import config from '@/config';
import Auth from '@/models/Auth';
import { redirectToLogin } from '@/lib/auth';
import snack from '@/lib/helpers/snack';
import parseValidationErrors from '@/lib/helpers/parseValidationErrors';
import i18n from '@/lib/i18n';
import { inRange } from 'range_check';

export class ErrorService {
    static initialised = false;

    static onError(error, data = {}, options = { notify: true }) {
        if (!ErrorService.initialised) {
            ErrorService.init();
        }

        if (!error) {
            return;
        }

        if (error.isAborted) {
            return;
        }

        if (error?.message === 'Network Error' || error?.name === 'Network Error') {
            return ErrorService.onNetworkError(error, data, options);
        }

        switch (error.code) {
            case 500:
                return ErrorService.onServerError(error, data, options);
            case 401:
                return ErrorService.onAuthError(error, data, options);
            case 403:
                return ErrorService.onForbiddenError(error, data, options);
            case 404:
                return ErrorService.onNotFoundError(error, data, options);
            case 405:
                return ErrorService.onMethodNotAllowedError(error, data, options);
            case 409:
                return ErrorService.onConflictError(error, data, options);
            case 422:
                return ErrorService.onValidationError(error, data, options);
            default:
                ErrorService.log(error, data);
                return error;
        }
    }

    static onServerError(error, data = {}, options = { notify: true }) {
        ErrorService.logConsole(error, data);
        ErrorService.logSentry(error, { feedback: true });

        const retry = ErrorService.getRetryOptions(data);

        if (retry && options.notify) {
            ErrorService.notifyUser(
                'error',
                i18n.t('weve_encountered_a_problem_processing_the_request_and_your_changes_have_not_been_saved'),
                retry
            );
        }

        return error;
    }

    static onNetworkError(error, data = {}, options = { notify: true }) {
        ErrorService.logConsole(error, data);
        ErrorService.logSentry(error, { feedback: true });

        const retry = ErrorService.getRetryOptions(data);

        if (retry && options.notify) {
            ErrorService.notifyUser(
                'error',
                i18n.t('weve_encountered_a_problem_processing_the_request_and_your_changes_have_not_been_saved'),
                retry
            );
        }

        return error;
    }

    static onNotFoundError(error, data = {}, options = { notify: true }) {
        ErrorService.logConsole(error, data);

        const retry = ErrorService.getRetryOptions(data);

        if (retry && options.notify) {
            ErrorService.notifyUser('error', i18n.t('we_cant_find_the_thing_youre_looking_for'), retry);
        }

        return error;
    }

    static onMethodNotAllowedError(error, data = {}) {
        ErrorService.logConsole(error, data);

        return error;
    }

    static onConflictError(error, data = {}) {
        return ErrorService.onValidationError(error, data);
    }

    static onValidationError(error, data = {}, options = { notify: true }) {
        ErrorService.logConsole(error, data);

        let text = i18n.t('validation_error');

        if (error?.response?.data?.data) {
            if (typeof error.response.data.data === 'string') {
                text = error.response.data.data;
            } else if (Array.isArray(error.response.data.data)) {
                text = parseValidationErrors(error.response.data.data)
                    .filter(error => error.field !== '__root__')
                    .map(error => error.name);
            }
        } else if (error?.response?.data?.message) {
            text = error.response.data.message;

            if (text && typeof text === 'object' && 'msg' in text) {
                text = text.msg;
            } else {
                try {
                    const json = JSON.parse(text);

                    if (typeof json === 'object' && Object.keys(json).length) {
                        text = [];

                        for (const key in json) {
                            const field = key.split('.').join(' > ');
                            text.push(`${field}: ${json[`${key}`]}.`);
                        }
                    }
                } catch (e) {
                    // do nothing
                }
            }
        }

        if (options.notify) {
            ErrorService.notifyUser('error', text);
        }

        error.message = text;

        return error;
    }

    static onAuthError(error, data = {}) {
        ErrorService.logConsole(error, data);

        redirectToLogin(
            {
                rd: window.location.origin,
                app_uri: window.location.origin
            },
            true
        );

        return error;
    }

    static onForbiddenError(error, data = {}, options = { notify: true }) {
        ErrorService.logConsole(error, data);

        let text = i18n.t('you_dont_have_permission_to_proceed_with_the_request');

        if (error?.response?.data?.message) {
            text = error.response.data.message;
        }

        if (options.notify) {
            ErrorService.notifyUser('error', text);
        }

        return error;
    }

    static getRetryOptions(data) {
        if (!data || (typeof data !== 'object' && typeof data !== 'function')) {
            return null;
        }

        const fn = typeof data === 'function' ? data : data.retry;

        if (!fn || typeof fn !== 'function') {
            return null;
        }

        return fn;
    }

    static notifyUser(type, text, retry = null) {
        snack({
            timeout: retry ? null : 20000,
            type,
            text,
            retry
        });
    }

    static log(error, data = {}) {
        ErrorService.logConsole(error, data);
        ErrorService.logSentry(error, data);
    }

    static logConsole(error, data = {}) {
        let log = [error.toString()];

        if (error.fileName || error.lineNumber || error.columnNumber) {
            log[0] += `\n`;
        }

        if (error.fileName) {
            log[0] += `\nFile: ${error.fileName}`;
        }
        if (error.lineNumber) {
            log[0] += `\nLine: ${error.lineNumber}`;
        }
        if (error.columnNumber) {
            log[0] += `\nColumn: ${error.columnNumber}`;
        }

        if (error.fileName || error.lineNumber || error.columnNumber) {
            log[0] += `\n`;
        }

        if (data && typeof data === 'object' && data.info) {
            log[0] += `\n${data.info}`;
        }

        if (data && typeof data === 'object' && data.vm) {
            log[0] += ` found in`;

            let component = data.vm;
            let indentation = '  ';

            while (component) {
                log[0] += `\n`;

                if (component === data.vm) {
                    log[0] += `--->  `;
                } else {
                    log[0] += `    ${indentation}`;
                }

                log[0] += `<${component.$options.name || 'Root'}>`;

                if (component.$options.__file) {
                    log[0] += ` at ${component.$options.__file}`;
                }

                component = component.$parent;
                indentation += '  ';
            }

            log[0] += `\n\n`;
            log.push(data.vm);
        }

        console.error(...log);

        if (config.ENV !== 'test') {
            console.error(error);
        }
    }

    static logSentry(error, data = {}) {
        if (config.ENV === 'test') {
            return;
        }

        if (!config.SENTRY_DSN) {
            return;
        }

        if (data?.vm) {
            error.message += ` found in ${data.vm.$options.name}`;
        }

        const eventId = Sentry.captureException(error);

        if (data?.feedback) {
            ErrorService.feedbackSentry(eventId);
        }
    }

    static feedbackSentry(eventId) {
        if (!config.SENTRY_DSN) {
            return;
        }

        store.dispatch('app/showUserFeedbackModal', {
            event_id: eventId,
            name: Auth().user_name,
            email: Auth().user_email
        });
    }

    static logSentryContext(name = 'context', context = {}) {
        if (config.ENV === 'test') {
            return;
        }

        if (!config.SENTRY_DSN) {
            return;
        }

        Sentry.setContext(name, context);
    }

    static logSentryEvent(message = '', extra = {}, level = 'info') {
        if (config.ENV === 'test') {
            return;
        }

        if (!config.SENTRY_DSN) {
            return;
        }

        Sentry.captureEvent({
            message,
            level,
            extra
        });
    }

    static async init() {
        if (ErrorService.initialised) {
            return;
        }

        window.onerror = (message, url, lineNo, columnNo, error) => {
            if (error) {
                return ErrorService.onError(error, {
                    message,
                    url,
                    lineNo,
                    columnNo
                });
            }

            return false;
        };

        if (config.SENTRY_DSN) {
            const integrations = [];

            if (config.SENTRY_BROWSER_TRACING) {
                integrations.push(
                    Sentry.browserTracingIntegration({
                        router,
                        tracingOrigins: ['growthinvest.com', /^\//]
                    })
                );
            }

            if (config.SENTRY_SESSION_REPLAY) {
                integrations.push(
                    Sentry.replayIntegration({
                        sessionSampleRate: 0.1,
                        onErrorSampleRate: 1.0
                    })
                );
            }

            Sentry.init({
                Vue,
                debug: false,
                environment: config.APP_ENV,
                dsn: config.SENTRY_DSN,
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                integrations,
                beforeSendTransaction: event => {
                    const appCheckNetworks = [
                        '62.253.231.16/28',
                        '89.248.48.224/27',
                        '185.221.147.96/27',
                        '213.123.49.48/29',
                        '157.96.33.80/28'
                    ];

                    const eventIp = event.request?.headers['x-real-ip'];

                    if (eventIp && inRange(eventIp, appCheckNetworks)) {
                        return null;
                    }

                    return event;
                }
            });
        }

        ErrorService.initialised = true;
    }
}

export default ErrorService;
