import Vue from 'vue';
import config from '@/config';

import { Client } from '@ideal-postcodes/core-axios';
import { lookupAddress } from '@ideal-postcodes/core-browser';

Vue.prototype.$idealPostcodesApiKey = config.IDEAL_POSTCODES_API_KEY;

Vue.prototype.$idealPostcodesClient = new Client({
    api_key: config.IDEAL_POSTCODES_API_KEY
});

Vue.prototype.$idealPostcodesLookupAddress = lookupAddress;
