import { Collection, Fees } from '@/models';
import i18n from '@/lib/i18n';

export class FeesCollection extends Collection {
    static type = 'FeeCollection';
    static endpoint = Fees.api.index;
    static model = Fees;

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '18rem'
            }),
            this.createHeader('initial', i18n.t('initial_fee'), {
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('existing_investor_initial', i18n.t('existing_investor_initial_fee'), {
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('annual_management', i18n.t('annual_management_fee'), {
                format: 'percentage',
                width: '11rem'
            }),
            this.createHeader('performance', i18n.t('performance_fee'), {
                format: 'percentage',
                width: '8rem'
            }),
            this.createHeader('performance_hurdle', i18n.t('performance_hurdle'), {
                width: '12rem'
            }),
            this.createHeader('additional', i18n.t('additional_fee'), {
                format: 'percentage',
                width: '7rem'
            }),
            this.createHeader('administration', i18n.t('administration_fee'), {
                format: 'money',
                width: '9rem'
            }),
            this.createHeader('investee_initial', i18n.t('investee_initial_fee'), {
                format: 'percentage',
                width: '9rem'
            }),
            this.createHeader('investee_ongoing', i18n.t('investee_ongoing_fee'), {
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('investee_additional', i18n.t('investee_additional_fee'), {
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default FeesCollection;
