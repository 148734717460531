import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import toCurrency from '@/lib/helpers/toCurrency';
import toNumber from '@/lib/helpers/toNumber';

import TiptapInput from '@/lib/tiptap/components/TiptapInput.vue';

export const css = `
.tiptap-input {
    border-radius: 4px;
    margin-bottom: 1rem;
}
.tiptap-input label {
    display: block;
}
.tiptap-input label:before {
    content: attr(data-value);
}
.tiptap-input p {
    background: var(--v-background-base);
    border-radius: 4px;
    border: 1px solid var(--v-background-darken4);
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 0;
    padding: .5rem .8rem;
    min-width: 10rem;
    min-height: 2rem;
}
.tiptap-input p:before {
    content: attr(data-value);
}
`;

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'Input',
    content: '',
    marks: '',
    group: 'block',
    inline: false,
    defining: false,

    addNodeView() {
        return VueNodeViewRenderer(TiptapInput);
    },

    addAttributes() {
        return {
            id: { default: null },
            value: { default: null },
            type: { default: 'String' },
            label: { default: 'Input' },
            disabled: { default: false }
        };
    },

    addCommands() {
        return {
            insertInput:
                attrs =>
                ({ commands }) => {
                    return commands.insertContent({ type: this.name, attrs });
                }
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {
                default: {
                    class: 'tiptap-input',
                    'data-id': null,
                    'data-value': null,
                    'data-type': 'String',
                    'data-label': 'Input',
                    'data-disabled': 'false'
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-input',
                getAttrs: node => ({
                    id: node.getAttribute('data-id'),
                    value: node.getAttribute('data-value'),
                    type: node.getAttribute('data-type'),
                    label: node.getAttribute('data-label'),
                    disabled: node.getAttribute('data-disabled') === 'true'
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        let value = HTMLAttributes.value;

        if (HTMLAttributes.type === 'Money' && value) {
            if (typeof value === 'object' && 'amount' in value && 'currency' in value) {
                value = toCurrency(value.amount, value.currency);
            }
        } else if (HTMLAttributes.type === 'Number' && value) {
            value = toNumber(value);
        }

        return [
            'div',
            {
                class: 'tiptap-input',
                'data-id': HTMLAttributes.id,
                'data-value': value,
                'data-type': HTMLAttributes.type,
                'data-label': HTMLAttributes.label,
                'data-disabled': HTMLAttributes.disabled
            },
            [
                'label',
                {
                    for: HTMLAttributes.id,
                    'data-label': HTMLAttributes.label
                }
            ],
            [
                'p',
                {
                    id: HTMLAttributes.id,
                    'data-value': value
                }
            ]
        ];
    }
});
