import { BaseModel } from '@/models';

export class CorporateAction extends BaseModel {
    static entity = 'corporateActions';

    static fields() {
        return {
            id: this.attr(null)
        };
    }
}

export default CorporateAction;
