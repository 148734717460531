import findConfig from './findConfig';

const findFieldStateConditions = value => {
    const config = findConfig(value);

    if (!config || !('state' in config)) {
        return undefined;
    }

    return config.state;
};

export default findFieldStateConditions;
