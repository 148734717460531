/**
 * @see https://github.com/RobinHerbots/Inputmask
 */
const globalMaskOptions = {
    undoOnEscape: false
};

export const iban = {
    ...globalMaskOptions,
    mask: '**** ',
    repeat: 8,
    definitions: { '*': { casing: 'upper' } },
    placeholder: ' '
};

export const phoneNumber = {
    ...globalMaskOptions,
    // alias: 'numeric',
    mask: '[+]9{1,17}',
    placeholder: ' ',
    prefix: '+44'
};

export const day = {
    ...globalMaskOptions,
    regex: '0[1-9]|[12]\\d|3[01]',
    placeholder: ' '
};

export const month = {
    ...globalMaskOptions,
    regex: '0[1-9]|1[0-2]',
    placeholder: ' '
};

export const year = {
    ...globalMaskOptions,
    regex: '(1[9]|2[0-9])\\d{2}',
    placeholder: ' '
};

export const date = {
    ...globalMaskOptions,
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    outputFormat: 'yyyy-mm-dd',
    autoUnmask: true,
    placeholder: 'DD/MM/YYYY',
    prefillYear: false
};

export const datetime = {
    ...globalMaskOptions,
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy HH:MM',
    outputFormat: 'yyyy-mm-dd HH:MM',
    autoUnmask: true,
    placeholder: 'DD/MM/YYYY HH:MM',
    prefillYear: false,
    hourFormat: '24'
};

export const time = {
    ...globalMaskOptions,
    regex: '(?:[01]\\d|2[0-3]):(?:[0-5]\\d):(?:[0-5]\\d)',
    placeholder: '00:00:00'
};

export const nationalInsuranceNumber = {
    ...globalMaskOptions,
    mask: '** ** ** ** *',
    definitions: { '*': { casing: 'upper' } },
    placeholder: ' '
};

export const sortCode = {
    ...globalMaskOptions,
    mask: '99 - 99 - 99',
    placeholder: ' ',
    autoUnmask: true
};

export const number = {
    ...globalMaskOptions,
    alias: 'decimal',
    digits: 2,
    radixPoint: '.',
    groupSeparator: ',',
    placeholder: '0',
    numericInput: false,
    allowMinus: true,
    positionCaretOnClick: 'select',
    positionCaretOnTab: false,

    /**
     * DO NOT REMOVE
     *
     * For some reason, leaving this here fixes the masking libraries ability to
     * handle KeyCode 109 (Numpad Subtract).
     *
     * Without it, when the subtract key is pressed, the input value does something
     * very strange to the internal value of the shadow element.
     */
    onKeyDown: () => {}
};

export default {
    date,
    datetime,
    day,
    iban,
    month,
    nationalInsuranceNumber,
    number,
    phoneNumber,
    sortCode,
    time,
    year
};
