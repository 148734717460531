import Vue from 'vue';
import axios from 'axios';
import config from '@/config';
import { stringify, parse } from 'qs';

export const axiosConfig = {
    baseURL: config.API_BASE_URL,
    timeout: config.API_TIMEOUT,
    withCredentials: true,
    maxRedirects: 0,
    paramsSerializer: {
        encode: parse,
        serialize: params => stringify(params, { arrayFormat: 'repeat' })
    }
};

const http = axios.create(axiosConfig);

Vue.prototype.$http = http;

export default http;
