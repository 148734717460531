export const mimeTypes = [
    'application/zip',
    'application/octet-stream',
    'application/x-zip-compressed',
    'multipart/x-zip',
    'application/x-7z-compressed'
];

export default file => {
    let type = file && typeof file === 'object' && 'type' in file ? file.type : file;
    return mimeTypes.includes(type);
};
