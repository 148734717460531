import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class YearValidator extends BaseValidator {
    static key = 'year';

    static validator(value, label = 'Year') {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = value.replace(/\s/g, '');
            valid = value.match(/^(1[0-9]|[2-9][0-9])\d{2}$/i);
        }

        if (!valid) {
            return i18n.t('validator.year', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default YearValidator;
