import { Organisation, Product } from '@/models';
import FundManagerApi from '@/api/FundManagerApi';

export class FundManager extends Organisation {
    static entity = 'fundManagers';
    static Api = FundManagerApi;

    static fields() {
        return {
            ...super.fields(),
            overview: this.attr(null).nullable(),
            products: this.hasMany(Product, 'company_id')
        };
    }
}

export default FundManager;
