import { BaseModel } from '@/models';
import declarationStatusEnum from '@/enums/declaration/status';
import clock from '@/lib/clock';
import TiptapValidator from '@/lib/validators/TiptapValidator';

export class DeclarationConsent extends BaseModel {
    static entity = 'declarationConsents';

    static fields() {
        return {
            id: this.attr(null),
            declaration_id: this.string(null).nullable(),
            declaration: this.attr(null).nullable(),
            declaration_status: this.enum(declarationStatusEnum, 'NOT_COMPLETE'),
            signature: this.string(null).nullable(),
            signed_at: this.string(null).nullable(),
            disabled: this.boolean(true)
        };
    }

    sign(signature = 'investor-onboarding') {
        if (this.is_valid === true) {
            this.declaration_status = declarationStatusEnum.COMPLETE;
            // todo - Remove when the API handles signatures
            this.signature = signature;
            this.signed_at = clock().format('YYYY-MM-DDTHH:mm:ss');
        }
    }

    get is_valid() {
        if (!this.declaration) {
            return false;
        }
        return TiptapValidator.validator(this.declaration) === true;
    }
}

export default DeclarationConsent;
