import isPublic from '@/router/lib/isPublic';
import loadUser from '@/router/middleware/loadUser';
import has from 'lodash/has';
import Auth from '@/models/Auth';

const authGuard = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        if (!Auth().is_logged_in) {
            localStorage.removeItem('currentTermsAccepted');
            await loadUser(to, from, next);
        } else if (Auth().has_organisation && to.meta.organisations) {
            if (!to.meta.organisations.some(o => o === Auth().organisation_type)) {
                next(Auth().home_route);
            }
        } else if (Auth().has_organisation && has(to.meta, 'permissions') && to.meta.permissions.length > 0) {
            if (!to.meta.permissions.every(p => Auth().can(p))) {
                // todo; Show notification about permissions
                next(Auth().home_route);
            }
        }

        next();
    }
};

export default authGuard;
