import { ReportCollection } from '@/models';
import i18n from '@/lib/i18n';

export class ReportSummaryPmiCollection extends ReportCollection {
    static type = 'ReportSummaryPmiCollection';

    get headers() {
        let headers = [
            this.createHeader('total_commitment_gbp', `${i18n.t('total_commitment')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9.6rem'
            }),
            this.createHeader('investment_amount_gbp', `${i18n.t('investment_amount')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('cumulative_loan_contribution_gbp', `${i18n.t('cumulative_loan_contribution')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem'
            }),
            this.createHeader('cumulative_net_contributions_gbp', `${i18n.t('cumulative_net_contributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11.4rem'
            }),
            this.createHeader('undrawn_capital_gbp', `${i18n.t('undrawn_capital')} / ${i18n.t('commitment')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem'
            }),
            this.createHeader('distributions_gbp', `${i18n.t('distributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('nav_gbp', `${i18n.t('nav')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: this.show_nav_totals
            }),
            this.createHeader('current_value_gbp', `${i18n.t('current_value')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('total_return_gbp', `${i18n.t('total_return')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('total_return_percentage_gbp', `${i18n.t('total_return_percentage')} (gbp)`, {
                format: 'percentage',
                formatOptions: super.percentage_format_options,
                width: '9rem'
            }),
            this.createHeader('total_return_moic_gbp', `${i18n.t('total_return_moic')} (gbp)`, {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem'
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'total_commitment_gbp',
            'investment_amount_gbp',
            'cumulative_loan_contribution_gbp',
            'cumulative_net_contributions_gbp',
            'undrawn_capital_gbp',
            'distributions_gbp',
            'nav_gbp',
            'current_value',
            'total_return_gbp',
            'total_return_percentage_gbp',
            'total_return_moic_gbp'
        ];
    }

    get pdf_headers() {
        return [
            'total_commitment_gbp',
            'investment_amount_gbp',
            'cumulative_loan_contribution_gbp',
            'cumulative_net_contributions_gbp',
            'undrawn_capital_gbp',
            'distributions_gbp',
            'nav_gbp',
            'current_value',
            'total_return_gbp',
            'total_return_percentage_gbp',
            'total_return_moic_gbp'
        ];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('is') + ':private-markets'
        };
    }
}

export default ReportSummaryPmiCollection;
