import { BaseModel, Offer, Product } from '@/models';
import i18n from '@/lib/i18n';
import toPercentage from '@/lib/helpers/toPercentage';
import toDate from '@/lib/helpers/toDate';
import clock from '@/lib/clock';

export class ProductOffer extends BaseModel {
    static entity = 'productOffer';

    static primaryKey = ['offer_id', 'product_id'];

    static fields() {
        return {
            ...super.fields(),

            offer_id: this.attr(null),
            offer: this.belongsTo(Offer, 'offer_id'),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            offer_price: this.attr(null).nullable(),
            nominal_value: this.attr(null).nullable(),
            quantity: this.number(null).nullable(),
            default_investment_percentage: this.number(null).nullable(),
            minimum_investment_amount: this.attr(null).nullable(),
            closed_at: this.string(null).nullable(),

            $vct: this.boolean(false)
        };
    }

    get is_closed() {
        return this.closed_at && toDate(this.closed_at, false).isBefore(clock());
    }

    get is_valid() {
        if (this.$vct) {
            return !!this.product_id;
        }
        return [this.product_id, this.offer_price, this.nominal_value, this.quantity].every(v => v !== null);
    }

    get computed_name() {
        let name = '';

        if (this.product && this.product.name) {
            name += ' ' + this.product.name;
        }

        if (this.$vct) {
            if (this.default_investment_percentage) {
                name += ` (${toPercentage(this.default_investment_percentage)})`;
            }
        } else {
            if (this.quantity) {
                name += ` (${this.quantity} shares)`;
            }
        }

        return name || i18n.t('product');
    }
}

export default ProductOffer;
