import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import toNumber from '@/lib/helpers/toNumber';

export class MaxValidator extends BaseValidator {
    static key = 'max';

    static validator(value, label, maximum = 0) {
        const isCharacterCount = typeof value === 'string';
        const isItemCount = Array.isArray(value);

        if (value && typeof value === 'object' && 'amount' in value) {
            value = parseInt(value.amount);
        } else if (value && (isCharacterCount || isItemCount)) {
            value = value.length;
        } else {
            value = parseInt(value);
        }

        maximum = parseInt(maximum);

        if (value > maximum) {
            if (isCharacterCount) {
                return i18n.t('validator.max_items', { field: label, maximum: toNumber(maximum), items: 'characters' });
            } else if (isItemCount) {
                return i18n.t('validator.max_items', { field: label, maximum: toNumber(maximum), items: 'items' });
            }
            return i18n.t('validator.max', { field: label, maximum: toNumber(maximum) });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default MaxValidator;
