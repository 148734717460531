import FilterOperator from '@/enums/filter/operator';
import get from 'lodash/get';
import isString from 'lodash/isString';

/**
 * Returns the FilterOperator object for the given key
 * @param {String} key
 * @returns {Object}
 */
const getFilterOperator = key => {
    if (!isString(key)) {
        return null;
    }

    key = key.toUpperCase();

    return get(FilterOperator, [key, 'value'], null);
};

export default getFilterOperator;
