import { BaseModel, Adviser, Investor, AgreementSignatory } from '@/models';
import AgreementStatusEnum from '@/enums/agreement/status';
import AgreementApi from '@/api/AgreementApi';

export class Agreement extends BaseModel {
    static entity = 'agreements';
    static Api = AgreementApi;

    static fields() {
        return {
            id: this.string(null).nullable(),
            name: this.string(null).nullable(),
            status: this.enum(AgreementStatusEnum).nullable(),
            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),
            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),
            external_id: this.string(null).nullable(),
            signatories: this.modelList(AgreementSignatory, []).nullable(),
            created_at: this.string(null).nullable(),
            last_updated: this.string(null).nullable()
        };
    }
}

export default Agreement;
