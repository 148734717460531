import { HoldingCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';
import PmiStructureEnum from '@/enums/pmi/structure';

export class HoldingPmiFundPanelCollection extends HoldingCollection {
    static type = 'HoldingPmiFundPanelCollection';
    static endpoint = Holding.api.byPrivateMarketStructure;

    static fields() {
        return {
            ...super.fields(),
            holding_id: this.string(null).nullable(),
            status: this.string('OPEN').nullable()
        };
    }

    get headers() {
        let headers = [
            this.createHeader('structure', i18n.t('structure'), {
                width: '8rem',
                format: 'enum',
                enum: PmiStructureEnum,
                enumA11yKey: 'enums.pmi_structure',
                sortable: false
            }),
            this.createHeader('denomination', i18n.t('denomination'), {
                width: '9rem',
                sortable: false
            }),
            this.createHeader('cumulative_loan_contribution_gbp', `${i18n.t('cumulative_loan_contribution')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem',
                sortable: false
            }),
            this.createHeader('cumulative_net_contributions_gbp', `${i18n.t('cumulative_net_contributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11.4rem',
                sortable: false
            }),
            this.createHeader('distributions_gbp', `${i18n.t('distributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader(
                'fund_share_gbp',
                `${i18n.t('investments_advisory_fess')} / ${i18n.t('gp_share')} (gbp)`,
                {
                    format: 'money',
                    formatOptions: super.money_format_options,
                    width: '9rem',
                    sortable: false
                }
            ),
            this.createHeader('operating_expenses_gbp', `${i18n.t('operating_expenses')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('unrealised_gain_gbp', `${i18n.t('unrealised_gain_loss_on_investments')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '12rem',
                sortable: false
            }),
            this.createHeader('carried_interest_gbp', `${i18n.t('carried_interest_provision')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('nav_gbp', `${i18n.t('nav')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('current_value_gbp', `${i18n.t('current_value')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem',
                sortable: false
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'structure',
            'denomination',
            'cumulative_loan_contribution_gbp',
            'cumulative_net_contributions_gbp',
            'distributions_gbp',
            'fund_share_gbp',
            'operating_expenses_gbp',
            'unrealised_gain_gbp',
            'carried_interest_gbp',
            'nav_gbp',
            'current_value'
        ];
    }

    get pdf_headers() {
        return [
            'structure',
            'denomination',
            'cumulative_loan_contribution_gbp',
            'cumulative_net_contributions_gbp',
            'distributions_gbp',
            'fund_share_gbp',
            'operating_expenses_gbp',
            'unrealised_gain_gbp',
            'carried_interest_gbp',
            'nav_gbp',
            'current_value'
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.show_consolidated_view) {
            filters.consolidated = true;
        }

        if (this.status) {
            filters.status = this.filterOperator('is') + ':OPEN';
        }

        if (this.holding_id) {
            filters.holding_id = this.filterOperator('is') + ':' + this.holding_id;
        }

        filters.structure = this.filterOperator('is') + ':' + PmiStructureEnum.FUND;

        return filters;
    }
}

export default HoldingPmiFundPanelCollection;
