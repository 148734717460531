import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import toIso8601 from '@/lib/helpers/toIso8601';
import clock from '@/lib/clock';

export class DateValidator extends BaseValidator {
    static key = 'date';

    static validator(value, label = 'Date') {
        if (!value) {
            return true;
        }

        value = clock(toIso8601(value));

        if (!value || !value.isValid()) {
            return i18n.t('validator.date', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default DateValidator;
