import Vue from 'vue';

import helpers from './helpers';
import to from './to';
import setup from './setup';
import countries from './countries';
import i18n from './i18n';

Vue.mixin(helpers);
Vue.mixin(to);
Vue.mixin(setup);

export { countries, i18n };
