import request from '@/api/lib/request';
import cleanModelData from '@/api/lib/helpers/cleanModelData';

/**
 * Send a PATCH request.
 *
 * @param {String} url
 * @param {Object} data
 * @returns {Object}
 */
const patch = async (url, data = {}, config = {}) => {
    return await request({
        method: 'PATCH',
        url,
        data: cleanModelData(data),
        ...config
    });
};

export default patch;
