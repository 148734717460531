import permissions from '@/enums/auth/permission';
import i18n from '@/lib/i18n';

export default [
    {
        path: '/proposals',
        component: () =>
            import(
                /* webpackChunkName: "proposals" */
                '@/views/Investments.vue'
            ),
        children: [
            {
                path: '/proposals',
                name: 'Proposals',
                component: () =>
                    import(
                        /* webpackChunkName: "proposals" */
                        '@/views/Proposals.vue'
                    ),
                meta: {
                    title: i18n.t('_page.proposals.heading'),
                    heading: i18n.t('_page.proposals.heading'),
                    description: i18n.t('_page.proposals.description'),
                    permissions: [permissions.INVESTMENT_BOOK],
                    organisations: ['ADVISER', 'INVESTOR']
                }
            }
        ]
    }
];
