import Auth from '@/models/Auth';
/**
 * Check the authenticated user for a role
 * @param {Object} options
 */
const hasRole = role => {
    return Auth().has_role(role);
};

export default hasRole;
