import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';

const categorisationExpired = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (!auth.is_initialised) {
            next();
        } else if (
            to.name !== 'FatalError' &&
            auth.is_individual_investor &&
            auth.is_owner &&
            (auth.category_expired || !auth.category_expires_on)
        ) {
            if (to.name !== 'SettingsAgreements' || to.query.force_recategorise !== true) {
                next({ name: 'SettingsAgreements', query: { ...to.query, force_recategorise: true } });
            } else {
                next();
            }
        } else {
            next({ to: to.path, query: { ...to.query, force_recategorise: null } });
        }
    }
};

export default categorisationExpired;
