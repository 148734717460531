import { Collection, Tranche } from '@/models';
import i18n from '@/lib/i18n';

export class SystemTranchesCollection extends Collection {
    static type = 'SystemTranchesCollection';
    static endpoint = Tranche.api.index;
    static model = Tranche;

    get default_headers() {
        return [
            'fund_id',
            'reconciled',
            'discretionary',
            'multi_investment',
            'cash_balance',
            'total_investment',
            'invested_on',
            'investor_id',
            'adviser_id'
        ];
    }

    get headers() {
        return [
            this.createHeader('control', '', {
                format: 'control',
                width: '5rem',
                persistent: true
            }),
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('fund_id', i18n.t('fund'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Fund')
                },
                transform: data => data.item?.fund?.name
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                format: 'boolean',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('discretionary', i18n.t('discretionary'), {
                format: 'boolean',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('multi_investment', i18n.t('multi_investment'), {
                format: 'boolean',
                filterable: true,
                width: '10rem'
            }),
            this.createHeader('cash_balance', i18n.t('cash_balance'), {
                format: 'money',
                filterable: true,
                formatOptions: this.money_format_options,
                width: '9rem'
            }),
            this.createHeader('total_investment', i18n.t('total_investment'), {
                format: 'money',
                filterable: true,
                formatOptions: this.money_format_options,
                width: '11rem'
            }),
            this.createHeader('invested_on', i18n.t('invested_on'), {
                format: 'date',
                filterable: true,
                width: '10rem'
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name
            })
        ];
    }
}

export default SystemTranchesCollection;
