import { Collection, User } from '@/models';
import i18n from '@/lib/i18n';
import OrganisationTypeEnum from '@/enums/organisation/type';
import UserStatusEnum from '@/enums/user/status';
import UserRoleEnum from '@/enums/user/role';

export class SettingsUsersCollection extends Collection {
    static type = 'SettingsUsersCollection';
    static endpoint = User.api.index;
    static model = User;

    static fields() {
        return {
            ...super.fields(),
            organisation_id: this.string(null).nullable(),
            role_in_organisation: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.organisation_id) {
            filters.organisation_id = this.filterOperator('is') + ':' + this.organisation_id;
        }

        if (this.role_in_organisation) {
            filters.role_in_organisation = this.role_in_organisation;
            filters.organisation_id = this.filterOperator('is_not') + ':' + this.role_in_organisation;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '15rem',
                filterable: true
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
                // to: data => {
                //     if (data.item.organisation) {
                //         return {
                //             name: 'Settings',
                //             query: {
                //                 organisation_id: data.item.organisation.id,
                //                 organisation_type: data.item.organisation.type
                //             }
                //         };
                //     }

                //     return null;
                // }
            }),
            this.createHeader('email', i18n.t('email'), {
                width: '15rem',
                filterable: true
            }),
            this.createHeader('organisation.name', i18n.t('organisation'), {
                path: 'organisation.name',
                width: '15rem',
                filterable: true
            }),
            this.createHeader('organisation.type', i18n.t('organisation_type'), {
                filterable: true,
                transform: data => data?.item?.organisation?.type,
                width: '11rem',
                format: 'enum',
                enum: OrganisationTypeEnum,
                enumA11yKey: 'enums.organisation_type'
            }),
            this.createHeader('role', i18n.t('role'), {
                format: 'enum',
                enum: UserRoleEnum,
                enumA11yKey: 'enums.user_role',
                multiple: true,
                width: '15rem',
                transform: data => {
                    let roles = [];

                    if (data.item.roles) {
                        data.item.roles.forEach(role => {
                            roles.push(...role.roles);
                        });
                    }

                    return [...new Set([...roles])];
                }
            }),
            this.createHeader('fca_number', i18n.t('fca_number'), {
                filterable: true,
                width: '12rem'
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: UserStatusEnum,
                enumA11yKey: 'enums.user_status',
                enumTypes: {
                    [UserStatusEnum.ACTIVE]: 'success',
                    [UserStatusEnum.INACTIVE]: 'grey',
                    [UserStatusEnum.SUSPENDED]: 'error',
                    [UserStatusEnum.UNVERIFIED]: 'warning'
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get default_headers() {
        return ['name', 'email', 'organisation_name', 'organisation_type', 'role', 'fca_number', 'status'];
    }
}

export default SettingsUsersCollection;
