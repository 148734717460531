import { Collection, Product } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';

export class SystemProductsCollection extends Collection {
    static type = 'SystemProductsCollection';
    static endpoint = Product.api.index;
    static model = Product;

    static fields() {
        return {
            pending_allotment: this.boolean(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.pending_allotment) {
            filters.pending_allotment = this.pending_allotment;
        }

        return filters;
    }

    get default_headers() {
        return ['name', 'gi_ref', 'tax_status', 'share_class', 'company'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '10rem',
                filterable: true
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '7rem',
                sortable: false
            }),
            this.createHeader('share_class', i18n.t('share_class'), {
                filterable: true,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('company', i18n.t('company'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Company')
                },
                transform: data => data.item?.company?.name
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default SystemProductsCollection;
