import isPublic from '@/router/lib/isPublic';
// import Auth from '@/models/Auth';

const preload = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        // if (Auth().is_adviser) {
        //     // Preload data to vuex
        // }

        next();
    }
};

export default preload;
