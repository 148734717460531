import { Collection, Investor } from '@/models';
import i18n from '@/lib/i18n';
import UserStatusEnum from '@/enums/user/status';
import InvestorCategoryEnum from '@/enums/investor/category';

export class AdviserClientCollection extends Collection {
    static type = 'AdviserClientCollection';
    static endpoint = Investor.api.index;
    static model = Investor;

    static fields() {
        return {
            ...super.fields(),
            adviser_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {
            include_statistics: true
        };

        if (this.adviser_id) {
            filters.adviser_id = this.filterOperator('is') + ':' + this.adviser_id;
        }

        return filters;
    }

    get pdf_headers() {
        return [
            'name',
            'gi_ref',
            'email',
            'status',
            'category',
            'advisers',
            'total_proposals',
            'proposals_amount',
            'current_value',
            'cash_balance'
        ];
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('gi_ref', i18n.t('client_reference'), {
                filterable: true,
                width: '11rem'
            }),
            this.createHeader('email', i18n.t('email'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: UserStatusEnum,
                enumA11yKey: 'enums.user_status',
                enumTypes: {
                    [UserStatusEnum.ACTIVE]: 'success',
                    [UserStatusEnum.INACTIVE]: 'grey',
                    [UserStatusEnum.SUSPENDED]: 'error',
                    [UserStatusEnum.UNVERIFIED]: 'warning'
                }
            }),
            this.createHeader('category', i18n.t('investor_type'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: InvestorCategoryEnum,
                enumA11yKey: 'enums.investor_category'
            }),
            this.createHeader('advisers', i18n.t('advisers'), {
                width: '20rem',
                transform: data => {
                    const adviserList = [];

                    data.item.adviser_accounts.forEach(aa => {
                        try {
                            let adviserString = '';

                            if (aa.adviser.name) {
                                adviserString = aa.adviser.name;

                                if (aa.primary_contact?.name) {
                                    adviserString += ` (${aa.primary_contact.name})`;
                                }
                            }

                            if (adviserString) {
                                adviserList.push(adviserString);
                            }
                        } catch (error) {
                            //
                        }
                    });

                    return adviserList.join(', ') || null;
                }
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                }
            }),
            this.createHeader('total_proposals', i18n.t('total_proposed'), {
                filterable: false,
                width: '10rem',
                format: 'number',
                to: data => ({
                    name: 'Proposals',
                    query: { investor_id: data.item.id }
                })
            }),
            this.createHeader('proposals_amount', i18n.t('proposed_amount'), {
                filterable: false,
                width: '11rem',
                format: 'money'
            }),
            this.createHeader('current_value', i18n.t('current_value'), {
                filterable: false,
                width: '10rem',
                format: 'money'
            }),
            this.createHeader('cash_balance', i18n.t('platform_cash_balance'), {
                filterable: false,
                width: '10rem',
                format: 'money'
            })
        ];
    }
}

export default AdviserClientCollection;
