const ProposalFundingSourceEnum = Object.freeze({
    EMPLOYMENT: 'EMPLOYMENT',
    INVESTMENT_ASSETS: 'INVESTMENT_ASSETS',
    PROPERTY: 'PROPERTY',
    INHERITANCE: 'INHERITANCE',
    BUSINESS_SALE: 'BUSINESS_SALE',
    BUSINESS_PROFIT: 'BUSINESS_PROFIT',
    GIFT: 'GIFT'
});

export default ProposalFundingSourceEnum;
