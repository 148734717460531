import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import AppQuestionTypeEnum from '@/enums/AppQuestionType';

export class AppropriatenessValidator extends BaseValidator {
    static key = 'appropriateness';

    static validator(value) {
        if (!value) {
            return true;
        }

        if (!Array.isArray(value)) {
            return 'Appropriateness must be a valid array of questions';
        }

        const results = [];

        for (const questionIndex in value) {
            const question = value[`${questionIndex}`];
            const field = `appropriateness[${questionIndex}]`;

            if (Array.isArray(question.value) && question.value.length > 0) {
                const answer = question.answers.find(a => a.id === question.value[0]);

                if (answer && answer.has_other_text && !answer.other_text_value) {
                    results.push({ field, result: i18n.t('required') });
                    continue;
                }
            }

            if (!question.required) {
                results.push({ field, result: true });
                continue;
            }

            if (question.type === AppQuestionTypeEnum.SECTION) {
                results.push({ field, result: true });
                continue;
            }

            if (typeof question.value === 'string' && question.value.length > 0) {
                results.push({ field, result: true });
                continue;
            }

            if (Array.isArray(question.value) && question.value.length > 0) {
                const answer = question.answers.find(a => a.id === question.value[0]);

                if (answer && (!answer.has_other_text || (answer.has_other_text && answer.other_text_value))) {
                    results.push({ field, result: true });
                    continue;
                }
            }

            results.push({ field, result: i18n.t('required') });
        }

        return results;
    }

    static arguments() {
        return [];
    }
}

export default AppropriatenessValidator;
