import applyThemeFromBranding from '@/lib/helpers/applyThemeFromBranding';
import setFavicon from '@/lib/helpers/setFavicon';
import Auth from '@/models/Auth';

const loadBranding = async (to, from, next) => {
    const auth = Auth();

    if (!auth.has_branding) {
        await auth.setBranding();
    }

    applyThemeFromBranding(auth.branding().colours);
    setFavicon(auth.branding().image('favicon'));

    next();
};

export default loadBranding;
