import clock from '@/lib/clock';

/**
 * Time until a date
 * @param {String} value
 * @returns {String}
 */
const timeUntil = value => {
    value = clock(value);

    if (!value.isValid()) {
        return 'in the future';
    }

    return value.fromNow();
};

export default timeUntil;
