export default Object.freeze({
    ALTS: 'ALTS',
    EIS: 'EIS',
    HYBRID: 'HYBRID',
    IHT: 'IHT',
    NQ: 'NQ',
    SEIS: 'SEIS',
    SEISEIS: 'SEISEIS',
    SITR: 'SITR',
    VCT: 'VCT',
    PMI: 'PMI',
    SINGLE_COMPANY: 'SINGLE_COMPANY'
});
