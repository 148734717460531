import { Collection, VisibilityGroup } from '@/models';
import i18n from '@/lib/i18n';

export class VisibilityGroupsCollection extends Collection {
    static type = 'VisibilityGroupsCollection';
    static endpoint = VisibilityGroup.api.index;
    static model = VisibilityGroup;

    static fields() {
        return {
            ...super.fields(),
            adviser_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.adviser_id) {
            filters.adviser_id = this.filterOperator('is') + ':' + this.adviser_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem'
            }),
            this.createHeader('investors', i18n.t('investors'), {
                width: '100%',
                minWidth: '18rem',
                transform: data => data.item.investor_names.join(', ') || null,
                fallback: i18n.t('none')
            })
        ];
    }

    get default_headers() {
        return ['name', 'investors'];
    }
}

export default VisibilityGroupsCollection;
