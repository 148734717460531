import http from '@/lib/http';
import addRequest from '@/api/lib/helpers/addRequest';
import setRequestPromise from '@/api/lib/helpers/setRequestPromise';
import completeRequest from '@/api/lib/helpers/completeRequest';
import rejectRequest from '@/api/lib/helpers/rejectRequest';
import findRequests from '@/api/lib/helpers/findRequests';
import getAbortSignal from '@/api/lib/helpers/getAbortSignal';
import isAborted from '@/api/lib/helpers/isAborted';
import Auth from '@/models/Auth';
import clock from '@/lib/clock';
import envConfig from '@/config';
import wait from '@/lib/helpers/wait';

/**
 * Send a custom request.
 *
 * @param {Object} config
 * @returns {Object}
 */
const request = async (config = {}) => {
    let response = null;

    // Add the scope (Organisation ID of the current user) to the request headers.
    config.headers = attachHeaders(config.headers);

    if (!config.persistent && config.method === 'GET') {
        const pending = findRequests(config).filter(request => request.status === 'pending');

        if (pending.length > 0) {
            await wait(100);
            response = await pending[0].promise;
        }

        if (!response) {
            const completed = findRequests(config).filter(request => {
                return (
                    request.status === 'completed' &&
                    clock().diff(clock(request.response_at), 'milliseconds') < envConfig.API_LIFETIME
                );
            });

            if (completed.length > 0) {
                response = completed[0].response;
            }
        }
    }

    if (!response) {
        const requestId = await addRequest({
            url: config.url,
            method: config.method,
            params: config.params || {},
            headers: config.headers || {},
            persistent: config.persistent,
            abortGroup: config.abortGroup || null
        });

        const signal = getAbortSignal(requestId);
        const promise = http.request({ ...config, signal });

        setRequestPromise(requestId, promise);

        response = await promise.catch(async error => {
            if (isAborted(requestId)) {
                const abortedError = new Error(`Request ${config.method} ${config.url} was aborted.`);
                abortedError.isAborted = true;
                throw abortedError;
            }

            rejectRequest(requestId, error);

            if (error && error.response && error.response.status) {
                error.code = error.response.status;
            } else if (error && error.request && error.request.status) {
                error.code = error.request.status;
            }

            if (error.response?.data instanceof Blob && error.response.data.type === 'application/json') {
                error.response.data = await error.response.data.text();
                error.response.data = JSON.parse(error.response.data);
            }

            throw error;
        });

        completeRequest(requestId, response);
    }

    if (config.returnFullResponse) {
        return response;
    } else {
        return response.data;
    }
};

export const attachHeaders = (headers = {}) => {
    return { ...headers, ...Auth().headers };
};

export default request;
