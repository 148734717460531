import { BaseModel, User, CompanyInfo, Address, PreviousCompanyName, PhoneNumber } from '@/models';
import OrganisationStatusEnum from '@/enums/organisation/status';
import OrganisationTypeEnum from '@/enums/organisation/type';
import i18n from '@/lib/i18n';

export class Organisation extends BaseModel {
    static entity = 'organisations';

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            name: this.string(null).nullable(),
            status: this.enum(OrganisationStatusEnum),
            type: this.enum(OrganisationTypeEnum).nullable(),
            gi_ref: this.string(null).nullable(),
            fca_number: this.string(null).nullable(),
            salesforce_id: this.string(null).nullable(),
            salesforce_last_sync: this.string(null).nullable(),
            addresses: this.modelList(Address, null).nullable(),
            previous_company_names: this.modelList(PreviousCompanyName, null).nullable(),
            company_info: this.model(CompanyInfo).nullable(),
            key_people: this.modelList(User, null).nullable(),
            verticals: this.attr(null),
            annual_stats: this.attr(null),
            users: this.hasMany(User, 'organisation_id'),
            phone_numbers: this.modelList(PhoneNumber, null).nullable(),
            primary_user_id: this.string(null).nullable(),
            primary_user: this.belongsTo(User, 'primary_user_id'),
            created_at: this.string(null).nullable(),
            last_updated: this.string(null).nullable()
        };
    }

    get is_unverified() {
        return this.status === OrganisationStatusEnum.UNVERIFIED;
    }

    get is_active() {
        return this.status === OrganisationStatusEnum.ACTIVE;
    }

    get is_suspended() {
        return this.status === OrganisationStatusEnum.SUSPENDED;
    }

    get is_inactive() {
        return this.status === OrganisationStatusEnum.INACTIVE;
    }

    get phone_number() {
        if (!this.phone_numbers || this.phone_numbers.length === 0) {
            return null;
        }

        const phone_number = this.phone_numbers[0];

        try {
            if (phone_number instanceof PhoneNumber) {
                return phone_number;
            } else {
                return new PhoneNumber({
                    e164: phone_number.number.e164,
                    number: phone_number.number.national_number,
                    country_code: phone_number.number.region,
                    type: phone_number.number_type
                });
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    get company_phone_number() {
        if (!this.company_info) {
            return null;
        }
        return this.company_info.phone_number;
    }

    set company_phone_number(value) {
        if (!this.company_info) {
            this.company_info = new CompanyInfo();
        }
        this.company_info.phone_number = value;
    }

    get company_email() {
        if (!this.company_info) {
            return null;
        }
        return this.company_info.email;
    }

    set company_email(value) {
        if (!this.company_info) {
            this.company_info = new CompanyInfo();
        }
        this.company_info.email = value;
    }

    get website() {
        if (!this.company_info) {
            return null;
        }
        return this.company_info.website;
    }

    set website(value) {
        if (!this.company_info) {
            this.company_info = new CompanyInfo();
        }
        this.company_info.website = value;
    }

    get linkedin_url() {
        if (!this.company_info) {
            return null;
        }
        return this.company_info.linkedin_url;
    }

    set linkedin_url(value) {
        if (!this.company_info) {
            this.company_info = new CompanyInfo();
        }
        this.company_info.linkedin_url = value;
    }

    get twitter_user() {
        if (!this.company_info) {
            return null;
        }
        return this.company_info.twitter_user;
    }

    set twitter_user(value) {
        if (!this.company_info) {
            this.company_info = new CompanyInfo();
        }
        this.company_info.twitter_user = value;
    }

    get twitter_url() {
        if (!this.twitter_user) {
            return null;
        }

        return `${i18n.t('external_urls.twitter')}/${this.twitter_user}`;
    }

    get company_number() {
        if (!this.company_info) {
            return null;
        }
        return this.company_info.company_number;
    }

    set company_number(value) {
        if (!this.company_info) {
            this.company_info = new CompanyInfo();
        }
        this.company_info.company_number = value;
    }

    get companieshouse_url() {
        if (!this.company_number) {
            return null;
        }

        return `${i18n.t('external_urls.companieshouse')}/${this.company_number}`;
    }

    get address() {
        let address = (this.addresses || []).find(address => address.default);

        if (!address) {
            return null;
        }

        if (!(address instanceof Address)) {
            address = new Address(address);
        }

        return address.computed_name;
    }

    get status_icon() {
        if (this.status === OrganisationStatusEnum.UNVERIFIED) {
            return 'dotCircle';
        } else if (this.status === OrganisationStatusEnum.ACTIVE) {
            return 'circle';
        } else if (this.status === OrganisationStatusEnum.SUSPENDED) {
            return 'circle';
        } else if (this.status === OrganisationStatusEnum.INACTIVE) {
            return 'dotCircle';
        }

        return 'batteryHalf';
    }

    get status_icon_colour() {
        if (this.status === OrganisationStatusEnum.UNVERIFIED) {
            return 'warning';
        } else if (this.status === OrganisationStatusEnum.ACTIVE) {
            return 'success';
        } else if (this.status === OrganisationStatusEnum.SUSPENDED) {
            return 'error';
        } else if (this.status === OrganisationStatusEnum.INACTIVE) {
            return 'grey';
        }

        return 'grey';
    }

    get status_text_colour() {
        if (this.status === OrganisationStatusEnum.UNVERIFIED) {
            return 'warning--text text--darken-1';
        } else if (this.status === OrganisationStatusEnum.ACTIVE) {
            return 'success--text';
        } else if (this.status === OrganisationStatusEnum.SUSPENDED) {
            return 'error--text text--darken-1';
        } else if (this.status === OrganisationStatusEnum.INACTIVE) {
            return 'grey--text';
        }

        return 'warning--text text--darken-1';
    }
}

export default Organisation;
