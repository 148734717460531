import { HoldingCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';

export class HoldingIhtCollection extends HoldingCollection {
    static type = 'HoldingIhtCollection';
    static endpoint = Holding.api.byTranche;

    get headers() {
        let headers = [
            // https://growthinvest.atlassian.net/browse/CORE-878
            // this.createHeader('investor_name', i18n.t('investor'), {
            //     path: 'investor.name',
            //     width: '15rem',
            //     minWidth: '15rem'
            // }),
            this.createHeader('fund_name', i18n.t('fund'), {
                path: 'fund.name',
                width: '16rem',
                minWidth: '16rem',
                sortable: false
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '14rem',
                minWidth: '14rem',
                transform: data => {
                    if (data.item?.multi_investment === true) {
                        return i18n.t('co_invest');
                    }
                    return data.item.name;
                },
                sortable: false
            }),
            this.createHeader('gross_investment_amount', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('uninvested_capital', i18n.t('fund_cash_balance'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('invested_on', i18n.t('application_on'), {
                format: 'date',
                width: '8rem',
                sortable: false
            }),
            this.createHeader('current_value_moic', i18n.t('current_value_moic'), {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '8rem',
                sortable: false
            }),

            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_dividends', i18n.t('total_dividends'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10.2rem',
                sortable: false
            }),
            this.createHeader('total_exits', i18n.t('total_exits'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_irr', i18n.t('total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_irr', i18n.t('nav_total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_profit_loss', i18n.t('total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_profit_loss', i18n.t('nav_total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_irr',
                i18n.t('total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_irr',
                i18n.t('nav_total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_profit_loss_including_tax_relief',
                i18n.t('total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_profit_loss_including_tax_relief',
                i18n.t('nav_total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader('total_return_with_exits', i18n.t('total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_with_exits', i18n.t('nav_total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem',
                sortable: false
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'fund_name',
            'name',
            'gross_investment_amount',
            'invested_amount',
            'uninvested_capital',
            'application_on',
            'total_dividends',
            'total_exits',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_moic',
            'nav_total_return_moic',

            'total_return_percentage',
            'nav_total_return_percentage'
        ];
    }

    get pdf_headers() {
        return [
            'fund_name',
            'name',
            'gross_investment_amount',
            'invested_amount',
            'uninvested_capital',
            'application_on',
            'total_dividends',
            'total_exits',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_percentage',
            'nav_total_return_percentage'
        ];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('is') + ':iht'
        };
    }
}

export default HoldingIhtCollection;
