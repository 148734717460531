import { Organisation } from '@/models';
import organisationTypeEnum from '@/enums/organisation/type';
import ReceivingAgentApi from '@/api/ReceivingAgentApi';

export class ReceivingAgent extends Organisation {
    static entity = 'receivingAgents';
    static Api = ReceivingAgentApi;

    static fields() {
        return {
            ...super.fields(),
            type: this.string(organisationTypeEnum.RECEIVINGAGENT)
        };
    }
}

export default ReceivingAgent;
