import { validators } from '@/lib/validators';

/**
 * Get a validation rule validator function
 * @param {String} key
 * @returns {Function}
 */
const getValidator = key => {
    if (typeof key !== 'string') {
        throw new Error('getValidator: key must be a string');
    }

    if (!validators[`${key}`]) {
        throw new Error(`getValidator: validator "${key}" does not exist`);
    }

    return validators[`${key}`];
};

export default getValidator;
