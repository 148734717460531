import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class IntegerValidator extends BaseValidator {
    static key = 'integer';

    static validator(value, label = 'Integer') {
        let valid = false;

        if (!value) {
            return true;
        }

        value = parseFloat(value);

        valid = !isNaN(value) && Number.isInteger(value);

        if (!valid) {
            return i18n.t('validator.integer', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default IntegerValidator;
