import each from 'lodash/each';
import range from 'lodash/range';

const findActiveNode = editor => {
    let activeNode = null;

    each(editor.schema.nodes, (node, nodeKey) => {
        if (editor.isActive(nodeKey)) {
            activeNode = nodeKey;

            if (nodeKey === 'heading') {
                each(range('1', '5'), level => {
                    if (editor.isActive(nodeKey, { level })) {
                        activeNode = `h${level}`;
                    }
                });
            }
        }
    });

    return activeNode;
};

export default findActiveNode;
