/**
 * Extracts all string values recursively from an object or array
 * @param {Object|Array} obj - The object or array to extract strings from
 * @return {string[]} An array containing all unique strings found in the object or array
 * @throws {TypeError} If the input is not an object or array
 */
const extractStrings = value => {
    let strings = new Set();

    if (typeof value === 'string') {
        strings.add(value);
    } else if (Array.isArray(value)) {
        value.forEach(item => {
            extractStrings(item).forEach(str => strings.add(str));
        });
    } else if (typeof value === 'object') {
        for (let key in value) {
            extractStrings(value[`${key}`]).forEach(str => strings.add(str));
        }
    }

    return [...strings];
};

export default extractStrings;
