import store from '@/store';

/**
 * Add a dialog item to the queue
 * @param {Object} options
 */
const dialog = options => {
    store.dispatch(`dialogQueue/add`, options);
};

export default dialog;
