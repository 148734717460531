<template>
    <node-view-wrapper :class="className" :data-id="uuid">
        <node-view-content :class="`${className}__content`" as="div" />
    </node-view-wrapper>
</template>

<script>
import TiptapRadioItem from './TiptapRadioItem.vue';
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2';
import wait from '@/lib/helpers/wait';

export default {
    name: 'TiptapRadioList',
    components: {
        NodeViewWrapper,
        NodeViewContent
    },
    props: {
        ...nodeViewProps
    },
    data: () => ({
        className: 'tiptap-radio-list',
        itemCount: 0
    }),
    computed: {
        inputValue: {
            get() {
                return this.node.attrs.value;
            },
            set(itemIndex) {
                this.updateAttributes({ value: itemIndex });

                this._.each(this._.range(this.itemCount), index => {
                    if (index !== itemIndex) {
                        this.$root.$emit(`TiptapRadioList/${this.uuid}/${index}`, false);
                    }
                });

                if (!this._.isNull(itemIndex)) {
                    this.$root.$emit(`TiptapRadioList/${this.uuid}/${itemIndex}`, true);
                }
            }
        }
    },
    async mounted() {
        await wait(150);

        const $radioList = this.$el;
        const $radioItems = Array.from($radioList.querySelectorAll(`.${TiptapRadioItem.data().className}`));

        this.itemCount = $radioItems.length;

        if (this.inputValue !== null) {
            this.$root.$emit(`TiptapRadioList/${this.uuid}/${this.inputValue}`, true);
        }

        this.$root.$on(`TiptapRadioList/${this.uuid}/updateCount`, count => {
            this.itemCount = count;
            this.inputValue = null;
        });

        this.$root.$on(`TiptapRadioList/${this.uuid}/change`, index => {
            this.inputValue = index;
        });
    },
    beforeDestroy() {
        this.$root.$off(`TiptapRadioList/${this.uuid}/updateCount`);
        this.$root.$off(`TiptapRadioList/${this.uuid}/change`);
    }
};
</script>

<style lang="scss">
.tiptap-radio-list {
    display: flex;
    flex-direction: row;
    background: var(--v-background-darken2);
    border-radius: 4px;
    padding: 0.5rem;
    .v-input--radio-group {
        margin-top: 0;
    }
    &__input {
        margin-right: 0.5rem;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
    &__content {
        *:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
