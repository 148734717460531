import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';
import Registration from '@/models/Registration';

const loadRegistration = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else if (Auth().onboarding && (!to.name || !to.name.startsWith('InvestorOnboarding'))) {
        // User needs to be redirected to the onboarding page
        next({ name: 'InvestorOnboarding' });
    } else if (Auth().onboarding && to.name === 'InvestorOnboarding') {
        // We need to decide which onboarding page to redirect the user to
        try {
            await Auth().setRegistration();
            const registration = new Registration(Auth().registration);

            if (registration.next_step) {
                next({ name: registration.next_step });
            } else if (registration.next_step === false) {
                window.location.href = '/settings?recategorised=true';
            } else {
                next();
            }
        } catch (error) {
            console.error(error);
            next();
        }
    } else {
        next();
    }
};

export default loadRegistration;
