import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import countryFromAlpha2Code from '@/lib/helpers/countryFromAlpha2Code';

export class CountryValidator extends BaseValidator {
    static key = 'country';

    static validator(value) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = value.trim();
            if (value.match(/^[A-Z]{2}$/i) && countryFromAlpha2Code(value)) {
                valid = true;
            }
        }

        if (!valid) {
            return i18n.t('validator.country');
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default CountryValidator;
