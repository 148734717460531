import { Collection } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';

export class ReportTaxYearCollection extends Collection {
    static type = 'ReportTaxYearCollection';

    get money_format_options() {
        return {
            decimals: 2,
            abbreviate: false
        };
    }

    get headers() {
        let headers = [
            this.createHeader('type', i18n.t('type'), {
                format: 'enum',
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                width: '9rem',
                filterable: true
            }),
            this.createHeader('fund', i18n.t('fund'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('invested_on', i18n.t('investment_date'), {
                format: 'date',
                width: '11rem'
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '7rem',
                tooltip: i18n.t('this_is_the_reported_tax_status_of_an_investment')
            }),
            this.createHeader('initial_investment', i18n.t('initial_investment'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                sortable: false
            }),
            this.createHeader('initial_tax_relief', i18n.t('initial_tax_relief'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                sortable: false
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '8rem'
            }),
            this.createHeader('share_issue_price', i18n.t('share_issue_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '8rem'
            }),
            this.createHeader('tax_year', i18n.t('tax_year'), {
                width: '6rem',
                transform: data => this.getTaxYear(data.item.tax_year, { abbreviate: true })
            }),
            this.createHeader('tax_certificate', i18n.t('tax_certificate'), {
                width: '8rem',
                format: 'boolean'
            }),
            this.createHeader('tax_certificate_on', i18n.t('tax_certificate_date'), {
                width: '8rem',
                format: 'date'
            })
        ];

        return headers;
    }
}

export default ReportTaxYearCollection;
