import { Extension } from '@tiptap/core';
import TiptapValidator from '@/lib/validators/TiptapValidator';

export default Extension.create({
    name: 'Validator',

    addStorage() {
        return {
            valid: false,
            results: null
        };
    },

    onCreate({ editor }) {
        const validate = TiptapValidator.validator(editor.getJSON());
        this.storage.valid = validate === true;

        if (this.storage.valid) {
            this.storage.results = null;
        } else {
            this.storage.results = validate;
        }
    },

    onUpdate({ editor }) {
        const validate = TiptapValidator.validator(editor.getJSON());
        this.storage.valid = validate === true;

        if (this.storage.valid) {
            this.storage.results = null;
        } else {
            this.storage.results = validate;
        }
    },

    addCommands() {
        return {
            validate:
                () =>
                ({ editor }) => {
                    const validate = TiptapValidator.validator(editor.getJSON());
                    this.storage.valid = validate === true;

                    if (this.storage.valid) {
                        this.storage.results = null;
                    } else {
                        this.storage.results = validate;
                    }

                    return validate;
                }
        };
    }
});
