import UserRoleEnum from '@/enums/user/role';

const AdviserRoleEnum = Object.freeze({
    SUPERUSER: UserRoleEnum.SUPERUSER,
    ADVISER: UserRoleEnum.ADVISER,
    ADMINISTRATOR: UserRoleEnum.ADMINISTRATOR,
    MANAGER: UserRoleEnum.MANAGER,
    READONLY: UserRoleEnum.READONLY
});

export default AdviserRoleEnum;
