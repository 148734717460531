import { BaseModel, Product } from '@/models';

export class ExchangeListing extends BaseModel {
    static entity = 'listings';

    static fields() {
        return {
            id: this.attr(null),
            // Listing Reference is ISIN
            listing_ref: this.string(null).nullable(),
            listing_ref_url: this.string(null).nullable(),
            morningstar_ref: this.string(null).nullable(),
            ticker: this.string(null).nullable(),
            primary: this.boolean(false).nullable(),
            currency: this.string(null).nullable(),
            nominal_value: this.attr(null).nullable(),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id')
        };
    }

    get isin() {
        return this.listing_ref;
    }

    get lse_link() {
        if (!this.isin) {
            return null;
        }
        // eslint-disable-next-line max-len
        return `http://www.londonstockexchange.com/exchange/prices-and-markets/stocks/summary/company-summary/${this.isin}.html`;
    }
}

export default ExchangeListing;
