import clock from '@/lib/clock';
import toIso8601 from './toIso8601';

/**
 * Date Formatting
 * @param {String} value
 * @param {String} format
 * @returns {String}
 */
const toDate = (value, format = 'DD/MM/YYYY', local = true) => {
    if (!value) {
        return null;
    }

    value = toIso8601(value);

    if (!value) {
        return null;
    }

    let date = clock.utc(value);

    if (!date.isValid()) {
        console.error(new Error('Date is not a valid date object:' + value));
        return null;
    }

    if (format === false) {
        return date;
    }

    return local ? date.local().format(format) : date.format(format);
};

export default toDate;
