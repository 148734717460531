import store from '@/store';

const resetPinnedInvestor = (to, from, next) => {
    const investorId = store.getters['app/query_selector']('investor_id');

    if (investorId) {
        const isPortfolioPage = to.matched.some(record => record.name === 'Portfolio');
        const isLibraryPage = to.matched.some(record => record.name === 'Library');
        const isTransactionsPage = to.matched.some(record => record.name === 'Transactions');

        if (!isPortfolioPage && !isLibraryPage && !isTransactionsPage) {
            store.dispatch('app/removeQuerySelector', 'investor_id');
        }
    }

    next();
};

export default resetPinnedInvestor;
