import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('i18n', ['languages', 'languagesLoading', 'locale', 'localeLoading'])
    },
    methods: {
        async loadLocale() {
            await this.$store.dispatch('i18n/getLocale');
        }
    }
};
