import { HoldingCollection } from '@/models';
import i18n from '@/lib/i18n';

export class HoldingIhtPanelCollection extends HoldingCollection {
    static type = 'HoldingIhtPanelCollection';

    static fields() {
        return {
            ...super.fields(),
            tranche_id: this.string(null).nullable(),
            status: this.string('OPEN').nullable()
        };
    }

    get headers() {
        let headers = [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                sortable: false
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '8rem'
            }),
            this.createHeader('share_issue_price', i18n.t('share_issue_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '8rem'
            }),
            this.createHeader('application_on', i18n.t('application_on'), {
                format: 'date',
                width: '8rem'
            }),
            this.createHeader('tax_year', i18n.t('tax_year'), {
                width: '6rem',
                sortable: false,
                transform: data => this.getTaxYear(data.item.tax_year, { abbreviate: true }),
                fallback: data => {
                    if (data.item.pending_allotment) {
                        return i18n.t('pending');
                    }

                    return '-';
                }
            }),

            this.createHeader('current_share_price', i18n.t('current_share_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_price', i18n.t('nav_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                active: this.show_nav_totals
            }),

            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_dividends', i18n.t('total_dividends'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10.2rem'
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_irr', i18n.t('total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_irr', i18n.t('nav_total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_profit_loss', i18n.t('total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_profit_loss', i18n.t('nav_total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_irr',
                i18n.t('total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_irr',
                i18n.t('nav_total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_profit_loss_including_tax_relief',
                i18n.t('total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_profit_loss_including_tax_relief',
                i18n.t('nav_total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader('total_return_with_exits', i18n.t('total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_with_exits', i18n.t('nav_total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem',
                sortable: false
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'name',
            'invested_amount',
            'share_quantity',
            'share_issue_price',
            'share_issue_on',
            'tax_year',
            'total_dividends',

            'current_share_price',
            'nav_price',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_moic',
            'nav_total_return_moic',

            'total_return_percentage',
            'nav_total_return_percentage'
        ];
    }

    get pdf_headers() {
        return [
            'name',
            'invested_amount',
            'share_quantity',
            'share_issue_price',
            'share_issue_on',
            'tax_year',
            'total_dividends',

            'current_share_price',
            'nav_price',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_percentage',
            'nav_total_return_percentage'
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.show_consolidated_view) {
            filters.consolidated = true;
        }

        if (this.status) {
            filters.status = this.filterOperator('is') + ':OPEN';
        }

        if (this.tranche_id) {
            filters.tranche_id = this.filterOperator('is') + ':' + this.tranche_id;
        }

        return filters;
    }
}

export default HoldingIhtPanelCollection;
