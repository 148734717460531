import Auth from '@/models/Auth';

/**
 * Check the authenticated user for a permission
 * @param {Object} options
 */
const hasPermission = permission => {
    return Auth().can(permission);
};

export default hasPermission;
