export const mimeTypes = [
    'video/x-msvideo',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/mp2t',
    'video/webm',
    'video/3gpp',
    'video/3gpp2'
];

export default file => {
    let type = file && typeof file === 'object' && 'type' in file ? file.type : file;
    return mimeTypes.includes(type);
};
