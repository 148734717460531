export default {
    keyboard: {
        namespaced: true,
        state: {
            _keys: []
        },
        getters: {
            keys: state => state._keys,
            has: state => key => state._keys.includes(key),
            // Returns true if only the specified keys are pressed
            only: state => keys => {
                if (state._keys.length !== keys.length) {
                    return false;
                }

                return keys.every(key => state._keys.includes(key));
            }
        },
        actions: {
            press({ commit, state }, event) {
                const key = event.key;

                if (state._keys.includes(key)) {
                    return;
                }

                const keys = [...new Set([...state._keys, event.key])];

                commit('SET_KEYS', keys);
            },
            release({ commit, state }, event) {
                const key = event.key;
                const keys = state._keys.filter(k => k !== key);

                commit('SET_KEYS', keys);
            },
            clear({ commit }) {
                commit('SET_KEYS', []);
            }
        },
        mutations: {
            SET_KEYS(state, keys) {
                state._keys = keys;
            }
        }
    }
};
