import isPublic from '@/router/lib/isPublic';

const checkUrlForContext = (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        let hasNewRoute = false;
        let query = to.query;

        if (query.currentOrganisation) {
            localStorage.setItem('currentOrganisation', query.currentOrganisation);
            delete query.currentOrganisation;
            hasNewRoute = true;
        }

        if (query.currentAccount) {
            localStorage.setItem('currentAccount', query.currentAccount);
            delete query.currentAccount;
            hasNewRoute = true;
        }

        if (hasNewRoute) {
            next({
                replace: true,
                path: to.path,
                query
            });
        } else {
            next();
        }
    }
};

export default checkUrlForContext;
