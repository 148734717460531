const getQueryParams = () => {
    const currentUrl = window.location.href;
    const params = new URLSearchParams(currentUrl.split('?')[1]);
    let queryParams = {};

    for (let [key, value] of params.entries()) {
        queryParams[`${key}`] = value;
    }

    return queryParams;
};

export default getQueryParams;
