import { Collection } from '@/models';
import store from '@/store';
import ReportApi from '@/api/ReportApi';

export class ReportCollection extends Collection {
    static type = 'ReportCollection';
    static Api = ReportApi;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable()
        };
    }

    get headers() {
        return [];
    }

    get default_headers() {
        return [];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.show_consolidated_view) {
            filters.consolidated = true;
        }

        return filters;
    }

    get money_format_options() {
        return {
            decimals: 2,
            abbreviate: false
        };
    }

    get percentage_format_options() {
        return {
            abbreviate: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
    }

    get show_nav_totals() {
        return store.getters['preferences/show_nav_totals'];
    }

    get show_consolidated_view() {
        return store.getters['preferences/show_consolidated_view'];
    }
}

export default ReportCollection;
