import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapRadioItem from '@/lib/tiptap/components/TiptapRadioItem.vue';

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'RadioItem',
    content: 'paragraph block*',
    defining: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapRadioItem);
    },

    addAttributes() {
        return {
            id: { default: null },
            value: { default: false }
        };
    },

    addCommands() {
        return {
            setRadioItem:
                () =>
                ({ commands }) => {
                    return commands.wrapIn(this.name);
                },
            toggleRadioItem:
                () =>
                ({ commands }) => {
                    return commands.toggleWrap(this.name);
                },
            unsetRadioItem:
                () =>
                ({ commands }) => {
                    return commands.lift(this.name);
                }
        };
    },

    addOptions() {
        return {
            itemTypeName: 'RadioItem',
            HTMLAttributes: {
                default: {
                    class: 'tiptap-radio-item',
                    'data-id': null,
                    'data-value': 'false'
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-radio-item',
                getAttrs: node => ({
                    id: node.getAttribute('data-id'),
                    value: node.getAttribute('data-value') === 'true'
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            {
                class: 'tiptap-radio-item',
                'data-id': HTMLAttributes.id,
                'data-value': HTMLAttributes.value
            },
            0
        ];
    },

    addKeyboardShortcuts() {
        return {
            Enter: () => this.editor.commands.splitListItem(this.name)
        };
    }
});
