const PermissionEnum = Object.freeze({
    // User Permissions
    USER_CREATE: 'USER_CREATE',
    USER_READ: 'USER_READ',
    USER_UPDATE: 'USER_UPDATE',
    USER_DELETE: 'USER_DELETE',

    // Organisation Permissions
    ORGANISATION_READ: 'ORGANISATION_READ',
    ORGANISATION_UPDATE: 'ORGANISATION_UPDATE',

    // Investment Permissions
    INVESTMENT_BOOK: 'INVESTMENT_BOOK',

    // Transaction Permissions
    TRANSACTION_CREATE: 'TRANSACTION_CREATE',
    TRANSACTION_READ: 'TRANSACTION_READ',
    TRANSACTION_UPDATE: 'TRANSACTION_UPDATE',
    TRANSACTION_LIMITED_UPDATE: 'TRANSACTION_LIMITED_UPDATE',
    TRANSACTION_CANCEL: 'TRANSACTION_CANCEL',

    // Report Permissions
    REPORT_CREATE: 'REPORT_CREATE',
    REPORT_READ: 'REPORT_READ',
    REPORT_UPDATE: 'REPORT_UPDATE',
    REPORT_DELETE: 'REPORT_DELETE',

    // Holding Permissions
    ASSET_CREATE: 'ASSET_CREATE',
    ASSET_READ: 'ASSET_READ',
    ASSET_UPDATE: 'ASSET_UPDATE',
    ASSET_DELETE: 'ASSET_DELETE',

    // Holding value Permissions
    ASSET_VALUE_CREATE: 'ASSET_VALUE_CREATE',
    ASSET_VALUE_UPDATE: 'ASSET_VALUE_UPDATE',
    ASSET_VALUE_DELETE: 'ASSET_VALUE_DELETE',

    // Child user permissions
    CHILD_USER_CREATE: 'CHILD_USER_CREATE',
    CHILD_USER_READ: 'CHILD_USER_READ',
    CHILD_USER_UPDATE: 'CHILD_USER_UPDATE',
    CHILD_USER_DELETE: 'CHILD_USER_DELETE',

    // Adviser Permissions
    ADVISER_CREATE: 'ADVISER_CREATE',
    ADVISER_READ: 'ADVISER_READ',
    ADVISER_UPDATE: 'ADVISER_UPDATE',
    ADVISER_DELETE: 'ADVISER_DELETE',

    // Investor Permissions
    INVESTOR_CREATE: 'INVESTOR_CREATE',
    INVESTOR_READ: 'INVESTOR_READ',
    INVESTOR_UPDATE: 'INVESTOR_UPDATE',
    INVESTOR_ASSIGN: 'INVESTOR_ASSIGN',

    // Product Permissions
    PRODUCT_CREATE: 'PRODUCT_CREATE',
    PRODUCT_READ: 'PRODUCT_READ',
    PRODUCT_UPDATE: 'PRODUCT_UPDATE',
    PRODUCT_DELETE: 'PRODUCT_DELETE',
    PRODUCT_REVIEW: 'PRODUCT_REVIEW',
    PRODUCT_APPROVE: 'PRODUCT_APPROVE',

    // Offer Permissions
    OFFER_CREATE: 'OFFER_CREATE',
    OFFER_READ: 'OFFER_READ',
    OFFER_DELETE: 'OFFER_DELETE',
    OFFER_UPDATE: 'OFFER_UPDATE',
    OFFER_REVIEW: 'OFFER_REVIEW',
    OFFER_APPROVE: 'OFFER_APPROVE',

    // Disclosure Permissions
    DISCLOSURE_CREATE: 'DISCLOSURE_CREATE',
    DISCLOSURE_READ: 'DISCLOSURE_READ',
    DISCLOSURE_UPDATE: 'DISCLOSURE_UPDATE',
    DISCLOSURE_DELETE: 'DISCLOSURE_DELETE',

    // Any-organisation permissions
    ANY_ORGANISATION_CREATE: 'ANY_ORGANISATION_CREATE',
    ANY_ORGANISATION_READ: 'ANY_ORGANISATION_READ',
    ANY_ORGANISATION_UPDATE: 'ANY_ORGANISATION_UPDATE',
    ANY_ORGANISATION_DELETE: 'ANY_ORGANISATION_DELETE',

    // Any-user permissions
    ANY_USER_CREATE: 'ANY_USER_CREATE',
    ANY_USER_READ: 'ANY_USER_READ',
    ANY_USER_UPDATE: 'ANY_USER_UPDATE',
    ANY_USER_DELETE: 'ANY_USER_DELETE',

    // Import/export permissions
    BULK_IMPORT: 'BULK_IMPORT',
    BULK_EXPORT: 'BULK_EXPORT',

    // Misc permissions
    PAYMENT_RUN_CREATE: 'PAYMENT_RUN_CREATE',
    INSTRUCTION_APPROVE: 'INSTRUCTION_APPROVE',
    EMULATE_ANY_USER: 'EMULATE_ANY_USER',

    // registration
    ANY_REGISTRATION_READ: 'ANY_REGISTRATION_READ',
    APPROVE_REGISTRATION: 'APPROVE_REGISTRATION'
});

export default PermissionEnum;
