import isString from 'lodash/isString';

/**
 * Remove whitespace from start and end of string
 * @param {String} str
 */
const strim = value => {
    if (!isString(value)) {
        return '';
    }
    return value.trim().replace(/\s\s+/g, ' ');
};

export default strim;
