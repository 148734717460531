import FilterOperator from '@/enums/filter/operator';
import map from 'lodash/map';
import values from 'lodash/values';
import filter from 'lodash/filter';

/**
 * Returns a list of operators that don't require criteria.
 * @returns Array
 */
const getNoCriteriaOperators = () => {
    return map(filter(values(FilterOperator), ['requiresCriteria', false]), o => {
        return o.value;
    });
};

export default getNoCriteriaOperators;
