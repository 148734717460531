import i18n from '@/lib/i18n';
import upperFirst from 'lodash/upperFirst';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import { tabKeys } from '@/views/Reports';

export default [
    {
        path: '/reports',
        name: 'Reports',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "reports" */
                    '@/views/Reports.vue'
                ),
            sidebar: () =>
                import(
                    /* webpackChunkName: "reports" */
                    '@/sidebars/ReportsSidebar.vue'
                )
        },
        meta: {
            title: i18n.t('_page.reports.heading'),
            heading: i18n.t('_page.reports.heading'),
            organisations: ['ADVISER', 'INVESTOR'],
            sidebarKey: 'reports-sidebar'
        },
        children: tabKeys.map(tab => ({
            path: kebabCase(tab),
            name: 'Reports' + upperFirst(camelCase(tab)),
            component: () =>
                import(
                    /* webpackChunkName: "reports" */
                    '@/views/Reports' + upperFirst(camelCase(tab)) + '.vue'
                ),
            meta: {
                title: i18n.t('reports') + ' - ' + i18n.t('enums.reports_tabs.' + snakeCase(tab)),
                heading: i18n.t('enums.reports_tabs.' + snakeCase(tab)),
                organisations: ['ADVISER', 'INVESTOR'],
                sidebarKey: 'reports-sidebar'
            }
        }))
    }
];
