import isFileTypeArchive from '@/lib/helpers/isFileTypeArchive';
import isFileTypeAudio from '@/lib/helpers/isFileTypeAudio';
import isFileTypeCsv from '@/lib/helpers/isFileTypeCsv';
import isFileTypeExcel from '@/lib/helpers/isFileTypeExcel';
import isFileTypeImage from '@/lib/helpers/isFileTypeImage';
import isFileTypePdf from '@/lib/helpers/isFileTypePdf';
import isFileTypeVideo from '@/lib/helpers/isFileTypeVideo';
import isFileTypeWord from '@/lib/helpers/isFileTypeWord';

const getFileTypeIcon = type => {
    if (isFileTypeArchive(type)) {
        return 'fileArchive';
    } else if (isFileTypeAudio(type)) {
        return 'fileAudio';
    } else if (isFileTypeCsv(type)) {
        return 'fileCsv';
    } else if (isFileTypeExcel(type)) {
        return 'fileExcel';
    } else if (isFileTypeImage(type)) {
        return 'fileImage';
    } else if (isFileTypePdf(type)) {
        return 'filePdf';
    } else if (isFileTypeVideo(type)) {
        return 'fileVideo';
    } else if (isFileTypeWord(type)) {
        return 'fileWord';
    }

    return 'file';
};

export default getFileTypeIcon;
