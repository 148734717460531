import { HoldingCollection } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';

export class HoldingEsgCollection extends HoldingCollection {
    static type = 'HoldingEsgCollection';

    get headers() {
        let headers = [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '7rem'
            }),
            this.createHeader('gross_investment_amount', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem'
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('uninvested_capital', i18n.t('fund_cash_balance'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('tax_relief', i18n.t('tax_relief'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('application_on', i18n.t('application_on'), {
                format: 'date',
                width: '8rem'
            }),
            this.createHeader('current_value', i18n.t('current_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('current_value_moic', i18n.t('current_value_moic'), {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '8rem'
            }),
            this.createHeader('total_exits', i18n.t('total_exits'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem'
            }),
            this.createHeader('distributions', i18n.t('distributions'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem'
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_irr', i18n.t('total_return_irr'), {
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_irr', i18n.t('nav_total_return_irr'), {
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_profit_loss', i18n.t('total_return_profit_loss'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_profit_loss', i18n.t('nav_total_return_profit_loss'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: this.show_nav_totals
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_irr',
                i18n.t('total_return_including_tax_relief_irr'),
                {
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_irr',
                i18n.t('nav_total_return_including_tax_relief_irr'),
                {
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader(
                'total_return_profit_loss_including_tax_relief',
                i18n.t('total_return_profit_loss_including_tax_relief'),
                {
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: !this.show_nav_totals
                }
            ),
            this.createHeader(
                'nav_total_return_profit_loss_including_tax_relief',
                i18n.t('nav_total_return_profit_loss_including_tax_relief'),
                {
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: this.show_nav_totals
                }
            ),

            this.createHeader('total_return_with_exits', i18n.t('total_return_with_exits'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: !this.show_nav_totals
            }),
            this.createHeader('nav_total_return_with_exits', i18n.t('nav_total_return_with_exits'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: this.show_nav_totals
            }),

            this.createHeader('profit_loss', i18n.t('profit_loss'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('profit_loss_including_tax_relief', i18n.t('profit_loss_including_tax_relief'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem'
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem'
            })
        ];

        return headers;
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('is') + ':esg'
        };
    }
}

export default HoldingEsgCollection;
