const TermsStatusEnum = Object.freeze({
    GLOBAL: 'GLOBAL',
    ADVISER: 'ADVISER',
    INVESTOR: 'INVESTOR',
    BROKER: 'BROKER',
    COMPANY: 'COMPANY',
    CUSTODIAN: 'CUSTODIAN',
    FUND_MANAGER: 'FUND_MANAGER',
    NOMINEE: 'NOMINEE',
    PRODUCT_PROVIDER: 'PRODUCT_PROVIDER',
    RECEIVINGAGENT: 'RECEIVINGAGENT',
    REGISTRAR: 'REGISTRAR'
});

export default TermsStatusEnum;
