import masks from '@/lib/masks';
import isFunction from 'lodash/isFunction';

/**
 * Get predefined mask options for Cleave.js
 * @param {String} key
 * @returns {Object}
 */
const getMask = (key, options = null) => {
    const mask = masks[`${key}`];

    if (isFunction(mask)) {
        return mask(options);
    }

    return { ...mask, ...options };
};

export default getMask;
