(function (_Date) {
    const standardizeArgs = args => {
        if (args.length === 1 && typeof args[0] === 'string' && isNaN(_Date.parse(args[0]))) {
            args[0] = args[0].replace(/-/g, '/');
        }
        return [...args];
    };

    class $Date extends _Date {
        constructor(...args) {
            super(...standardizeArgs(args));
        }
        static now() {
            return _Date.now();
        }
        static UTC() {
            return _Date.UTC();
        }
        static parse(...args) {
            return _Date.parse(...standardizeArgs(args));
        }
    }

    // eslint-disable-next-line no-global-assign
    Date = $Date;
})(Date);
