import { BaseModel, Offer, RestrictedPanel } from '@/models';

export class RestrictedPanelEntry extends BaseModel {
    static entity = 'restrictedPanelEntries';

    static primaryKey = ['offer_id', 'panel_id'];

    static fields() {
        return {
            offer_id: this.attr(null),
            offer: this.belongsTo(Offer, 'offer_id'),
            panel_id: this.attr(null),
            panel: this.belongsTo(RestrictedPanel, 'panel_id')
        };
    }
}

export default RestrictedPanelEntry;
