import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import IBAN from 'iban';

export class IbanValidator extends BaseValidator {
    static key = 'iban';

    static validator(value, label = 'IBAN') {
        if (!value) {
            return true;
        }

        if (!IBAN.isValid(value)) {
            return i18n.t('validator.iban', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default IbanValidator;
