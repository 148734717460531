import { BaseModel } from '@/models';

export class InvestmentFocus extends BaseModel {
    static entity = 'investmentFocuses';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string('')
        };
    }
}

export default InvestmentFocus;
