import { BaseModel } from '@/models';
import i18n from '@/lib/i18n';

export class TaxReferenceNumber extends BaseModel {
    static entity = 'taxReferenceNumbers';

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            tax_reference_number_type: this.string(null).nullable(),
            tax_reference_number: this.string(null).nullable(),
            jurisdiction: this.attr(null).nullable()
        };
    }

    get is_valid() {
        return [this.tax_reference_number_type, this.tax_reference_number, this.jurisdiction].every(v => v);
    }

    get computed_name() {
        if (this.is_valid) {
            return [this.tax_reference_number, this.tax_reference_number_type].join(' - ');
        }
        return i18n.t('tax_reference_number');
    }
}

export default TaxReferenceNumber;
