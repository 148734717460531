const ActivityTypeEnum = Object.freeze({
    ACCOUNT: 'ACCOUNT',
    ACTIVITY: 'ACTIVITY',
    DETAILCREATE: 'DETAILCREATE',
    DETAILDELETE: 'DETAILDELETE',
    DETAILEDIT: 'DETAILEDIT',
    DETAILVIEW: 'DETAILVIEW',
    OTHER: 'OTHER',
    PORTFOLIO: 'PORTFOLIO',
    PROPOSAL: 'PROPOSAL',
    SECURITY: 'SECURITY',
    REGISTRATION: 'REGISTRATION',
    ONBOARDING: 'ONBOARDING',
    APPLICATION: 'APPLICATION'
});

export default ActivityTypeEnum;
