export const mimeTypes = [
    'audio/midi',
    'audio/x-midi',
    'audio/mpeg',
    'audio/ogg',
    'audio/opus',
    'audio/wav',
    'audio/webm',
    'audio/3gpp',
    'audio/3gpp2'
];

export default file => {
    let type = file && typeof file === 'object' && 'type' in file ? file.type : file;
    return mimeTypes.includes(type);
};
