import { BaseModel, PhoneNumber } from '@/models';
import countryFromAlpha2Code from '@/lib/helpers/countryFromAlpha2Code';

export class CompanyInfo extends BaseModel {
    static entity = 'companyInfos';

    static fields() {
        return {
            company_number: this.string(null).nullable(),
            company_name: this.string(null).nullable(),
            company_status: this.string(null).nullable(),
            company_status_detail: this.string(null).nullable(),
            //
            accounting_reference_day: this.number(null).nullable(),
            accounting_reference_month: this.number(null).nullable(),
            last_accounts_at: this.string(null).nullable(),
            last_accounts_type: this.string(null).nullable(),
            //
            accounts_next_due: this.string(null).nullable(),
            accounts_overdue: this.boolean(null).nullable(),
            accounts_last_made_up_to: this.string(null).nullable(),
            accounts_next_made_up_to: this.string(null).nullable(),
            //
            annual_return_next_due: this.string(null).nullable(),
            annual_return_overdue: this.boolean(null).nullable(),
            annual_return_last_made_up_to: this.string(null).nullable(),
            annual_return_next_made_up_to: this.string(null).nullable(),
            //
            confirmation_statement_next_due: this.string(null).nullable(),
            confirmation_statement_overdue: this.boolean(null).nullable(),
            confirmation_statement_last_made_up_to: this.string(null).nullable(),
            confirmation_statement_next_made_up_to: this.string(null).nullable(),
            //
            date_of_creation: this.string(null).nullable(),
            date_of_cessation: this.string(null).nullable(),
            //
            liquidated: this.boolean(null).nullable(),
            has_charges: this.boolean(null).nullable(),
            has_insolvency_history: this.boolean(null).nullable(),
            //
            jurisdiction: this.string(null).nullable(),
            previous_names: this.attr(null).nullable(),
            sic_codes: this.attr(null).nullable(),
            type: this.string(null).nullable(),
            // These next few are technically not from CH but we do need them for every company
            website: this.string(null).nullable(),
            email: this.string(null).nullable(),
            tel: this.attr({ national_number: null, region: null }).nullable(),
            linkedin_url: this.string(null).nullable(),
            twitter_user: this.string(null).nullable()
        };
    }

    get phone_number() {
        let tel = this.tel;

        if (!tel) {
            return null;
        }

        if (typeof tel !== 'object') {
            return null;
        }

        if ('number' in tel) {
            tel.national_number = tel.number;
        }

        if ('country' in tel) {
            tel.region = tel.country;
        }

        if ('country_code' in tel) {
            tel.prefix = `+${tel.country_code}`;
        }

        if (!('national_number' in tel)) {
            return null;
        }

        if (!('region' in tel)) {
            return null;
        }

        if ((!('prefix' in tel) || tel.prefix === null) && tel.region) {
            const country = countryFromAlpha2Code(tel.region);
            if (country) {
                tel.prefix = `+${country.callingCode}`;
            }
        }

        return new PhoneNumber({
            number: tel.national_number,
            country: tel.region,
            prefix: tel.prefix
        });
    }

    set phone_number(v) {
        if (!v) {
            this.tel = null;
        } else if (typeof v !== 'object') {
            this.tel = null;
        } else if (!('number' in v)) {
            this.tel = null;
        } else if (!('country' in v)) {
            this.tel = null;
        } else {
            this.tel = {
                national_number: v.number,
                region: v.country
            };
        }
    }
}

export default CompanyInfo;
