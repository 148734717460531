import { Media, Adviser } from '@/models';
import MediaTypeEnum from '@/enums/media/type';
import MediaBrandingApi from '@/api/MediaBrandingApi';

export class MediaBranding extends Media {
    static entity = 'mediaBranding';
    static Api = MediaBrandingApi;

    static fields() {
        return {
            ...super.fields(),
            library_type: this.string(MediaTypeEnum.BRANDING),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id')
        };
    }

    static get(id) {
        return MediaBranding.query().whereId(id).withAllRecursive().first();
    }
}

export default MediaBranding;
