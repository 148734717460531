import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class QuestionValidator extends BaseValidator {
    static key = 'question';

    static validator(answer, correctAnswer) {
        if (answer === null) {
            return true;
        }

        if (answer !== correctAnswer) {
            return i18n.t('validator.question');
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default QuestionValidator;
