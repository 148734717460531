import { BaseModel, Investor, Adviser, Proposal } from '@/models';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import OfferStatusEnum from '@/enums/offer/status';
import ShortlistApi from '@/api/ShortlistApi';

export class Shortlist extends BaseModel {
    static entity = 'shortlists';
    static Api = ShortlistApi;

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(null).nullable(),
            last_edited_date: this.string(null).nullable(),
            entries: this.hasMany(Proposal, 'shortlist_id'),
            total_maximum_tax_relief: this.attr(null).nullable(),
            total_cleared_funds_required: this.attr(null).nullable(),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id')
        };
    }

    get computed_name() {
        return ['Shortlist', this.name].filter(Boolean).join(' - ');
    }

    get has_investor_mismatch() {
        const investorId = this.investor_id || this.investor?.id || null;

        if (!investorId) {
            return false;
        }

        if (!this.investor) {
            return true;
        }

        if (this.investor.$responseFormat < 20) {
            return true;
        }

        if (this.investor.id && this.investor.id !== investorId) {
            return true;
        }

        return false;
    }

    async setup() {
        const investorId = this.investor_id || this.investor?.id || null;

        if (this.has_investor_mismatch) {
            this.investor = await Investor.$get(investorId, {
                force: true
            });
            this.investor_id = investorId;
        }

        for (const entry of this.entries) {
            await entry.setup();
        }
    }

    get advisers() {
        if (!this.investor) {
            return [];
        }

        return this.investor.adviser_accounts.map(aa => aa.adviser);
    }

    get adviser_filter() {
        if (!this.investor || !this.advisers.length) {
            return null;
        }
        return {
            id: `${getFilterOperator('in')}:[${this.advisers.map(a => a.id).join(',')}]`
        };
    }

    get entry_offer_ids() {
        return this.entries.map(entry => entry.offer.id) || [];
    }

    get entry_quantity() {
        return this.entries.filter(entry => entry.offer.status !== OfferStatusEnum.CLOSED).length;
    }

    get total_proposed_amount() {
        return this.entries.reduce(
            (total, entry) => {
                if (!entry) {
                    return total;
                }

                if (entry.offer.status === OfferStatusEnum.CLOSED) {
                    return total;
                }

                if (entry.application_amount && 'currency' in entry.application_amount) {
                    return {
                        currency: entry.application_amount?.currency || 'GBP',
                        amount: total.amount + (entry.application_amount?.amount || 0)
                    };
                }

                return total;
            },
            { currency: 'GBP', amount: 0 }
        );
    }

    get total_including_fees() {
        return this.entries.reduce(
            (total, entry) => {
                if (entry.offer.status === OfferStatusEnum.CLOSED) {
                    return total;
                }

                const entryTotal = entry.calculateTotal();

                if (entryTotal && 'currency' in entryTotal) {
                    return {
                        currency: entryTotal.currency,
                        amount: total.amount + entryTotal.amount
                    };
                }

                return total;
            },
            { currency: 'GBP', amount: 0 }
        );
    }

    get has_closed_offer() {
        return this.entries.some(entry => entry.has_closed_offer);
    }

    get has_cash() {
        return (this.investor?.cash?.amount || 0) > 0;
    }

    get has_cleared_funds() {
        const cash = this.investor?.cash?.amount || 0;
        const total = this.total_cleared_funds_required?.amount || 0;

        if (!total) {
            return true;
        }

        return cash >= total;
    }

    async $removeEntry(entryId) {
        if (!this.id) {
            throw new ReferenceError('Missing ID required while attempting to send DELETE request');
        }

        if (!entryId) {
            throw new ReferenceError('Missing Entry ID required while attempting to send DELETE request');
        }

        return await Shortlist.api.removeEntry(entryId, this.id);
    }
}

export default Shortlist;
