import now from '@/lib/helpers/now';
import getTaxYear from '@/lib/helpers/getTaxYear';
import getDaysUntilTaxYearEnd from '@/lib/helpers/getDaysUntilTaxYearEnd';
import i18n from '@/lib/i18n';

export const dynamicValueEnum = Object.freeze({
    TODAY: {
        icon: 'clock',
        name: i18n.t('today'),
        render: () => now(true)
    },
    PREVIOUS_FINANCIAL_YEAR: {
        icon: 'history',
        name: i18n.t('previous_financial_year'),
        render: () => getTaxYear('last', { asString: true })
    },
    DAYS_UNTIL_TAX_YEAR_END: {
        icon: 'forward',
        name: i18n.t('days_until_end_of_tax_year'),
        render: () => getDaysUntilTaxYearEnd()
    },
    ADVISER_FIRM_NAME: {
        icon: 'user',
        name: i18n.t('adviser_firm_name'),
        render: () => 'adviser_firm_name'
    },
    SHARE_CLASS: {
        icon: 'shares',
        name: i18n.t('share_class'),
        render: () => 'share_class'
    },
    PRODUCT_NAME: {
        icon: 'product',
        name: i18n.t('product_name'),
        render: () => 'product_name'
    },
    COMPANY_NAME: {
        icon: 'company',
        name: i18n.t('company_name'),
        render: () => 'company_name'
    },
    COMPANY_NUMBER: {
        icon: 'company',
        name: i18n.t('company_number'),
        render: () => 'company_number'
    },
    OFFER_SHARE_QUANTITY: {
        icon: 'shares',
        name: i18n.t('offer') + ' ' + i18n.t('share_quantity'),
        render: () => 'offer_share_quantity'
    },
    OFFER_SHARE_PRICE: {
        icon: 'money',
        name: i18n.t('offer') + ' ' + i18n.t('share_price'),
        render: () => 'offer_share_price'
    }
});

export default dynamicValueEnum;
