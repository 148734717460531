import { HoldingCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';
import PmiStructureEnum from '@/enums/pmi/structure';

export class HoldingPmiProductPanelCollection extends HoldingCollection {
    static type = 'HoldingPmiProductPanelCollection';
    static endpoint = Holding.api.byPrivateMarketStructure;

    static fields() {
        return {
            ...super.fields(),
            holding_id: this.string(null).nullable(),
            status: this.string('OPEN').nullable()
        };
    }

    get headers() {
        let headers = [
            this.createHeader('product', i18n.t('product'), {
                path: 'product.name',
                width: '15rem',
                sortable: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Product')
                }
            }),
            this.createHeader('structure', i18n.t('structure'), {
                width: '8rem',
                format: 'enum',
                enum: PmiStructureEnum,
                enumA11yKey: 'enums.pmi_structure',
                sortable: false
            }),
            this.createHeader('share_class', i18n.t('share_class'), {
                width: '8rem',
                sortable: false
            }),
            this.createHeader('investment_amount_gbp', `${i18n.t('investment_amount')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '8rem',
                sortable: false
            }),
            this.createHeader('share_issue_nav', `${i18n.t('share_issue_nav')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                format: 'date',
                width: '8rem',
                sortable: false
            }),
            this.createHeader('current_nav_price_gbp', `${i18n.t('current_nav_price')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_gbp', `${i18n.t('nav')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('distributions_gbp', `${i18n.t('distributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('total_return_gbp', `${i18n.t('total_return')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('total_return_percentage_gbp', `${i18n.t('total_return_percentage')} (gbp)`, {
                format: 'percentage',
                formatOptions: super.percentage_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('total_return_moic_gbp', `${i18n.t('total_return_moic')} (gbp)`, {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                sortable: false
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem',
                sortable: false
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'product',
            'structure',
            'share_class',
            'investment_amount_gbp',
            'share_quantity',
            'share_issue_nav',
            'share_issue_on',
            'current_nav_price_gbp',
            'nav_gbp',
            'distributions_gbp',
            'total_return_gbp',
            'total_return_percentage_gbp',
            'total_return_moic_gbp'
        ];
    }

    get pdf_headers() {
        return [
            'product',
            'structure',
            'share_class',
            'investment_amount_gbp',
            'share_quantity',
            'share_issue_nav',
            'share_issue_on',
            'current_nav_price_gbp',
            'nav_gbp',
            'distributions_gbp',
            'total_return_gbp',
            'total_return_percentage',
            'total_return_moic'
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.show_consolidated_view) {
            filters.consolidated = true;
        }

        if (this.status) {
            filters.status = this.filterOperator('is') + ':OPEN';
        }

        if (this.holding_id) {
            filters.holding_id = this.filterOperator('is') + ':' + this.holding_id;
        }

        filters.structure = this.filterOperator('is') + ':' + PmiStructureEnum.PRODUCT;

        return filters;
    }
}

export default HoldingPmiProductPanelCollection;
