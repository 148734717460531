const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'GBP'
        }
    }
};

export default numberFormats;
