import { Collection, Adviser } from '@/models';
import i18n from '@/lib/i18n';
import UserStatusEnum from '@/enums/user/status';

export class SettingsChildAdvisersCollection extends Collection {
    static type = 'SettingsChildAdvisersCollection';
    static endpoint = Adviser.api.index;
    static model = Adviser;

    static fields() {
        return {
            ...super.fields(),
            child_of: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.child_of) {
            filters.child_of = this.child_of;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                to: data => ({
                    name: 'Settings',
                    query: {
                        organisation_id: data.item.id,
                        organisation_type: 'adviser'
                    }
                })
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: UserStatusEnum,
                enumA11yKey: 'enums.user_status',
                enumTypes: {
                    [UserStatusEnum.ACTIVE]: 'success',
                    [UserStatusEnum.INACTIVE]: 'grey',
                    [UserStatusEnum.SUSPENDED]: 'error',
                    [UserStatusEnum.UNVERIFIED]: 'warning'
                }
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('fca_number', i18n.t('fca_number'), {
                width: '12rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get default_headers() {
        return ['name', 'status', 'gi_ref', 'fca_number'];
    }
}

export default SettingsChildAdvisersCollection;
