import i18n from '@/lib/i18n';

export const supportedBrowsers = [
    { name: 'Chrome', minVersion: 60 },
    { name: 'Firefox', minVersion: 55 },
    { name: 'Safari', minVersion: 14 },
    { name: 'Edge', minVersion: 15 }
];

export const getBrowser = () => {
    return supportedBrowsers.find(browser => navigator.userAgent.includes(browser.name)) || null;
};

export const isBrowserCompatible = () => !!getBrowser();

export const getBrowserVersion = browser => {
    browser = browser || getBrowser();

    if (!browser) {
        return 0;
    }

    // eslint-disable-next-line security/detect-non-literal-regexp
    const matches = navigator.userAgent.match(new RegExp(`${browser.name}/(\\d+)`));

    if (matches && matches.length === 2) {
        return parseInt(matches[1], 10);
    }

    return 0;
};

export const isBrowserVersionCompatible = browser => {
    browser = browser || getBrowser();
    return browser && getBrowserVersion(browser) >= browser.minVersion;
};

const checkBrowserCompatibility = async (to, from, next) => {
    if (to.name === 'PageError') {
        return next();
    }

    const browser = getBrowser();
    const browserSupported = isBrowserCompatible();
    const browserVersionSupported = isBrowserVersionCompatible(browser);

    if (browserSupported && !browserVersionSupported && browser.name === 'Safari') {
        return next({
            name: 'PageError',
            params: {
                title: 'Browser Compatibility Issue',
                message: i18n.t('_page.error.safari_browser_incompatible'),
                hideDashboardButton: true
            }
        });
    }

    return next();
};

export default checkBrowserCompatibility;
