import clock from '@/lib/clock';
import today from '@/lib/helpers/now';
import map from 'lodash/map';

/**
 * Return the previous financial year
 * @param {String|Object}
 */
const getTaxYear = (date = null, options = {}) => {
    if (date === null || date === undefined || date === '') {
        return null;
    }

    if (date === 'last') {
        date = clock().subtract(1, 'year');
    }

    if (typeof date === 'string' && date.split('-').length === 2) {
        if (date.split('-')[0].length === 4 && date.split('-')[1].length === 2) {
            return date;
        }
    }

    const asString = options.asString || false;
    const abbreviate = options.abbreviate || false;

    const now = getTaxYearStart(date);

    if (!now) {
        return null;
    }

    let taxYearEndDate = now.clone().month(3).date(5);

    if (taxYearEndDate.isBefore(now)) {
        taxYearEndDate = taxYearEndDate.add(1, 'year');
    }

    let taxYearStartDate = taxYearEndDate.clone().subtract(1, 'year').date(6);

    const financialYear = [taxYearStartDate, taxYearEndDate];

    if (abbreviate) {
        return `${taxYearStartDate.format('YYYY')}-${taxYearEndDate.format('YY')}`;
    } else if (asString) {
        return map(financialYear, date => date.format('Do MMMM YYYY')).join(' - ');
    }

    return financialYear;
};

const getTaxYearStart = (date = null) => {
    if (date === null || date === undefined || date === '') {
        return null;
    } else if (date === 'now') {
        date = today().clone().format('YYYY-MM-DD');
    }

    let taxYearStart = null;

    if (typeof date === 'string' && date.length === 4 && !isNaN(Number(date))) {
        date = `${Number(date) + 1}-01-01`;
    } else if (typeof date === 'number' && String(date).length === 4) {
        date = `${date + 1}-01-01`;
    } else if (typeof date === 'string' && date.length === 2 && !isNaN(Number(date))) {
        date = `20${Number(date) + 1}-01-01`;
    } else if (typeof date === 'number' && String(date).length === 2) {
        date = `20${date + 1}-01-01`;
    }

    if (date) {
        taxYearStart = clock(date).clone().startOf('day');
    }

    return taxYearStart;
};

export default getTaxYear;
