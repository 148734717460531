const InvestorCategoryEnum = Object.freeze({
    ELECTIVE_PROFESSIONAL: 'ELECTIVE_PROFESSIONAL',
    PROFESSIONAL: 'PROFESSIONAL', // GI Only
    HIGH_NET_WORTH: 'HIGH_NET_WORTH',
    CERTIFIED_SOPHISTICATED: 'CERTIFIED_SOPHISTICATED', // GI Only
    SELF_CERT_SOPHISTICATED: 'SELF_CERT_SOPHISTICATED',
    RETAIL_ADVISED: 'RETAIL_ADVISED',
    RETAIL_RESTRICTED: 'RETAIL_RESTRICTED'
});

export default InvestorCategoryEnum;
