import icons from '@/lib/icons';

export const AlertTypeEnum = Object.freeze({
    INFO: {
        color: 'info',
        icon: icons.info
    },
    WARNING: {
        color: 'warning',
        icon: icons.warning
    },
    ERROR: {
        color: 'error',
        icon: icons.error
    },
    SUCCESS: {
        color: 'success',
        icon: icons.success
    }
});

export default AlertTypeEnum;
