import { OfferCollection } from '@/models';
import i18n from '@/lib/i18n';
import toCurrency from '@/lib/helpers/toCurrency';
import taxStatusEnum from '@/enums/taxStatus';
import OfferStatusEnum from '@/enums/offer/status';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';
import SDRLabelEnum from '@/enums/SDRLabel';

export class OfferSingleCompanyCollection extends OfferCollection {
    static type = 'OfferSingleCompanyCollection';

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            }),
            this.createHeader('workflow_status', i18n.t('workflow_status'), {
                active: false,
                format: 'enum',
                width: '9rem',
                filterable: this.filter_workflow_status
                    ? {
                          operators: this.getOperators(['eq', 'neq', 'in'])
                      }
                    : false,
                enum: OfferWorkflowStatusEnum,
                enumA11yKey: 'enums.offer_workflow_status',
                enumTypes: {
                    [OfferWorkflowStatusEnum.DRAFT]: 'grey',
                    [OfferWorkflowStatusEnum.LIVE]: 'success'
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                width: '7rem',
                filterable: true,
                sortable: true,
                enum: OfferStatusEnum,
                enumA11yKey: 'enums.offer_status',
                enumTypes: {
                    [OfferStatusEnum.OPEN]: 'info',
                    [OfferStatusEnum.CLOSED]: 'error',
                    [OfferStatusEnum.EVERGREEN]: 'success',
                    [OfferStatusEnum.COMING_SOON]: 'warning'
                }
            }),
            this.createHeader('max_fund_size_details', i18n.t('max_fund_size_details'), {
                format: 'wysiwyg',
                width: '25rem'
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '8rem'
            }),
            this.createHeader('introducer_name', i18n.t('introducer'), {
                path: 'introducer.name',
                width: '15rem',
                minWidth: '15rem'
            }),
            this.createHeader('post_money_valuation', i18n.t('post_money_valuation'), {
                format: 'money',
                width: '12rem'
            }),
            this.createHeader('pre_money_valuation', i18n.t('pre_money_valuation'), {
                format: 'money',
                width: '12rem'
            }),
            this.createHeader('business_stage', i18n.t('business_stage'), {
                path: 'business_stage.name',
                width: '12rem'
            }),
            this.createHeader('shares_issued', i18n.t('shares_issued'), {
                format: 'number',
                formatOptions: { decimals: 0 },
                width: '9.2rem'
            }),
            this.createHeader('shares_offered', i18n.t('shares_offered'), {
                format: 'number',
                formatOptions: { decimals: 0 },
                width: '10rem'
            }),
            this.createHeader('product_offer.offer_price', i18n.t('share_price_for_current_investment_round'), {
                format: 'string',
                width: '10rem',
                filterable: false,
                sortable: false,
                align: 'right',
                transform: data => {
                    if (data.item.product_offers.length === 1) {
                        if (data.item.product_offers[0]?.offer_price?.amount) {
                            return toCurrency(
                                data.item.product_offers[0].offer_price.amount,
                                data.item.product_offers[0].offer_price.currency
                            );
                        }
                    } else if (data.item.product_offers.length > 1) {
                        return i18n.t('multiple');
                    }

                    return null;
                }
            }),
            this.createHeader('max_size', i18n.t('raise_amount'), {
                format: 'money',
                width: '10rem'
            }),
            this.createHeader('min_investment', i18n.t('min_investment'), {
                format: 'money',
                width: '10rem'
            }),
            this.createHeader('equity_issued', i18n.t('equity_issued'), {
                format: 'percentage',
                width: '9rem'
            }),
            this.createHeader('deadline_details', i18n.t('deadline_details'), {
                format: 'wysiwyg',
                width: '15rem'
            }),
            this.createHeader('close_at', i18n.t('close_at'), {
                format: 'datetime',
                width: '9rem'
            }),

            this.createHeader('sdr_label', i18n.t('sdr_label'), {
                format: 'enum',
                filterable: true,
                enum: SDRLabelEnum,
                enumA11yKey: 'enums.sdr_label',
                width: '8rem',
                active: false
            })
        ];
    }

    get default_headers() {
        return [
            'name',
            'status',
            'max_fund_size_details',
            'tax_status',
            'pre_money_valuation',
            'business_stage',
            'max_size',
            'min_investment',
            'equity_issued',
            'close_at'
        ];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            single_company: this.filterOperator('is') + ':true'
        };
    }
}

export default OfferSingleCompanyCollection;
