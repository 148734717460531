import uuid from '@/lib/helpers/uuid';
import upperFirst from 'lodash/upperFirst';
import store from '@/store';
import getNoCriteriaOperators from '@/lib/helpers/getNoCriteriaOperators';

export const createFilterPanel = (panel = {}) => {
    panel = {
        id: panel.id || uuid(8),
        options: createFilterPanelOptions(panel.options),
        active: panel.active || false,
        requestItems: panel.requestItems || null,
        filters: panel.filters || []
    };

    return panel;
};

export const createFilterPanelOptions = (options = []) => {
    options = options.filter(option => !('filterable' in option) || option.filterable);

    options = options.map(option => {
        let item = {
            label: option.label || option.text || '',
            column: option.column || option.value || '',
            format: option.format || 'string',
            operators: option.operators || option.fieldType?.operators || null,
            component: option.component || null,
            options: option.options || null,
            filters: option.filters || null,
            enum: option.enum || null,
            enumA11yKey: option.enumA11yKey || null,
            enumTypes: option.enumTypes || null
        };

        if (!item.component) {
            item.component = `Edit${upperFirst(item.format)}`;
        }

        if (option.filterable && typeof option.filterable === 'object') {
            item = { ...item, ...option.filterable };
        }

        return item;
    });

    return options;
};

export const isValidFilter = filter => {
    if (!filter.column || !filter.operator) {
        return false;
    }

    const requiresCriteria = !getNoCriteriaOperators().includes(filter.operator);

    if (requiresCriteria && (filter.criteria === null || filter.criteria === undefined || filter.criteria === '')) {
        return false;
    }

    return true;
};

export const createFilterQuery = filters => {
    filters = filters.filter(isValidFilter);

    if (filters.length === 0) {
        return {};
    }

    let query = {};

    filters.forEach(filter => {
        let filterString = filter.operator;

        if (filter.criteria !== null && filter.criteria !== undefined) {
            if (Array.isArray(filter.criteria)) {
                filterString += `:[${filter.criteria.join(',')}]`;
            } else if (typeof filter.criteria === 'object' && 'amount' in filter.criteria) {
                filterString += `:${filter.criteria.amount}`;
            } else {
                filterString += `:${filter.criteria}`;
            }
        }

        query[filter.column] = filterString;
    });

    return query;
};

export default {
    filterPanels: {
        namespaced: true,
        state: {
            panels: []
        },
        getters: {
            panels: state => state.panels,
            panel: state => panelId => state.panels.find(panel => panel.id === panelId),
            options: (state, getters) => panelId => {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return [];
                }

                return panel.options;
            },
            columns: (state, getters) => panelId => {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return [];
                }

                return panel.options.map(option => ({
                    text: option.label,
                    value: option.column
                }));
            },
            filterCount: (state, getters) => panelId => {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return 0;
                }

                return panel.filters.length;
            },
            filters: (state, getters) => panelId => {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return [];
                }

                return panel.filters;
            },
            filterFromFilterString: (state, getters) => (panelId, column, filterString) => {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return null;
                }

                const option = panel.options.find(option => option.column === column);

                if (!option) {
                    return null;
                }

                const noCriteriaOperators = getNoCriteriaOperators();

                if (!filterString.includes(':') && !noCriteriaOperators.includes(filterString)) {
                    filterString = `eq:${filterString}`;
                }

                let [operator, criteria] = filterString.match(/([^:]+):(.*)/).slice(1);

                if (!operator || option.operators.find(op => op.value === operator) === undefined) {
                    return null;
                }

                const requiresCriteria = !noCriteriaOperators.includes(operator);

                if (requiresCriteria && (criteria === null || criteria === undefined || criteria === '')) {
                    return null;
                }

                if (criteria === 'true') {
                    criteria = true;
                } else if (criteria === 'false') {
                    criteria = false;
                }

                if (operator === 'in' || operator === 'not_in') {
                    criteria = criteria.replace(/[[\]]/g, '').replace(', ', ',').split(',');
                }

                if (option.format === 'money') {
                    criteria = {
                        amount: parseFloat(criteria),
                        currency: 'GBP'
                    };
                }

                return {
                    column,
                    operator,
                    criteria,
                    condition: 'and'
                };
            }
        },
        actions: {
            add({ commit }, panel) {
                panel = createFilterPanel(panel);
                commit('ADD_PANEL', panel);
                return panel;
            },
            update({ commit, state }, panel) {
                const existingPanel = state.panels.find(p => p.id === panel.id);

                if (existingPanel) {
                    panel = { ...existingPanel, panel };
                } else {
                    panel = createFilterPanel(panel);
                }

                commit('ADD_PANEL', panel);
                return panel;
            },
            remove({ commit }, id) {
                commit('REMOVE_PANEL', id);
            },
            removeAll({ commit }) {
                commit('REMOVE_PANELS');
            },
            show({ commit }, id) {
                commit('SHOW_PANEL', id);
            },
            hide({ commit }, id) {
                commit('HIDE_PANEL', id);
            },
            hideAll({ commit }) {
                commit('HIDE_PANELS');
            },
            removeFilters({ getters, commit }, { panelId, columns = [] }) {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return;
                }

                if (columns.length === 0) {
                    commit('REMOVE_PANEL_FILTERS', { panelId });
                    return;
                }

                columns.forEach(column => {
                    store.dispatch('filterPanels/removeFilter', { panelId, column });
                });
            },
            removeFilter({ commit }, { panelId, column }) {
                commit('REMOVE_PANEL_FILTER', { panelId, column });
            },
            setFilters({ getters }, { panelId, filters }) {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return;
                }

                filters.forEach(filter => {
                    store.dispatch('filterPanels/setFilter', { panelId, filter });
                });
            },
            setFilter({ getters, commit }, { panelId, filter = {} }) {
                filter = {
                    column: filter.column || null,
                    operator: filter.operator || null,
                    criteria: filter.criteria || null,
                    condition: filter.condition || 'and',
                    data: null
                };

                if (!filter.column || !filter.operator) {
                    return;
                }

                const panel = getters.panel(panelId);

                if (!panel) {
                    return;
                }

                const option = panel.options.find(option => option.column === filter.column);

                if (!option) {
                    return;
                }

                const requiresCriteria = !getNoCriteriaOperators().includes(filter.operator);

                if (
                    requiresCriteria &&
                    (filter.criteria === null || filter.criteria === undefined || filter.criteria === '')
                ) {
                    return;
                }

                commit('ADD_PANEL_FILTER', { panelId, column: filter.column, filter });
                return filter;
            },
            setFilterData({ getters, commit }, { panelId, column, data }) {
                const panel = getters.panel(panelId);

                if (!panel) {
                    return;
                }

                const filter = panel.filters.find(f => f.column === column);

                if (!filter) {
                    return;
                }

                filter.data = data;
                commit('ADD_PANEL_FILTER', { panelId, column, filter });
            }
        },
        mutations: {
            ADD_PANEL(state, payload) {
                const index = state.panels.findIndex(p => p.id === payload.id);

                if (index !== -1) {
                    state.panels.splice(index, 1, payload);
                    return;
                }

                state.panels.push(payload);
            },
            REMOVE_PANEL(state, id) {
                state.panels = state.panels.filter(panel => panel.id !== id);
            },
            REMOVE_PANELS(state) {
                state.panels = [];
            },
            ADD_PANEL_FILTER(state, { panelId, column, filter }) {
                const panel = state.panels.find(panel => panel.id === panelId);

                if (!panel) {
                    return;
                }

                const index = panel.filters.findIndex(f => f.column === column);

                if (index !== -1) {
                    panel.filters.splice(index, 1, filter);
                    return;
                }

                panel.filters.push(filter);
            },
            REMOVE_PANEL_FILTER(state, { panelId, column }) {
                const panel = state.panels.find(panel => panel.id === panelId);

                if (!panel) {
                    return;
                }

                panel.filters = panel.filters.filter(f => f.column !== column);
            },
            REMOVE_PANEL_FILTERS(state, { panelId }) {
                const panel = state.panels.find(panel => panel.id === panelId);

                if (!panel) {
                    return;
                }

                panel.filters = [];
            },
            SHOW_PANEL(state, id) {
                state.panels.forEach(panel => {
                    panel.active = panel.id === id;
                });
            },
            HIDE_PANEL(state, id) {
                state.panels.forEach(panel => {
                    if (panel.id === id) {
                        panel.active = false;
                    }
                });
            },
            HIDE_PANELS(state) {
                state.panels.forEach(panel => {
                    panel.active = false;
                });
            }
        }
    }
};
