import { lighten, darken, desaturate, adjustHue, toHex, toRgba, getContrast } from 'color2k';

const isValidColour = v => {
    try {
        lighten(v);
        return true;
    } catch (error) {
        return false;
    }
};

export { isValidColour, lighten, darken, desaturate, adjustHue, toHex, toRgba, getContrast };
