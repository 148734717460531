import * as Sentry from '@sentry/vue';
import Auth from '@/models/Auth';
import config from '@/config';

const setSentryScope = async (to, from, next) => {
    const auth = Auth();

    if (auth.id && config.SENTRY_DSN) {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: auth.user_id,
                email: auth.user_email,
                name: auth.user_name,
                gi_ref: auth.gi_ref,
                organisation_id: auth.organisation_id,
                organisation_type: auth.organisation_type,
                organisation_roles: auth.organisation_roles,
                is_gi: auth.is_gi
            });
        });
    }

    next();
};

export default setSentryScope;
