import Vue from 'vue';
import Auth from '@/models/Auth';

export default {
    preferences: {
        namespaced: true,
        state: {
            _preferences: {},
            _defaults: {
                show_breadcrumbs: true,
                show_nav_totals: false,
                show_consolidated_view: false,
                show_approved_panel_only: true,
                show_closed_offers: false,
                table_display_format: null
            }
        },
        getters: {
            preference: state => key => {
                const auth = Auth();
                const id = [auth.organisation_id, auth.user_id].join('.');

                return state._preferences?.[`${id}`]?.[`${key}`] ?? state._defaults[`${key}`];
            },
            show_breadcrumbs: (state, getters) => getters.preference('show_breadcrumbs'),
            show_nav_totals: (state, getters) => getters.preference('show_nav_totals'),
            show_consolidated_view: (state, getters) => getters.preference('show_consolidated_view'),
            show_approved_panel_only: (state, getters) => getters.preference('show_approved_panel_only'),
            show_closed_offers: (state, getters) => getters.preference('show_closed_offers'),
            table_display_format: (state, getters) => getters.preference('table_display_format')
        },
        actions: {
            setShowBreadcrumbs({ commit }, show) {
                commit('SET_PREFERENCE', { key: 'show_breadcrumbs', value: show });
            },
            toggleShowNavTotals({ commit, getters }) {
                commit('SET_PREFERENCE', { key: 'show_nav_totals', value: !getters.show_nav_totals });
            },
            toggleShowConsolidatedView({ commit, getters }) {
                commit('SET_PREFERENCE', { key: 'show_consolidated_view', value: !getters.show_consolidated_view });
            },
            toggleShowApprovedPanelOnly({ commit, getters }) {
                commit('SET_PREFERENCE', { key: 'show_approved_panel_only', value: !getters.show_approved_panel_only });
            },
            toggleShowClosedOffers({ commit, getters }) {
                commit('SET_PREFERENCE', { key: 'show_closed_offers', value: !getters.show_closed_offers });
            },
            setTableDisplayFormat({ commit }, format) {
                commit('SET_PREFERENCE', { key: 'table_display_format', value: format });
            }
        },
        mutations: {
            SET_PREFERENCE(state, { key, value }) {
                if (!state._preferences) {
                    Vue.set(state, '_preferences', {});
                }

                if (state._defaults[`${key}`] === undefined) {
                    throw new Error(`Preference ${key} is not defined.`);
                }

                const auth = Auth();
                const id = [auth.organisation_id, auth.user_id].join('.');

                if (!state._preferences[`${id}`]) {
                    Vue.set(state._preferences, id, {});
                }

                if (value === undefined) {
                    value = state._defaults[`${key}`];
                }

                Vue.set(state._preferences[`${id}`], key, value);
            }
        }
    }
};
