<template>
    <node-view-wrapper
        class="tiptap-checkbox"
        :class="{
            'tiptap-checkbox--error': !valid,
            'tiptap-checkbox--editable': isGrowthInvest() && editor.options.editable
        }"
    >
        <transition appear name="fade-transition" mode="out-in">
            <span class="tiptap-checkbox__wrap">
                <span v-if="isGrowthInvest() && editor.options.editable" class="tiptap-checkbox__options">
                    <tiptap-checkbox-options :options="options" :update-attributes="updateAttributes" />
                </span>
                <app-tooltip :value="errorMessage" :disabled="valid" color="error">
                    <v-checkbox
                        v-model="inputValue"
                        class="tiptap-checkbox__input"
                        color="success"
                        hide-details
                        dense
                        :disabled="disabled || node.attrs.disabled"
                    />
                </app-tooltip>
                <node-view-content class="tiptap-checkbox__content" as="span" />
            </span>
        </transition>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2';
import { findFieldStateConditions, findNodeById } from '@/lib/tiptap';

export default {
    name: 'TiptapCheckbox',
    components: {
        NodeViewWrapper,
        NodeViewContent,
        TiptapCheckboxOptions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/lib/tiptap/components/TiptapCheckboxOptions.vue'
            )
    },
    props: {
        ...nodeViewProps
    },
    computed: {
        inputValue: {
            get() {
                return this.node.attrs.value;
            },
            set(value) {
                this.updateAttributes({ value });

                this.$root.$emit(`Tiptap/input`, {
                    name: this.node.type.name,
                    content: this.node.content,
                    value
                });
            }
        },
        options() {
            return {
                id: this.id
            };
        },
        id() {
            return this.node.attrs.id;
        },
        valid() {
            if (!this.id) {
                return true;
            }
            if (this.editor.storage.Validator.valid) {
                return true;
            }
            if (!this.editor.storage.Validator.results) {
                return true;
            }
            if (
                typeof this.editor.storage.Validator.results === 'object' &&
                !(this.id in this.editor.storage.Validator.results)
            ) {
                return true;
            }
            return false;
        },
        errorMessage() {
            if (this.valid) {
                return null;
            }

            const messages = this.editor.storage.Validator.results[`${this.id}`];

            if (Array.isArray(messages)) {
                return messages.join(', ');
            }

            return messages;
        },
        disabled() {
            const json = this.editor.getJSON();

            const state = findFieldStateConditions(json);
            if (!state) {
                return false;
            }

            const condition = state.find(item => item.field_id === this.id && item.field_state === 'disabled');
            if (!condition) {
                return false;
            }
            const conditionNode = findNodeById(json, condition.condition_field_id);
            if (!conditionNode) {
                return false;
            }

            return conditionNode.attrs.value === condition.condition_field_value;
        }
    }
};
</script>

<style lang="scss">
.tiptap-checkbox {
    position: relative;
    background: var(--v-background-darken2);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    &--editable {
        padding-left: 2rem;
    }
    &__wrap {
        display: flex;
        flex-direction: row;
    }
    &__input {
        margin-right: 0.5rem;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
    &__content {
        *:last-child {
            margin-bottom: 0;
        }
    }
    &--error {
        .tiptap-checkbox__input .v-icon {
            color: var(--v-error-base);
        }
    }
    &__options {
        position: absolute;
        top: 0.4rem;
        left: 0.8rem;
        width: 2rem;
    }
}
</style>
