import BaseApi from '@/api/BaseApi';

export class DeclarationApi extends BaseApi {
    static resource = 'declarations';

    get endpoints() {
        return {
            get: this.get.bind(this),
            post: this.post.bind(this),
            put: this.put.bind(this)
        };
    }

    async get(id = null) {
        return await BaseApi.api.get(id ? `${this.base_url}/${id}` : this.base_url);
    }

    async post(declaration) {
        return await BaseApi.api.post(this.base_url, BaseApi.removeNull(declaration));
    }

    async put(declaration) {
        return await BaseApi.api.put(`${this.base_url}/${declaration.id}`, declaration);
    }
}

export default DeclarationApi;
