import { isValidColour, lighten, darken, desaturate, toHex } from '@/lib/colour';

/**
 * Creates a Vuetify theme colour object
 * @param {String} hex
 * @returns Object
 */
const createColoursFromHex = (hex, override = {}) => {
    if (!isValidColour(hex)) {
        return false;
    }

    const colours = {
        base: toHex(hex),
        lighten1: toHex(lighten(hex, 0.05)),
        lighten2: toHex(lighten(hex, 0.1)),
        lighten3: toHex(lighten(hex, 0.15)),
        lighten4: toHex(lighten(desaturate(hex, 0.55), 0.25)),
        lighten5: toHex(lighten(desaturate(hex, 0.55), 0.35)),
        darken1: toHex(darken(hex, 0.2)),
        darken2: toHex(darken(hex, 0.3)),
        darken3: toHex(darken(hex, 0.4)),
        darken4: toHex(darken(hex, 0.47)),
        darken5: toHex(darken(hex, 0.55))
    };

    return { ...colours, ...override };
};

export default createColoursFromHex;
