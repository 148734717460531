import { BaseModel } from '@/models';

export class BusinessStage extends BaseModel {
    static entity = 'businessStages';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string('')
        };
    }
}

export default BusinessStage;
