import toNumber from '@/lib/helpers/toNumber';
import toCurrency from '@/lib/helpers/toCurrency';
import toPercentage from '@/lib/helpers/toPercentage';
import toDatetime from '@/lib/helpers/toDatetime';
import toDate from '@/lib/helpers/toDate';

export default {
    methods: {
        toNumber,
        toCurrency,
        toPercentage,
        toDatetime,
        toDate
    }
};
