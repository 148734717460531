import { Collection, Agreement } from '@/models';
import i18n from '@/lib/i18n';
import AgreementStatusEnum from '@/enums/agreement/status';

export class SystemAgreementsCollection extends Collection {
    static type = 'SystemAgreementsCollection';
    static endpoint = Agreement.api.index;
    static model = Agreement;

    get default_headers() {
        return ['name', 'status', 'investor_id', 'adviser_id', 'created_at', 'last_updated'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '28rem',
                filterable: true
            }),
            this.createHeader('external_id', i18n.t('external_id'), {
                width: '14rem',
                filterable: true
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '9rem',
                format: 'enum',
                enum: AgreementStatusEnum,
                enumA11yKey: 'enums.agreement_status',
                enumTypes: {
                    [AgreementStatusEnum.OUT_FOR_SIGNATURE]: 'warning',
                    [AgreementStatusEnum.OUT_FOR_DELIVERY]: 'warning',
                    [AgreementStatusEnum.OUT_FOR_ACCEPTANCE]: 'warning',
                    [AgreementStatusEnum.OUT_FOR_FORM_FILLING]: 'warning',
                    [AgreementStatusEnum.OUT_FOR_APPROVAL]: 'warning',
                    [AgreementStatusEnum.AUTHORING]: 'info',
                    [AgreementStatusEnum.CANCELLED]: 'error',
                    [AgreementStatusEnum.SIGNED]: 'success',
                    [AgreementStatusEnum.APPROVED]: 'success',
                    [AgreementStatusEnum.DELIVERED]: 'info',
                    [AgreementStatusEnum.ACCEPTED]: 'success',
                    [AgreementStatusEnum.FORM_FILLED]: 'info',
                    [AgreementStatusEnum.EXPIRED]: 'warning',
                    [AgreementStatusEnum.ARCHIVED]: 'info',
                    [AgreementStatusEnum.PREFILL]: 'info',
                    [AgreementStatusEnum.WIDGET_WAITING_FOR_VERIFICATION]: 'warning',
                    [AgreementStatusEnum.DRAFT]: 'grey',
                    [AgreementStatusEnum.DOCUMENTS_NOT_YET_PROCESSED]: 'warning',
                    [AgreementStatusEnum.WAITING_FOR_FAXIN]: 'warning',
                    [AgreementStatusEnum.WAITING_FOR_VERIFICATION]: 'warning',
                    [AgreementStatusEnum.WAITING_FOR_NOTARIZATION]: 'warning'
                }
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                path: 'investor.name',
                minWidth: '10rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                }
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                path: 'adviser.name',
                minWidth: '10rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                }
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('last_updated', i18n.t('last_updated'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            })
        ];
    }
}

export default SystemAgreementsCollection;
