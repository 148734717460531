import TaxStatusEnum from '@/enums/taxStatus';

const getTaxRelief = (taxStatus, amount = 0) => {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
        return 0;
    }

    return amount * getTaxReliefPercentage(taxStatus);
};

export const getTaxReliefPercentage = taxStatus => {
    switch (taxStatus) {
        case TaxStatusEnum.EIS:
        case TaxStatusEnum.VCT:
            return 0.3;
        case TaxStatusEnum.SEIS:
            return 0.5;
        default:
            return 0;
    }
};

export default getTaxRelief;
