import request from '@/api/lib/request';

/**
 * Send a OPTIONS request.
 *
 * @param {String} url
 * @returns {Object}
 */
const options = async (url, config = {}) => {
    return await request({
        method: 'OPTIONS',
        url,
        ...config
    });
};

export default options;
