import discoverLanguages from '@/lib/helpers/discoverLanguages';

export default {
    i18n: {
        namespaced: true,
        state: {
            loading: {
                languages: false,
                locale: false
            },
            languages: [],
            locale: null
        },
        getters: {
            languages: state => state.languages,
            languagesLoading: state => state.loading.languages,
            locale: state => state.locale,
            localeLoading: state => state.loading.locale
        },
        actions: {
            async getLanguages({ commit }) {
                commit('SET_LOADING', ['languages', true]);
                const languages = discoverLanguages();
                commit('SET_LOADING', ['languages', false]);

                commit('SET_LANGUAGES', languages);
            },
            async getLocale({ commit, state, dispatch, rootGetters }) {
                commit('SET_LOADING', ['locale', true]);

                await dispatch('getLanguages');
                await dispatch('countries/getCountries', null, { root: true });

                const locale = rootGetters['countries/countries'].find(
                    country => country.alpha2Code === state.languages[0]
                );

                commit('SET_LOADING', ['locale', false]);
                commit('SET_LOCALE', locale);
            }
        },
        mutations: {
            SET_LANGUAGES(state, payload) {
                state.languages = payload;
            },
            SET_LOCALE(state, payload) {
                state.locale = payload;
            },
            SET_LOADING(state, payload) {
                state.loading[payload[0]] = payload[1];
            }
        }
    }
};
