const RemoteApplicationBlockStatusEnum = Object.freeze({
    NONE: 'NONE',
    SUBMITTED: 'SUBMITTED',
    RFA: 'RFA',
    ALLOTTED: 'ALLOTTED',
    WITHDRAWN: 'WITHDRAWN',
    REJECTED: 'REJECTED',
    RETURNED: 'RETURNED'
});

export default RemoteApplicationBlockStatusEnum;
