import isNull from 'lodash/isNull';
import config from '@/config';

/**
 * Sets the document Favicon
 * @param {String} url
 */
const setFavicon = url => {
    if (config.ENV === 'test') {
        return false;
    }

    let link = document.querySelector('link[rel~=icon]');

    if (isNull(link)) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = url;
};

export default setFavicon;
