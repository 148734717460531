import { OfferCollection } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';
import OfferStatusEnum from '@/enums/offer/status';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';
import SDRLabelEnum from '@/enums/SDRLabel';

export class OfferEisSeisCollection extends OfferCollection {
    static type = 'OfferEisSeisCollection';

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                sortable: true,
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('workflow_status', i18n.t('workflow_status'), {
                active: false,
                format: 'enum',
                width: '9rem',
                filterable: this.filter_workflow_status
                    ? {
                          operators: this.getOperators(['eq', 'neq', 'in'])
                      }
                    : false,
                enum: OfferWorkflowStatusEnum,
                enumA11yKey: 'enums.offer_workflow_status',
                enumTypes: {
                    [OfferWorkflowStatusEnum.DRAFT]: 'grey',
                    [OfferWorkflowStatusEnum.LIVE]: 'success'
                }
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: false,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '8rem'
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                width: '7rem',
                filterable: true,
                sortable: true,
                enum: OfferStatusEnum,
                enumA11yKey: 'enums.offer_status',
                enumTypes: {
                    [OfferStatusEnum.OPEN]: 'info',
                    [OfferStatusEnum.CLOSED]: 'error',
                    [OfferStatusEnum.EVERGREEN]: 'success',
                    [OfferStatusEnum.COMING_SOON]: 'warning'
                }
            }),
            this.createHeader('close_at', i18n.t('close_at'), {
                format: 'datetime',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('over_allotment_available', i18n.t('over_allotting'), {
                format: 'boolean',
                path: 'over_allotment_available',
                transform: data => {
                    if (!data.item.over_allotment_actual && !data.item.over_allotment_proposed) {
                        return false;
                    }

                    return data.item.over_allotment_available;
                },
                filterable: true,
                width: '12rem'
            }),
            this.createHeader('over_allotment', i18n.t('over_allotment'), {
                format: 'money',
                transform: data => {
                    if (data.item.over_allotment_actual) {
                        return data.item.over_allotment_actual;
                    } else if (data.item.over_allotment_proposed) {
                        return data.item.over_allotment_proposed;
                    } else {
                        return null;
                    }
                },
                width: '10rem'
            }),
            this.createHeader('max_fund_size_details', i18n.t('max_fund_size_details'), {
                filterable: true,
                format: 'wysiwyg',
                width: '25rem'
            }),
            this.createHeader('min_investment', i18n.t('min_investment'), {
                filterable: true,
                format: 'money',
                width: '10rem'
            }),
            this.createHeader('fund_structure', i18n.t('fund_structure'), {
                path: 'fund.fund_structure.name',
                width: '8rem'
            }),
            this.createHeader('loyalty_discount', i18n.t('loyalty_discount'), {
                filterable: true,
                format: 'percentage',
                width: '7rem'
            }),
            this.createHeader('existing_investor_close_at', i18n.t('existing_investor_close_at'), {
                filterable: true,
                format: 'datetime',
                width: '10rem'
            }),
            this.createHeader('existing_investor_close_description', i18n.t('existing_investor_close_description'), {
                filterable: true,
                format: 'wysiwyg',
                width: '15rem'
            }),
            this.createHeader('early_bird_offer_feature', i18n.t('early_bird_offer'), {
                width: '30rem'
            }),
            // this.createHeader('max_adviser_facilitated', i18n.t('max_adviser_facilitated'), {
            //     filterable: true,
            //     format: 'percentage',
            //     width: '10rem'
            // }),

            this.createHeader('sdr_label', i18n.t('sdr_label'), {
                format: 'enum',
                filterable: true,
                enum: SDRLabelEnum,
                enumA11yKey: 'enums.sdr_label',
                width: '8rem',
                active: false
            })
        ];
    }

    get default_headers() {
        return [
            'name',
            'tax_status',
            'status',
            'close_at',
            'max_size',
            'max_fund_size_details',
            'min_investment',
            'fund_structure'
        ];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('in') + ':[SEIS,EIS]',
            single_company: this.filterOperator('is') + ':false'
        };
    }
}

export default OfferEisSeisCollection;
