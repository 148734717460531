import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class DayValidator extends BaseValidator {
    static key = 'day';

    static validator(value, label = 'Day') {
        let valid = false;

        if (!value) {
            return true;
        }

        value = typeof value === 'string' ? value : `${value}`;
        value = value.replace(/\s/g, '');

        valid = value.match(/^(([0]?[1-9])|([1-2][0-9])|(3[01]))$/i);

        if (!valid) {
            return i18n.t('validator.day', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default DayValidator;
