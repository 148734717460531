import '@/lib/date-fix';

import Vue from 'vue';
import App from '@/components/App.vue';

import store from '@/store';
import router from '@/router';
import i18n from '@/lib/i18n';
import ErrorService from '@/services/ErrorService';

import '@/lib/http';
import '@/mixins';
import '@/directives';
import '@/lib/lodash';
import '@/lib/ideal-postcodes';
import '@/global-components';

import vuetify from '@/lib/vuetify';
import '@/scss/app.scss';

Vue.config.productionTip = false;
Vue.config.errorHandler = (error, vm, info) => ErrorService.onError(error, { vm, info });

window.Vue = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#gi-app');

ErrorService.init();
