import { BaseModel } from '@/models';
import RestrictedPanelApi from '@/api/RestrictedPanelApi';

export class RestrictedPanel extends BaseModel {
    static entity = 'restrictedPanels';
    static Api = RestrictedPanelApi;

    static fields() {
        return {
            name: this.string(null).nullable(),
            offers: this.attr([])
        };
    }

    static is_valid() {
        return this.name && this.offers && this.offers.length > 0;
    }
}

export default RestrictedPanel;
