import AddressesValidator from './AddressesValidator';
import AddressHistoryValidator from './AddressHistoryValidator';
import AfterValidator from './AfterValidator';
import AppropriatenessValidator from './AppropriatenessValidator';
import BankAccountsOrDeferredValidator from './BankAccountsOrDeferredValidator';
import BankAccountsValidator from './BankAccountsValidator';
import BeforeValidator from './BeforeValidator';
import BetweenValidator from './BetweenValidator';
import CountryValidator from './CountryValidator';
import DateValidator from './DateValidator';
import DayValidator from './DayValidator';
import EmailValidator from './EmailValidator';
import FileValidator from './FileValidator';
import IbanValidator from './IbanValidator';
import ImageValidator from './ImageValidator';
import IntegerValidator from './IntegerValidator';
import IsFalseValidator from './IsFalseValidator';
import IsTrueValidator from './IsTrueValidator';
import MaxValidator from './MaxValidator';
import LessValidator from './LessValidator';
import MinValidator from './MinValidator';
import MoreValidator from './MoreValidator';
import MoneyValidator from './MoneyValidator';
import MonthValidator from './MonthValidator';
import NationalInsuranceNumberValidator from './NationalInsuranceNumberValidator';
import PhoneNumberValidator from './PhoneNumberValidator';
import PhoneNumbersValidator from './PhoneNumbersValidator';
import PostcodeValidator from './PostcodeValidator';
import QuestionValidator from './QuestionValidator';
import RequiredValidator from './RequiredValidator';
import SortCodeValidator from './SortCodeValidator';
import TiptapValidator from './TiptapValidator';
import UrlValidator from './UrlValidator';
import UtrValidator from './UtrValidator';
import UuidValidator from './UuidValidator';
import YearValidator from './YearValidator';
import YouTubeUrlValidator from './YouTubeUrlValidator';

export const validators = {
    [AddressesValidator.key]: AddressesValidator.validator,
    [AddressHistoryValidator.key]: AddressHistoryValidator.validator,
    [AfterValidator.key]: AfterValidator.validator,
    [AppropriatenessValidator.key]: AppropriatenessValidator.validator,
    [BankAccountsValidator.key]: BankAccountsValidator.validator,
    [BankAccountsOrDeferredValidator.key]: BankAccountsOrDeferredValidator.validator,
    [BeforeValidator.key]: BeforeValidator.validator,
    [BetweenValidator.key]: BetweenValidator.validator,
    [DateValidator.key]: DateValidator.validator,
    [CountryValidator.key]: CountryValidator.validator,
    [DayValidator.key]: DayValidator.validator,
    [EmailValidator.key]: EmailValidator.validator,
    [FileValidator.key]: FileValidator.validator,
    [IbanValidator.key]: IbanValidator.validator,
    [ImageValidator.key]: ImageValidator.validator,
    [IntegerValidator.key]: IntegerValidator.validator,
    [IsFalseValidator.key]: IsFalseValidator.validator,
    [IsTrueValidator.key]: IsTrueValidator.validator,
    [MaxValidator.key]: MaxValidator.validator,
    [LessValidator.key]: LessValidator.validator,
    [MinValidator.key]: MinValidator.validator,
    [MoreValidator.key]: MoreValidator.validator,
    [MoneyValidator.key]: MoneyValidator.validator,
    [MonthValidator.key]: MonthValidator.validator,
    [NationalInsuranceNumberValidator.key]: NationalInsuranceNumberValidator.validator,
    [PhoneNumberValidator.key]: PhoneNumberValidator.validator,
    [PhoneNumbersValidator.key]: PhoneNumbersValidator.validator,
    [PostcodeValidator.key]: PostcodeValidator.validator,
    [QuestionValidator.key]: QuestionValidator.validator,
    [RequiredValidator.key]: RequiredValidator.validator,
    [SortCodeValidator.key]: SortCodeValidator.validator,
    [TiptapValidator.key]: TiptapValidator.validator,
    [UrlValidator.key]: UrlValidator.validator,
    [UtrValidator.key]: UtrValidator.validator,
    [UuidValidator.key]: UuidValidator.validator,
    [YearValidator.key]: YearValidator.validator,
    [YouTubeUrlValidator.key]: YouTubeUrlValidator.validator
};

export const fieldValidators = {
    [AfterValidator.key]: AfterValidator,
    [BeforeValidator.key]: BeforeValidator,
    [BetweenValidator.key]: BetweenValidator,
    [DateValidator.key]: DateValidator,
    [CountryValidator.key]: CountryValidator,
    [DayValidator.key]: DayValidator,
    [EmailValidator.key]: EmailValidator,
    [FileValidator.key]: FileValidator,
    [IbanValidator.key]: IbanValidator,
    [ImageValidator.key]: ImageValidator,
    [IntegerValidator.key]: IntegerValidator,
    [IsFalseValidator.key]: IsFalseValidator,
    [IsTrueValidator.key]: IsTrueValidator,
    [MaxValidator.key]: MaxValidator,
    [MinValidator.key]: MinValidator,
    [MoneyValidator.key]: MoneyValidator,
    [MonthValidator.key]: MonthValidator,
    [NationalInsuranceNumberValidator.key]: NationalInsuranceNumberValidator,
    [PhoneNumberValidator.key]: PhoneNumberValidator,
    [PostcodeValidator.key]: PostcodeValidator,
    [RequiredValidator.key]: RequiredValidator,
    [SortCodeValidator.key]: SortCodeValidator,
    [UrlValidator.key]: UrlValidator,
    [UtrValidator.key]: UtrValidator,
    [UuidValidator.key]: UuidValidator,
    [YearValidator.key]: YearValidator
};

export default validators;
