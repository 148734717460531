import BaseValidator from './BaseValidator';

export class YouTubeUrlValidator extends BaseValidator {
    static key = 'youtubeUrl';

    static validator(value) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            valid = value.match(
                /* eslint-disable max-len */
                /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/i
            );
        }

        if (!valid) {
            return 'YouTube video not valid';
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default YouTubeUrlValidator;
