import UserRoleEnum from '@/enums/user/role';

const GrowthInvestRoleEnum = Object.freeze({
    PLATFORM_SUPERADMIN: UserRoleEnum.PLATFORM_SUPERADMIN,
    OPERATIONS: UserRoleEnum.OPERATIONS,
    MANAGEMENT: UserRoleEnum.MANAGEMENT,
    DIRECTOR: UserRoleEnum.DIRECTOR
});

export default GrowthInvestRoleEnum;
