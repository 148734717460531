import { Collection, Registration } from '@/models';

import i18n from '@/lib/i18n';
import InvestorCategoryEnum from '@/enums/investor/category';
import RegistrationStatusEnum from '@/enums/registration/status';

export class SystemRegistrationsCollection extends Collection {
    static type = 'SystemRegistrationsCollection';
    static endpoint = Registration.api.index;
    static model = Registration;

    get default_headers() {
        return [
            'name',
            'email',
            'investor_type',
            'on_boarding_status',
            'investor_type_attempts',
            'created_at',
            'last_updated'
        ];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '14rem',
                filterable: true
            }),
            this.createHeader('email', i18n.t('email'), {
                width: '16rem',
                filterable: true
            }),
            this.createHeader('investor_type', i18n.t('investor_type'), {
                filterable: true,
                width: '14rem',
                format: 'enum',
                enum: InvestorCategoryEnum,
                enumA11yKey: 'enums.investor_type'
            }),
            this.createHeader('on_boarding_status', i18n.t('status'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: RegistrationStatusEnum,
                enumA11yKey: 'enums.registration_status',
                enumTypes: {
                    [RegistrationStatusEnum.COMPLETE]: 'success',
                    [RegistrationStatusEnum.FAILED_CLASSIFICATION]: 'error',
                    [RegistrationStatusEnum.PENDING]: 'grey'
                }
            }),
            this.createHeader('investor_type_attempts', i18n.t('attempts'), {
                format: 'number',
                width: '8rem',
                filterable: true
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('last_updated', i18n.t('last_updated'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            })
        ];
    }
}

export default SystemRegistrationsCollection;
