import isObject from 'lodash/isObject';
import has from 'lodash/has';

export default {
    routerHistory: {
        namespaced: true,
        state: {
            items: []
        },
        getters: {
            history: state => state.items,
            hasHistory: state => state.items.length > 0
        },
        actions: {
            add({ commit }, route) {
                if (isObject(route) && has(route, 'name') && route.name) {
                    commit('ADD_ITEM', route);
                }
            }
        },
        mutations: {
            ADD_ITEM(state, payload) {
                state.items.push(payload);
            }
        }
    }
};
