/* eslint-disable max-len */

export const validator = {
    address_history_required: 'Addresses must have at least {months} months of history',
    after: '{field} must be after {after}',
    bank_account_number: 'Bank account number must be valid',
    before: '{field} must be before {before}',
    between: '{field} must be between {start} and {end}',
    country: 'Country must be a valid IS0 3166-1 alpha-2 country code',
    date: '{field} must be a valid date',
    day: '{field} must be a valid day',
    declaration: "Declaration must be valid, make sure you've ticked all required checkboxes",
    email: '{field} must be a valid email address',
    false: '{field} must be false',
    file: '{field} must be a valid file of type {type}',
    guid: '{field} must be a valid GUID',
    iban: '{field} must be a valid IBAN number',
    integer: '{field} must be a valid integer',
    max: '{field} must be less than {maximum}',
    max_items: '{field} must have less than {maximum} {items}',
    min: '{field} must be more than {minimum}',
    min_items: '{field} must have more than {minimum} {items}',
    money: '{field} must be a valid monetary value',
    month: '{field} must be a valid month',
    national_insurance_number:
        "It's on your National Insurance card, benefit letter, payslip or P60. For example, ‘QQ 12 34 56 C’",
    phone_number: '{field} must be a valid phone number',
    postcode: '{field} must be a valid UK postcode',
    required: 'Required',
    required_field: '{field} is required',
    spaces_not_allowed: '{field} must not contain spaces',
    sort_code: "Sort code must be a valid UK sort code. For example, '12-34-56'",
    tiptap: "The form must be valid, make sure you've ticked all required checkboxes and answered every required question",
    true: '{field} must be true',
    url: '{field} must be a valid URL',
    utr: 'UTR must be a valid Unique Taxpayer Reference number',
    uuid: '{field} must be a valid UUID',
    year: '{field} must be a valid year'
};

export const names = {
    after: 'After',
    before: 'Before',
    between: 'Between',
    country: 'Country',
    date: 'Date',
    day: 'Day',
    email: 'Email',
    file: 'File',
    iban: 'IBAN',
    image: 'Image',
    integer: 'Integer',
    isFalse: 'False',
    isTrue: 'True',
    max: 'Max',
    min: 'Min',
    money: 'Money',
    month: 'Month',
    nationalInsuranceNumber: 'National Insurance Number',
    phoneNumber: 'Phone Number',
    postcode: 'Postcode',
    required: 'Required',
    sortCode: 'Sort Code',
    url: 'URL',
    utr: 'UTR',
    uuid: 'UUID',
    year: 'Year'
};

export default validator;
