import md5 from 'md5';
import isNil from 'lodash/isNil';

const hash = (item, length = 18) => {
    if (isNil(item)) {
        item = {};
    }
    return md5(JSON.stringify(item)).slice(0, length);
};

export default hash;
