import { Collection, Product } from '@/models';
import i18n from '@/lib/i18n';
import TaxStatusEnum from '@/enums/taxStatus';
import ProductTypeEnum from '@/enums/product/type';
import getFilterOperator from '@/lib/helpers/getFilterOperator';

export class ProductsCollection extends Collection {
    static type = 'ProductsCollection';
    static endpoint = Product.api.index;
    static model = Product;

    get headers() {
        return [
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '10rem',
                filterable: {
                    operator: getFilterOperator('CONTAINS')
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: true
            }),
            this.createHeader('type', i18n.t('type'), {
                format: 'enum',
                enum: ProductTypeEnum,
                enumA11yKey: 'enums.product_type',
                width: '11rem',
                filterable: true
            }),
            this.createHeader('share_class', i18n.t('share_class'), {
                width: '8rem',
                filterable: true
            }),
            this.createHeader('isin', i18n.t('isin'), {
                width: '8rem',
                filterable: true
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: TaxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '8rem'
            })
        ];
    }
}

export default ProductsCollection;
