import BaseApi from '@/api/BaseApi';

export class TransactionApi extends BaseApi {
    static resource = 'transactions';
    static mockItemCount = 30;

    get endpoints() {
        return {
            ...super.endpoints,
            index: this.index.bind(this)
        };
    }

    async index(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        if ('tax_year' in filters) {
            if (Array.isArray(filters.tax_year)) {
                filters.tax_year = filters.tax_year.map(filter => {
                    return filter.replace('eq:', '');
                });
            } else {
                filters.tax_year = filters.tax_year.replace('eq:', '');
            }
        }

        return super.index(offset, limit, sort_by, filters, config);
    }

    _toSearchFilters(criteria, filters = {}) {
        return BaseApi.toSearchFilters(criteria, filters, 'gi_ref');
    }
}

export default TransactionApi;
