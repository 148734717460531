export class BaseValidator {
    static key = 'base';

    constructor() {
        this.instance = this;
    }

    static validator() {
        let valid = false;

        // Validation logic here

        return valid;
    }

    static arguments() {
        return [];
    }
}

export default BaseValidator;
