import { MediaCollection, MediaAdviser } from '@/models';
import i18n from '@/lib/i18n';
import MediaFormatEnum from '@/enums/media/format';
import MediaAdviserTypeEnum from '@/enums/media/adviser/type';

export class MediaAdviserCollection extends MediaCollection {
    static type = 'MediaAdviserCollection';
    static endpoint = MediaAdviser.api.index;
    static model = MediaAdviser;

    static fields() {
        return {
            ...super.fields(),
            adviser_id: this.string(null).nullable()
        };
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                filterable: true,
                width: '14rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                sortable: true,
                width: '20rem'
            }),
            this.createHeader('description', i18n.t('description'), {
                width: '18rem',
                format: 'wysiwyg'
            }),
            this.createHeader('format', i18n.t('format'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: MediaFormatEnum,
                enumA11yKey: 'enums.media_format'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '14rem',
                format: 'enum',
                enum: MediaAdviserTypeEnum,
                enumA11yKey: 'enums.media_adviser_type'
            }),
            this.createHeader('adviser_name', i18n.t('adviser'), {
                path: 'adviser.name',
                width: '18rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.adviser_id) {
            filters.adviser_id = this.adviser_id;
        }

        if (this.media_format) {
            filters.format = this.media_format_filter_value;
        }

        if (this.media_type) {
            filters.type = this.media_type_filter_value;
        }

        return filters;
    }
}

export default MediaAdviserCollection;
