import omitBy from 'lodash/omitBy';
import endsWith from 'lodash/endsWith';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

/**
 * Returns a filtered object with only the properties that are not undefined
 * or properties that don't end with ':undefined'
 *
 * @param {Object} v
 * @returns {Object}
 */
export function removeUndefined(v) {
    v = mapValues(v, item => {
        if (!Array.isArray(item) && isObject(item)) {
            return removeUndefined(item);
        }
        return item;
    });

    return omitBy(v, isUndefinedValue);
}

export function isUndefinedValue(item) {
    if (item === undefined) {
        return true;
    }

    if (endsWith(item, ':undefined')) {
        return true;
    }

    return false;
}

export default removeUndefined;
