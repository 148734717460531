import BaseApi from '@/api/BaseApi';

export class UserApi extends BaseApi {
    static resource = 'users';

    get endpoints() {
        return {
            ...super.endpoints,
            verifyEmail: this.verifyEmail.bind(this),
            setupMfa: this.setupMfa.bind(this),
            logoutAllDevices: this.logoutAllDevices.bind(this),
            getTerms: this.getTerms.bind(this),
            acceptTerms: this.acceptTerms.bind(this),
            downloadTerms: this.downloadTerms.bind(this)
        };
    }

    async verifyEmail(id, email) {
        const response = await BaseApi.api.post(`${this.base_url}/${id}/verify-email`, { email });

        return this._respond(response, () => {
            this._updateItemInCollections(id, response.data);
        });
    }

    async setupMfa(id) {
        const response = await BaseApi.api.post(`${this.base_url}/${id}/setup-mfa`);

        return this._respond(response, () => {
            this._updateItemInCollections(id, response.data);
        });
    }

    async logoutAllDevices(id) {
        return await BaseApi.api.get(`${this.base_url}/${id}/forcelogout`);
    }

    async getTerms(userId) {
        const response = await BaseApi.api.get(`${this.base_url}/${userId}/terms`);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = response.data.map(t => ({ status: 'APPROVED', ...t }));
        });
    }

    async acceptTerms(userId, terms) {
        const response = await BaseApi.api.post(`${this.base_url}/${userId}/terms`, terms);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
        });
    }

    async downloadTerms(userId) {
        let url = `${this.base_url}`;

        if (userId) {
            url += `/${userId}`;
        }

        url += '/terms/download';

        return await BaseApi.api.get(
            url,
            {},
            {
                headers: {
                    Accept: 'application/pdf'
                },
                responseType: 'blob',
                returnFullResponse: true
            }
        );
    }
}

export default UserApi;
