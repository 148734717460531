import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import toDate from '@/lib/helpers/toDate';
import clock from '@/lib/clock';

export class AddressHistoryValidator extends BaseValidator {
    static key = 'addressHistory';

    static validator(value, label, months) {
        if (!value) {
            return true;
        }

        if (Array.isArray(value) && !value.length) {
            return true;
        }

        const startDates = value.reduce((acc, address) => {
            if ('start_on' in address && address.start_on) {
                const date = toDate(address.start_on, false);
                if (date) {
                    acc.push(date);
                }
            }
            return acc;
        }, []);

        if (!startDates.length) {
            return i18n.t('validator.address_history_required', { months });
        }

        const minDate = clock.min(startDates);

        if (!minDate) {
            return i18n.t('validator.address_history_required', { months });
        }

        if (clock().subtract(months, 'month').isBefore(minDate)) {
            return i18n.t('validator.address_history_required', { months });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default AddressHistoryValidator;
