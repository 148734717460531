import BaseApi from '@/api/BaseApi';

export class VisibilityGroupApi extends BaseApi {
    static resource = 'visibility-groups';

    get base_url() {
        return `/${this.constructor.version}/advisers`;
    }

    async get(adviserId, id) {
        return await super.get(id, {}, { base_url: `${this.base_url}/${adviserId}/visibility-groups` });
    }

    async index(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        let extract = BaseApi.extractFilter(filters, 'adviser_id');

        if (!extract.value) {
            return null;
        }

        return await super.index(offset, limit, sort_by, extract.filters, {
            ...config,
            base_url: `${this.base_url}/${extract.value}/visibility-groups`
        });
    }

    async patch(id, data) {
        const adviserId = data.adviser_id;

        if (!adviserId) {
            return null;
        }

        return await super.patch(id, data, { base_url: `${this.base_url}/${adviserId}/visibility-groups` });
    }

    async post(data) {
        data = BaseApi.removeNull(data);

        const adviserId = data.adviser_id;

        if (!adviserId) {
            return null;
        }

        return await super.post(data, { base_url: `${this.base_url}/${adviserId}/visibility-groups` });
    }

    async put(data) {
        data = BaseApi.removeNull(data);

        const adviserId = data.adviser_id;

        if (!adviserId) {
            return null;
        }

        return await super.put(data, { base_url: `${this.base_url}/${adviserId}/visibility-groups` });
    }

    async remove(adviserId, id) {
        return await super.remove(id, { base_url: `${this.base_url}/${adviserId}/visibility-groups` });
    }

    async search(criteria, filters = {}) {
        let extract = BaseApi.extractFilter(filters, 'adviser_id');

        if (!extract.value) {
            return null;
        }

        return await super.search(criteria, extract.filters, {
            base_url: `${this.base_url}/${extract.value}/visibility-groups`
        });
    }
}

export default VisibilityGroupApi;
