const DynamicFormTypeEnum = Object.freeze({
    STRING: 'String',
    NUMBER: 'Number',
    BOOLEAN: 'Boolean',
    MONEY: 'Money',
    PERCENTAGE: 'Percentage',
    DATE: 'Date',
    DATETIME: 'Datetime',
    ARRAY: 'Array',
    ENUM: 'Enum'
});

export default DynamicFormTypeEnum;
