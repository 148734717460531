export default {
    data: () => ({
        setupLoading: false,
        setupLoaded: false,
        setupOnMounted: true
    }),
    async mounted() {
        if (this.setupOnMounted) {
            await this.trySetup();
        }
    },
    methods: {
        async trySetup(args) {
            this.setupLoading = true;
            await this.setup(args);
            await this.afterSetup();
            this.setupLoading = false;
            this.setupLoaded = true;
        },
        async setup() {},
        async afterSetup() {}
    }
};
