import { News, Company } from '@/models';

export class CompanyNews extends News {
    static entity = 'companyNews';

    static fields() {
        return {
            ...super.fields(),
            company_id: this.string(null).nullable(),
            company: this.belongsTo(Company, 'company_id')
        };
    }
}

export default CompanyNews;
