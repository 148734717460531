const TransactionTypeEnum = Object.freeze({
    ADJUSTMENT: 'ADJUSTMENT',
    AGREEMENT: 'AGREEMENT',
    CASH_RECEIPT: 'CASH_RECEIPT',
    CASH_WITHDRAWAL: 'CASH_WITHDRAWAL',
    CONTRIBUTION: 'CONTRIBUTION',
    CUSTODY_TRANSFER: 'CUSTODY_TRANSFER',
    DISTRIBUTION: 'DISTRIBUTION',
    DIVIDEND: 'DIVIDEND',
    ESCROW: 'ESCROW',
    EXIT: 'EXIT',
    FEE_ADVISER_INITIAL: 'FEE_ADVISER_INITIAL',
    FEE_ADVISER_INITIAL_ACCRUAL: 'FEE_ADVISER_INITIAL_ACCRUAL',
    FEE_ADVISER_ONGOING: 'FEE_ADVISER_ONGOING',
    FEE_ADVISER_ONGOING_ACCRUAL: 'FEE_ADVISER_ONGOING_ACCRUAL',
    FEE_PARTNER: 'FEE_PARTNER',
    FEE_PLATFORM: 'FEE_PLATFORM',
    FEE_PLATFORM_ACCRUAL: 'FEE_PLATFORM_ACCRUAL',
    FEE_PRODUCT: 'FEE_PRODUCT',
    FEE_PROVIDER: 'FEE_PROVIDER',
    FUND_INVESTMENT: 'FUND_INVESTMENT',
    FUND_TRANSFER: 'FUND_TRANSFER',
    INTEREST: 'INTEREST',
    OPERATING_EXPENSE: 'OPERATING_EXPENSE',
    PM_UPDATE: 'PM_UPDATE',
    PURCHASE: 'PURCHASE',
    RD_PAYMENT: 'RD_PAYMENT',
    REBATE: 'REBATE',
    SALE: 'SALE',
    TAX_ADJUSTMENT: 'TAX_ADJUSTMENT'
});

export const TransactionTypeEnumLegacy = Object.freeze({
    ...TransactionTypeEnum,
    FEE_ADVISER: 'FEE_ADVISER' // @deprecated v2.60.0
});

export default TransactionTypeEnum;
