const findNodesByType = (value, nodeType) => {
    let found = [];

    if (value && 'content' in value) {
        for (const node of value.content) {
            if (node.type === nodeType) {
                found.push(node);
            }
            found = [...found, ...findNodesByType(node, nodeType)];
        }
    }

    return found;
};

export default findNodesByType;
