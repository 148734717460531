const MediaMarketingTypeEnum = Object.freeze({
    APPLICATION: 'APPLICATION',
    KID: 'KID',
    DD: 'DD',
    IM: 'IM',
    CERTIFICATION: 'CERTIFICATION',
    SUMMARY: 'SUMMARY',
    // For single companies:
    INVESTMENT_DECK: 'INVESTMENT_DECK',
    //
    OTHER: 'OTHER',
    SUSTAINABLE_DISCLOSURE: 'SUSTAINABLE_DISCLOSURE'
});

export default MediaMarketingTypeEnum;
