import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import toIso8601 from '@/lib/helpers/toIso8601';
import clock from '@/lib/clock';

export class BeforeValidator extends BaseValidator {
    static key = 'before';

    static validator(value, label, before = null, beforeLabel = null) {
        if (!value || !before) {
            return true;
        }

        let date = clock(toIso8601(value));

        if (!date || !date.isValid()) {
            return i18n.t('validator.date', { field: label });
        }

        before = clock(toIso8601(before));

        if (!before || !before.isValid()) {
            return true;
        }

        if (!date.isBefore(before)) {
            return i18n.t('validator.before', { field: label, before: beforeLabel || before.format('DD/MM/YYYY') });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default BeforeValidator;
