import { BaseModel, FundManager, Provider, Sector, InvestmentFocus, Company, ExchangeListing } from '@/models';
import DriShareTypeEnum from '@/enums/driShareType';
import TaxStatusEnum from '@/enums/taxStatus';
import wait from '@/lib/helpers/wait';
import ProductApi from '@/api/ProductApi';
import snack from '@/lib/helpers/snack';
import i18n from '@/lib/i18n';
import SDRLabelEnum from '@/enums/SDRLabel';

export class Product extends BaseModel {
    static entity = 'products';
    static Api = ProductApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            logo: this.attr(null).nullable(),
            header_img: this.attr(null).nullable(),
            name: this.string(''),
            status: this.string(null).nullable(),
            type: this.string(null).nullable(),
            tax_status: this.enum(TaxStatusEnum).nullable(),
            gi_ref: this.string(null).nullable(),
            summary: this.attr(null).nullable(),
            description: this.attr(null).nullable(),
            hmrc_ref: this.string(null).nullable(),
            isin: this.string(null).nullable(),
            sedol: this.string(null).nullable(),
            esg_qualifying: this.boolean(null).nullable(),
            market_cap_recorded: this.attr(this.defaultMoney).nullable(),
            market_cap_source: this.string(null).nullable(),
            nav_discount: this.number(null).nullable(),
            nav_discount_12m_average: this.number(null).nullable(),
            total_assets: this.attr(this.defaultMoney).nullable(),
            dividend_growth: this.number(null).nullable(),
            dividend_yield: this.number(null).nullable(),
            dividend_type: this.string(null).nullable(),
            dividend_frequency: this.number(null).nullable(),
            gross_dividend: this.attr(this.defaultMoney).nullable(),
            shares_in_issue: this.number(null).nullable(),
            share_price_total_return_1y: this.number(null).nullable(),
            share_price_total_return_5y: this.number(null).nullable(),
            share_price_total_return_10y: this.number(null).nullable(),
            share_class: this.string(null).nullable(),
            linked_exchange: this.boolean(false).nullable(),
            dri_available: this.boolean(false),
            dri_share_type: this.enum(DriShareTypeEnum).nullable(),
            dri_enrolled: this.boolean(false),
            sdr_label: this.enum(SDRLabelEnum, SDRLabelEnum.NO_LABEL).nullable(),

            fund_manager_id: this.string(null).nullable(),
            fund_manager: this.belongsTo(FundManager, 'fund_manager_id'),

            provider_id: this.string(null).nullable(),
            provider: this.belongsTo(Provider, 'provider_id'),

            parent_id: this.string(null).nullable(),
            parent: this.belongsTo(Product, 'parent_id'),

            sector_id: this.string(null).nullable(),
            sector: this.belongsTo(Sector, 'sector_id'),

            investment_focus_id: this.string(null).nullable(),
            investment_focus: this.belongsTo(InvestmentFocus, 'investment_focus_id'),

            company_id: this.string(null).nullable(),
            company: this.belongsTo(Company, 'company_id'),

            listings: this.hasMany(ExchangeListing, 'product_id'),

            // Convertible Loan
            secured: this.boolean(null).nullable(),
            issued_on: this.string(null).nullable(),
            converts_on: this.string(null).nullable(),
            matures_on: this.string(null).nullable(),
            maturity_price: this.attr(this.defaultMoney).nullable(),
            coupon: this.number(null).nullable(),
            coupon_settlement_period: this.number(null).nullable(),
            redemption_premium: this.attr(this.defaultMoney).nullable(),
            ratio: this.number(null).nullable(),
            conversion_price: this.attr(this.defaultMoney).nullable(),
            conversion_valuation_cap: this.attr(this.defaultMoney).nullable(),
            conversion_valuation_floor: this.attr(this.defaultMoney).nullable(),

            // Warrant
            strike_price: this.attr(this.defaultMoney).nullable(),
            style: this.string(null).nullable(),
            settlement: this.string(null).nullable()
        };
    }

    get target_dividend_display() {
        return null;
    }

    get listing() {
        if (!this.listings || !this.listings.length) {
            return new ExchangeListing();
        }

        if (this.listings.length === 1) {
            return this.listings[0];
        }

        const primary = this.listings.find(listing => listing.primary === true);
        return primary || this.listings[0];
    }

    async $resyncPricing() {
        if (!this.id) {
            throw new ReferenceError('Missing ID required while attempting to resync pricing');
        }

        this.$saving = ['resyncPricing'];

        try {
            await wait(1400);
            const response = await this.constructor.api.resyncPricing(this.id);
            this.$saving = [];

            snack({
                timeout: 20000,
                type: 'success',
                text: i18n.t('pricing_update_request_submitted')
            });

            return response;
        } catch (error) {
            this.$saving = [];
            throw error;
        }
    }
}

export default Product;
