export const clone = data => {
    if (['string', 'number', 'boolean'].includes(typeof data) || data === null || data === undefined) {
        return data;
    } else if (Array.isArray(data)) {
        return data.map(item => clone(item));
    } else if (typeof data === 'object') {
        let cloned = {};

        for (let key in data) {
            if (typeof data[`${key}`] !== 'function') {
                cloned[`${key}`] = clone(data[`${key}`]);
            }
        }

        return Object.keys(cloned).length ? cloned : null;
    } else {
        throw new Error('Invalid data type');
    }
};

export default clone;
