import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import strim from '@/lib/helpers/strim';

export class SortCodeValidator extends BaseValidator {
    static key = 'sortCode';

    static validator(value, label) {
        let valid = false;

        if (!value) {
            return true;
        }

        if (typeof value === 'string') {
            value = strim(value);

            const rgx = new RegExp(/^[0-9]{6}$/);

            valid = value.match(rgx);
        }

        if (!valid) {
            return i18n.t('validator.sort_code', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default SortCodeValidator;
