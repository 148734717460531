import { BaseModel } from '@/models';
import toDate from '@/lib/helpers/toDate';
import i18n from '@/lib/i18n';

export class PreviousCompanyName extends BaseModel {
    static entity = 'previousCompanyNames';

    static fields() {
        return {
            ...super.fields(),
            company_name: this.string(null).nullable(),
            date_of_creation: this.string(null).nullable(),
            date_of_cessation: this.string(null).nullable()
        };
    }

    get is_valid() {
        if (!this.company_name || !this.date_of_creation) {
            return false;
        }

        if (this.date_of_cessation && toDate(this.date_of_cessation, false).isBefore(this.date_of_creation)) {
            return false;
        }

        return true;
    }

    get computed_name() {
        if (this.is_valid) {
            let name = this.company_name;
            let from = toDate(this.date_of_creation);
            let to = this.date_of_cessation ? toDate(this.date_of_cessation) : i18n.t('current');

            return `${name} - from ${from} to ${to}`;
        }
        return i18n.t('previous_company_name');
    }
}

export default PreviousCompanyName;
