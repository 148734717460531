import { BaseModel, Offer } from '@/models';
import FeeApi from '@/api/FeeApi';

export class Fees extends BaseModel {
    static entity = 'fees';
    static Api = FeeApi;

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(null).nullable(),

            initial: this.number(null).nullable(),
            initial_description: this.string(null).nullable(),

            existing_investor_initial: this.number(null).nullable(),
            existing_investor_initial_description: this.string(null).nullable(),

            annual_management: this.number(null).nullable(),
            annual_management_description: this.string(null).nullable(),

            performance: this.number(null).nullable(),
            performance_description: this.string(null).nullable(),

            performance_hurdle: this.string(null).nullable(),

            additional: this.number(null).nullable(),
            additional_description: this.string(null).nullable(),

            administration: this.attr(this.defaultMoney).nullable(),
            administration_description: this.string(null).nullable(),

            investee_initial: this.number(null).nullable(),
            investee_initial_description: this.string(null).nullable(),

            investee_ongoing: this.number(null).nullable(),
            investee_ongoing_description: this.string(null).nullable(),

            investee_additional: this.number(null).nullable(),
            investee_additional_description: this.string(null).nullable(),

            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id')
        };
    }
}

export default Fees;
