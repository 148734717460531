import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapAlert from '@/lib/tiptap/components/TiptapAlert.vue';

export const css = `
.tiptap-alert {
    position: relative;
    background: var(--v-background-darken2);
    border-radius: 4px;
    padding: 1rem 1.4rem;
    margin-bottom: 1rem;
}
.tiptap-alert :last-child {
    margin-bottom: 0;
}
.tiptap-alert p {
    color: inherit;
}

.tiptap-alert[data-type=INFO] {
    color: var(--v-info-darken1);
    background: var(--v-info-lighten5);
}
.tiptap-alert[data-type=INFO] h1,
.tiptap-alert[data-type=INFO] h2,
.tiptap-alert[data-type=INFO] h3,
.tiptap-alert[data-type=INFO] h4,
.tiptap-alert[data-type=INFO] h5,
.tiptap-alert[data-type=INFO] h6 {
    color: var(--v-info-darken2);
}

.tiptap-alert[data-type=WARNING] {
    color: var(--v-warning-darken1);
    background: var(--v-warning-lighten5);
}
.tiptap-alert[data-type=WARNING] h1,
.tiptap-alert[data-type=WARNING] h2,
.tiptap-alert[data-type=WARNING] h3,
.tiptap-alert[data-type=WARNING] h4,
.tiptap-alert[data-type=WARNING] h5,
.tiptap-alert[data-type=WARNING] h6 {
    color: var(--v-warning-darken2);
}

.tiptap-alert[data-type=ERROR] {
    color: var(--v-error-darken1);
    background: var(--v-error-lighten5);
}
.tiptap-alert[data-type=ERROR] h1,
.tiptap-alert[data-type=ERROR] h2,
.tiptap-alert[data-type=ERROR] h3,
.tiptap-alert[data-type=ERROR] h4,
.tiptap-alert[data-type=ERROR] h5,
.tiptap-alert[data-type=ERROR] h6 {
    color: var(--v-error-darken2);
}

.tiptap-alert[data-type=SUCCESS] {
    color: var(--v-success-darken1);
    background: var(--v-success-lighten5);
}
.tiptap-alert[data-type=SUCCESS] h1,
.tiptap-alert[data-type=SUCCESS] h2,
.tiptap-alert[data-type=SUCCESS] h3,
.tiptap-alert[data-type=SUCCESS] h4,
.tiptap-alert[data-type=SUCCESS] h5,
.tiptap-alert[data-type=SUCCESS] h6 {
    color: var(--v-success-darken2);
}
`;

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'Alert',
    content: 'block*',
    marks: '_',
    group: 'block',
    inline: false,
    defining: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapAlert);
    },

    addAttributes() {
        return {
            type: { default: 'INFO' }
        };
    },

    addCommands() {
        return {
            setAlert:
                () =>
                ({ commands }) => {
                    return commands.wrapIn(this.name);
                },
            toggleAlert:
                () =>
                ({ commands }) => {
                    return commands.toggleWrap(this.name);
                },
            unsetAlert:
                () =>
                ({ commands }) => {
                    return commands.lift(this.name);
                }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-alert',
                getAttrs: node => ({
                    type: node.getAttribute('data-type')
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            {
                class: 'tiptap-alert',
                'data-type': HTMLAttributes.type
            },
            0
        ];
    }
});
