import request from '@/api/lib/request';

/**
 * Send a HEAD request.
 *
 * @param {String} url
 * @returns {Object}
 */
const head = async (url, config = {}) => {
    return await request({
        method: 'HEAD',
        url,
        ...config
    });
};

export default head;
