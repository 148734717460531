import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import toIso8601 from '@/lib/helpers/toIso8601';
import clock from '@/lib/clock';

export class AfterValidator extends BaseValidator {
    static key = 'after';

    static validator(value, label, after = null, afterLabel = null) {
        if (!value || !after) {
            return true;
        }

        let date = clock(toIso8601(value));

        if (!date || !date.isValid()) {
            return i18n.t('validator.date', { field: label });
        }

        after = clock(toIso8601(after));

        if (!after || !after.isValid()) {
            return true;
        }

        if (!date.isAfter(after)) {
            return i18n.t('validator.after', { field: label, after: afterLabel || after.format('DD/MM/YYYY') });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default AfterValidator;
