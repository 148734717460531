import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import isCurrency from 'validator/lib/isCurrency';
import strim from '@/lib/helpers/strim';

export class MoneyValidator extends BaseValidator {
    static key = 'money';

    static validator(value, label, symbol = '£') {
        let valid = false;

        if (typeof value === 'string') {
            valid = isCurrency(strim(value), {
                symbol,
                require_symbol: false,
                allow_space_after_symbol: false,
                symbol_after_digits: false,
                allow_negatives: true,
                parens_for_negatives: false,
                negative_sign_before_digits: false,
                negative_sign_after_digits: false,
                allow_negative_sign_placeholder: false,
                thousands_separator: ',',
                decimal_separator: '.',
                allow_decimal: true,
                require_decimal: false,
                digits_after_decimal: [2],
                allow_space_after_digits: false
            });
        }

        if (!valid) {
            return i18n.t('validator.money', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default MoneyValidator;
