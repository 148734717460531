import BaseApi from '@/api/BaseApi';

export class FundApi extends BaseApi {
    static resource = 'funds';

    get endpoints() {
        return {
            ...super.endpoints,
            getNews: this.getNews.bind(this),
            getNewsArticle: this.getNewsArticle.bind(this),
            postNewsArticle: this.postNewsArticle.bind(this),
            putNewsArticle: this.putNewsArticle.bind(this),
            deleteNewsArticle: this.deleteNewsArticle.bind(this)
        };
    }

    async getNews(id) {
        const response = await this.index(null, null, null, {}, { base_url: `${this.base_url}/${id}/news` });

        return this._respond(response, () => {
            response.data = response.data.map(article => ({ ...article, fund_id: id }));
            response.data = this._filterResponseData(response.data);
        });
    }

    async getNewsArticle(id, articleId) {
        const response = await BaseApi.api.get(`${this.base_url}/${id}/news/${articleId}`);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('FundNews');
        });
    }

    async postNewsArticle(data = {}) {
        const response = await BaseApi.api.post(`${this.base_url}/${data.fund_id}/news`, data);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('FundNews');
        });
    }

    async putNewsArticle(data = {}) {
        const response = await BaseApi.api.put(`${this.base_url}/${data.fund_id}/news/${data.id}`, data);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('FundNews');
        });
    }

    async deleteNewsArticle(id, articleId) {
        const response = await BaseApi.api.remove(`${this.base_url}/${id}/news/${articleId}`);
        return this._respond(response, () => {
            this._clearCollection('FundNews');
        });
    }
}

export default FundApi;
