import BaseApi from '@/api/BaseApi';
import getFilterOperator from '@/lib/helpers/getFilterOperator';

export class LookupApi extends BaseApi {
    static resource = 'lookups';

    get endpoints() {
        return {
            post: this.post.bind(this),
            search: this.search.bind(this),
            searchCompaniesHouse: this.searchCompaniesHouse.bind(this),
            getCountries: this.getCountries.bind(this)
        };
    }

    async post(type, name) {
        return await BaseApi.api.post(`${this.base_url}/${type}`, { name });
    }

    async search(type = 'name', searchCriteria = null, searchKey = 'name', filterOperator = 'CONTAINS') {
        const filters = BaseApi.removeNull({
            [searchKey]: `${getFilterOperator(filterOperator)}:${searchCriteria}`
        });

        return await BaseApi.api.get(`${this.base_url}/${type}`, filters);
    }

    async searchCompaniesHouse(type = 'name', searchCriteria = null) {
        const filters = BaseApi.removeNull({
            [type]: searchCriteria
        });

        return await BaseApi.api.get(`${this.base_url}/companies`, filters);
    }

    async getCountries(searchCriteria) {
        const filters = BaseApi.removeNull({
            q: searchCriteria
        });

        return await BaseApi.api.get(`${this.base_url}/countryinfo`, filters);
    }
}

export default LookupApi;
