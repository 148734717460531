import { HoldingCollection } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';

export class HoldingSummaryByTaxStatusCollection extends HoldingCollection {
    static type = 'HoldingSummaryByTaxStatusCollection';

    get headers() {
        let headers = [
            this.createHeader('investment_type', i18n.t('investment_type'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '7rem'
            }),
            this.createHeader('gross_investment', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),
            this.createHeader('investment_count', i18n.t('investment_count'), {
                format: 'number',
                width: '10rem'
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem',
                sortable: false
            }),
            this.createHeader('uninvested_capital', i18n.t('fund_cash_balance'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('tax_relief', i18n.t('tax_relief'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('net_cost', i18n.t('net_cost'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('profit_loss', i18n.t('profit_loss'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('profit_loss_percentage', i18n.t('profit_loss_percentage'), {
                format: 'percentage',
                formatOptions: super.percentage_format_options,
                width: '7rem',
                sortable: false
            }),
            this.createHeader('total_exits', i18n.t('total_exits'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('distributions', i18n.t('distributions'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),

            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_irr', i18n.t('total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_irr', i18n.t('nav_total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_profit_loss', i18n.t('total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_profit_loss', i18n.t('nav_total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_irr',
                i18n.t('total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_irr',
                i18n.t('nav_total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_profit_loss_including_tax_relief',
                i18n.t('total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_profit_loss_including_tax_relief',
                i18n.t('nav_total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader('total_return_with_exits', i18n.t('total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_with_exits', i18n.t('nav_total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem'
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'investment_type',
            'gross_investment',
            'investment_count',
            'invested_amount',
            'uninvested_capital',
            'tax_relief',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_moic',
            'nav_total_return_moic',

            'total_return_percentage',
            'nav_total_return_percentage',

            'total_return_including_tax_relief',
            'nav_total_return_including_tax_relief',

            'total_return_including_tax_relief_moic',
            'nav_total_return_including_tax_relief_moic',

            'total_return_including_tax_relief_percentage',
            'nav_total_return_including_tax_relief_percentage'
        ];
    }

    get pdf_headers() {
        return [
            'investment_type',
            'gross_investment',
            'investment_count',
            'invested_amount',
            'uninvested_capital',
            'tax_relief',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_percentage',
            'nav_total_return_percentage',

            'total_return_including_tax_relief',
            'nav_total_return_including_tax_relief',

            'total_return_including_tax_relief_percentage',
            'nav_total_return_including_tax_relief_percentage'
        ];
    }
}

export default HoldingSummaryByTaxStatusCollection;
