import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class MonthValidator extends BaseValidator {
    static key = 'month';

    static validator(value, label) {
        let valid = false;

        if (!value) {
            return true;
        }

        value = typeof value === 'string' ? value : `${value}`;
        value = value.replace(/\s/g, '');

        valid = value.match(/^(([0]?[1-9])|(1[0-2]))$/i);

        if (!valid) {
            return i18n.t('validator.month', { field: label });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default MonthValidator;
