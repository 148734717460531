import has from 'lodash/has';
import isObject from 'lodash/isObject';

const isPublic = route => {
    if (isObject(route) && has(route, 'meta.public')) {
        return route.meta.public === true;
    }

    return false;
};

export default isPublic;
