import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';
import AdviserApi from '@/api/AdviserApi';

const loadAccount = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        if (!Auth().is_investor) {
            Auth().account = null;
            Auth().investor = null;
            next();
        } else {
            const account = Auth().account;
            const hostAccounts = Auth().getHostAccounts();

            if (Auth().accounts.length === 1) {
                Auth().account = Auth().accounts[0];
            } else if (hostAccounts.length === 1) {
                Auth().account = hostAccounts[0];
            } else if (account && hostAccounts.length > 0) {
                Auth().account = hostAccounts.find(acc => acc.adviser_id === account.adviser_id);
            } else if (account) {
                if (!Auth().accounts.find(acc => acc.adviser_id === account.adviser_id)) {
                    Auth().account = null;
                }
            }

            if (Auth().account && Auth().account.adviser_id) {
                let adviser = Auth().adviser;

                try {
                    adviser = (await new AdviserApi().methods.get(Auth().account.adviser_id)).data;
                } catch (error) {
                    //
                }

                Auth().adviser = adviser;
            }

            next();
        }
    }
};

export default loadAccount;
