import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class BetweenValidator extends BaseValidator {
    static key = 'between';

    static validator(value, label, start = null, end = null) {
        if (value === null) {
            return true;
        }

        const isCharacterCount = typeof value === 'string';
        const isItemCount = Array.isArray(value);

        if (value && typeof value === 'object' && 'amount' in value) {
            value = parseInt(value.amount);
        } else if (value && (isCharacterCount || isItemCount)) {
            value = value.length;
        } else {
            value = parseInt(value);
        }

        if (value < start || value > end) {
            return i18n.t('validator.between', { field: label, start, end });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default BetweenValidator;
