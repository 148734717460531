export default {
    account: 'far fa-wallet',
    accountant: 'far fa-calculator-alt',
    accounts: 'far fa-wallet',
    activity: 'far fa-calendar-alt',
    add: 'fas fa-plus-square',
    advisers: 'far fa-user-headset',
    agreement: 'far fa-signature',
    alert: 'far fa-bell-on',
    apply: 'far fa-paint-brush',
    array: 'far fa-stream',
    arrowDown: 'far fa-long-arrow-down',
    arrowUp: 'far fa-long-arrow-up',
    back: 'far fa-angle-left',
    backward: 'far fa-step-backward',
    badge: 'far fa-badge',
    badgeCheck: 'far fa-badge-check',
    balanced: 'far fa-scale-balanced',
    batteryBolt: 'far fa-battery-bolt',
    batteryEmpty: 'far fa-battery-empty',
    batteryFull: 'far fa-battery-full',
    batteryHalf: 'far fa-battery-half',
    blockquote: 'far fa-quote-right',
    bold: 'far fa-bold',
    boolean: 'far fa-toggle-off',
    branding: 'far fa-paint-roller',
    build: 'far fa-tools',
    bulk: 'far fa-pen-field',
    bulletlist: 'far fa-list-ul',
    calculate: 'fas fa-calculator',
    cancel: 'far fa-times-circle',
    cards: 'far fa-th',
    chart: 'far fa-chart-mixed',
    checkbox: 'far fa-check-square',
    checkboxIndeterminate: 'far fa-minus-square',
    checkboxOff: 'far fa-square',
    checkboxOn: 'fas fa-check-square',
    circle: 'far fa-circle',
    clear: 'far fa-times-circle',
    clock: 'far fa-clock',
    close: 'far fa-times',
    code: 'far fa-code',
    collapse: 'far fa-chevron-double-up',
    columns: 'far fa-columns',
    company: 'far fa-city',
    complete: 'far fa-check',
    contact: 'far fa-map-marked-alt',
    copy: 'far fa-copy',
    custodian: 'far fa-hand-holding-box',
    custodians: 'far fa-hand-holding-box',
    dataEntry: 'far fa-typewriter',
    date: 'far fa-calendar-day',
    datetime: 'far fa-clock',
    day: 'far fa-calendar-day',
    debug: 'far fa-debug',
    declaration: 'far fa-signature',
    delete: 'far fa-trash-alt',
    delimiter: 'far fa-circle',
    detail: 'far fa-album-collection',
    dividend: 'far fa-receipt',
    documents: 'far fa-folders',
    dotCircle: 'far fa-dot-circle',
    down: 'far fa-arrow-down',
    download: 'far fa-download',
    dri: 'far fa-recycle',
    dropdown: 'far fa-caret-down',
    dynamicValue: 'far fa-atom',
    edit: 'far fa-edit',
    empty: 'far fa-empty-set',
    ended: 'far fa-hourglass-end',
    enum: 'far fa-list',
    error: 'fas fa-exclamation-circle',
    estate: 'far fa-house-user',
    expand: 'far fa-angle-right',
    expansionPanel: 'far fa-chevron-double-down',
    fcaIdentity: 'far fa-id-card',
    fee: 'far fa-coins',
    file: 'far fa-file',
    fileArchive: 'far fa-file-archive',
    fileAudio: 'far fa-file-audio',
    fileCsv: 'far fa-file-csv',
    fileDocument: 'far fa-file-alt',
    fileExcel: 'far fa-file-excel',
    fileImage: 'far fa-file-image',
    filePdf: 'far fa-file-pdf',
    fileVideo: 'far fa-file-video',
    fileWord: 'far fa-file-word',
    filter: 'far fa-filter',
    finish: 'fad fa-flag-checkered',
    first: 'far fa-step-backward',
    folder: 'far fa-folder',
    folders: 'far fa-folders',
    form: 'far fa-list-alt',
    forward: 'far fa-step-forward',
    fund: 'far fa-shopping-bag',
    fundManager: 'far fa-users-crown',
    fundManagers: 'far fa-users-crown',
    gavel: 'fas fa-gavel',
    h1: 'far fa-h1',
    h2: 'far fa-h2',
    h3: 'far fa-h3',
    h4: 'far fa-h4',
    handshake: 'far fa-handshake',
    hide: 'far fa-eye-slash',
    history: 'far fa-history',
    holding: 'far fa-file-certificate',
    home: 'far fa-home',
    image: 'far fa-image',
    images: 'far fa-images',
    import: 'far fa-upload',
    infected: 'fad fa-bug',
    info: 'fas fa-info-circle',
    input: 'far fa-keyboard',
    invest: 'far fa-seedling',
    investments: 'far fa-chart-bar',
    investors: 'far fa-users-class',
    italic: 'far fa-italic',
    kanban: 'far fa-columns',
    lacuna: 'fal fa-exclamation-triangle',
    last: 'far fa-step-forward',
    layerGroup: 'far fa-layer-group',
    left: 'far fa-arrow-left',
    legal: 'far fa-gavel',
    link: 'far fa-link',
    load: 'far fa-arrow-alt-circle-right',
    loading: 'far fa-sync',
    lock: 'fas fa-lock-alt',
    logout: 'far fa-sign-out',
    longLeft: 'far fa-long-arrow-left',
    longRight: 'far fa-long-arrow-right',
    magic: 'fas fa-magic',
    marketing: 'far fa-photo-video',
    media: 'far fa-photo-video',
    menu: 'far fa-bars',
    minus: 'far fa-minus',
    money: 'far fa-coins',
    more: 'far fa-angle-double-right',
    moreDown: 'far fa-chevron-double-down',
    news: 'far fa-newspaper',
    next: 'far fa-angle-right',
    nominee: 'far fa-user-crown',
    nominees: 'far fa-user-crown',
    note: 'far fa-pen-nib',
    notification: 'far fa-bell',
    number: 'far fa-tally',
    offers: 'far fa-tags',
    orderedlist: 'far fa-list-ol',
    panels: 'far fa-ballot-check',
    paragraph: 'far fa-paragraph',
    pdf: 'far fa-file-pdf',
    percentage: 'far fa-percentage',
    platform: 'fas fa-browser',
    play: 'fas fa-play-circle',
    plus: 'far fa-plus',
    portfolio: 'far fa-chart-pie',
    prev: 'far fa-angle-left',
    product: 'far fa-box-open',
    products: 'far fa-box-open',
    progress: 'far fa-tasks',
    proposals: 'far fa-hand-holding-seedling',
    propose: 'far fa-hand-holding-seedling',
    provider: 'far fa-person-dolly',
    question: 'far fa-question-circle',
    radiolist: 'far fa-dot-circle',
    radioOff: 'far fa-circle',
    radioOn: 'fas fa-dot-circle',
    ratingEmpty: 'far fa-star',
    ratingFull: 'fas fa-star',
    ratingHalf: 'fas fa-star-half',
    receivingAgent: 'far fa-user-check',
    redo: 'far fa-redo',
    registrar: 'far fa-user-shield',
    registration: 'far fa-handshake',
    rejected: 'far fa-vote-nay',
    remoteApplication: 'far fa-laptop-house',
    remove: 'far fa-minus-square',
    removeFormat: 'far fa-remove-format',
    removeRow: 'far fa-layer-minus',
    replyAll: 'far fa-reply-all',
    reports: 'far fa-file-chart-line',
    right: 'far fa-arrow-right',
    save: 'fal fa-save',
    search: 'far fa-search',
    send: 'far fa-paper-plane',
    settings: 'far fa-cogs',
    shapes: 'far fa-shapes',
    shareAll: 'far fa-share-all',
    shares: 'fad fa-ball-pile',
    shield: 'fad fa-shield-alt',
    shortlists: 'far fa-star',
    show: 'far fa-eye',
    soon: 'far fa-hourglass-half',
    sort: 'far fa-angle-up',
    spreadsheet: 'far fa-file-spreadsheet',
    started: 'far fa-hourglass',
    strike: 'far fa-strikethrough',
    string: 'far fa-quote-right',
    subgroup: 'far fa-angle-down',
    submit: 'far fa-arrow-right',
    success: 'fas fa-check-circle',
    superscript: 'far fa-superscript',
    swap: 'fas fa-random',
    sync: 'far fa-sync',
    system: 'far fa-gear-complex',
    table: 'far fa-list',
    target: 'far fa-bullseye-pointer',
    tasklist: 'far fa-clipboard-list-check',
    tax: 'far fa-briefcase',
    terms: 'far fa-file-signature',
    textSize: 'far fa-text-size',
    times: 'fas fa-times-circle',
    todo: 'fad fa-circle',
    todoSuccess: 'fad fa-check-circle',
    toggleOff: 'far fa-toggle-off',
    toggleOn: 'far fa-toggle-on',
    tranche: 'far fa-luggage-cart',
    tranches: 'far fa-luggage-cart',
    transaction: 'far fa-exchange',
    transactions: 'far fa-exchange',
    unbalanced: 'far fa-scale-unbalanced',
    undo: 'far fa-undo',
    unfold: 'far fa-arrows-alt-v',
    unreconciled: 'fad fa-business-time',
    up: 'far fa-arrow-up',
    user: 'far fa-user',
    users: 'far fa-users',
    warning: 'fas fa-exclamation-triangle',
    website: 'far fa-globe',
    wysiwyg: 'far fa-edit'
};
