import i18n from '@/lib/i18n';

const FilterOperator = Object.freeze({
    CONTAINS: {
        text: i18n.t('contains'),
        value: 'contains',
        requiresCriteria: true
    },
    NOT_CONTAINS: {
        text: i18n.t('does_not_contain'),
        value: 'not_contains',
        requiresCriteria: true
    },
    STARTS_WITH: {
        text: i18n.t('starts_with'),
        value: 'starts_with',
        requiresCriteria: true
    },
    ENDS_WITH: {
        text: i18n.t('ends_with'),
        value: 'ends_with',
        requiresCriteria: true
    },
    IS: {
        text: i18n.t('is'),
        value: 'eq',
        requiresCriteria: true
    },
    IS_NOT: {
        text: i18n.t('is_not'),
        value: 'neq',
        requiresCriteria: true
    },
    EMPTY: {
        text: i18n.t('is_empty'),
        value: 'is_empty',
        requiresCriteria: false
    },
    NOT_EMPTY: {
        text: i18n.t('is_not_empty'),
        value: 'is_not_empty',
        requiresCriteria: false
    },
    LESS_THAN: {
        text: i18n.t('is_less_than'),
        value: 'lt',
        requiresCriteria: true
    },
    LESS_THAN_OR_IS: {
        text: i18n.t('is_less_than_or_is'),
        value: 'lte',
        requiresCriteria: true
    },
    MORE_THAN: {
        text: i18n.t('is_more_than'),
        value: 'gt',
        requiresCriteria: true
    },
    MORE_THAN_OR_IS: {
        text: i18n.t('is_more_than_or_is'),
        value: 'gte',
        requiresCriteria: true
    },
    IS_BEFORE: {
        text: i18n.t('is_before'),
        value: 'is_before',
        requiresCriteria: true
    },
    IS_AFTER: {
        text: i18n.t('is_after'),
        value: 'is_after',
        requiresCriteria: true
    },
    IN: {
        text: i18n.t('in'),
        value: 'in',
        requiresCriteria: true
    },
    NOT_IN: {
        text: i18n.t('not_in'),
        value: 'not_in',
        requiresCriteria: true
    }
});

export default FilterOperator;
