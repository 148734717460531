import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import { PhoneNumber } from '@/models';

export class PhoneNumbersValidator extends BaseValidator {
    static key = 'phoneNumbers';

    static validator(value) {
        if (!value) {
            return true;
        }

        if (!Array.isArray(value)) {
            return 'Phone numbers must be a valid array';
        }

        const results = [];

        const requiredFields = ['number', 'country', 'number_type'];

        for (const baIndex in value) {
            const ba = new PhoneNumber(value[`${baIndex}`]);
            const field = `phone_numbers[${baIndex}]`;

            for (const attr of requiredFields) {
                if (!ba[`${attr}`]) {
                    results.push({
                        field: `${field}.${attr}`,
                        result: i18n.t('validator.required_field', attr)
                    });
                }
            }
        }

        return results;
    }

    static arguments() {
        return [];
    }
}

export default PhoneNumbersValidator;
