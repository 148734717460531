import { BaseModel, Holding, User } from '@/models';
import ValuationTypeEnum from '@/enums/valuation/type';
import ValuationSourceEnum from '@/enums/valuation/source';
import UpdateFrequencyEnum from '@/enums/updateFrequency';
import DispositionResultEnum from '@/enums/holding/dispositionResult';
import toCurrency from '@/lib/helpers/toCurrency';
import toDatetime from '@/lib/helpers/toDatetime';
import i18n from '@/lib/i18n';

export class Valuation extends BaseModel {
    static entity = 'valuations';

    static fields() {
        return {
            ...super.fields(),
            type: this.enum(ValuationTypeEnum).nullable(), // r
            source: this.enum(ValuationSourceEnum).nullable(), // r
            description: this.string(null).nullable(),
            external_ref: this.string(null).nullable(),
            share_price: this.attr(null).nullable(), // r
            est_nav: this.attr(null).nullable(), // r
            last_nav_on: this.string(null).nullable(), // r
            last_nav_entry_on: this.string(null).nullable(),
            last_nav_share_price_entry_on: this.string(null).nullable(),
            nav_frequency: this.enum(UpdateFrequencyEnum).nullable(), // r
            valuation_on: this.string(null).nullable(), // r
            discount_to_nav: this.number(null).nullable(),
            disposition: this.enum(DispositionResultEnum).nullable(), // r
            //
            created_at: this.string(null).nullable(), // r
            created_by_id: this.string(null).nullable(),
            created_by: this.belongsTo(User, 'created_by_id'),
            //
            authorised_on: this.string(null).nullable(), // r
            authorised_by_id: this.string(null).nullable(),
            authorised_by: this.belongsTo(User, 'authorised_by_id'),
            //
            holding_id: this.string(null).nullable(),
            holding: this.belongsTo(Holding, 'holding_id')
        };
    }

    get is_valid() {
        return [
            this.type,
            this.source,
            this.share_price,
            this.est_nav,
            this.last_nav_on,
            this.nav_frequency,
            this.valuation_on,
            this.disposition,
            this.created_at,
            this.authorised_on
        ].every(v => v);
    }

    get computed_name() {
        if (this.is_valid) {
            return [toCurrency(this.share_price.amount, this.share_price.currency), toDatetime(this.valuation_on)].join(
                ' - '
            );
        }
        return i18n.t('valuation');
    }
}

export default Valuation;
