import { Collection, Adviser } from '@/models';
import i18n from '@/lib/i18n';
import UserStatusEnum from '@/enums/user/status';

export class AdviserCollection extends Collection {
    static type = 'AdviserCollection';
    static endpoint = Adviser.api.index;
    static model = Adviser;

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: UserStatusEnum,
                enumA11yKey: 'enums.user_status',
                enumTypes: {
                    [UserStatusEnum.ACTIVE]: 'success',
                    [UserStatusEnum.INACTIVE]: 'grey',
                    [UserStatusEnum.SUSPENDED]: 'error',
                    [UserStatusEnum.UNVERIFIED]: 'warning'
                }
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('fca_number', i18n.t('fca_number'), {
                width: '12rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default AdviserCollection;
