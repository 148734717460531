import BaseApi from '@/api/BaseApi';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import UuidValidator from '@/lib/validators/UuidValidator';

export class InvestorRegistrationApi extends BaseApi {
    static resource = 'investor-registrations';

    get endpoints() {
        return {
            get: this.get.bind(this),
            index: this.index.bind(this),
            search: this.search.bind(this)
        };
    }

    get mock_endpoints() {
        return {
            get: this.get_mock.bind(this),
            index: this.index_mock.bind(this),
            search: this.search_mock.bind(this)
        };
    }

    async get(id) {
        const [investorId, registrationId] = id.split('.');
        const filters = {};

        if (UuidValidator.validator(investorId) === true) {
            filters.investor_id = getFilterOperator('is') + ':' + investorId;
        }

        if (UuidValidator.validator(registrationId) === true) {
            filters.registration_id = getFilterOperator('is') + ':' + registrationId;
        }

        if (!Object.keys(filters).length) {
            throw new ReferenceError(
                'Missing ID required while attempting to send GET request for investor-registrations'
            );
        }

        const response = await BaseApi.api.get(this.base_url, filters);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterGetResponseData(response.data);
            response.data = response.data[0];

            this._clearCollection();
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }
    _filterGetResponseData(data) {
        const investorId = data.investor_id || data.investor?.id || 'InvestorUUID';
        const registrationId = data.registration_id || data.registration?.id || 'RegistrationUUID';

        data.id = `${investorId}.${registrationId}`;
        data.investor_id = data.investor?.id || null;
        data.registration_id = data.registration?.id || null;

        return data;
    }

    _filterIndexResponseData(data) {
        return data.map(item => this._filterGetResponseData(item));
    }
}

export default InvestorRegistrationApi;
