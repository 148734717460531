import { BaseModel } from '@/models';

export class IhtSector extends BaseModel {
    static entity = 'ihtSectors';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string('')
        };
    }
}

export default IhtSector;
