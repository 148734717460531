const MediaInvestorTypeEnum = Object.freeze({
    APPLICATION_FORM: 'APPLICATION_FORM',
    CAPITAL_CALL_NOTICE: 'CAPITAL_CALL_NOTICE',
    CATEGORISATION_CERTIFICATE: 'CATEGORISATION_CERTIFICATE',
    COMMUNICATION: 'COMMUNICATION',
    CONTRACTUAL: 'CONTRACTUAL',
    DISTRIBUTION_NOTICE: 'DISTRIBUTION_NOTICE',
    EIS_CERTIFICATE: 'EIS_CERTIFICATE',
    EXIT_LETTER: 'EXIT_LETTER',
    FEE_ILLUSTRATION: 'FEE_ILLUSTRATION',
    FINANCIAL: 'FINANCIAL',
    IDENTITY: 'IDENTITY',
    LEGAL: 'LEGAL',
    LOSS_RELIEF_LETTER: 'LOSS_RELIEF_LETTER',
    OTHER: 'OTHER',
    PLATFORM_APPLICATION: 'PLATFORM_APPLICATION',
    SEIS_CERTIFICATE: 'SEIS_CERTIFICATE',
    SHARE_CERTIFICATE: 'SHARE_CERTIFICATE',
    TAX_CERTIFICATE: 'TAX_CERTIFICATE',
    TAX_REPORT: 'TAX_REPORT',
    VALUATION: 'VALUATION'
});

export default MediaInvestorTypeEnum;
