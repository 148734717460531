import { Organisation } from '@/models';
import NomineeApi from '@/api/NomineeApi';

export class Nominee extends Organisation {
    static entity = 'nominees';
    static Api = NomineeApi;

    static fields() {
        return {
            ...super.fields()
        };
    }
}

export default Nominee;
