import router from '@/router';

const pageErrorRedirect = (title = null, message = null, next = null) => {
    let nextRoute = { name: 'PageError', params: {} };

    if (title) {
        nextRoute.params.title = title;
    }

    if (message) {
        nextRoute.params.message = message;
    }

    if (next && typeof next === 'function') {
        return next(nextRoute);
    }

    return router.push(nextRoute);
};

export default pageErrorRedirect;
