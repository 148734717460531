import { Collection, Holding } from '@/models';
import i18n from '@/lib/i18n';
import store from '@/store';
import taxStatusEnum from '@/enums/taxStatus';

export class HoldingCollection extends Collection {
    static type = 'HoldingCollection';
    static endpoint = Holding.api.index;
    static model = Holding;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable(),
            status: this.string(null).nullable()
        };
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '7rem'
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem'
            })
        ];
    }

    get default_headers() {
        return ['name', 'tax_status'];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.show_consolidated_view) {
            filters.consolidated = true;
        }

        if (this.status) {
            filters.status = this.filterOperator('is') + ':OPEN';
        }

        return filters;
    }

    get money_format_options() {
        return {
            decimals: 2,
            abbreviate: false
        };
    }

    get percentage_format_options() {
        return {
            abbreviate: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
    }

    get show_nav_totals() {
        return store.getters['preferences/show_nav_totals'];
    }

    get show_consolidated_view() {
        return store.getters['preferences/show_consolidated_view'];
    }
}

export default HoldingCollection;
