import { ActivityCollection, Activity } from '@/models';
import i18n from '@/lib/i18n';

export class ActivityAdviserDocumentsCollection extends ActivityCollection {
    static type = 'ActivityAdviserDocumentsCollection';
    static endpoint = Activity.api.adviserLibrary;

    get default_headers() {
        return ['created_at', 'activity', 'library_entry', 'created_by_id', 'created_by_email'];
    }

    get pdf_headers() {
        return ['created_at', 'title', 'text', 'library_entry', 'created_by_id', 'created_by_email'];
    }

    get headers() {
        return [
            this.createHeader('created_at', i18n.t('date'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('activity', i18n.t('activity'), {
                width: '35rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('title', i18n.t('activity'), {
                width: '12rem',
                filterable: true
            }),
            this.createHeader('text', i18n.t('text'), {
                width: '38rem',
                filterable: true
            }),
            this.createHeader('library_entry', i18n.t('library_entry'), {
                width: '14rem',
                classList: ['no-wrap'],
                transform: data => data.item?.file?.media[0]?.name,
                to: data => {
                    const mediaId = data.item?.file?.media[0]?.id;

                    if (!mediaId) return null;

                    return {
                        name: 'Library',
                        params: { library: 'investor' },
                        query: { media: mediaId }
                    };
                }
            }),
            this.createHeader('created_by_id', i18n.t('created_by'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.created_by?.name,
                fallback: i18n.t('system')
            }),
            this.createHeader('created_by_email', i18n.t('created_by') + ' ' + i18n.t('email'), {
                width: '14rem',
                transform: data => data.item?.created_by?.email,
                classList: ['text--secondary', 'text--small'],
                filterable: true
            })
        ];
    }
}

export default ActivityAdviserDocumentsCollection;
