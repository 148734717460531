import Auth from '@/models/Auth';
import config from '@/config';

const setFreshdeskScope = async (to, from, next) => {
    const auth = Auth();

    if (auth.id && config.FRESHDESK_WIDGET_ID) {
        window.FreshworksWidget('identify', 'ticketForm', {
            name: auth.user_name,
            email: auth.user_email
        });

        window.FreshworksWidget('prefill', 'ticketForm', {
            custom_fields: {
                gi_ref: auth.gi_ref,
                organisation_id: auth.organisation_id,
                organisation_type: auth.organisation_type,
                organisation_roles: auth.organisation_roles,
                is_gi: auth.is_gi,
                url: to.fullPath
            }
        });

        window.FreshworksWidget('show');
    }

    next();
};

export default setFreshdeskScope;
