import BaseApi from '@/api/BaseApi';

export class CompanyApi extends BaseApi {
    static resource = 'companies';

    get endpoints() {
        return {
            ...super.endpoints,
            getNews: this.getNews.bind(this),
            getNewsArticle: this.getNewsArticle.bind(this),
            postNewsArticle: this.postNewsArticle.bind(this),
            putNewsArticle: this.putNewsArticle.bind(this),
            deleteNewsArticle: this.deleteNewsArticle.bind(this)
        };
    }

    _filterGetResponseData(data) {
        if (data && Array.isArray(data.news_items)) {
            data.news_items = data.news_items.map(item => {
                item.company_id = data.id;
                return item;
            });
        }

        if (data && Array.isArray(data.products)) {
            data.products = data.products.map(product => {
                product.company_id = data.id;
                return product;
            });
        }

        return data;
    }

    async getNews(id) {
        const response = await this.index(null, null, null, {}, { base_url: `${this.base_url}/${id}/news` });

        return this._respond(response, () => {
            response.data = response.data.map(article => ({ ...article, company_id: id }));
            response.data = this._filterResponseData(response.data);
        });
    }

    async getNewsArticle(id, articleId) {
        const response = await BaseApi.api.get(`${this.base_url}/${id}/news/${articleId}`);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('CompanyNews');
        });
    }

    async postNewsArticle(data = {}) {
        const response = await BaseApi.api.post(`${this.base_url}/${data.company_id}/news`, data);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('CompanyNews');
        });
    }

    async putNewsArticle(data = {}) {
        const response = await BaseApi.api.put(`${this.base_url}/${data.company_id}/news/${data.id}`, data);
        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('CompanyNews');
        });
    }

    async deleteNewsArticle(id, articleId) {
        const response = await BaseApi.api.remove(`${this.base_url}/${id}/news/${articleId}`);
        return this._respond(response, () => {
            this._clearCollection('CompanyNews');
        });
    }
}

export default CompanyApi;
