import { BaseModel, PhoneNumber, Address } from '@/models';
import i18n from '@/lib/i18n';

export class EstateContact extends BaseModel {
    static entity = 'estateContact';

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            title: this.string(null).nullable(),
            name: this.string(null).nullable(),
            firm_name: this.string(null).nullable(),
            email: this.string(null).nullable(),
            phone_number: this.model(PhoneNumber).nullable(),
            address: this.model(Address).nullable(),
            relation: this.string(null).nullable()
        };
    }

    get is_valid() {
        return [this.name, this.email, this.phone_number, this.address, this.relation].every(v => v);
    }

    get computed_name() {
        if (this.is_valid) {
            return [(this.title ? this.title + ' ' : '') + this.name, this.relation].join(' - ');
        }
        return i18n.t('estate_contact');
    }
}

export default EstateContact;
