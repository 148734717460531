import { BaseModel, Organisation, VisibilityGroup } from '@/models';
import i18n from '@/lib/i18n';
import OrganisationTypeEnum from '@/enums/organisation/type';

import AdviserRoleEnum from '@/enums/adviser/role';
import InvestorRoleEnum from '@/enums/investor/role';
// import BrokerRoleEnum from '@/enums/broker/role';
import CompanyRoleEnum from '@/enums/company/role';
// import CustodianRoleEnum from '@/enums/custodian/role';
import FundManagerRoleEnum from '@/enums/fundManager/role';
// import InvesteeRoleEnum from '@/enums/investee/role';
// import NomineeRoleEnum from '@/enums/nominee/role';
import ProductProviderRoleEnum from '@/enums/productProvider/role';
// import ReceivingAgentRoleEnum from '@/enums/receivingAgent/role';
// import RegistrarRoleEnum from '@/enums/registrar/role';
import GrowthInvestRoleEnum from '@/enums/growthInvest/role';

export class UserRole extends BaseModel {
    static entity = 'userRole';
    static runSetup = true;

    static fields() {
        return {
            ...super.fields(),
            roles: this.attr([]).nullable(),
            type: this.enum(OrganisationTypeEnum).nullable(),
            organisation_id: this.string(null).nullable(),
            organisation: this.belongsTo(Organisation, 'organisation_id'),
            visibility_group_id: this.string(null).nullable(),
            visibility_group: this.belongsTo(VisibilityGroup, 'visibility_group_id')
        };
    }

    setupLogic() {
        const auth = this.Auth();

        if (auth.is_investor || auth.is_provider || auth.is_company) {
            if (!this.type) {
                this.type = auth.organisation_type;
            }

            if (!this.organisation_id) {
                this.organisation_id = auth.organisation_id;
                this.organisation = auth.organisation;
            }
        }
    }

    get is_valid() {
        return [this.organisation_id, this.roles].every(v => v && v.length);
    }

    get computed_name() {
        if (this.is_valid) {
            let name = [];

            if (this.organisation_type) {
                name.push(i18n.t(`enums.organisation_type.${this.organisation_type}`));
            }

            name.push(this.roles.map(r => i18n.t(`enums.user_role.${r}`)).join(', '));

            if (this.organisation) {
                name.push(this.organisation.name);
            }

            return name.join(' - ');
        }
        return i18n.t('user_role');
    }

    get organisation_type() {
        if (this.type && this.type in OrganisationTypeEnum) {
            return this.type;
        }

        if (this.organisation && this.organisation.type in OrganisationTypeEnum) {
            if (this.organisation.name === 'GrowthInvest') {
                return OrganisationTypeEnum.GROWTH_INVEST;
            }
            return this.organisation.type;
        }

        return null;
    }

    set organisation_type(value = null) {
        if (value !== this.type) {
            this.roles = [];
            this.organisation_id = null;
            this.organisation = null;
            //
            this.type = value;
        }
    }

    get all_roles() {
        return {
            ADVISER: Object.values(AdviserRoleEnum),
            INVESTOR: Object.values(InvestorRoleEnum),
            // BROKER: Object.values(BrokerRoleEnum),
            COMPANY: Object.values(CompanyRoleEnum),
            // CUSTODIAN: Object.values(CustodianRoleEnum),
            FUND_MANAGER: Object.values(FundManagerRoleEnum),
            // NOMINEE: Object.values(NomineeRoleEnum),
            PRODUCT_PROVIDER: Object.values(ProductProviderRoleEnum),
            // RECEIVINGAGENT: Object.values(ReceivingAgentRoleEnum),
            // REGISTRAR: Object.values(RegistrarRoleEnum),
            GROWTH_INVEST: Object.values(GrowthInvestRoleEnum)
        };
    }

    get available_roles() {
        let items = [];

        if (this.organisation_type && this.organisation_type in OrganisationTypeEnum) {
            for (const role of this.all_roles[`${this.organisation_type}`]) {
                items.push({
                    text: i18n.t(`enums.user_role.${role}`),
                    value: role,
                    disabled: false
                });
            }
        }

        return items;
    }

    get can_change_organisation() {
        const auth = this.Auth();

        if (auth.is_provider || auth.is_company) {
            return false;
        }

        return !!this.organisation_type;
    }

    get can_change_roles() {
        return !!this.organisation_type;
    }

    get can_change_visibility_group() {
        return this.organisation_type === OrganisationTypeEnum.ADVISER && !!this.organisation_id;
    }
}

export default UserRole;
