const InvestorAccountStageEnum = Object.freeze({
    REGISTRATION: 'REGISTRATION',
    CATEGORISATION: 'CATEGORISATION',
    PROFILE: 'PROFILE',
    APPROPRIATENESS: 'APPROPRIATENESS',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    APPLICATION: 'APPLICATION',
    AUTHORISATION: 'AUTHORISATION'
});

export default InvestorAccountStageEnum;
