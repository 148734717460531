import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import { Address } from '@/models';

export class AddressesValidator extends BaseValidator {
    static key = 'addresses';

    static validator(value) {
        if (!value) {
            return true;
        }

        if (!Array.isArray(value)) {
            return 'Addresses must be a valid array';
        }

        const results = [];

        const requiredFields = ['line1', 'city', 'postcode', 'country'];

        for (const itemIndex in value) {
            const item =
                value[`${itemIndex}`] instanceof Address ? value[`${itemIndex}`] : new Address(value[`${itemIndex}`]);

            if (item.$deleted) {
                continue;
            }

            const field = `addresses[${itemIndex}]`;

            for (const attr of requiredFields) {
                if (!item[`${attr}`]) {
                    results.push({
                        field: `${field}.${attr}`,
                        result: i18n.t('validator.required_field', attr)
                    });
                }
            }

            if (!item.start_on) {
                results.push({
                    field: `${field}.start_on`,
                    result: i18n.t('validator.required_field', 'start_on')
                });
            }

            if (!item.default && !item.end_on) {
                results.push({
                    field: `${field}.end_on`,
                    result: i18n.t('validator.required_field', 'end_on')
                });
            }
        }

        return results;
    }

    static arguments() {
        return [];
    }
}

export default AddressesValidator;
