export function yearsAndMonths(start, end) {
    let months = end.diff(start, 'month');
    let years = end.diff(start, 'year');
    months = months % 12;
    let duration = [];
    // Dayjs has no easy way of doing y+ms in a friendly way
    // so I decided to just do it manually for now
    if (years === 1) {
        duration.push('1 year');
    } else if (years > 1) {
        duration.push(`${years} years`);
    }

    if (months === 1) {
        duration.push('1 month');
    } else if (months > 1) {
        duration.push(`${months} months`);
    }

    return duration.join(', ');
}
