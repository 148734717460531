import config from '@/config';

const download = (url, download = false) => {
    const link = document.createElement('a');

    if (!url.startsWith('data') && !url.startsWith('http')) {
        url = `${config.API_BASE_URL}${url}`;
    }

    link.setAttribute('href', url);

    if (download) {
        link.setAttribute('download', download);
    }

    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default download;
