import { BaseModel } from '@/models';
import BrokerApi from '@/api/BrokerApi';

export class Broker extends BaseModel {
    static entity = 'brokers';
    static Api = BrokerApi;

    static fields() {
        return {
            id: this.attr(null),
            name: this.string('')
        };
    }
}

export default Broker;
