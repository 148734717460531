import { Collection, RemoteApplication } from '@/models';
import i18n from '@/lib/i18n';
import RemoteApplicationStatusEnum from '@/enums/remoteApplication/status';

export class SystemRemoteApplicationsCollection extends Collection {
    static type = 'SystemRemoteApplicationsCollection';
    static endpoint = RemoteApplication.api.index;
    static model = RemoteApplication;

    get default_headers() {
        return [
            'adviser_id',
            'investor_id',
            'offer_id',
            'status',
            'most_recent_funds_cleared_at',
            'most_recent_blocks_allotted_at',
            'remote_id',
            'submitted',
            'created',
            'updated',
            'control'
        ];
    }

    get pdf_headers() {
        return [
            'id',
            'adviser_id',
            'investor_id',
            'offer_id',
            'status',
            'most_recent_funds_cleared_at',
            'most_recent_blocks_allotted_at',
            'remote_id',
            'submitted_at',
            'submitted_by_id',
            'created_at',
            'created_by_id',
            'last_updated',
            'last_updated_by_id'
        ];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                },
                active: false
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '5rem',
                persistent: true
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name,
                to: data => {
                    const adviserId = data.item?.adviser?.id;

                    if (!adviserId) return null;

                    return {
                        name: 'SettingsFirmInformation',
                        query: { organisation_id: adviserId, organisation_type: 'adviser' }
                    };
                }
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name,
                to: data => {
                    const investorId = data.item?.investor?.id;

                    if (!investorId) return null;

                    return {
                        name: 'SettingsProfile',
                        query: { organisation_id: investorId, organisation_type: 'investor' }
                    };
                }
            }),
            this.createHeader('offer_id', i18n.t('offer'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Offer'),
                    filters: {
                        data_template: 'neq:null',
                        closed: true,
                        off_panel: true
                    }
                },
                transform: data => data.item?.offer?.name,
                to: data => {
                    const offerId = data.item?.offer?.id;

                    if (!offerId) return null;

                    return {
                        name: 'Offer',
                        query: { offer_id: offerId }
                    };
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: RemoteApplicationStatusEnum,
                enumA11yKey: 'enums.remote_application_status',
                enumTypes: {
                    [RemoteApplicationStatusEnum.DRAFT]: 'grey',
                    [RemoteApplicationStatusEnum.SENT]: 'info',
                    [RemoteApplicationStatusEnum.ACCEPTED]: 'success',
                    [RemoteApplicationStatusEnum.REJECTED]: 'error'
                }
            }),
            this.createHeader('most_recent_funds_cleared_at', i18n.t('funds_cleared'), {
                format: 'date',
                filterable: true,
                sortable: true,
                width: '9rem'
            }),
            this.createHeader('most_recent_blocks_allotted_at', i18n.t('blocks_allotted'), {
                format: 'date',
                filterable: true,
                sortable: true,
                width: '9rem'
            }),
            this.createHeader('remote_id', i18n.t('remote_id'), {
                filterable: true,
                width: '11rem'
            }),
            this.createHeader('submitted', i18n.t('submitted_by'), {
                width: '12rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('submitted_at', i18n.t('submitted_at'), {
                width: '8rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('submitted_by_id', i18n.t('submitted_by'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.submitted_by?.name,
                fallback: i18n.t('system'),
                active: false
            }),
            this.createHeader('created', i18n.t('created_by'), {
                width: '12rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('created_by_id', i18n.t('created_by'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.created_by?.name,
                fallback: i18n.t('system'),
                active: false
            }),
            this.createHeader('updated', i18n.t('last_updated_by'), {
                width: '12rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('last_updated', i18n.t('last_updated_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('last_updated_by_id', i18n.t('last_updated_by'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.last_updated_by?.name,
                fallback: i18n.t('system'),
                active: false
            })
        ];
    }
}

export default SystemRemoteApplicationsCollection;
