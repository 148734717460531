import { Collection, Fees } from '@/models';
import i18n from '@/lib/i18n';

export class SystemFeesCollection extends Collection {
    static type = 'SystemFeesCollection';
    static endpoint = Fees.api.index;
    static model = Fees;

    get default_headers() {
        return [
            'name',
            'initial',
            'existing_investor_initial',
            'annual_management',
            'performance',
            'additional',
            'administration',
            'investee_initial',
            'investee_ongoing',
            'investee_additional',
            'control'
        ];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '18rem'
            }),
            this.createHeader('initial', i18n.t('initial_fee'), {
                filterable: false,
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('existing_investor_initial', i18n.t('existing_investor_initial_fee'), {
                filterable: false,
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('annual_management', i18n.t('annual_management_fee'), {
                filterable: false,
                format: 'percentage',
                width: '11rem'
            }),
            this.createHeader('performance', i18n.t('performance_fee'), {
                filterable: false,
                format: 'percentage',
                width: '8rem'
            }),
            this.createHeader('performance_hurdle', i18n.t('performance_hurdle'), {
                filterable: false,
                width: '12rem'
            }),
            this.createHeader('additional', i18n.t('additional_fee'), {
                filterable: false,
                format: 'percentage',
                width: '7rem'
            }),
            this.createHeader('administration', i18n.t('administration_fee'), {
                filterable: false,
                format: 'money',
                width: '9rem'
            }),
            this.createHeader('investee_initial', i18n.t('investee_initial_fee'), {
                filterable: false,
                format: 'percentage',
                width: '9rem'
            }),
            this.createHeader('investee_ongoing', i18n.t('investee_ongoing_fee'), {
                filterable: false,
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('investee_additional', i18n.t('investee_additional_fee'), {
                filterable: false,
                format: 'percentage',
                width: '10rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default SystemFeesCollection;
