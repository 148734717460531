import BaseApi from '@/api/BaseApi';

export class HoldingApi extends BaseApi {
    static resource = 'holdings';

    get endpoints() {
        return {
            ...super.endpoints,
            getStatistics: this.getStatistics.bind(this),
            byCompany: this.byCompany.bind(this),
            byProduct: this.byProduct.bind(this),
            byTranche: this.byTranche.bind(this),
            byPrivateMarketStructure: this.byPrivateMarketStructure.bind(this),
            investmentsByTranche: this.investmentsByTranche.bind(this),
            allot: this.allot.bind(this)
        };
    }

    async getStatistics(params = {}) {
        return await BaseApi.api.get(`${this.base_url}/statistics`, BaseApi.removeNull(params));
    }

    async byCompany(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        let extract = BaseApi.extractFilter(filters, 'investor_id');

        let url = `${this.base_url}/summaries/company`;

        if (extract.value) {
            url += `/${extract.value}`;
        }

        return this.index(offset, limit, sort_by, extract.filters, {
            ...config,
            base_url: url
        });
    }

    async byProduct(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        let extract = BaseApi.extractFilter(filters, 'investor_id');

        let url = `${this.base_url}/summaries/product`;

        if (extract.value) {
            url += `/${extract.value}`;
        }

        return this.index(offset, limit, sort_by, extract.filters, {
            ...config,
            base_url: url
        });
    }

    async byTranche(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        let investorExtract = BaseApi.extractFilter(filters, 'investor_id');
        let taxStatusExtract = BaseApi.extractFilter(investorExtract.filters, 'tax_status');

        let url = `${this.base_url}/summaries/tranche`;

        if (taxStatusExtract.value) {
            url += `/${taxStatusExtract.value}`;
        }

        if (investorExtract.value) {
            url += `/${investorExtract.value}`;
        }

        return this.index(offset, limit, sort_by, taxStatusExtract.filters, {
            ...config,
            base_url: url
        });
    }

    async byPrivateMarketStructure(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        let holdingExtract = BaseApi.extractFilter(filters, 'holding_id');
        let structureExtract = BaseApi.extractFilter(holdingExtract.filters, 'structure');

        let url = `${this.base_url}/private-market`;

        if (structureExtract.value) {
            url += `/${structureExtract.value.toLowerCase()}`;

            if (holdingExtract.value) {
                url += `/${holdingExtract.value}`;
            }
        }

        return this.index(offset, limit, sort_by, structureExtract.filters, {
            ...config,
            base_url: url
        });
    }

    async investmentsByTranche(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/summaries/tranche`
        });
    }

    async allot(id, allotment = {}) {
        let response = await BaseApi.api.post(`${this.base_url}/${id}/allot`, allotment);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }
}

export default HoldingApi;
