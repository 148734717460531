import { Media, Adviser } from '@/models';
import MediaTypeEnum from '@/enums/media/type';
import MediaFormatEnum from '@/enums/media/format';
import MediaAdviserTypeEnum from '@/enums/media/adviser/type';
import MediaAdviserApi from '@/api/MediaAdviserApi';

export class MediaAdviser extends Media {
    static entity = 'mediaAdviser';
    static Api = MediaAdviserApi;

    static fields() {
        return {
            ...super.fields(),
            type: this.enum(MediaAdviserTypeEnum, MediaAdviserTypeEnum.FEES),
            library_type: this.string(MediaTypeEnum.ADVISER),
            format: this.enum(MediaFormatEnum, MediaFormatEnum.DOC),
            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id')
        };
    }
}

export default MediaAdviser;
