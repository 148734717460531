const findNodeById = (value, id) => {
    for (const item of value.content) {
        if (item.attrs && item.attrs.id === id) {
            return item;
        }
        if (item.content) {
            const result = findNodeById(item, id);
            if (result) {
                return result;
            }
        }
    }
    return null;
};

export default findNodeById;
