<template>
    <v-container fluid class="app-container-height d-flex flex-column">
        <app-tabs v-if="!setupLoading" :items="tabs" class="mb-4" />
        <div class="app-container-wrapper">
            <router-view />
        </div>
    </v-container>
</template>

<script>
export const tabs = [
    'all',
    'single_company',
    'eis_seis',
    'vct',
    'iht',
    'pmi'
    // 'esg'
];

export default {
    name: 'Offers',
    components: {
        AppTabs: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppTabs'
            )
    },
    data: () => ({
        loading: false,
        tabs: []
    }),
    methods: {
        async setup() {
            try {
                this.tabs = tabs.map(item => ({
                    id: this._.snakeCase(item),
                    name: this.$t('enums.offers_tabs.' + this._.snakeCase(item)),
                    to: { name: 'Offers' + this._.upperFirst(this._.camelCase(item)) }
                }));
            } catch (error) {
                this.handleError(error, () => this.setup());
            }

            let breadcrumbs = [];

            if (this.$route.name !== 'Offers') {
                breadcrumbs.push({
                    heading: this.$t('offers'),
                    to: this.$route.name === 'OffersAll' ? null : { name: 'Offers' }
                });
            }

            breadcrumbs.push({
                heading: this.$route.meta.heading
            });

            this.setBreadcrumbs(breadcrumbs);
        }
    }
};
</script>
