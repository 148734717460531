const ProposalStatusEnum = Object.freeze({
    ABORTED: 'ABORTED',
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_ALLOTMENT: 'PENDING_ALLOTMENT',
    PROPOSED: 'PROPOSED',
    REJECTED: 'REJECTED',
    SCHEDULED: 'SCHEDULED',
    SHORTLISTED: 'SHORTLISTED'
});

export default ProposalStatusEnum;
