import BaseApi from '@/api/BaseApi';

export class ActivityApi extends BaseApi {
    static resource = 'activity';

    get endpoints() {
        return {
            ...super.endpoints,
            adviserSecurity: this.adviserSecurity.bind(this),
            adviserMarketing: this.adviserMarketing.bind(this),
            adviserLibrary: this.adviserLibrary.bind(this),
            adviserFlow: this.adviserFlow.bind(this),
            investorSecurity: this.investorSecurity.bind(this),
            investorMarketing: this.investorMarketing.bind(this),
            investorLibrary: this.investorLibrary.bind(this),
            investorFlow: this.investorFlow.bind(this)
        };
    }

    _toSearchFilters(criteria, filters = {}) {
        return BaseApi.toSearchFilters(criteria, filters, 'title');
    }

    async adviserSecurity(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/adviser/security`
        });
    }

    async adviserMarketing(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/adviser/marketing`
        });
    }

    async adviserLibrary(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/adviser/library`
        });
    }

    async adviserFlow(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/adviser/flow`
        });
    }

    async investorSecurity(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/investor/security`
        });
    }

    async investorMarketing(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/investor/marketing`
        });
    }

    async investorLibrary(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/investor/library`
        });
    }

    async investorFlow(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/reports/investor/flow`
        });
    }
}

export default ActivityApi;
