import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';

export class FileValidator extends BaseValidator {
    static key = 'file';

    static validator(value, label = 'File', accept = []) {
        if (!value) {
            return true;
        }

        if (typeof accept === 'string') {
            accept = accept.split(',').map(ext => ext.trim().toLowerCase());
        }

        if (Array.isArray(value)) {
            value.forEach(v => {
                if (!FileValidator.fileIsValid(v, accept)) {
                    return i18n.t('validator.file', { field: label, type: accept.join(', ') });
                }
            });
        } else {
            if (!FileValidator.fileIsValid(value, accept)) {
                return i18n.t('validator.file', { field: label, type: accept.join(', ') });
            }
        }

        return true;
    }

    static arguments() {
        return [];
    }

    static fileIsValid(value, accept = []) {
        if (!(value instanceof File)) {
            return false;
        }

        if (accept.length > 0 && !accept.includes(value.type.split('/').pop().toLowerCase())) {
            return false;
        }

        return true;
    }
}

export default FileValidator;
