import { BaseModel, Registration, Investor } from '@/models';
import InvestorRegistrationStatusEnum from '@/enums/investor/registrationStatus';
import RegistrationStatusEnum from '@/enums/registration/status';
import ProfileStatusEnum from '@/enums/profile/status';
import ApplicationStatusEnum from '@/enums/application/status';
import i18n from '@/lib/i18n';
import InvestorApi from '@/api/InvestorApi';
import RegistrationApi from '@/api/RegistrationApi';
import InvestorRegistrationApi from '@/api/InvestorRegistrationApi';

export class InvestorRegistration extends BaseModel {
    static entity = 'investorRegistrations';
    static Api = InvestorRegistrationApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            status: this.enum(InvestorRegistrationStatusEnum, null).nullable(),
            registration_status: this.enum(RegistrationStatusEnum, RegistrationStatusEnum.PENDING).nullable(),
            profile_status: this.enum(ProfileStatusEnum, ProfileStatusEnum.PENDING).nullable(),
            application_status: this.enum(
                ApplicationStatusEnum,
                ApplicationStatusEnum.AWAITING_ADVISER_SIGNATURE
            ).nullable(),

            registration_id: this.string(null).nullable(),
            registration: this.belongsTo(Registration, 'registration_id'),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id')
        };
    }

    static mock() {
        return {
            investor: InvestorApi,
            investor_id: (faker, item) => item.investor.id,

            registration: RegistrationApi,
            registration_id: (faker, item) => item.registration.id,

            id: (faker, item) => `${item.investor.id}.${item.registration.id}`,
            status: faker => faker.helpers.arrayElement(Object.keys(InvestorRegistrationStatusEnum)),
            registration_status: faker => faker.helpers.arrayElement(Object.keys(RegistrationStatusEnum)),
            profile_status: faker => faker.helpers.arrayElement(Object.keys(ProfileStatusEnum)),
            application_status: faker => faker.helpers.arrayElement(Object.keys(ApplicationStatusEnum))
        };
    }

    get name() {
        return this.investor?.name || this.registration?.name || null;
    }

    get email() {
        return this.investor?.email || this.registration?.email || null;
    }

    get last_updated() {
        return this.investor?.last_updated || this.registration?.last_updated || null;
    }

    get is_registering() {
        return this.status === InvestorRegistrationStatusEnum.REGISTERING;
    }

    get is_onboarding() {
        return this.status === InvestorRegistrationStatusEnum.ONBOARDING;
    }

    get is_applying() {
        return this.status === InvestorRegistrationStatusEnum.APPLYING;
    }

    get is_complete() {
        return this.status === InvestorRegistrationStatusEnum.COMPLETE;
    }

    // Registration Status

    get registration_status_icon() {
        const icons = {
            [RegistrationStatusEnum.PENDING]: 'soon',
            [RegistrationStatusEnum.FAILED_CLASSIFICATION]: 'error',
            [RegistrationStatusEnum.COMPLETE]: 'complete'
        };

        return this.registration_status in icons ? icons[`${this.registration_status}`] : null;
    }

    get registration_status_text() {
        return this.registration_status ? i18n.t(`enums.registration_status.${this.registration_status}`) : null;
    }

    get registration_status_icon_colour() {
        const colours = {
            [RegistrationStatusEnum.PENDING]: 'info',
            [RegistrationStatusEnum.FAILED_CLASSIFICATION]: 'error',
            [RegistrationStatusEnum.COMPLETE]: 'success'
        };

        return this.registration_status in colours ? colours[`${this.registration_status}`] : 'grey';
    }

    get registration_status_text_colour() {
        const colours = {
            [RegistrationStatusEnum.PENDING]: 'info--text text--darken-1',
            [RegistrationStatusEnum.FAILED_CLASSIFICATION]: 'error--text text-darken-1',
            [RegistrationStatusEnum.COMPLETE]: 'success--text'
        };

        return this.registration_status in colours ? colours[`${this.registration_status}`] : 'grey--text';
    }

    // Profile Status

    get profile_status_icon() {
        const icons = {
            [ProfileStatusEnum.PENDING]: 'soon',
            [ProfileStatusEnum.COMPLETE]: 'complete'
        };

        return this.profile_status in icons ? icons[`${this.profile_status}`] : null;
    }

    get profile_status_text() {
        return this.profile_status ? i18n.t(`enums.profile_status.${this.profile_status}`) : null;
    }

    get profile_status_icon_colour() {
        const colours = {
            [ProfileStatusEnum.PENDING]: 'info',
            [ProfileStatusEnum.COMPLETE]: 'success'
        };

        return this.profile_status in colours ? colours[`${this.profile_status}`] : 'grey';
    }

    get profile_status_text_colour() {
        const colours = {
            [ProfileStatusEnum.PENDING]: 'info--text text--darken-1',
            [ProfileStatusEnum.COMPLETE]: 'success--text'
        };

        return this.profile_status in colours ? colours[`${this.profile_status}`] : 'grey--text';
    }

    // Application Status

    get application_status_icon() {
        const icons = {
            [ApplicationStatusEnum.AWAITING_ADVISER_SIGNATURE]: 'soon',
            [ApplicationStatusEnum.AWAITING_INVESTOR_SIGNATURE]: 'soon',
            [ApplicationStatusEnum.COMPLETE]: 'complete'
        };

        return this.application_status in icons ? icons[`${this.application_status}`] : null;
    }

    get application_status_text() {
        return this.application_status ? i18n.t(`enums.application_status.${this.application_status}`) : null;
    }

    get application_status_icon_colour() {
        const colours = {
            [ApplicationStatusEnum.AWAITING_ADVISER_SIGNATURE]: 'error',
            [ApplicationStatusEnum.AWAITING_INVESTOR_SIGNATURE]: 'warning',
            [ApplicationStatusEnum.COMPLETE]: 'success'
        };

        return this.application_status in colours ? colours[`${this.application_status}`] : 'grey';
    }

    get application_status_text_colour() {
        const colours = {
            [ApplicationStatusEnum.AWAITING_ADVISER_SIGNATURE]: 'error--text text-darken-1',
            [ApplicationStatusEnum.AWAITING_INVESTOR_SIGNATURE]: 'warning--text text-darken-1',
            [ApplicationStatusEnum.COMPLETE]: 'success--text'
        };

        return this.application_status in colours ? colours[`${this.application_status}`] : 'grey--text';
    }

    // Active Status

    get active_status_icon() {
        if (this.is_registering) {
            return this.registration_status_icon;
        } else if (this.is_onboarding) {
            return this.profile_status_icon;
        } else if (this.is_applying) {
            return this.application_status_icon;
        }

        return null;
    }

    get active_status_text() {
        if (this.is_registering) {
            return this.registration_status_text;
        } else if (this.is_onboarding) {
            return this.profile_status_text;
        } else if (this.is_applying) {
            return this.application_status_text;
        }

        return null;
    }

    get active_status_icon_colour() {
        if (this.is_registering) {
            return this.registration_status_icon_colour;
        } else if (this.is_onboarding) {
            return this.profile_status_icon_colour;
        } else if (this.is_applying) {
            return this.application_status_icon_colour;
        }

        return null;
    }

    get active_status_text_colour() {
        if (this.is_registering) {
            return this.registration_status_text_colour;
        } else if (this.is_onboarding) {
            return this.profile_status_text_colour;
        } else if (this.is_applying) {
            return this.application_status_text_colour;
        }

        return null;
    }
}

export default InvestorRegistration;
