<template>
    <node-view-wrapper :class="classList" as="span">
        <span class="tiptap-dynamic-value__wrap">
            {{ value || $t('dynamic_value') }}
            <tiptap-dynamic-value-options
                v-if="isGrowthInvest() && editor.options.editable"
                :options="options"
                :update-attributes="updateAttributes"
            />
        </span>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';
import types from '@/enums/dynamicValue';

export default {
    name: 'TiptapDynamicValue',
    components: {
        NodeViewWrapper,
        TiptapDynamicValueOptions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/lib/tiptap/components/TiptapDynamicValueOptions.vue'
            )
    },
    props: {
        ...nodeViewProps
    },
    data: () => ({
        value: null
    }),
    computed: {
        type() {
            return this.node.attrs.type;
        },
        typeObject() {
            if (this._.has(types, this.type)) {
                return types[`${this.type}`];
            }

            return null;
        },
        options() {
            return {
                type: this.type
            };
        },
        classList() {
            let classList = ['tiptap-dynamic-value'];

            if (this.editor.options.editable) {
                classList.push('tiptap-dynamic-value--editable');
            }

            return classList.join(' ');
        }
    },
    watch: {
        'node.attrs.type'(type) {
            if (this._.has(types, type)) {
                this.value = types[`${type}`].render();
            } else {
                this.value = null;
            }
        },
        'editor.options.editable'(editable) {
            if (!editable && this._.has(types, this.node.attrs.type)) {
                this.value = this.typeObject.render();
                this.updateAttributes({ value: this.value });
            }
        }
    },
    mounted() {
        if (this.editor.options.editable) {
            this.updateAttributes({ value: null });
            if (this._.has(types, this.node.attrs.type)) {
                this.value = this.typeObject.render();
            }
        } else {
            if (this.node.attrs.value) {
                this.value = this.node.attrs.value;
            } else {
                if (this._.has(types, this.node.attrs.type)) {
                    this.value = this.typeObject.render();
                }
                this.$nextTick(() => {
                    this.updateAttributes({ value: this.value });
                });
            }
        }
    }
};
</script>

<style lang="scss">
.tiptap-dynamic-value {
    display: inline-block;

    &__wrap {
        display: inline-block;
    }

    &--editable {
        vertical-align: middle;
        .tiptap-dynamic-value__wrap {
            vertical-align: middle;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 0.1rem 0.4rem;
            font-size: 0.9rem;
        }
    }
}
</style>
