import { BaseModel } from '@/models';

export class AppQuestionAnswer extends BaseModel {
    static entity = 'questionAnswers';

    static fields() {
        return {
            id: this.string(null).nullable(),
            text: this.attr(null),
            correct: this.boolean(false),
            value: this.boolean(false),
            disabled: this.boolean(false),
            has_other_text: this.boolean(false),
            other_text_label: this.string('Please specify'),
            other_text_value: this.string(null).nullable()
        };
    }

    get answer_text() {
        if (this.has_other_text && this.other_text_value) {
            return this.other_text_value;
        }

        return this.text;
    }

    get is_valid() {
        // Answer is valid because it is incorrect and the user has not selected it
        if (!this.correct && !this.value) {
            return true;
        }

        // Answer is valid because it is correct and has a value or an other value
        if (this.has_other_text) {
            return this.other_text_value && this.other_text_value.length > 0;
        }

        return !!this.value;
    }

    get is_correct() {
        return this.is_valid && this.correct;
    }

    get result() {
        return {
            answer_id: this.id,
            answer_text: this.answer_text
            // answer_correct: this.correct
        };
    }
}

export default AppQuestionAnswer;
