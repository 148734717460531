import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapExpansionPanel from '@/lib/tiptap/components/TiptapExpansionPanel.vue';

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'ExpansionPanel',
    content: 'block*',
    marks: '_',
    group: 'block',
    inline: false,
    defining: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapExpansionPanel);
    },

    addAttributes() {
        return {
            heading: { default: 'Expansion Panel' },
            type: { default: 'INFO' }
        };
    },

    addCommands() {
        return {
            setExpansionPanel:
                () =>
                ({ commands }) => {
                    return commands.wrapIn(this.name);
                },
            toggleExpansionPanel:
                () =>
                ({ commands }) => {
                    return commands.toggleWrap(this.name);
                },
            unsetExpansionPanel:
                () =>
                ({ commands }) => {
                    return commands.lift(this.name);
                }
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {
                default: {
                    class: 'tiptap-expansion-panel',
                    'data-heading': '',
                    'data-type': ''
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.tiptap-expansion-panel',
                getAttrs: node => ({
                    heading: node.getAttribute('data-heading'),
                    type: node.getAttribute('data-type')
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            {
                class: 'tiptap-expansion-panel',
                'data-heading': HTMLAttributes.heading,
                'data-type': HTMLAttributes.type
            },
            0
        ];
    }
});
