import clock from '@/lib/clock';

/**
 * Return the date now
 * @param {String|Object}
 */
const now = asString => {
    if (asString) {
        return clock().format('Do MMMM YYYY');
    }
    return clock();
};

export default now;
