import { ActivityCollection, Activity } from '@/models';
import i18n from '@/lib/i18n';

export class ActivityInvestorSecurityCollection extends ActivityCollection {
    static type = 'ActivityInvestorSecurityCollection';
    static endpoint = Activity.api.investorSecurity;

    get default_headers() {
        return ['created_at', 'activity', 'user_id', 'user_email', 'ip_address'];
    }

    get pdf_headers() {
        return ['created_at', 'title', 'text', 'user_id', 'user_email', 'ip_address'];
    }

    get headers() {
        return [
            this.createHeader('created_at', i18n.t('date'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('activity', i18n.t('activity'), {
                width: '35rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('title', i18n.t('activity'), {
                width: '12rem',
                filterable: {
                    component: () => import('@/components/fields/ActivityTitle'),
                    options: {
                        type: 'security'
                    }
                }
            }),
            this.createHeader('text', i18n.t('text'), {
                width: '38rem',
                filterable: true
            }),
            this.createHeader('user_id', i18n.t('user'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.user?.name
            }),
            this.createHeader('user_email', i18n.t('user_email'), {
                width: '14rem',
                transform: data => data.item?.user?.email,
                classList: ['text--secondary', 'text--small'],
                filterable: true
            }),
            this.createHeader('user.organisation_id', i18n.t('user_organisation'), {
                width: '14rem',
                transform: data => data.item?.user?.organisation?.name
            }),
            this.createHeader('ip_address', i18n.t('source_ip_address'), {
                width: '18rem',
                filterable: false
            })
        ];
    }
}

export default ActivityInvestorSecurityCollection;
