import BaseApi from '@/api/BaseApi';

export class ProductApi extends BaseApi {
    static resource = 'products';

    get endpoints() {
        return {
            ...super.endpoints,
            resyncPricing: this.resyncPricing.bind(this)
        };
    }

    _filterResponseData(data) {
        data = super._filterResponseData(data);

        if (Array.isArray(data)) {
            data = data.map(item => this._filterResponseData(item));
        } else if (Array.isArray(data.listings)) {
            data.listings = data.listings.map(listing => {
                listing.product_id = data.id;
                return listing;
            });
        }

        return data;
    }

    async resyncPricing(offerId) {
        const response = await BaseApi.api.post(`${this.base_url}/${offerId}/resync-pricing`);
        return this._respond(response, () => {
            this._updateItemInCollections(offerId, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }
}

export default ProductApi;
