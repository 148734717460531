import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';

const loadOrganisation = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        if (Auth().has_user) {
            const organisation = Auth().organisation;
            const organisations = Auth().user.organisations;
            const hostOrganisations = Auth().getHostOrganisations();

            if (organisations.length === 1) {
                Auth().organisation = organisations[0];
            } else if (hostOrganisations.length === 1) {
                Auth().organisation = hostOrganisations[0];
            } else if (hostOrganisations.length > 1) {
                if (organisation) {
                    Auth().organisation = hostOrganisations.find(org => org.id === organisation.id);
                } else {
                    // Show the switcher when the user has more than one host organisations
                    Auth().organisation = null;
                }
            } else if (organisations.length > 1 && hostOrganisations.length === 0) {
                // Show the switcher when the user has more than one organisation
                Auth().organisation = null;
            } else if (organisation && !organisations.find(org => org.id === organisation.id)) {
                // Show the switcher when the organisation is not in the list of user organisations
                Auth().organisation = null;
            }
        } else {
            Auth().user = null;
            Auth().organisation = null;
            Auth().account = null;
            Auth().investor = null;
            Auth().adviser = null;
        }

        next();
    }
};

export default loadOrganisation;
