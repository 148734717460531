import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

import TiptapDynamicValue from '@/lib/tiptap/components/TiptapDynamicValue.vue';

export const css = `
.tiptap-dynamic-value:before {
    content: attr(data-value);
}
`;

export default Node.create({
    // @see https://tiptap.dev/api/schema

    name: 'DynamicValue',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,

    addNodeView() {
        return VueNodeViewRenderer(TiptapDynamicValue);
    },

    addAttributes() {
        return {
            type: { default: null },
            value: { default: null }
        };
    },

    addCommands() {
        return {
            insertDynamicValue:
                attrs =>
                ({ commands }) => {
                    return commands.insertContent({ type: this.name, attrs });
                }
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {
                default: {
                    class: 'tiptap-dynamic-value',
                    'data-value': '',
                    'data-type': ''
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span.tiptap-dynamic-value',
                getAttrs: node => ({
                    value: node.getAttribute('data-value'),
                    type: node.getAttribute('data-type')
                })
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'span',
            {
                class: 'tiptap-dynamic-value',
                'data-value': HTMLAttributes.value,
                'data-type': HTMLAttributes.type
            }
        ];
    }
});
