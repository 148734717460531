const ProductTypeEnum = Object.freeze({
    EQUITY: 'EQUITY',
    CONVERTIBLELOAN: 'CONVERTIBLELOAN',
    WARRANT: 'WARRANT',
    LOAN: 'LOAN',
    OPTION: 'OPTION',
    UNIT: 'UNIT',
    HYBRID: 'HYBRID'
});

export default ProductTypeEnum;
