import { BaseModel } from '@/models';

export class MediaFile extends BaseModel {
    static entity = 'files';

    static fields() {
        return {
            id: this.attr(null),
            filename: this.string(null).nullable(),
            mimetype: this.string(null).nullable(),
            etag: this.string(null).nullable()
        };
    }
}

export default MediaFile;
