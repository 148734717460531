import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import { BankAccount } from '@/models';

export class BankAccountsValidator extends BaseValidator {
    static key = 'bankAccounts';

    static validator(value) {
        if (!value) {
            return true;
        }

        if (!Array.isArray(value)) {
            return 'Bank accounts must be a valid array';
        }

        const results = [];

        const requiredFields = ['account_name', 'account_number', 'sort_code'];

        for (const baIndex in value) {
            const ba = new BankAccount(value[`${baIndex}`]);
            const field = `bank_accounts[${baIndex}]`;

            for (const attr of requiredFields) {
                if (!ba[`${attr}`]) {
                    results.push({
                        field: `${field}.${attr}`,
                        result: i18n.t('validator.required_field', attr)
                    });
                }
            }
        }

        return results;
    }

    static arguments() {
        return [];
    }
}

export default BankAccountsValidator;
