export const mimeTypes = [
    'image/avif',
    'image/bmp',
    'image/gif',
    'image/vnd.microsoft.icon',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp'
];

export default file => {
    let type = file && typeof file === 'object' && 'type' in file ? file.type : file;
    return mimeTypes.includes(type);
};
